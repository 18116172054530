import React, { useEffect, useState } from 'react';
import {
  Box,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useToast,
  Flex,
  Text,
  Badge,
} from '@chakra-ui/react';
import apiClient from 'api/axios';
import { Global, css } from '@emotion/react';
import { format } from 'date-fns';

const editorStyles = {
  '.editor-input': {
    minHeight: '200px',
    padding: '10px',
    outline: 'none',
    width: '100%',
    cursor: 'text',
    fontSize: '16px',
    lineHeight: '1.5',
    '& h1': { fontSize: '2em', fontWeight: 'bold', margin: '0.67em 0' },
    '& h2': { fontSize: '1.5em', fontWeight: 'bold', margin: '0.83em 0' },
    '& h3': { fontSize: '1.17em', fontWeight: 'bold', margin: '1em 0' },
    '& strong': { fontWeight: 'bold' },
    '& em': { fontStyle: 'italic' },
    '& u': { textDecoration: 'underline' },
    '& ul': { listStyle: 'disc', marginLeft: '20px' },
    '& ol': { listStyle: 'decimal', marginLeft: '20px' },
  },
  '.editor-header-one': {
    fontSize: '2em',
    fontWeight: 'bold',
    margin: '0.67em 0',
  },
  '.editor-header-two': {
    fontSize: '1.5em',
    fontWeight: 'bold',
    margin: '0.83em 0',
  },
  '.editor-header-three': {
    fontSize: '1.17em',
    fontWeight: 'bold',
    margin: '1em 0',
  },
};

export default function CompanySubscriptions() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [customerSessionSecret, setCustomerSessionSecret] = useState(null);
  const [canCreateSubscription, setCanCreateSubscription] = useState(false);
  const [activeSubscriptionManager, setActiveSubscriptionManager] = useState(null);
  const toast = useToast();

  // Setup Stripe customer and get session
  const setupStripeCustomer = async () => {
    try {
      setIsLoading(true);
      const userUuid = localStorage.getItem('userUuid');
      const companyUuid = localStorage.getItem('companyUuid');
      const userEmail = localStorage.getItem('userEmail');

      console.log('Debug - User Info:', {
        userUuid,
        companyUuid,
        userEmail,
      });

      if (!userUuid || !companyUuid || !userEmail) {
        console.error('Missing user information:', {
          hasUserUuid: !!userUuid,
          hasCompanyUuid: !!companyUuid,
          hasUserEmail: !!userEmail,
        });

        // Try to refresh user information from the API
        try {
          const response = await apiClient.get('/user');
          const { user, company } = response.data;

          localStorage.setItem('userUuid', user.uuid);
          localStorage.setItem('userEmail', user.email);
          localStorage.setItem('companyUuid', company.uuid);

          // Retry setup with refreshed information
          return setupStripeCustomer();
        } catch (refreshError) {
          console.error('Error refreshing user information:', refreshError);
          throw new Error('Unable to retrieve user information');
        }
      }

      // Setup/update Stripe customer
      await apiClient.post('/setup-stripe-customer', {
        email: userEmail,
        userUuid,
        companyUuid,
      });

      try {
        // Get customer session for pricing table
        const sessionResponse = await apiClient.post('/create-customer-session');
        if (sessionResponse.data?.clientSecret) {
          setCustomerSessionSecret(sessionResponse.data.clientSecret);
        } else {
          console.warn('No client secret received from customer session');
          setCustomerSessionSecret(null);
        }
      } catch (sessionError) {
        console.error('Error creating customer session:', sessionError);
        setCustomerSessionSecret(null);
      }

      // Fetch updated subscriptions
      await fetchSubscriptions();
    } catch (error) {
      console.error('Error setting up customer:', error);
      toast({
        title: 'Error setting up subscription',
        description:
          error.response?.data?.error || error.message || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Load Stripe Pricing Table script
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);

    // Initial setup
    setupStripeCustomer();

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  // Fetch subscriptions
  const fetchSubscriptions = async () => {
    try {
      const response = await apiClient.get('/subscriptions');
      const subs = response.data.subscriptions || [];
      setSubscriptions(subs);

      // Set current active subscription
      const activeSub = subs.find(
        (sub) => sub.status === 'active' || sub.status === 'trialing',
      );
      setCurrentSubscription(activeSub);

      // Set management permissions
      setCanCreateSubscription(response.data.canCreateSubscription);
      setActiveSubscriptionManager(response.data.activeSubscriptionManager);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
      toast({
        title: 'Error fetching subscriptions',
        description: error.response?.data?.error || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Handle customer portal access
  const handleManageSubscription = async () => {
    try {
      const response = await apiClient.post('/create-portal-session', {
        returnUrl: window.location.href,
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error accessing customer portal:', error);
      toast({
        title: 'Error accessing subscription management',
        description: error.response?.data?.error || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Get status badge color
  const getStatusBadge = (status) => {
    const statusConfig = {
      active: { color: 'green', text: 'Active' },
      trialing: { color: 'blue', text: 'Trial' },
      past_due: { color: 'orange', text: 'Past Due' },
      canceled: { color: 'red', text: 'Canceled' },
      incomplete: { color: 'yellow', text: 'Incomplete' },
      incomplete_expired: { color: 'red', text: 'Expired' },
      unpaid: { color: 'red', text: 'Unpaid' },
    };

    const config = statusConfig[status] || { color: 'gray', text: status };
    return (
      <Badge colorScheme={config.color} variant="subtle">
        {config.text}
      </Badge>
    );
  };

  return (
    <>
      <Global styles={editorStyles} />
      <Box p={5} mt={{ sm: '150px', md: '125px', lg: '80px' }}>
        <SimpleGrid columns={{ sm: 1, xl: 1 }} spacing={5} mb={5}>
          <Tabs w="100%">
            <Flex justify="space-between" align="center">
              <TabList>
                <Tab>General</Tab>
                <Tab>History</Tab>
              </TabList>
              {currentSubscription?.can_manage && (
                <Button
                  colorScheme="blackAlpha"
                  bg="black"
                  color="white"
                  variant="solid"
                  onClick={handleManageSubscription}
                  isLoading={isLoading}
                >
                  Manage Subscription
                </Button>
              )}
            </Flex>
            <TabPanels>
              <TabPanel>
                {isLoading ? (
                  <Box textAlign="center" py={4}>
                    Loading subscription information...
                  </Box>
                ) : (
                  <>
                    {currentSubscription && (
                      <Box mb={4} p={4} borderRadius="md" borderWidth="1px">
                        <Text fontSize="lg" fontWeight="bold">
                          Current Plan
                        </Text>
                        {!currentSubscription.can_manage && activeSubscriptionManager && (
                          <Text color="gray.600" mt={2}>
                            {activeSubscriptionManager} is managing this subscription
                          </Text>
                        )}
                        <Flex alignItems="center" mt={2}>
                          <Text fontWeight="medium" mr={2}>
                            Status:
                          </Text>
                          {getStatusBadge(currentSubscription.status)}
                        </Flex>
                        <Text mt={2} fontSize="lg" color="gray.700">
                          {currentSubscription.product_name} -{' '}
                          {currentSubscription.price_amount}{' '}
                          {currentSubscription.currency?.toUpperCase()}/
                          {currentSubscription.billing_interval}
                        </Text>
                        <Text mt={2}>
                          Current Period:{' '}
                          {format(
                            new Date(currentSubscription.current_period_start),
                            'MMM dd, yyyy',
                          )}{' '}
                          -{' '}
                          {format(
                            new Date(currentSubscription.current_period_end),
                            'MMM dd, yyyy',
                          )}
                        </Text>
                        {currentSubscription.cancel_at_period_end && (
                          <Text color="red.500" mt={2}>
                            Your subscription will end on{' '}
                            {format(
                              new Date(currentSubscription.current_period_end),
                              'MMM dd, yyyy',
                            )}
                          </Text>
                        )}
                      </Box>
                    )}
                    {scriptLoaded && (
                      <Box mt={4}>
                        <Text mb={4} fontSize="lg" fontWeight="medium">
                          {currentSubscription
                            ? 'Change Plan'
                            : 'Choose a Plan'}
                        </Text>
                        {currentSubscription ? (
                          currentSubscription.can_manage ? (
                            <Flex direction="column" align="center" gap={4}>
                              <Text>
                                To change your subscription plan, manage your
                                billing, or update payment methods, please use the
                                Customer Portal.
                              </Text>
                              <Button
                                colorScheme="blue"
                                onClick={handleManageSubscription}
                                isLoading={isLoading}
                              >
                                Open Customer Portal
                              </Button>
                            </Flex>
                          ) : (
                            <Text textAlign="center" color="gray.600">
                              Only {activeSubscriptionManager} can manage this subscription
                            </Text>
                          )
                        ) : canCreateSubscription ? (
                          <div
                            style={{
                              borderRadius: '16px',
                              overflow: 'hidden',
                            }}
                          >
                            <stripe-pricing-table
                              pricing-table-id="prctbl_1QPjjXLJ4wJXMuTJav6aed2C"
                              publishable-key="pk_test_51Pqs65LJ4wJXMuTJO5aSQknYIHeNS4swumr5NYiqhw33rnSun1rCjvHoGpafvzDgTSFAD30IC6r5uiesqNWxmchP00TPdWl15A"
                              client-reference-id={localStorage.getItem('companyUuid')}
                              {...(customerSessionSecret
                                ? { 'customer-session-client-secret': customerSessionSecret }
                                : { 'customer-email': localStorage.getItem('userEmail') }
                              )}
                              return-url={`${window.location.origin}/subscription/success`}
                              onError={(error) => {
                                console.error('Stripe pricing table error:', error);
                                toast({
                                  title: 'Error loading pricing table',
                                  description: error.message || 'An error occurred',
                                  status: 'error',
                                  duration: 5000,
                                  isClosable: true,
                                });
                              }}
                            />
                          </div>
                        ) : (
                          <Text textAlign="center" color="gray.600">
                            {activeSubscriptionManager} is currently managing the active subscription
                          </Text>
                        )}
                      </Box>
                    )}
                  </>
                )}
              </TabPanel>
              <TabPanel>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Plan</Th>
                      <Th>Status</Th>
                      <Th>Price</Th>
                      <Th>Start Date</Th>
                      <Th>End Date</Th>
                      <Th>Manager</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {isLoading ? (
                      <Tr>
                        <Td colSpan={6} textAlign="center">
                          Loading subscriptions...
                        </Td>
                      </Tr>
                    ) : subscriptions.length > 0 ? (
                      subscriptions.map((sub) => (
                        <Tr key={sub.stripe_subscription_id}>
                          <Td>{sub.product_name}</Td>
                          <Td>{getStatusBadge(sub.status)}</Td>
                          <Td>
                            {sub.price_amount} {sub.currency?.toUpperCase()}/
                            {sub.billing_interval}
                          </Td>
                          <Td>
                            {format(new Date(sub.start_date), 'MM/dd/yyyy')}
                          </Td>
                          <Td>
                            {sub.end_date
                              ? format(new Date(sub.end_date), 'MM/dd/yyyy')
                              : 'Ongoing'}
                          </Td>
                          <Td>{sub.subscription_manager_name}</Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr>
                        <Td colSpan={6} textAlign="center">
                          No subscriptions found.
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </SimpleGrid>
      </Box>
    </>
  );
}
