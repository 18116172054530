import { SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import React from 'react';
import Information from 'views/admin/main/profile/overview/components/Information';

export default function GeneralInformation({ userData = {}, ...rest }) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';

  return (
    <Card mb={{ base: '0px', '2xl': '20px' }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        General Information
      </Text>
      <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
        <Information title="Phone Number" value="+372 5621 8861" />
        <Information title="Organization" value="Simmmple Web LLC" />
        <Information title="Birthday" value="20 July 1986" />

        {/* Default Wallet Information */}
        {/* <Box>
          <Heading size="sm" mb="2">
            Default Wallet
          </Heading>
          <VStack align="start" spacing={1}>
            <Box display="flex" alignItems="center">
              <Text>
                <strong>Address:</strong> {default_wallet.wallet_address}
              </Text>
              <Tooltip label="Copy Address">
                <Button
                  size="xs"
                  ml={2}
                  onClick={() => copyToClipboard(default_wallet.wallet_address)}
                >
                  <CopyIcon />
                </Button>
              </Tooltip>
            </Box>
            <Text>
              <strong>Name:</strong> {default_wallet.wallet_name}
            </Text>
            <Text>
              <strong>Provider:</strong> {default_wallet.wallet_provider}
            </Text>
          </VStack>
        </Box>
 */}
        {/* Connected Wallets Information */}
        {/* <Box>
          <Heading size="sm" mb="2">
            Connected Wallets
          </Heading>
          {connected_wallets.length > 0 ? (
            connected_wallets.map((wallet) => (
              <Box key={wallet.wallet_address} mb="4">
                <VStack align="start" spacing={1}>
                  <Box display="flex" alignItems="center">
                    <Text>
                      <strong>Address:</strong> {wallet.wallet_address}
                    </Text>
                    <Tooltip label="Copy Address">
                      <Button
                        size="xs"
                        ml={2}
                        onClick={() => copyToClipboard(wallet.wallet_address)}
                      >
                        <CopyIcon />
                      </Button>
                    </Tooltip>
                  </Box>
                  <Text>
                    <strong>Name:</strong> {wallet.wallet_name}
                  </Text>
                  <Text>
                    <strong>Provider:</strong> {wallet.wallet_provider}
                  </Text>
                  <Text>
                    <strong>Primary:</strong> {wallet.is_primary ? 'Yes' : 'No'}
                  </Text>
                  <Text>
                    <strong>Verified:</strong>{' '}
                    {wallet.is_verified ? 'Yes' : 'No'}
                  </Text>
                </VStack>
                <Divider mt="2" />
              </Box>
            ))
          ) : (
            <Text>No connected wallets.</Text>
          )}
        </Box> */}
      </SimpleGrid>
    </Card>
  );
}
