import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
  Box,
  Tooltip,
  HStack,
  Badge,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerFooter,
  FormControl,
  FormLabel,
  Switch,
  Divider,
  VStack,
  CheckboxGroup,
  Checkbox,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  MdChevronRight,
  MdChevronLeft,
  MdPerson,
  MdCardMembership,
  MdArchive,
  MdDone,
  MdPause,
  MdCheckCircle,
} from 'react-icons/md';
import { FiFilter } from 'react-icons/fi';
import { getLoyaltyLevels } from 'api/loyalty';
import { getSoldMemberships } from 'api/product';
import DebouncedInput from 'components/DebouncedInput';
import { createPages } from 'utils/helpers';
import { MdEdit, MdVisibility } from 'react-icons/md';
import { AuthContext } from 'contexts/AuthContext';

// LoyaltyLevelFilters component
const LoyaltyLevelFilters = ({
  isOpen,
  onClose,
  filterOptions,
  setFilterOptions,
  applyFilters,
}) => {
  const [tempFilters, setTempFilters] = useState(filterOptions);

  const handleStatusChange = (values) => {
    setTempFilters((prev) => ({
      ...prev,
      statuses: values,
    }));
  };

  const resetFilters = () => {
    const defaultFilters = {
      statuses: ['active', 'inactive', 'soldOut', 'archived'],
    };
    setTempFilters(defaultFilters);
  };

  const handleApply = () => {
    setFilterOptions(tempFilters);
    applyFilters(tempFilters);
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Filter Loyalty Levels
        </DrawerHeader>
        <DrawerBody>
          <VStack spacing={5} align="stretch">
            <Box>
              <Text fontWeight="medium" mb={3}>
                Status
              </Text>
              <CheckboxGroup
                value={tempFilters.statuses}
                onChange={handleStatusChange}
                colorScheme="blue"
              >
                <VStack align="start" spacing={2}>
                  <Checkbox value="active">
                    <Flex align="center">
                      <Badge colorScheme="green" mr={2} px={2} py={0.5}>
                        <Icon as={MdCheckCircle} mr={1} w="12px" h="12px" />
                        Active
                      </Badge>
                      <Text fontSize="sm">Show active levels</Text>
                    </Flex>
                  </Checkbox>
                  <Checkbox value="inactive">
                    <Flex align="center">
                      <Badge colorScheme="orange" mr={2} px={2} py={0.5}>
                        <Icon as={MdPause} mr={1} w="12px" h="12px" />
                        Inactive
                      </Badge>
                      <Text fontSize="sm">Show inactive levels</Text>
                    </Flex>
                  </Checkbox>
                  <Checkbox value="soldOut">
                    <Flex align="center">
                      <Badge colorScheme="red" mr={2} px={2} py={0.5}>
                        <Icon as={MdDone} mr={1} w="12px" h="12px" />
                        Sold Out
                      </Badge>
                      <Text fontSize="sm">Show sold out levels</Text>
                    </Flex>
                  </Checkbox>
                  <Checkbox value="archived">
                    <Flex align="center">
                      <Badge colorScheme="gray" mr={2} px={2} py={0.5}>
                        <Icon as={MdArchive} mr={1} w="12px" h="12px" />
                        Archived
                      </Badge>
                      <Text fontSize="sm">Show archived levels</Text>
                    </Flex>
                  </Checkbox>
                </VStack>
              </CheckboxGroup>
            </Box>

            <Divider />

            <Box>
              <Text fontWeight="medium" mb={3}>
                Sort By
              </Text>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="revenue-sort" mb="0" fontSize="sm">
                  Sort by Revenue (High to Low)
                </FormLabel>
                <Switch
                  id="revenue-sort"
                  isChecked={tempFilters.sortByRevenue}
                  onChange={(e) =>
                    setTempFilters((prev) => ({
                      ...prev,
                      sortByRevenue: e.target.checked,
                    }))
                  }
                />
              </FormControl>
            </Box>
          </VStack>
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button
            variant="outline"
            mr={3}
            onClick={resetFilters}
            colorScheme="red"
          >
            Reset
          </Button>
          <Button colorScheme="blue" onClick={handleApply}>
            Apply Filters
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default function LoyaltyLevelsOverview() {
  const [loyaltyLevels, setLoyaltyLevels] = useState([]);
  const [soldMemberships, setSoldMemberships] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [filterOptions, setFilterOptions] = useState({
    statuses: ['active', 'inactive', 'soldOut', 'archived'],
    sortByRevenue: false,
  });
  const [filteredLevels, setFilteredLevels] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { userRole } = useContext(AuthContext);
  const isViewer = userRole === 'VIEWER';

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch both loyalty levels and sold memberships
        const [allLoyaltyLevels, allSoldMemberships] = await Promise.all([
          getLoyaltyLevels(),
          getSoldMemberships(),
        ]);

        setSoldMemberships(allSoldMemberships);

        setLoyaltyLevels(
          allLoyaltyLevels.map((loyaltyLevel) => ({
            uuid: loyaltyLevel.uuid,
            loyalty_program_uuid: loyaltyLevel.loyalty_program_uuid,
            translations: loyaltyLevel.translations,
            name: loyaltyLevel.name,
            created_at: loyaltyLevel.created_at,
            price: loyaltyLevel.price,
            sold: loyaltyLevel.sold_quantity,
            total: loyaltyLevel.quantity + loyaltyLevel.sold_quantity,
            is_finite_quantity: loyaltyLevel.is_finite_quantity,
            is_archived: loyaltyLevel.is_archived,
            is_active: loyaltyLevel.is_active,
          })),
        );
      } catch (error) {
        console.error('Error fetching loyalty data:', error);
      }
    };
    fetchData();
  }, []);

  const calculateRevenueForLevel = useCallback(
    (levelUuid) => {
      return soldMemberships
        .filter((membership) => membership.loyalty_level_uuid === levelUuid)
        .reduce(
          (total, membership) => total + (membership.purchase_price || 0),
          0,
        );
    },
    [soldMemberships],
  );

  // Apply filters whenever loyalty levels or filter options change
  useEffect(() => {
    let result = [...loyaltyLevels];

    // Filter by status
    if (filterOptions.statuses.length < 4) {
      result = result.filter((level) => {
        const status = getRowStatus(level);
        return filterOptions.statuses.includes(status);
      });
    }

    // Sort by revenue if enabled
    if (filterOptions.sortByRevenue) {
      result.sort((a, b) => {
        const revenueA = calculateRevenueForLevel(a.uuid);
        const revenueB = calculateRevenueForLevel(b.uuid);
        return revenueB - revenueA; // Sort high to low
      });
    }

    setFilteredLevels(result);
  }, [loyaltyLevels, filterOptions, calculateRevenueForLevel]);

  const isSoldOutButActive = (level) => {
    return level.is_finite_quantity && level.sold >= level.total;
  };

  const getRowStatus = (level) => {
    if (level.is_archived) {
      return 'archived';
    } else if (level.is_finite_quantity && level.sold >= level.total) {
      return 'soldOut';
    } else if (level.is_active === false) {
      return 'inactive';
    }
    return 'active';
  };

  const applyFilters = (newFilters) => {
    setFilterOptions(newFilters);
  };

  // Calculate active filters count
  const activeFiltersCount =
    (filterOptions.statuses.length < 4 ? 1 : 0) +
    (filterOptions.sortByRevenue ? 1 : 0);

  // Add a function to get status priority (higher number = higher priority)
  const getStatusPriority = (level) => {
    const status = getRowStatus(level);

    // Priority order: soldOut (4), active (3), inactive (2), archived (1)
    switch (status) {
      case 'soldOut':
        return 4;
      case 'active':
        return 3;
      case 'inactive':
        return 2;
      case 'archived':
        return 1;
      default:
        return 0;
    }
  };

  const [sorting] = useState([
    {
      id: 'statusPriority',
      desc: true,
    },
  ]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => getStatusPriority(row), {
      id: 'statusPriority',
      header: () => null,
      cell: () => null,
      enableHiding: true,
    }),
    columnHelper.accessor((row) => isSoldOutButActive(row), {
      id: 'soldOutPriority',
      header: () => null,
      cell: () => null,
      enableHiding: true,
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="flex-start"
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="left"
        >
          Name
        </Text>
      ),
      cell: (info) => {
        const translations = info.row.original.translations;

        const enName = translations?.find(
          (t) => t.language === 'en' && t.key === 'name',
        )?.value;
        return (
          <Text
            color={
              getRowStatus(info.row.original) === 'soldOut'
                ? 'red.500'
                : getRowStatus(info.row.original) === 'archived'
                  ? 'gray.400'
                  : getRowStatus(info.row.original) === 'inactive'
                    ? 'orange.500'
                    : textColor
            }
            fontSize="md"
            fontWeight="500"
            textAlign="left"
          >
            {enName}
          </Text>
        );
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Status
        </Text>
      ),
      cell: (info) => {
        const status = getRowStatus(info.row.original);

        const statusConfig = {
          archived: {
            label: 'Archived',
            colorScheme: 'gray',
            icon: MdArchive,
          },
          soldOut: {
            label: 'Sold Out',
            colorScheme: 'red',
            icon: MdDone,
          },
          inactive: {
            label: 'Inactive',
            colorScheme: 'orange',
            icon: MdPause,
          },
          active: {
            label: 'Active',
            colorScheme: 'green',
            icon: MdCheckCircle,
          },
        };

        const config = statusConfig[status];

        return (
          <Badge
            colorScheme={config.colorScheme}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
            px={2}
            py={1}
          >
            <Icon as={config.icon} mr={1} w="12px" h="12px" />
            {config.label}
          </Badge>
        );
      },
    }),
    columnHelper.accessor('price', {
      id: 'price',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Price
        </Text>
      ),
      cell: (info) => {
        const price = info.row.original.price || 0;
        const currency = 'EUR';
        const formattedPrice = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 2,
        }).format(price);
        return (
          <Text
            color={
              getRowStatus(info.row.original) === 'soldOut'
                ? 'red.500'
                : getRowStatus(info.row.original) === 'archived'
                  ? 'gray.400'
                  : getRowStatus(info.row.original) === 'inactive'
                    ? 'orange.500'
                    : textColor
            }
            fontSize="md"
            fontWeight="500"
          >
            {formattedPrice}
          </Text>
        );
      },
    }),

    columnHelper.accessor('stock', {
      id: 'stock',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Sold/Total
        </Text>
      ),
      cell: (info) => {
        const soldCount = info.row.original.sold || 0;
        const totalDisplay = info.row.original.is_finite_quantity
          ? info.row.original.total
          : 'Infinite';

        return (
          <Text
            color={
              getRowStatus(info.row.original) === 'soldOut'
                ? 'red.500'
                : getRowStatus(info.row.original) === 'archived'
                  ? 'gray.400'
                  : getRowStatus(info.row.original) === 'inactive'
                    ? 'orange.500'
                    : textColor
            }
            fontSize="md"
            fontWeight="500"
          >
            {soldCount}/{totalDisplay}
          </Text>
        );
      },
    }),
    columnHelper.accessor('revenue', {
      id: 'revenue',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Revenue
        </Text>
      ),
      cell: (info) => {
        const levelUuid = info.row.original.uuid;
        const revenue = calculateRevenueForLevel(levelUuid);
        const currency = 'EUR';
        const formattedRevenue = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 2,
        }).format(revenue);
        return (
          <Text
            color={
              getRowStatus(info.row.original) === 'soldOut'
                ? 'red.500'
                : getRowStatus(info.row.original) === 'archived'
                  ? 'gray.400'
                  : getRowStatus(info.row.original) === 'inactive'
                    ? 'orange.500'
                    : textColor
            }
            fontSize="md"
            fontWeight="500"
          >
            {formattedRevenue}
          </Text>
        );
      },
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Date Created
        </Text>
      ),
      cell: (info) => (
        <Text
          color={
            getRowStatus(info.row.original) === 'soldOut'
              ? 'red.500'
              : getRowStatus(info.row.original) === 'archived'
                ? 'gray.400'
                : getRowStatus(info.row.original) === 'inactive'
                  ? 'orange.500'
                  : textColor
          }
          fontSize="md"
          fontWeight="500"
        >
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
          width="100%"
        >
          {isViewer ? 'VIEW' : 'ACTIONS'}
        </Text>
      ),
      cell: (info) => {
        const levelUrl = `/admin/loyalty-programs/${info.row.original.loyalty_program_uuid}/levels/${info.row.original.uuid}`;
        const programUrl = `/admin/loyalty-programs/${info.row.original.loyalty_program_uuid}`;
        const membershipsUrl = `/admin/loyalty-memberships?level=${info.row.original.uuid}`;

        return (
          <Flex align="center" justify="center" width="100%">
            <HStack spacing={3} justify="center">
              <Tooltip label={isViewer ? 'View Details' : 'Edit Level'}>
                <Box
                  as="a"
                  href={levelUrl}
                  onClick={(e) => {
                    if (e.button === 0) {
                      e.preventDefault();
                      navigate(levelUrl);
                    }
                  }}
                  display="inline-block"
                >
                  <Icon
                    w="20px"
                    h="20px"
                    as={isViewer ? MdVisibility : MdEdit}
                    cursor="pointer"
                    color={brandColor}
                  />
                </Box>
              </Tooltip>

              <Tooltip label="View Memberships">
                <Box
                  as="a"
                  href={membershipsUrl}
                  onClick={(e) => {
                    if (e.button === 0) {
                      e.preventDefault();
                      navigate(membershipsUrl);
                    }
                  }}
                  display="inline-block"
                >
                  <Icon
                    w="20px"
                    h="20px"
                    as={MdPerson}
                    cursor="pointer"
                    color={brandColor}
                  />
                </Box>
              </Tooltip>

              <Tooltip label="View Program">
                <Box
                  as="a"
                  href={programUrl}
                  onClick={(e) => {
                    if (e.button === 0) {
                      e.preventDefault();
                      navigate(programUrl);
                    }
                  }}
                  display="inline-block"
                >
                  <Icon
                    w="20px"
                    h="20px"
                    as={MdCardMembership}
                    cursor="pointer"
                    color={brandColor}
                  />
                </Box>
              </Tooltip>
            </HStack>
          </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: filteredLevels,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Flex direction="column" mt={{ sm: '150px', md: '125px', lg: '75px' }}>
      <Card
        w="100%"
        px={{ base: '16px', md: '24px' }}
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify="space-between"
          mb="36px"
          mt="24px"
        >
          <DebouncedInput
            placeholder="Search levels..."
            onChange={(value) => setGlobalFilter(value)}
          />

          <Button
            leftIcon={<FiFilter />}
            colorScheme={activeFiltersCount > 0 ? 'blue' : 'gray'}
            variant={activeFiltersCount > 0 ? 'solid' : 'outline'}
            onClick={onOpen}
          >
            Filter
            {activeFiltersCount > 0 && (
              <Badge ml={2} colorScheme="blue" borderRadius="full">
                {activeFiltersCount}
              </Badge>
            )}
          </Button>
        </Flex>

        <Table variant="simple" color="gray.500" layout="fixed" width="100%">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    borderColor={borderColor}
                    key={header.id}
                    colSpan={header.colSpan}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    width={
                      header.id === 'name'
                        ? '25%'
                        : header.id === 'statusPriority' ||
                            header.id === 'soldOutPriority'
                          ? '0px'
                          : 'auto'
                    }
                    px={header.id === 'name' ? '8px' : '16px'}
                  >
                    {header.isPlaceholder ? null : (
                      <Flex
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        justify={
                          header.id === 'name' ? 'flex-start' : 'space-between'
                        }
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                px="20px"
                key={row.id}
                bg={
                  getRowStatus(row.original) === 'soldOut'
                    ? 'red.50'
                    : getRowStatus(row.original) === 'archived'
                      ? 'gray.50'
                      : getRowStatus(row.original) === 'inactive'
                        ? 'orange.50'
                        : undefined
                }
                _dark={
                  getRowStatus(row.original) === 'soldOut'
                    ? 'rgba(254, 178, 178, 0.12)'
                    : getRowStatus(row.original) === 'archived'
                      ? 'rgba(255, 255, 255, 0.06)'
                      : getRowStatus(row.original) === 'inactive'
                        ? 'rgba(251, 211, 141, 0.12)'
                        : undefined
                }
                borderLeftWidth="3px"
                borderLeftStyle="solid"
                borderLeftColor={
                  getRowStatus(row.original) === 'soldOut'
                    ? 'red.500'
                    : getRowStatus(row.original) === 'archived'
                      ? 'gray.400'
                      : getRowStatus(row.original) === 'inactive'
                        ? 'orange.400'
                        : 'transparent'
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize={{ sm: '14px' }}
                    minW={cell.column.id === 'name' ? '200px' : 'auto'}
                    maxW={cell.column.id === 'name' ? '300px' : 'auto'}
                    borderColor={borderColor}
                    align={cell.column.id === 'name' ? 'left' : 'center'}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    px={cell.column.id === 'name' ? '8px' : '16px'}
                  >
                    <Tooltip
                      label={
                        <>
                          <Text fontWeight="bold">{cell.getValue() || ''}</Text>
                          {cell.column.id !== 'status' && (
                            <Text fontSize="xs" mt={1}>
                              Status:{' '}
                              {getRowStatus(row.original) === 'soldOut'
                                ? 'Sold Out'
                                : getRowStatus(row.original) === 'archived'
                                  ? 'Archived'
                                  : getRowStatus(row.original) === 'inactive'
                                    ? 'Inactive'
                                    : 'Active'}
                            </Text>
                          )}
                        </>
                      }
                      aria-label="Full text"
                      borderRadius="md"
                      bg="gray.600"
                      placement="top"
                    >
                      <Box>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Box>
                    </Tooltip>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Flex
          w="100%"
          justify="space-between"
          align="center"
          px="20px"
          py="24px"
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            mb={{ sm: '24px', md: '0px' }}
          >
            Showing {pagination.pageSize * pagination.pageIndex + 1} to{' '}
            {pagination.pageSize * (pagination.pageIndex + 1) <=
            loyaltyLevels.length
              ? pagination.pageSize * (pagination.pageIndex + 1)
              : loyaltyLevels.length}{' '}
            of {loyaltyLevels.length} entries
          </Text>
          <div className="flex items-center gap-2">
            <Stack direction="row" spacing="4px" ms="auto">
              <Button
                variant="no-effects"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="50%"
                bg="transparent"
                border="1px solid"
                borderColor={useColorModeValue('gray.200', 'white')}
                display={
                  pagination.pageSize === 5
                    ? 'none'
                    : table.getCanPreviousPage()
                      ? 'flex'
                      : 'none'
                }
                _hover={{
                  bg: 'whiteAlpha.100',
                  opacity: '0.7',
                }}
              >
                <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
              </Button>
              {createPages(table.getPageCount()).map((pageNumber, index) => (
                <Button
                  variant="no-effects"
                  transition="all .5s ease"
                  onClick={() => table.setPageIndex(pageNumber - 1)}
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                  bg={
                    pageNumber === pagination.pageIndex + 1
                      ? brandColor
                      : 'transparent'
                  }
                  border={
                    pageNumber === pagination.pageIndex + 1
                      ? 'none'
                      : '1px solid lightgray'
                  }
                  _hover={
                    pageNumber === pagination.pageIndex + 1
                      ? {
                          opacity: '0.7',
                        }
                      : {
                          bg: 'whiteAlpha.100',
                        }
                  }
                  key={index}
                >
                  <Text
                    fontSize="sm"
                    color={
                      pageNumber === pagination.pageIndex + 1
                        ? '#fff'
                        : textColor
                    }
                  >
                    {pageNumber}
                  </Text>
                </Button>
              ))}
              <Button
                variant="no-effects"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="50%"
                bg="transparent"
                border="1px solid"
                borderColor={useColorModeValue('gray.200', 'white')}
                display={
                  pagination.pageSize === 5
                    ? 'none'
                    : table.getCanNextPage()
                      ? 'flex'
                      : 'none'
                }
                _hover={{
                  bg: 'whiteAlpha.100',
                  opacity: '0.7',
                }}
              >
                <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
              </Button>
            </Stack>
          </div>
        </Flex>

        <Box
          mb={4}
          p={3}
          borderWidth="1px"
          borderRadius="md"
          borderColor={borderColor}
        >
          <Text fontSize="sm" fontWeight="medium" mb={2}>
            Status Legend:
          </Text>
          <Flex wrap="wrap" gap={3}>
            <Badge
              colorScheme="green"
              display="flex"
              alignItems="center"
              px={2}
              py={1}
            >
              <Icon as={MdCheckCircle} mr={1} w="12px" h="12px" />
              Active
            </Badge>
            <Badge
              colorScheme="orange"
              display="flex"
              alignItems="center"
              px={2}
              py={1}
            >
              <Icon as={MdPause} mr={1} w="12px" h="12px" />
              Inactive
            </Badge>
            <Badge
              colorScheme="red"
              display="flex"
              alignItems="center"
              px={2}
              py={1}
            >
              <Icon as={MdDone} mr={1} w="12px" h="12px" />
              Sold Out
            </Badge>
            <Badge
              colorScheme="gray"
              display="flex"
              alignItems="center"
              px={2}
              py={1}
            >
              <Icon as={MdArchive} mr={1} w="12px" h="12px" />
              Archived
            </Badge>
          </Flex>
        </Box>
      </Card>

      {/* Render the filter drawer */}
      <LoyaltyLevelFilters
        isOpen={isOpen}
        onClose={onClose}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        applyFilters={applyFilters}
      />
    </Flex>
  );
}
