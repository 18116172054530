import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Button,
  useToast,
  Text,
  Badge,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Icon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Container,
  HStack,
  VStack,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import {
  MdArrowBack,
  MdComment,
  MdOpenInNew,
  MdCardMembership,
  MdPerson,
  MdShoppingCart,
  MdStars,
  MdVerified,
} from 'react-icons/md';
import React, { useState, useEffect } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import { getSoldMembershipDetails } from '../../api/product';
import { format } from 'date-fns';
import { createIcon } from '@chakra-ui/react';

// Create Polygon icon for blockchain interactions
export const PolygonIcon = createIcon({
  displayName: 'PolygonIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <title>Polygon</title>
      <path
        d="m17.82 16.342 5.692-3.287A.98.98 0 0 0 24 12.21V5.635a.98.98 0 0 0-.488-.846l-5.693-3.286a.98.98 0 0 0-.977 0L11.15 4.789a.98.98 0 0 0-.489.846v11.747L6.67 19.686l-3.992-2.304v-4.61l3.992-2.304 2.633 1.52V8.896L7.158 7.658a.98.98 0 0 0-.977 0L.488 10.945a.98.98 0 0 0-.488.846v6.573a.98.98 0 0 0 .488.847l5.693 3.286a.981.981 0 0 0 .977 0l5.692-3.286a.98.98 0 0 0 .489-.846V6.618l.072-.041 3.92-2.263 3.99 2.305v4.609l-3.99 2.304-2.63-1.517v3.092l2.14 1.236a.981.981 0 0 0 .978 0v-.001Z"
        fill="currentColor"
      />
    </svg>
  ),
});

export default function MembershipDetail() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [membershipDetails, setMembershipDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch membership details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const membership = await getSoldMembershipDetails(uuid);
        setMembershipDetails(membership);
      } catch (error) {
        console.error('Error fetching membership details:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch membership details.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [uuid, toast]);

  const isExpired = (expirationDate) => {
    return new Date(expirationDate) < new Date();
  };

  const truncateAddress = (address) => {
    if (!address) return '';
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  };

  const shortenHash = (hash) => {
    if (!hash) return '';
    return `${hash.substring(0, 10)}...${hash.substring(hash.length - 6)}`;
  };

  // Loading state
  if (loading) {
    return (
      <Flex
        justify="center"
        align="center"
        h="100vh"
        pt={{ base: '130px', md: '80px', xl: '80px' }}
      >
        <Spinner size="xl" thickness="4px" color="blue.500" />
      </Flex>
    );
  }

  // Error state
  if (!membershipDetails) {
    return (
      <Container centerContent pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Card p={8} textAlign="center" w="100%">
          <Icon
            as={MdComment}
            boxSize="50px"
            color="gray.300"
            mx="auto"
            mb={4}
          />
          <Heading size="md" mb={3}>
            No Membership Found
          </Heading>
          <Text color="gray.500">
            The membership details could not be found.
          </Text>
          <Button
            mt={6}
            colorScheme="blue"
            onClick={() => navigate('/admin/loyalty-memberships')}
          >
            Go To Memberships
          </Button>
        </Card>
      </Container>
    );
  }

  const membershipExpired = isExpired(membershipDetails.expiration_date);
  const membershipStatus = membershipExpired ? 'Expired' : 'Active';
  const statusColor = membershipExpired ? 'red' : 'green';

  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      px={{ base: 4, md: 8 }}
      maxW="1600px"
      mx="auto"
    >
      {/* Header with back button */}
      <HStack spacing={3} mb={6}>
        <IconButton
          icon={<MdArrowBack />}
          variant="outline"
          rounded="full"
          onClick={() => navigate('/admin/loyalty-memberships')}
          aria-label="Back to memberships"
        />
        <Heading size="lg">Membership Details</Heading>
        <Badge
          colorScheme={statusColor}
          variant="solid"
          fontSize="md"
          px={3}
          py={1}
          borderRadius="full"
          ml={2}
        >
          {membershipStatus}
        </Badge>
      </HStack>

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} mb={8}>
        {/* Membership Information Card */}
        <Card
          variant="outline"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          overflow="hidden"
          height="fit-content"
        >
          <CardHeader bg="blue.50" py={4}>
            <Heading size="md">Membership Information</Heading>
          </CardHeader>
          <CardBody px={6} pt={4} pb={5}>
            {/* Two-column grid layout for basic details */}
            <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4}>
              {/* Level Name */}
              <Box>
                <Text color="gray.500" fontSize="sm" mb={1}>
                  Membership Level
                </Text>
                <HStack>
                  <Icon as={MdStars} color="gold" />
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    noOfLines={2}
                    title={membershipDetails.loyalty_level_name}
                  >
                    {membershipDetails.loyalty_level_name}
                  </Text>
                </HStack>
              </Box>

              {/* Token ID */}
              <Box>
                <Text color="gray.500" fontSize="sm" mb={1}>
                  Token ID
                </Text>
                <HStack>
                  <Icon as={PolygonIcon} color="purple.500" />
                  <Text fontSize="md" fontWeight="bold">
                    #{membershipDetails.tokenId}
                  </Text>
                </HStack>
              </Box>
            </SimpleGrid>

            <Divider my={3} borderColor="gray.200" />

            {/* Dates */}
            <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4}>
              {/* Purchase Date */}
              <Box>
                <Text color="gray.500" fontSize="sm" mb={1}>
                  Purchase Date
                </Text>
                <Text fontSize="md">
                  {format(
                    new Date(membershipDetails.purchase_date),
                    'MMM dd, yyyy, h:mm a',
                  )}
                </Text>
              </Box>

              {/* Expiration Date */}
              <Box>
                <Text color="gray.500" fontSize="sm" mb={1}>
                  Expiration Date
                </Text>
                <HStack spacing={2}>
                  <Text
                    fontSize="md"
                    color={membershipExpired ? 'red.500' : undefined}
                    textDecoration={membershipExpired ? 'line-through' : 'none'}
                  >
                    {format(
                      new Date(membershipDetails.expiration_date),
                      'MMM dd, yyyy, h:mm a',
                    )}
                  </Text>
                  {membershipExpired && (
                    <Badge colorScheme="red" fontSize="xs">
                      Expired
                    </Badge>
                  )}
                </HStack>
              </Box>
            </SimpleGrid>

            <Divider my={3} borderColor="gray.200" />

            {/* Ownership Information */}
            <Box>
              <Text color="gray.500" fontSize="sm" mb={1}>
                Owned By
              </Text>
              <VStack spacing={1} align="flex-start">
                <Text fontSize="lg" fontWeight="bold">
                  {membershipDetails.user?.name ||
                    membershipDetails.user?.email ||
                    'Unknown User'}
                </Text>
                {membershipDetails.user?.email && (
                  <Text fontSize="sm" color="gray.600">
                    {membershipDetails.user.email}
                  </Text>
                )}
                {membershipDetails.user?.wallet_address && (
                  <HStack spacing={2} mt={1}>
                    <Text fontSize="sm" color="gray.600">
                      Wallet:{' '}
                      {truncateAddress(membershipDetails.user.wallet_address)}
                    </Text>
                    <Tooltip label="View on blockchain explorer">
                      <IconButton
                        icon={<MdOpenInNew />}
                        aria-label="View on blockchain explorer"
                        size="xs"
                        variant="ghost"
                        onClick={() =>
                          window.open(
                            `https://polygonscan.com/address/${membershipDetails.user.wallet_address}`,
                            '_blank',
                          )
                        }
                      />
                    </Tooltip>
                  </HStack>
                )}
              </VStack>
            </Box>

            <Divider my={3} borderColor="gray.200" />

            {/* Loyalty Program and Level */}
            <VStack spacing={2} align="flex-start">
              <Box>
                <Text color="gray.500" fontSize="sm" mb={1}>
                  Loyalty Program
                </Text>
                <HStack spacing={2}>
                  <Icon as={MdCardMembership} color="blue.500" />
                  <Text fontSize="md" fontWeight="medium">
                    {membershipDetails.loyalty_program_name}
                  </Text>
                  <Button
                    size="xs"
                    variant="outline"
                    colorScheme="blue"
                    leftIcon={<MdOpenInNew />}
                    onClick={() =>
                      navigate(
                        `/admin/loyalty-programs/${membershipDetails.loyalty_program_uuid}`,
                      )
                    }
                  >
                    View Program
                  </Button>
                </HStack>
              </Box>

              <Box>
                <Text color="gray.500" fontSize="sm" mb={1}>
                  Loyalty level
                </Text>
                <HStack spacing={2}>
                  <Icon as={MdCardMembership} color="blue.500" />
                  <Text fontSize="md" fontWeight="medium">
                    {membershipDetails.loyalty_level_name}
                  </Text>
                  <Button
                    size="xs"
                    variant="outline"
                    colorScheme="blue"
                    leftIcon={<MdOpenInNew />}
                    onClick={() =>
                      navigate(
                        `/admin/loyalty-programs/${membershipDetails.loyalty_program_uuid}/levels/${membershipDetails.loyalty_level_uuid}`,
                      )
                    }
                  >
                    View Level
                  </Button>
                </HStack>
              </Box>
            </VStack>

            <Divider my={3} borderColor="gray.200" />

            {/* Actions */}
            <HStack spacing={4} pt={2}>
              <Button
                leftIcon={<MdPerson />}
                colorScheme="blue"
                variant="outline"
                size="sm"
                onClick={() =>
                  navigate(`/admin/users/user/${membershipDetails.user.uuid}`)
                }
              >
                User Profile
              </Button>

              <Button
                leftIcon={<MdShoppingCart />}
                colorScheme="teal"
                variant="outline"
                size="sm"
                onClick={() =>
                  navigate(
                    `/admin/products/edit/${membershipDetails.product_uuid}`,
                  )
                }
              >
                View Product
              </Button>
            </HStack>
          </CardBody>
        </Card>

        {/* Membership Image Card */}
        <Card
          variant="outline"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          overflow="hidden"
          height="fit-content"
          flexDirection="column"
        >
          <CardHeader bg="blue.50" py={4}>
            <Heading size="md">Membership Image</Heading>
          </CardHeader>
          <CardBody p={0}>
            {membershipDetails.image_url ? (
              <Image
                src={membershipDetails.image_url}
                alt={membershipDetails.loyalty_level_name}
                objectFit="contain"
                w="100%"
                h="350px"
                cursor="pointer"
                onClick={onOpen}
              />
            ) : (
              <Flex
                justify="center"
                align="center"
                bg="gray.100"
                h="350px"
                w="100%"
              >
                <VStack spacing={2}>
                  <Icon as={MdCardMembership} boxSize="40px" color="gray.300" />
                  <Text color="gray.500">No image available</Text>
                </VStack>
              </Flex>
            )}

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
              <ModalOverlay backdropFilter="blur(3px)" />
              <ModalContent maxH="90vh">
                <ModalCloseButton
                  zIndex={2}
                  color="white"
                  bg="blackAlpha.600"
                  _hover={{ bg: 'blackAlpha.700' }}
                />
                <ModalBody
                  p={0}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bg="gray.900"
                >
                  <Image
                    src={membershipDetails.image_url}
                    alt={membershipDetails.loyalty_level_name}
                    maxH="80vh"
                    maxW="100%"
                    objectFit="contain"
                    borderRadius="md"
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </CardBody>
        </Card>
      </SimpleGrid>

      {/* Activity History */}
      <Card
        variant="outline"
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="md"
        overflow="hidden"
        mb={8}
      >
        <CardHeader bg="blue.50" py={4}>
          <Heading size="md">Activity History</Heading>
        </CardHeader>
        <CardBody px={0} py={0}>
          {membershipDetails.activityHistory &&
          membershipDetails.activityHistory.length > 0 ? (
            <Box overflowX="auto">
              <Table variant="striped" size="md" colorScheme="gray">
                <Thead bg="gray.50">
                  <Tr>
                    <Th width="15%">Timestamp</Th>
                    <Th width="15%">Event Type</Th>
                    <Th width="20%">From</Th>
                    <Th width="20%">To</Th>
                    <Th width="30%">Details</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {membershipDetails.activityHistory.map((event, index) => {
                    // Determine event-specific styling and display
                    const isMintEvent = event.eventName === 'Mint';
                    const isTransferEvent = event.eventName === 'Transfer';
                    const isRenewalEvent = event.eventName === 'Renewal';
                    const isExpiryEvent = event.eventName === 'Expiration';

                    let rowBgColor;
                    if (isMintEvent) rowBgColor = 'green.50';
                    else if (isRenewalEvent) rowBgColor = 'purple.50';
                    else if (isExpiryEvent) rowBgColor = 'red.50';

                    return (
                      <Tr key={index} bg={rowBgColor}>
                        <Td fontWeight="medium">
                          {format(
                            new Date(event.timestamp),
                            'MMM dd, yyyy HH:mm',
                          )}
                        </Td>
                        <Td>
                          <Badge
                            colorScheme={
                              isMintEvent
                                ? 'green'
                                : isRenewalEvent
                                  ? 'purple'
                                  : isExpiryEvent
                                    ? 'red'
                                    : isTransferEvent
                                      ? 'blue'
                                      : 'gray'
                            }
                            variant="solid"
                            px={2}
                            py={0.5}
                          >
                            {event.eventName}
                          </Badge>
                        </Td>
                        <Td>
                          {isTransferEvent || isMintEvent ? (
                            <HStack spacing={2}>
                              <Text>
                                {isMintEvent ? (
                                  <Text
                                    as="span"
                                    fontWeight="500"
                                    color="green.600"
                                  >
                                    Minting Contract
                                  </Text>
                                ) : event.from ? (
                                  truncateAddress(event.from)
                                ) : (
                                  'Genesis'
                                )}
                              </Text>
                              {event.from && !isMintEvent && (
                                <Tooltip label="View on blockchain explorer">
                                  <IconButton
                                    icon={<MdOpenInNew />}
                                    aria-label="View address"
                                    size="xs"
                                    variant="ghost"
                                    onClick={() =>
                                      window.open(
                                        `https://polygonscan.com/address/${event.from}`,
                                        '_blank',
                                      )
                                    }
                                  />
                                </Tooltip>
                              )}
                            </HStack>
                          ) : isRenewalEvent ? (
                            <Text color="purple.600">System</Text>
                          ) : isExpiryEvent ? (
                            <Text color="red.600">System</Text>
                          ) : (
                            <Text>-</Text>
                          )}
                        </Td>
                        <Td>
                          {isTransferEvent || isMintEvent ? (
                            <HStack spacing={2}>
                              <Text>{truncateAddress(event.to)}</Text>
                              {event.to && (
                                <Tooltip label="View on blockchain explorer">
                                  <IconButton
                                    icon={<MdOpenInNew />}
                                    aria-label="View address"
                                    size="xs"
                                    variant="ghost"
                                    onClick={() =>
                                      window.open(
                                        `https://polygonscan.com/address/${event.to}`,
                                        '_blank',
                                      )
                                    }
                                  />
                                </Tooltip>
                              )}
                            </HStack>
                          ) : isRenewalEvent ? (
                            <HStack>
                              <Icon as={MdVerified} color="purple.500" />
                              <Text color="purple.600">Membership Renewed</Text>
                            </HStack>
                          ) : (
                            <Text>-</Text>
                          )}
                        </Td>
                        <Td>
                          {event.transactionHash ? (
                            <HStack spacing={2}>
                              <Text fontFamily="monospace">
                                {shortenHash(event.transactionHash)}
                              </Text>
                              <Tooltip
                                label={
                                  isMintEvent
                                    ? 'View mint transaction'
                                    : 'View transaction on blockchain explorer'
                                }
                              >
                                <IconButton
                                  icon={<MdOpenInNew />}
                                  aria-label="View transaction"
                                  size="xs"
                                  variant="ghost"
                                  onClick={() =>
                                    window.open(
                                      `https://polygonscan.com/tx/${event.transactionHash}`,
                                      '_blank',
                                    )
                                  }
                                />
                              </Tooltip>
                              {isMintEvent && (
                                <Tooltip label="This is the original minting transaction">
                                  <Icon
                                    as={PolygonIcon}
                                    color="green.500"
                                    boxSize="14px"
                                  />
                                </Tooltip>
                              )}
                            </HStack>
                          ) : (
                            <Text color="gray.600" fontSize="sm">
                              {isRenewalEvent
                                ? `Extended until ${format(
                                    new Date(
                                      event.details?.newExpiryDate ||
                                        membershipDetails.expiration_date,
                                    ),
                                    'MMM dd, yyyy',
                                  )}`
                                : isExpiryEvent
                                  ? 'Membership expired'
                                  : event.details?.message ||
                                    'No additional details'}
                            </Text>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          ) : (
            <Flex justify="center" align="center" py={10} direction="column">
              <Icon as={PolygonIcon} boxSize="40px" color="gray.300" mb={3} />
              <Text color="gray.500">No activity history available</Text>
            </Flex>
          )}
        </CardBody>
      </Card>
    </Box>
  );
}
