import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Spinner,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';
import LineChart from 'components/charts/LineChart';
import Card from 'components/card/Card';
import { getProductsSold } from 'api/dashboard';
import { format, parseISO, startOfWeek, addWeeks, isValid } from 'date-fns';

export default function ProductSalesWidget(props) {
  const { startDate, endDate, groupBy, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // State variables
  const [salesData, setSalesData] = useState({
    total_sales: 0,
    total_orders: 0,
    data: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  // Fetch product sales data
  useEffect(() => {
    const fetchProductSales = async () => {
      setIsLoading(true);
      try {
        const params = {
          group_by: groupBy,
        };
        if (startDate) params.start_date = startDate;
        if (endDate) params.end_date = endDate;

        const data = await getProductsSold(params);
        setSalesData(data);
      } catch (error) {
        console.error('Error fetching product sales data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProductSales();
  }, [groupBy, startDate, endDate]);

  const renderChart = () => {
    if (!salesData.data || salesData.data.length === 0) {
      return (
        <Flex justify="center" align="center" h="200px">
          <Text color="secondaryGray.600">No sales data available</Text>
        </Flex>
      );
    }

    // Prepare chart categories
    const chartCategories = salesData.data.map((item) => {
      let date = parseISO(item.period);

      if (groupBy === 'week') {
        // Parse week number and year
        const year = parseInt(item.period.slice(0, 4), 10);
        const week = parseInt(item.period.slice(4), 10);
        date = startOfWeek(addWeeks(new Date(year, 0, 1), week - 1), {
          weekStartsOn: 1,
        });
      }

      if (!isValid(date)) {
        console.error(`Invalid date value: ${item.period}`);
        return item.period; // Return the original value if the date is invalid
      }

      switch (groupBy) {
        case 'day':
          return format(date, 'dd MMM');
        case 'week':
          return `W${format(date, 'w yyyy')}`;
        case 'month':
          return format(date, 'MMM');
        case 'year':
          return format(date, 'yyyy');
        default:
          return item.period;
      }
    });

    const chartData = [
      {
        name: 'Orders',
        data: salesData.data.map((item, index) => ({
          x: chartCategories[index],
          y: item.orders,
        })),
      },
      {
        name: 'Sales (€)',
        data: salesData.data.map((item, index) => ({
          x: chartCategories[index],
          y: parseFloat(item?.sales?.toFixed(2)),
        })),
      },
    ];

    // Chart options
    const chartOptions = {
      chart: {
        toolbar: { show: false },
        stacked: false,
      },
      xaxis: {
        show: false,
        categories: chartCategories,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: false,
      },
      stroke: {
        curve: 'smooth',
        width: 5,
      },
      colors: ['#3182CE', '#68D391'],
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (value, { seriesIndex }) {
            if (seriesIndex === 1) {
              return `€${value?.toFixed(2)}`;
            }
            return value;
          },
        },
      },
      legend: {
        show: true,
        position: 'top',
      },
    };

    return (
      <LineChart chartData={chartData} chartOptions={chartOptions} h="300px" />
    );
  };

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      {...rest}
    >
      {isLoading ? (
        <Flex justify="center" align="center" h="300px">
          <Spinner />
        </Flex>
      ) : (
        <>
          <Flex
            justify="space-between"
            px="20px"
            pt="20px"
            w="100%"
            flexWrap="wrap"
          >
            <Text
              color={textColor}
              fontSize="xl"
              fontWeight="700"
              lineHeight="100%"
            >
              Product Sales Overview
            </Text>
          </Flex>

          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing="20px"
            w="100%"
            p="20px"
          >
            <Flex
              direction="column"
              align="center"
              bg="blue.50"
              p={4}
              borderRadius="lg"
            >
              <Text color="secondaryGray.600" fontSize="sm">
                Total Sales
              </Text>
              <Text color={textColor} fontSize="2xl" fontWeight="700" mt={2}>
                €
                {salesData.total_sales
                  ? salesData?.total_sales?.toFixed(2)
                  : '0.00'}
              </Text>
            </Flex>

            <Flex
              direction="column"
              align="center"
              bg="green.50"
              p={4}
              borderRadius="lg"
            >
              <Text color="secondaryGray.600" fontSize="sm">
                Total Orders
              </Text>
              <Text color={textColor} fontSize="2xl" fontWeight="700" mt={2}>
                {salesData.total_orders || 0}
              </Text>
            </Flex>
          </SimpleGrid>

          <Box minH="300px" w="100%" px="20px" pb="20px">
            {renderChart()}
          </Box>
        </>
      )}
    </Card>
  );
}
