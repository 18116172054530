import React, { useContext } from 'react';
import {
  Box,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { Global } from '@emotion/react';
import CmsBlocks from 'components/companySettings/CmsBlocks';
import GeneralSettings from 'components/companySettings/GeneralSettings';
import CompanyUsers from 'components/companySettings/CompanyUsers';
import InvoiceDetails from 'components/companySettings/InvoiceDetails';
import { AuthContext } from 'contexts/AuthContext';

const editorStyles = {
  '.editor-input': {
    minHeight: '200px',
    padding: '10px',
    outline: 'none',
    width: '100%',
    cursor: 'text',
    fontSize: '16px',
    lineHeight: '1.5',
    '& h1': { fontSize: '2em', fontWeight: 'bold', margin: '0.67em 0' },
    '& h2': { fontSize: '1.5em', fontWeight: 'bold', margin: '0.83em 0' },
    '& h3': { fontSize: '1.17em', fontWeight: 'bold', margin: '1em 0' },
    '& strong': { fontWeight: 'bold' },
    '& em': { fontStyle: 'italic' },
    '& u': { textDecoration: 'underline' },
    '& ul': { listStyle: 'disc', marginLeft: '20px' },
    '& ol': { listStyle: 'decimal', marginLeft: '20px' },
  },
  '.editor-header-one': {
    fontSize: '2em',
    fontWeight: 'bold',
    margin: '0.67em 0',
  },
  '.editor-header-two': {
    fontSize: '1.5em',
    fontWeight: 'bold',
    margin: '0.83em 0',
  },
  '.editor-header-three': {
    fontSize: '1.17em',
    fontWeight: 'bold',
    margin: '1em 0',
  },
};

export default function CompanySettings() {
  const { userRole } = useContext(AuthContext);

  // Check if user has admin access
  const hasAdminAccess =
    userRole === 'SUPER_ADMIN' || userRole === 'COMPANY_ADMIN';

  return (
    <>
      <Global styles={editorStyles} />
      <Box p={5} mt={{ sm: '150px', md: '125px', lg: '80px' }}>
        <SimpleGrid columns={{ sm: 1, xl: 1 }} spacing={5} mb={5}>
          <Tabs w="100%">
            <TabList>
              <Tab>General Settings</Tab>
              <Tab>Content</Tab>
              <Tab>Users</Tab>
              {hasAdminAccess && <Tab>Invoice Details</Tab>}
            </TabList>
            <TabPanels>
              <TabPanel>
                <GeneralSettings />
              </TabPanel>
              <TabPanel>
                <CmsBlocks />
              </TabPanel>
              <TabPanel>
                <CompanyUsers />
              </TabPanel>
              <TabPanel>
                <InvoiceDetails />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </SimpleGrid>
      </Box>
    </>
  );
}
