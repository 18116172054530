import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Box,
  Flex,
  Spinner,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Alert,
  AlertIcon,
  TabPanel,
  IconButton,
  Badge,
  Heading,
  HStack,
  Card,
  CardHeader,
  CardBody,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import ProductForm from '../../components/products/ProductForm';
import ProductImageUploader from '../../components/products/ProductImageUploader';

import ProductVariationsSection from '../../components/products/ProductVariationsSection';
import ProductAttributesSection from '../../components/products/ProductAttributesSection';
import CategoryAttributesSection from '../../components/products/CategoryAttributesSection';
import { MdArrowBack } from 'react-icons/md';
import {
  getProductDetails,
  updateProduct,
  handleImageUpload,
  handleDeleteImage,
  handleSetFeaturedImage,
  getProducts,
} from '../../api/product';
import { getCategories } from '../../api/category';
import { setTranslation } from '../../utils/translationUtils';
import {
  transformCategoriesForTreeSelect,
  addProductCounts,
} from '../../utils/CategoryUtils';
import {
  getAttributes,
  getAttributeValues,
  getProductAttributes,
  getCategoryAttributes,
} from '../../api/attributesVariations';
import { AuthContext } from 'contexts/AuthContext';

export default function ProductEdit() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { userRole } = useContext(AuthContext);
  const isViewer = userRole === 'VIEWER';

  const [, setProductId] = useState(null);
  const [productUuid, setProductUuid] = useState(null);

  const [productPrice, setProductPrice] = useState(0);
  const [isFeatured, setIsFeatured] = useState(false);
  const [soldQuantity, setSoldQuantity] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [unlimitedQuantity, setUnlimitedQuantity] = useState(false);
  const [existingImages, setExistingImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [bookedQuantity, setBookedQuantity] = useState(0);

  const [languages] = useState([
    { code: 'en', name: 'English' },
    { code: 'et', name: 'Estonian' },
  ]);
  const [translations, setTranslations] = useState({
    en: { name: '', description: '', price: 0 },
    et: { name: '', description: '' },
  });

  const [categoriesData, setCategoriesData] = useState([]);
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [productType, setProductType] = useState('physical');
  const [visibility, setVisibility] = useState('public');
  const [additionalFields, setAdditionalFields] = useState({
    sku: '',
    isbn: '',
    ean_upc: '',
    external_id: '',
    weight: '',
    weight_unit: '',
    external_url: '',
    tax_class: '',
    country_of_origin: '',
    brand_name: '',
    dimensions: '',
  });
  const [productCounts, setProductCounts] = useState({});

  const [availableAttributes, setAvailableAttributes] = useState([]);
  const [linkedAttributes, setLinkedAttributes] = useState([]);
  const [activeAttributesTab, setActiveAttributesTab] = useState(0);
  const [categoryLinkedAttributes, setCategoryLinkedAttributes] = useState([]);

  const [variationKey, setVariationKey] = useState(0);
  const refreshVariations = useCallback(() => {
    setVariationKey((prev) => prev + 1);
  }, []);

  const refreshCategoryAttributes = useCallback(async () => {
    if (selectedCategory) {
      try {
        const data = await getCategoryAttributes(selectedCategory);
        setCategoryLinkedAttributes(data);
      } catch (error) {
        console.error('Error refreshing category attributes:', error);
        setCategoryLinkedAttributes([]);
      }
    } else {
      setCategoryLinkedAttributes([]);
    }
  }, [selectedCategory]);

  const fetchProduct = useCallback(async () => {
    try {
      const product = await getProductDetails(uuid);
      setProductId(Number(product.id));
      setProductUuid(product.uuid || uuid);
      setProductPrice(product.price);
      setIsFeatured(product.is_featured);
      setQuantity(product.quantity || 0);
      setBookedQuantity(product.booked_quantity || 0);
      setSoldQuantity(product.sold_quantity || 0);
      setExistingImages(product.images || []);

      if (!product.is_finite_quantity) {
        setUnlimitedQuantity(true);
      } else {
        setUnlimitedQuantity(false);
        setQuantity(product.quantity);
      }

      setTranslations({
        en: {
          name: product.translations?.en?.product_name || product.name,
          description: product.translations?.en?.product_description || '',
          price: product.price,
        },
        et: {
          name: product.translations?.et?.product_name || '',
          description: product.translations?.et?.product_description || '',
        },
      });
      setSelectedCategory(product.category_uuid || null);
      setProductType(product.product_type || 'physical');
      setVisibility(product.visibility || 'public');
      setAdditionalFields({
        sku: product.sku || '',
        isbn: product.isbn || '',
        ean_upc: product.ean_upc || '',
        external_id: product.external_id || '',
        weight: product.weight || '',
        weight_unit: product.weight_unit || '',
        external_url: product.external_url || '',
        tax_class: product.tax_class || '',
        country_of_origin: product.country_of_origin || '',
        brand_name: product.brand_name || '',
        dimensions: product.dimensions || '',
      });
    } catch (error) {
      console.error('Error fetching product:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch product details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid, toast, setProductId]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoadingCategories(true);
      try {
        const data = await getCategories();
        setCategoriesData(data);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
        toast({
          title: 'Error fetching categories.',
          description: 'Unable to load categories.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoadingCategories(false);
      }
    };
    fetchCategories();
  }, [toast]);

  useEffect(() => {
    if (categoriesData.length > 0) {
      let transformedCategories = transformCategoriesForTreeSelect(
        categoriesData,
        'en',
      );
      transformedCategories = addProductCounts(
        transformedCategories,
        productCounts,
      );
      setCategoriesTree(transformedCategories);
    }
  }, [categoriesData, productCounts]);

  useEffect(() => {
    const fetchProductCounts = async () => {
      try {
        const response = await getProducts();
        const counts = {};
        response.forEach((product) => {
          if (product.category_uuid) {
            counts[product.category_uuid] =
              (counts[product.category_uuid] || 0) + 1;
          }
        });
        setProductCounts(counts);
      } catch (error) {
        console.error('Error fetching product counts:', error);
      }
    };
    fetchProductCounts();
  }, []);

  useEffect(() => {
    const fetchAttributesAndValues = async () => {
      try {
        const [attrs, attrValues] = await Promise.all([
          getAttributes(),
          getAttributeValues(),
        ]);
        const combined = attrs.map((attr) => ({
          ...attr,
          values: attrValues.filter((val) => val.attribute_id === attr.id),
        }));
        setAvailableAttributes(combined);
      } catch (error) {
        console.error('Error fetching attributes and values:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch attributes and values.',
          status: 'error',
          isClosable: true,
        });
      }
    };
    fetchAttributesAndValues();
  }, [toast]);

  const refreshLinkedAttributes = useCallback(async () => {
    try {
      if (!productUuid) return;
      const data = await getProductAttributes(productUuid);
      setLinkedAttributes(data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setLinkedAttributes([]);
      } else {
        toast({
          title: 'Error',
          description: 'Failed to fetch product attributes.',
          status: 'error',
          isClosable: true,
        });
      }
    }
  }, [productUuid, toast]);

  useEffect(() => {
    if (productUuid) {
      refreshLinkedAttributes();
    }
  }, [productUuid, refreshLinkedAttributes]);

  useEffect(() => {
    refreshCategoryAttributes();
  }, [selectedCategory, refreshCategoryAttributes]);

  const filteredProductAttributes = availableAttributes.filter((attr) =>
    linkedAttributes.some((linkedAttr) => linkedAttr.id === attr.id),
  );

  const enrichedCategoryAttributes = categoryLinkedAttributes.map((catAttr) => {
    const fullAttribute = availableAttributes.find(
      (attr) => attr.id === catAttr.id,
    );
    return {
      ...catAttr,
      values: fullAttribute ? fullAttribute.values : [],
    };
  });

  const variationAttributes =
    activeAttributesTab === 0
      ? filteredProductAttributes
      : enrichedCategoryAttributes;

  const handleCategoryChange = (selectedUuid) => {
    setSelectedCategory(selectedUuid);
  };

  const handleImageChange = (acceptedFiles) => {
    if (isViewer) return;

    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 5 * 1024 * 1024;
    const newValidFiles = [];

    acceptedFiles.forEach((file) => {
      if (!validTypes.includes(file.type)) {
        toast({
          title: 'Invalid file type.',
          description: 'Only PNG, JPG, and GIF files are allowed.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else if (file.size > maxSize) {
        toast({
          title: 'File too large.',
          description: 'Image size should be less than 5MB.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        newValidFiles.push(file);
      }
    });

    if (newValidFiles.length > 0) {
      setNewImages((prev) => {
        const updatedImages = [...prev, ...newValidFiles];
        setCurrentImageIndex(existingImages.length + updatedImages.length - 1);
        return updatedImages;
      });
    }
  };

  const handleSave = async () => {
    if (isViewer) {
      toast({
        title: 'Permission Denied',
        description: 'You do not have permission to edit products.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const translationsPayload = {
      en: {
        product_name: translations.en.name,
        product_description: translations.en.description,
      },
      et: {
        product_name: translations.et.name,
        product_description: translations.et.description,
      },
    };

    const updatedProduct = {
      price: Number(productPrice),
      is_featured: isFeatured,
      is_archived: false,
      status: 'active',
      translations: translationsPayload,
      quantity: unlimitedQuantity ? 0 : quantity,
      is_finite_quantity: !unlimitedQuantity,
      category_uuid: selectedCategory || null,
      product_type: productType || null,
      visibility: visibility,
      sku: additionalFields.sku,
      isbn: additionalFields.isbn,
      ean_upc: additionalFields.ean_upc,
      external_id: additionalFields.external_id,
      weight: additionalFields.weight,
      weight_unit: additionalFields.weight_unit,
      external_url: additionalFields.external_url,
      tax_class: additionalFields.tax_class,
      country_of_origin: additionalFields.country_of_origin,
      brand_name: additionalFields.brand_name,
      dimensions: additionalFields.dimensions,
    };

    try {
      if (!productUuid) {
        throw new Error('Product ID not set');
      }

      await updateProduct(productUuid, updatedProduct);
      if (newImages.length > 0) {
        for (const img of newImages) {
          const uploadedImage = await handleImageUpload([img], productUuid);
          setExistingImages((prev) => [...prev, uploadedImage]);
        }
        setNewImages([]);
      }

      for (const lang of languages.map((l) => l.code)) {
        if (translations[lang]) {
          if (translations[lang].name?.trim()) {
            await setTranslation(
              `product_name_${productUuid}`,
              translations[lang].name,
              lang,
            );
          }
          if (translations[lang].description?.trim()) {
            await setTranslation(
              `product_description_${productUuid}`,
              translations[lang].description,
              lang,
            );
          }
        }
      }

      toast({
        title: 'Product updated.',
        description: 'The product has been updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/admin/products');
    } catch (error) {
      console.error('Error updating product:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while updating the product.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleQuantityChange = (value) => {
    const newQuantity = parseInt(value, 10) || 0;
    setQuantity(newQuantity);
    return newQuantity;
  };

  const handleTranslationChange = (lang, field, value) => {
    setTranslations((prev) => ({
      ...prev,
      [lang]: { ...prev[lang], [field]: value },
    }));
  };

  const handleDeleteImageWrapper = (imageUuid) => {
    if (isViewer) return;

    handleDeleteImage(productUuid, imageUuid)
      .then(() => {
        setExistingImages((prev) =>
          prev.filter((image) => image.uuid !== imageUuid),
        );
        toast({
          title: 'Image Deleted',
          description: 'The image has been deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error('Error deleting image:', error);
        toast({
          title: 'Error',
          description: 'Failed to delete image',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleSetFeaturedImageWrapper = (imageUuid) => {
    if (isViewer) return;
    handleSetFeaturedImage(productUuid, imageUuid)
      .then(() => {
        setExistingImages((prevImages) =>
          prevImages.map((img) => ({
            ...img,
            is_featured: img.uuid === imageUuid,
          })),
        );
        setIsFeatured(true);
        toast({
          title: 'Featured Image Set',
          description: 'The image has been set as featured.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error('Error setting featured image:', error);
        toast({
          title: 'Error',
          description: 'Failed to set featured image',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const sortImages = (images) => {
    return images.sort((a, b) => (b.is_featured ? 1 : -1));
  };

  const combinedImages = sortImages([
    ...existingImages,
    ...newImages.map((file) => ({
      uuid: file.name + '-' + file.lastModified,
      image_url: URL.createObjectURL(file),
      is_featured: false,
    })),
  ]);

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      px={{ base: 4, md: 8 }}
      maxW="1600px"
      mx="auto"
    >
      {isViewer && (
        <Alert status="info" mb={4}>
          <AlertIcon />
          You are in view-only mode. Contact an administrator to make changes.
        </Alert>
      )}

      <HStack spacing={3} mb={6}>
        <IconButton
          icon={<MdArrowBack />}
          variant="outline"
          rounded="full"
          onClick={() => navigate('/admin/products')}
          aria-label="Back to products"
        />
        <Heading size="lg">Edit Product</Heading>
        {productType && (
          <Badge
            colorScheme={productType === 'physical' ? 'green' : 'purple'}
            variant="solid"
            fontSize="md"
            px={3}
            py={1}
            borderRadius="full"
            ml={2}
          >
            {productType.charAt(0).toUpperCase() + productType.slice(1)}
          </Badge>
        )}
      </HStack>
      <ProductForm
        languages={languages}
        translations={translations}
        handleTranslationChange={handleTranslationChange}
        isFeatured={isFeatured}
        setIsFeatured={setIsFeatured}
        quantity={quantity}
        bookedQuantity={bookedQuantity}
        unlimitedQuantity={unlimitedQuantity}
        setUnlimitedQuantity={setUnlimitedQuantity}
        soldQuantity={soldQuantity}
        handleQuantityChange={handleQuantityChange}
        selectedCategory={selectedCategory}
        categoriesTree={categoriesTree}
        isLoadingCategories={isLoadingCategories}
        handleCategoryChange={handleCategoryChange}
        productType={productType}
        setProductType={setProductType}
        visibility={visibility}
        setVisibility={setVisibility}
        productPrice={productPrice}
        setProductPrice={setProductPrice}
        saveAction={handleSave}
        isViewer={isViewer}
        hideHeader={true}
        leftChildren={
          <ProductImageUploader
            images={combinedImages}
            onUpload={handleImageChange}
            onDelete={handleDeleteImageWrapper}
            onSetFeatured={handleSetFeaturedImageWrapper}
            isViewer={isViewer}
          />
        }
        afterDetails={
          productUuid && (
            <Box mb={8}>
              <Card
                variant="outline"
                borderWidth="1px"
                borderRadius="lg"
                boxShadow="md"
                overflow="hidden"
                mb={6}
              >
                <CardHeader bg="blue.50" py={4}>
                  <Heading size="md">Product Attributes</Heading>
                </CardHeader>
                <CardBody px={6} pt={4} pb={5}>
                  <Tabs
                    variant="enclosed"
                    colorScheme="blue"
                    onChange={(index) => setActiveAttributesTab(index)}
                  >
                    <TabList>
                      <Tab>Linked Product Attributes</Tab>
                      <Tab>Linked Category Attributes</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel px={0} pt={4}>
                        <ProductAttributesSection
                          productUuid={productUuid}
                          onLinkedAttributesChange={refreshLinkedAttributes}
                          onVariationsChange={refreshVariations}
                          isViewer={isViewer}
                        />
                      </TabPanel>
                      <TabPanel px={0} pt={4}>
                        {selectedCategory ? (
                          <CategoryAttributesSection
                            categoryId={selectedCategory}
                            onLinkedAttributesChange={refreshCategoryAttributes}
                            isViewer={isViewer}
                          />
                        ) : (
                          <Box>No category selected.</Box>
                        )}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </CardBody>
              </Card>

              <Card
                variant="outline"
                borderWidth="1px"
                borderRadius="lg"
                boxShadow="md"
                overflow="hidden"
                mb={6}
              >
                <CardHeader bg="blue.50" py={4}>
                  <Heading size="md">Product Variations</Heading>
                </CardHeader>
                <CardBody px={6} pt={4} pb={5}>
                  <ProductVariationsSection
                    key={variationKey}
                    productUuid={productUuid}
                    categoryId={selectedCategory}
                    availableAttributes={variationAttributes}
                    variationType={
                      activeAttributesTab === 0 ? 'product' : 'category'
                    }
                    isViewer={isViewer}
                    productPrice={productPrice}
                    productQuantity={quantity}
                    unlimitedQuantity={unlimitedQuantity}
                  />
                </CardBody>
              </Card>
            </Box>
          )
        }
        additionalFields={additionalFields}
        setAdditionalFields={setAdditionalFields}
      />
    </Box>
  );
}
