import apiClient from './axios';

export const getAllVouchers = async () => {
  try {
    const response = await apiClient.get(`/rewards`);
    return response.data;
  } catch (error) {
    console.error('Error fetching vouchers:', error);
    throw error;
  }
};

export const getVoucherByUuid = async (voucherUuid) => {
  try {
    const response = await apiClient.get(`/reward/${voucherUuid}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching voucher:', error);
    throw error;
  }
};

export const deleteVoucher = async (voucherUuid) => {
  try {
    const response = await apiClient.delete(`/reward/${voucherUuid}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting voucher:', error);
    throw error;
  }
};
