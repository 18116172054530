import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  useColorModeValue,
  Spinner,
  Badge,
  Box,
  Select,
  VStack,
  Divider,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { getProductsSold } from 'api/dashboard';

export default function TopSalesWidget(props) {
  const { startDate, endDate, groupBy, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bgHighlight = useColorModeValue('blue.50', 'blue.900');
  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.800');

  // State variables
  const [topProducts, setTopProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortBy, setSortBy] = useState('sales');

  // Fetch best selling products
  useEffect(() => {
    const fetchTopProducts = async () => {
      setIsLoading(true);
      try {
        const params = {
          group_by: groupBy,
        };
        if (startDate) params.start_date = startDate;
        if (endDate) params.end_date = endDate;

        const data = await getProductsSold(params);
        setTopProducts(data.top_products || []);
      } catch (error) {
        console.error('Error fetching top products:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTopProducts();
  }, [groupBy, startDate, endDate]);

  const sortedProducts = [...topProducts].sort((a, b) => {
    if (sortBy === 'sales') {
      return b.sales - a.sales;
    } else {
      return b.orders - a.orders;
    }
  });

  // Get badge styling based on rank
  const getBadgeStyles = (index) => {
    if (index === 0) {
      return {
        colorScheme: 'yellow',
        variant: 'solid',
        fontSize: 'sm',
        px: 2.5,
        py: 1,
      };
    } else if (index < 3) {
      return {
        colorScheme: 'yellow',
        variant: 'subtle',
        fontSize: 'xs',
        px: 2,
      };
    } else {
      return {
        colorScheme: 'gray',
        variant: 'subtle',
        fontSize: 'xs',
        px: 2,
      };
    }
  };

  return (
    <Card
      justifyContent="center"
      direction="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      pb="15px"
      {...rest}
    >
      <Flex justify="space-between" align="center" mb="16px">
        <Text
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="100%"
        >
          Best Selling Products
        </Text>

        <Select
          size="sm"
          width="130px"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          variant="subtle"
        >
          <option value="sales">By Revenue</option>
          <option value="orders">By Quantity</option>
        </Select>
      </Flex>

      {isLoading ? (
        <Flex justify="center" align="center" minH="200px">
          <Spinner />
        </Flex>
      ) : topProducts.length === 0 ? (
        <Flex justify="center" align="center" minH="200px">
          <Text color="secondaryGray.600">No product data available</Text>
        </Flex>
      ) : (
        <VStack spacing={0} align="stretch">
          {sortedProducts.slice(0, 5).map((product, index) => (
            <React.Fragment key={`${product.product_id}-${index}`}>
              {index > 0 && <Divider borderColor={borderColor} />}
              <Flex
                alignItems="center"
                w="100%"
                p="12px"
                borderRadius={index === 0 ? 'lg' : 'md'}
                _hover={{ bg: index === 0 ? bgHighlight : hoverBgColor }}
                transition="background 0.2s"
              >
                <Box minWidth="44px" mr={3}>
                  <Badge borderRadius="full" {...getBadgeStyles(index)}>
                    #{index + 1}
                  </Badge>
                </Box>

                <VStack align="start" spacing={0} flex={1}>
                  <Text
                    color={textColor}
                    fontSize="md"
                    fontWeight={index === 0 ? '700' : '600'}
                    noOfLines={1}
                  >
                    {product.product_name}
                  </Text>
                  <Text fontSize="xs" color="secondaryGray.500">
                    {product.orders} {product.orders === 1 ? 'order' : 'orders'}
                  </Text>
                </VStack>

                <Text
                  ms="auto"
                  color={textColor}
                  fontSize={index === 0 ? 'md' : 'sm'}
                  fontWeight={index === 0 ? '700' : '600'}
                >
                  €{product.sales.toFixed(2)}
                </Text>
              </Flex>
            </React.Fragment>
          ))}
        </VStack>
      )}
    </Card>
  );
}
