/**
 * Convert data to CSV format and trigger download
 * @param {Array} data - Array of objects to be converted to CSV
 * @param {Array} columns - Array of column definitions with 'accessor' and 'header' properties
 * @param {String} filename - Name of the CSV file to be downloaded
 */
export default function exportToCSV(data, columns, filename) {
  if (!data || data.length === 0) {
    console.warn('No data to export');
    return;
  }

  const headers = columns.map((col) => `"${col.header}"`).join(',');

  const csvRows = data
    .map((row) => {
      return columns
        .map((col) => {
          let value;
          if (typeof col.accessor === 'string') {
            if (col.accessor.includes('.')) {
              value = col.accessor
                .split('.')
                .reduce((obj, key) => obj && obj[key], row);
            } else {
              value = row[col.accessor];
            }
          } else if (typeof col.accessor === 'function') {
            value = col.accessor(row);
          }

          if (value === null || value === undefined) {
            return '""';
          } else if (value instanceof Date) {
            return `"${value.toLocaleDateString()}"`;
          } else if (typeof value === 'string') {
            return `"${value.replace(/"/g, '""')}"`;
          } else {
            return `"${value}"`;
          }
        })
        .join(',');
    })
    .join('\n');

  const csvContent = `${headers}\n${csvRows}`;

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${filename || 'export'}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
