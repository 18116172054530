import React, { useState, useEffect } from 'react';
import {
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
  Spinner,
  VStack,
  Divider,
  Box,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Card from 'components/card/Card';
import { getLatestProducts } from 'api/dashboard';
import { BsArrowRight } from 'react-icons/bs';
import { format, parseISO } from 'date-fns';

export default function LatestProducts(props) {
  const { ...rest } = props;
  const navigate = useNavigate();

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.800');

  // State variables
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch latest products
  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const data = await getLatestProducts({ limit: 5 });
        setProducts(data);
      } catch (error) {
        console.error('Error fetching latest products:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <Card
      justifyContent="center"
      direction="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      {...rest}
    >
      <Flex justify="space-between" align="center" mb="8px" pt="20px" px="20px">
        <Text
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="100%"
        >
          Latest Non-Loyalty Purchases
        </Text>
      </Flex>

      {isLoading ? (
        <Flex justify="center" align="center" minH="200px">
          <Spinner />
        </Flex>
      ) : products.length === 0 ? (
        <Flex justify="center" align="center" minH="200px">
          <Text color="secondaryGray.600">No recent purchases</Text>
        </Flex>
      ) : (
        <VStack spacing={0} align="stretch" px="10px">
          {products.map((product, index) => (
            <React.Fragment key={`${product.id}-${index}`}>
              {index > 0 && <Divider borderColor={borderColor} />}
              <Flex
                alignItems="center"
                w="100%"
                py="12px"
                px="10px"
                borderRadius="md"
                _hover={{ bg: hoverBgColor }}
                transition="background 0.2s"
              >
                <Box
                  borderRadius="full"
                  overflow="hidden"
                  boxSize="48px"
                  mr="14px"
                  border="1px solid"
                  borderColor={borderColor}
                >
                  <Image
                    src={`https://api.perxify.com/${product.image}` || ''}
                    alt={product.name}
                    w="100%"
                    h="100%"
                    objectFit="cover"
                    fallbackSrc="https://via.placeholder.com/48"
                  />
                </Box>

                <VStack align="start" spacing="2px" flex={1}>
                  <Text
                    color={textColor}
                    fontSize="md"
                    fontWeight="600"
                    noOfLines={1}
                  >
                    {product.name}
                  </Text>
                  <Text fontSize="xs" color="secondaryGray.500">
                    Purchased on{' '}
                    {format(parseISO(product.purchase_date), 'dd MMM yyyy')}
                  </Text>
                </VStack>

                <Text
                  ms="auto"
                  color={textColor}
                  fontSize="sm"
                  fontWeight="700"
                >
                  €{product.purchase_price.toFixed(2)}
                </Text>
              </Flex>
            </React.Fragment>
          ))}
        </VStack>
      )}

      <Flex justify="center" mt="10px">
        <Button
          variant="ghost"
          colorScheme="blue"
          size="sm"
          rightIcon={
            <Icon
              as={BsArrowRight}
              w="16px"
              h="16px"
              transition="transform 0.2s"
            />
          }
          _hover={{
            '& svg': {
              transform: 'translateX(4px)',
            },
          }}
          onClick={() => navigate('/admin/orders?page=1&product_type=products')}
        >
          View all purchases
        </Button>
      </Flex>
    </Card>
  );
}
