import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  Box,
  useToast,
  Flex,
  Divider,
  HStack,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdDelete, MdFileUpload } from 'react-icons/md';
import DropzoneLoyalty from './LoyaltyDropzone';
import { deleteLoyaltyLevelArt, updateLoyaltyLevelArt } from 'api/loyalty';

export default function LoyaltyImageModal({
  isOpen,
  onClose,
  image,
  setSelectedImage,
  levelUuid,
  onImageUpdate,
  onImageDelete,
}) {
  const [isReplacing, setIsReplacing] = useState(false);
  const [occurrencePercentage, setOccurrencePercentage] = useState(
    image?.occurrence_percentage || 0,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const toast = useToast();
  const headerBg = useColorModeValue('blue.50', 'navy.800');

  useEffect(() => {
    if (image) {
      setOccurrencePercentage(image.occurrence_percentage || 0);
    }
  }, [image]);

  const handleSliderChange = (value) => {
    setOccurrencePercentage(value);
    setHasChanged(true);
  };

  const handleDelete = async () => {
    try {
      await deleteLoyaltyLevelArt(levelUuid, image.uuid);
      onImageDelete(image.uuid);
      onClose();
      toast({
        title: 'Success',
        description: 'Image deleted successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete image',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleReplace = async (files) => {
    setIsSaving(true);
    try {
      const newImageUrl = URL.createObjectURL(files[0]);
      const response = await updateLoyaltyLevelArt(
        levelUuid,
        image.uuid,
        occurrencePercentage,
        files[0],
      );

      if (!response) {
        throw new Error('No response received from server');
      }

      const updatedImage = {
        ...response,
        image_url: response.image_url || newImageUrl,
      };

      onImageUpdate(updatedImage);
      setSelectedImage(updatedImage);
      setIsReplacing(false);

      const preloadImage = document.createElement('img');
      preloadImage.onload = () => {
        URL.revokeObjectURL(newImageUrl);
        onImageUpdate(updatedImage);
        setSelectedImage(updatedImage);
        setIsReplacing(false);
      };
      preloadImage.src = response.image_url;
      toast({
        title: 'Success',
        description: 'Image replaced successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      console.error(`Error replacing image: ${error.message}`);
      toast({
        title: 'Error',
        description: 'Failed to replace image',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const response = await updateLoyaltyLevelArt(
        levelUuid,
        image.uuid,
        occurrencePercentage,
      );
      onImageUpdate(response);
      toast({
        title: 'Success',
        description: 'Image updated successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update image',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsSaving(false);
      if (hasChanged) {
        setHasChanged(false);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent>
        <ModalHeader
          bg={headerBg}
          py={4}
          borderBottomWidth={!isReplacing ? '0' : '1px'}
          borderColor="gray.200"
        >
          Edit Art Image
        </ModalHeader>

        <ModalBody p={0}>
          {!isReplacing ? (
            <Box p={0}>
              <Image
                src={image?.image_url}
                alt="Level art"
                w="100%"
                maxH="400px"
                objectFit="contain"
                bg="gray.900"
              />
            </Box>
          ) : (
            <Box p={6}>
              <DropzoneLoyalty
                onDrop={handleReplace}
                height="300px"
                width="100%"
                iconSize="40px"
                maxFiles={1}
              />
            </Box>
          )}

          <Box p={6}>
            <Text fontWeight="medium" mb={2}>
              Occurrence Percentage: {occurrencePercentage}%
            </Text>
            <Flex align="center" mb={2}>
              <Slider
                flex="1"
                value={occurrencePercentage}
                onChange={handleSliderChange}
                min={0}
                max={100}
                colorScheme="blue"
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb boxSize={5} />
              </Slider>
              {hasChanged && (
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={handleSave}
                  isLoading={isSaving}
                  ml={4}
                >
                  Set
                </Button>
              )}
            </Flex>

            <Text fontSize="sm" color="gray.500" mt={2}>
              This percentage determines how often this image appears compared
              to other images.
            </Text>
          </Box>
        </ModalBody>

        <Divider />

        <ModalFooter bg="gray.50">
          <Flex w="100%" justify="space-between">
            <Button
              colorScheme="red"
              onClick={handleDelete}
              leftIcon={<Icon as={MdDelete} />}
              variant="outline"
            >
              Delete
            </Button>

            <HStack>
              {!isReplacing ? (
                <Button
                  colorScheme="blue"
                  onClick={() => setIsReplacing(true)}
                  leftIcon={<Icon as={MdFileUpload} />}
                >
                  Replace Image
                </Button>
              ) : (
                <>
                  <Button
                    variant="outline"
                    onClick={() => setIsReplacing(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={handleSave}
                    isLoading={isSaving}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </HStack>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
