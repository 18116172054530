import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
  Tooltip,
  HStack,
  Box,
  Badge,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
  Radio,
  RadioGroup,
  VStack,
  Divider,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  MdChevronRight,
  MdChevronLeft,
  MdPerson,
  MdReceipt,
  MdVisibility,
  MdFilterList,
} from 'react-icons/md';
import { getSoldMemberships } from 'api/product';
import DebouncedInput from 'components/DebouncedInput';
import MembershipAnalyticsWidget from 'components/loyalty/MembershipAnalyticsWidget';
import { createPages } from 'utils/helpers';
import { FaDownload } from 'react-icons/fa';
import exportToCSV from 'utils/exportUtils';

export default function LoyaltyMembershipsList() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Filter state - Initialize from URL params if available
  const [loyaltyProgramUuid, setLoyaltyProgramUuid] = useState(
    queryParams.get('program') || '',
  );
  const [loyaltyLevelUuid, setLoyaltyLevelUuid] = useState(
    queryParams.get('level') || '',
  );
  const [userUuid, setUserUuid] = useState(queryParams.get('user') || '');
  const [expirationPeriod, setExpirationPeriod] = useState(
    queryParams.get('expiration') || 'all',
  );
  const [purchasePeriod, setPurchasePeriod] = useState(
    queryParams.get('purchase') || 'all',
  );

  const [memberships, setMemberships] = useState([]);
  const [filteredMemberships, setFilteredMemberships] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  // For filter drawer
  const { isOpen, onOpen, onClose } = useDisclosure();

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const navigate = useNavigate();

  const handleExportCSV = () => {
    // Define the columns for export
    const exportColumns = [
      { header: 'Membership Level', accessor: 'loyalty_level_name' },
      { header: 'Program Name', accessor: 'loyalty_program_name' },
      {
        header: 'Member Name',
        accessor: (row) => row.user.name || '',
      },
      { header: 'Member Email', accessor: 'user.email' },
      {
        header: 'Purchase Price',
        accessor: (row) => `€${row.purchase_price.toFixed(2)}`,
      },
      {
        header: 'Purchase Date',
        accessor: (row) => new Date(row.purchase_date).toLocaleDateString(),
      },
      {
        header: 'Expiration Date',
        accessor: (row) => new Date(row.expiration_date).toLocaleDateString(),
      },
      {
        header: 'Status',
        accessor: (row) =>
          new Date(row.expiration_date) < new Date() ? 'Expired' : 'Active',
      },
    ];

    // Export all filtered data (not just the current page)
    exportToCSV(filteredMemberships, exportColumns, 'membership-data');
  };

  useEffect(() => {
    const fetchMemberships = async () => {
      try {
        const soldMemberships = await getSoldMemberships();
        setMemberships(soldMemberships);
        setFilteredMemberships(soldMemberships);
      } catch (error) {
        console.error('Error getting sold memberships:', error);
      }
    };
    fetchMemberships();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();
    if (loyaltyProgramUuid) params.set('program', loyaltyProgramUuid);
    if (loyaltyLevelUuid) params.set('level', loyaltyLevelUuid);
    if (userUuid) params.set('user', userUuid);
    if (expirationPeriod !== 'all') params.set('expiration', expirationPeriod);
    if (purchasePeriod !== 'all') params.set('purchase', purchasePeriod);

    const newUrl = `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`;
    window.history.replaceState(null, '', newUrl);
  }, [
    loyaltyProgramUuid,
    loyaltyLevelUuid,
    userUuid,
    expirationPeriod,
    purchasePeriod,
  ]);

  // Extract unique values for filter dropdowns
  const uniqueLoyaltyPrograms = useMemo(() => {
    const programs = [
      ...new Map(
        memberships.map((m) => [
          m.loyalty_program_uuid,
          { uuid: m.loyalty_program_uuid, name: m.loyalty_program_name },
        ]),
      ).values(),
    ];
    return programs.filter((p) => p.name); // Filter out null names
  }, [memberships]);

  const uniqueLoyaltyLevels = useMemo(() => {
    const levels = [
      ...new Map(
        memberships.map((m) => [
          m.loyalty_level_uuid,
          { uuid: m.loyalty_level_uuid, name: m.loyalty_level_name },
        ]),
      ).values(),
    ];
    return levels.filter((l) => l.name); // Filter out null names
  }, [memberships]);

  const uniqueUsers = useMemo(() => {
    const users = [
      ...new Map(
        memberships.map((m) => [
          m.user.uuid,
          { uuid: m.user.uuid, name: m.user.name || m.user.email },
        ]),
      ).values(),
    ];
    return users.filter((u) => u.name); // Filter out null names
  }, [memberships]);

  // Apply filters
  useEffect(() => {
    let result = [...memberships];

    // Filter by loyalty program
    if (loyaltyProgramUuid) {
      result = result.filter(
        (m) => m.loyalty_program_uuid === loyaltyProgramUuid,
      );
    }

    // Filter by loyalty level
    if (loyaltyLevelUuid) {
      result = result.filter((m) => m.loyalty_level_uuid === loyaltyLevelUuid);
    }

    // Filter by user
    if (userUuid) {
      result = result.filter((m) => m.user.uuid === userUuid);
    }

    // Filter by expiration period
    const now = new Date();
    if (expirationPeriod === 'expired') {
      result = result.filter((m) => new Date(m.expiration_date) < now);
    } else if (expirationPeriod === 'active') {
      result = result.filter((m) => new Date(m.expiration_date) >= now);
    } else if (expirationPeriod === '30days') {
      const thirtyDaysLater = new Date();
      thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);
      result = result.filter((m) => {
        const expDate = new Date(m.expiration_date);
        return expDate >= now && expDate <= thirtyDaysLater;
      });
    } else if (expirationPeriod === '90days') {
      const ninetyDaysLater = new Date();
      ninetyDaysLater.setDate(ninetyDaysLater.getDate() + 90);
      result = result.filter((m) => {
        const expDate = new Date(m.expiration_date);
        return expDate >= now && expDate <= ninetyDaysLater;
      });
    }

    // Filter by purchase period
    if (purchasePeriod === 'last30days') {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      result = result.filter((m) => new Date(m.purchase_date) >= thirtyDaysAgo);
    } else if (purchasePeriod === 'last90days') {
      const ninetyDaysAgo = new Date();
      ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
      result = result.filter((m) => new Date(m.purchase_date) >= ninetyDaysAgo);
    } else if (purchasePeriod === 'lastYear') {
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      result = result.filter((m) => new Date(m.purchase_date) >= oneYearAgo);
    }

    setFilteredMemberships(result);
    // Reset to first page when filters change
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [
    memberships,
    loyaltyProgramUuid,
    loyaltyLevelUuid,
    userUuid,
    expirationPeriod,
    purchasePeriod,
  ]);

  const resetFilters = () => {
    setLoyaltyProgramUuid('');
    setLoyaltyLevelUuid('');
    setUserUuid('');
    setExpirationPeriod('all');
    setPurchasePeriod('all');
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('loyalty_level_name', {
      id: 'loyalty_level_name',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Membership Level
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('loyalty_program_name', {
      id: 'loyalty_program_name',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Program Name
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('user.name', {
      id: 'user_name',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Member
        </Text>
      ),
      cell: (info) => {
        // Show email if name is not available
        const userName = info.getValue() || info.row.original.user.email;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {userName}
          </Text>
        );
      },
    }),
    columnHelper.accessor('purchase_price', {
      id: 'purchase_price',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Purchase Price
        </Text>
      ),
      cell: (info) => {
        const purchase_price = info.getValue();
        const currency = 'EUR';
        const formattedPrice = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currency,
        }).format(purchase_price);
        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {formattedPrice}
          </Text>
        );
      },
    }),
    columnHelper.accessor('purchase_date', {
      id: 'purchase_date',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Purchase Date
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor('expiration_date', {
      id: 'expiration_date',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Expiration Date
        </Text>
      ),
      cell: (info) => {
        const expirationDate = new Date(info.getValue());
        const isExpired = expirationDate < new Date();

        return (
          <Text
            color={isExpired ? 'red.500' : textColor}
            fontSize="md"
            fontWeight="500"
            textDecoration={isExpired ? 'line-through' : 'none'}
          >
            {expirationDate.toLocaleDateString()}
            {isExpired && ' (Expired)'}
          </Text>
        );
      },
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Actions
        </Text>
      ),
      cell: (info) => {
        // Generate all URLs upfront
        const membershipUrl = `/admin/sold-memberships/${info.row.original.purchase_uuid}`;
        const userUrl = `/admin/users/user/${info.row.original.user.uuid}`;
        const orderUrl = `/admin/orders/${info.row.original.purchase_uuid}`;

        return (
          <HStack spacing={3} justify="center" width="100%">
            <Tooltip label="View Membership">
              <Box
                as="a"
                href={membershipUrl}
                onClick={(e) => {
                  if (e.button === 0) {
                    e.preventDefault();
                    navigate(membershipUrl);
                  }
                }}
                display="inline-block"
              >
                <Icon
                  w="20px"
                  h="20px"
                  as={MdVisibility}
                  cursor="pointer"
                  color={brandColor}
                />
              </Box>
            </Tooltip>

            <Tooltip label="View User">
              <Box
                as="a"
                href={userUrl}
                onClick={(e) => {
                  if (e.button === 0) {
                    e.preventDefault();
                    navigate(userUrl);
                  }
                }}
                display="inline-block"
              >
                <Icon
                  w="20px"
                  h="20px"
                  as={MdPerson}
                  cursor="pointer"
                  color={brandColor}
                />
              </Box>
            </Tooltip>

            <Tooltip label="View Order">
              <Box
                as="a"
                href={orderUrl}
                onClick={(e) => {
                  if (e.button === 0) {
                    e.preventDefault();
                    navigate(orderUrl);
                  }
                }}
                display="inline-block"
              >
                <Icon
                  w="20px"
                  h="20px"
                  as={MdReceipt}
                  cursor="pointer"
                  color={brandColor}
                />
              </Box>
            </Tooltip>
          </HStack>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: filteredMemberships,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  // Calculate filter indicator - how many filters are active
  const activeFiltersCount = [
    loyaltyProgramUuid,
    loyaltyLevelUuid,
    userUuid,
    expirationPeriod !== 'all',
    purchasePeriod !== 'all',
  ].filter(Boolean).length;

  return (
    <Flex direction="column" mt={{ sm: '150px', md: '125px', lg: '75px' }}>
      <MembershipAnalyticsWidget memberships={filteredMemberships} />

      <Card
        w="100%"
        px={{ base: '16px', md: '24px' }}
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify="space-between"
          mb="36px"
          mt="24px"
        >
          <DebouncedInput
            placeholder="Search memberships"
            onChange={(value) => setGlobalFilter(value)}
          />

          <HStack spacing={3}>
            <Button
              leftIcon={<FaDownload />}
              onClick={handleExportCSV}
              colorScheme="green"
              variant="outline"
            >
              Export CSV
            </Button>

            <Button
              leftIcon={<MdFilterList />}
              onClick={onOpen}
              colorScheme={activeFiltersCount > 0 ? 'blue' : 'gray'}
              variant={activeFiltersCount > 0 ? 'solid' : 'outline'}
            >
              Filter
              {activeFiltersCount > 0 && (
                <Badge ml={2} colorScheme="blue" borderRadius="full">
                  {activeFiltersCount}
                </Badge>
              )}
            </Button>
          </HStack>
        </Flex>
        <Table variant="simple" color="gray.500">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    borderColor={borderColor}
                    key={header.id}
                    colSpan={header.colSpan}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {header.isPlaceholder ? null : (
                      <Flex
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        justify="center"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                px="20px"
                key={row.id}
                bg={
                  new Date(row.original.expiration_date) < new Date()
                    ? 'red.50'
                    : 'transparent'
                }
                _dark={{
                  bg:
                    new Date(row.original.expiration_date) < new Date()
                      ? 'red.900'
                      : 'transparent',
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize={{ sm: '14px' }}
                    borderColor={borderColor}
                    textAlign="center"
                    py={4}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>

        {filteredMemberships.length === 0 && (
          <Flex justify="center" align="center" py={10}>
            <Text color="gray.500">
              No memberships match the current filters
            </Text>
          </Flex>
        )}

        <Flex
          w="100%"
          justify="space-between"
          align="center"
          px="20px"
          py="24px"
        >
          <Text fontSize="sm" color="gray.500" fontWeight="normal">
            Showing {pagination.pageSize * pagination.pageIndex + 1} to{' '}
            {pagination.pageSize * (pagination.pageIndex + 1) <=
            filteredMemberships.length
              ? pagination.pageSize * (pagination.pageIndex + 1)
              : filteredMemberships.length}{' '}
            of {filteredMemberships.length} entries
          </Text>
          <Stack direction="row" spacing="4px" ms="auto">
            <Button
              variant="no-effects"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={table.getCanPreviousPage() ? 'flex' : 'none'}
              _hover={{
                bg: 'whiteAlpha.100',
                opacity: '0.7',
              }}
            >
              <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
            </Button>
            {createPages(table.getPageCount()).map((pageNumber, index) => (
              <Button
                variant="no-effects"
                transition="all .5s ease"
                onClick={() => table.setPageIndex(pageNumber - 1)}
                w="40px"
                h="40px"
                borderRadius="50%"
                bg={
                  pageNumber === pagination.pageIndex + 1
                    ? brandColor
                    : 'transparent'
                }
                border={
                  pageNumber === pagination.pageIndex + 1
                    ? 'none'
                    : '1px solid lightgray'
                }
                _hover={
                  pageNumber === pagination.pageIndex + 1
                    ? { opacity: '0.7' }
                    : { bg: 'whiteAlpha.100' }
                }
                key={index}
              >
                <Text
                  fontSize="sm"
                  color={
                    pageNumber === pagination.pageIndex + 1 ? '#fff' : textColor
                  }
                >
                  {pageNumber}
                </Text>
              </Button>
            ))}
            <Button
              variant="no-effects"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={table.getCanNextPage() ? 'flex' : 'none'}
              _hover={{
                bg: 'whiteAlpha.100',
                opacity: '0.7',
              }}
            >
              <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
            </Button>
          </Stack>
        </Flex>
      </Card>

      {/* Filter Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Filter Memberships
          </DrawerHeader>

          <DrawerBody>
            <VStack spacing={6} align="start" mt={4}>
              {/* Loyalty Program Filter */}
              <FormControl>
                <FormLabel fontWeight="semibold">Loyalty Program</FormLabel>
                <Select
                  placeholder="All loyalty programs"
                  value={loyaltyProgramUuid}
                  onChange={(e) => setLoyaltyProgramUuid(e.target.value)}
                >
                  {uniqueLoyaltyPrograms.map((program) => (
                    <option key={program.uuid} value={program.uuid}>
                      {program.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* Loyalty Level Filter */}
              <FormControl>
                <FormLabel fontWeight="semibold">Membership Level</FormLabel>
                <Select
                  placeholder="All membership levels"
                  value={loyaltyLevelUuid}
                  onChange={(e) => setLoyaltyLevelUuid(e.target.value)}
                >
                  {uniqueLoyaltyLevels.map((level) => (
                    <option key={level.uuid} value={level.uuid}>
                      {level.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* Customer Filter */}
              <FormControl>
                <FormLabel fontWeight="semibold">Member</FormLabel>
                <Select
                  placeholder="All members"
                  value={userUuid}
                  onChange={(e) => setUserUuid(e.target.value)}
                >
                  {uniqueUsers.map((user) => (
                    <option key={user.uuid} value={user.uuid}>
                      {user.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* Expiration Period Filter */}
              <FormControl>
                <FormLabel fontWeight="semibold">Expiration Period</FormLabel>
                <RadioGroup
                  value={expirationPeriod}
                  onChange={setExpirationPeriod}
                >
                  <VStack align="start" spacing={2}>
                    <Radio value="all">All memberships</Radio>
                    <Radio value="active">Active memberships</Radio>
                    <Radio value="expired">Expired memberships</Radio>
                    <Radio value="30days">Expiring in 30 days</Radio>
                    <Radio value="90days">Expiring in 90 days</Radio>
                  </VStack>
                </RadioGroup>
              </FormControl>

              <Divider />

              {/* Purchase Period Filter */}
              <FormControl>
                <FormLabel fontWeight="semibold">Purchase Period</FormLabel>
                <RadioGroup value={purchasePeriod} onChange={setPurchasePeriod}>
                  <VStack align="start" spacing={2}>
                    <Radio value="all">All time</Radio>
                    <Radio value="last30days">Last 30 days</Radio>
                    <Radio value="last90days">Last 90 days</Radio>
                    <Radio value="lastYear">Last year</Radio>
                  </VStack>
                </RadioGroup>
              </FormControl>

              <Divider />

              {/* Filter Actions */}
              <Flex width="100%" justify="space-between" mt={4}>
                <Button
                  colorScheme="red"
                  variant="outline"
                  onClick={resetFilters}
                >
                  Clear Filters
                </Button>
                <Button colorScheme="blue" onClick={onClose}>
                  Apply Filters
                </Button>
              </Flex>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
