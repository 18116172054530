import React, { useState, useContext } from 'react';
import {
  Box,
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Input,
  Textarea,
  Switch,
  Select,
  Button,
  Spinner,
  Collapse,
  IconButton,
  Alert,
  AlertIcon,
  SimpleGrid,
  Badge,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Divider,
  HStack,
  VStack,
  Icon,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowBackIcon,
} from '@chakra-ui/icons';
import { MdLockOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';

import Details from '../products/Details';
import CategoryTreeSelect from '../products/CategoryTreeSelect';

const getAvailableQuantity = (quantity, bookedQty, isFinite) => {
  if (!isFinite) return 'Unlimited';

  // Calculate available by subtracting both sold and booked quantities
  const total = quantity || 0;
  const booked = bookedQty || 0;
  const available = total - booked;
  return Math.max(available, 0); // Ensure it never goes negative
};

export default function ProductForm({
  languages,
  translations,
  handleTranslationChange,
  isFeatured,
  setIsFeatured,
  quantity,
  bookedQuantity,
  soldQuantity,
  handleQuantityChange,
  selectedCategory,
  categoriesTree,
  isLoadingCategories,
  handleCategoryChange,
  productType,
  setProductType,
  visibility,
  setVisibility,
  productPrice,
  setProductPrice,
  saveAction,
  leftChildren,
  children,
  afterDetails,
  additionalFields,
  setAdditionalFields,
  unlimitedQuantity,
  setUnlimitedQuantity,
  hideHeader = false,
}) {
  const navigate = useNavigate();
  const [isAdditionalFieldsOpen, setIsAdditionalFieldsOpen] = useState(false);
  const { userRole } = useContext(AuthContext);
  const isViewer = userRole === 'VIEWER';

  const toggleAdditionalFields = () => {
    setIsAdditionalFieldsOpen(!isAdditionalFieldsOpen);
  };

  // Helper function to render read-only field
  const renderReadOnlyField = (value, placeholder = 'No data') => {
    return (
      <Box
        p={2}
        borderWidth="1px"
        borderRadius="md"
        bg="gray.50"
        minHeight="40px"
      >
        {value || <Text color="gray.400">{placeholder}</Text>}
      </Box>
    );
  };

  const findCategoryName = (categoriesList, targetUuid) => {
    if (!targetUuid || !categoriesList) return null;

    for (const category of categoriesList) {
      if (category.value === targetUuid) {
        return category.label;
      }

      if (category.children && category.children.length > 0) {
        const found = findCategoryName(category.children, targetUuid);
        if (found) return found;
      }
    }

    return null;
  };

  return (
    <Box
      pt={{
        base: hideHeader ? '0' : '20px',
        md: hideHeader ? '0' : '20px',
        xl: hideHeader ? '0' : '10px',
      }}
      maxW="1600px"
      mx="auto"
    >
      {/* Header with back button */}
      {!hideHeader && (
        <HStack spacing={3} mb={6}>
          <IconButton
            icon={<ArrowBackIcon />}
            variant="outline"
            rounded="full"
            onClick={() => navigate(-1)}
            aria-label="Go back"
          />
          <Heading size="lg">Product Details</Heading>
          {visibility && (
            <Badge
              colorScheme={
                visibility === 'public'
                  ? 'green'
                  : visibility === 'private'
                    ? 'purple'
                    : 'gray'
              }
              variant="solid"
              fontSize="md"
              px={3}
              py={1}
              borderRadius="full"
              ml={2}
            >
              {visibility.charAt(0).toUpperCase() + visibility.slice(1)}
            </Badge>
          )}
        </HStack>
      )}
      {isViewer && !hideHeader && (
        <Alert status="info" mb={4}>
          <AlertIcon />
          You are in view-only mode. Contact an administrator to make changes.
        </Alert>
      )}
      <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={8} mb={8}>
        {/* Left Column: Product Details */}
        <Flex direction="column" flex="1">
          {leftChildren && <Box mb="20px">{leftChildren}</Box>}

          {/* Product Information Card */}
          <Card
            variant="outline"
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="md"
            overflow="hidden"
            mb={6}
          >
            <CardHeader bg="blue.50" py={4}>
              <Heading size="md">Product Information</Heading>
            </CardHeader>
            <CardBody px={6} pt={4} pb={5}>
              <Tabs variant="enclosed" colorScheme="blue" mb="20px">
                <TabList>
                  {languages.map((lang) => (
                    <Tab key={lang.code}>{lang.name}</Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {languages.map((lang) => (
                    <TabPanel key={lang.code}>
                      <Box mb="20px">
                        <Text mb="10px" color="gray.500" fontSize="sm">
                          Product Name
                        </Text>
                        {isViewer ? (
                          renderReadOnlyField(
                            translations[lang.code]?.name,
                            'No product name',
                          )
                        ) : (
                          <Input
                            placeholder="Product Name"
                            value={translations[lang.code]?.name || ''}
                            onChange={(e) =>
                              handleTranslationChange(
                                lang.code,
                                'name',
                                e.target.value,
                              )
                            }
                          />
                        )}
                      </Box>
                      <Box mb="20px">
                        <Text mb="10px" color="gray.500" fontSize="sm">
                          Product Description
                        </Text>
                        {isViewer ? (
                          renderReadOnlyField(
                            translations[lang.code]?.description,
                            'No description',
                          )
                        ) : (
                          <Textarea
                            placeholder="Product Description"
                            value={translations[lang.code]?.description || ''}
                            onChange={(e) =>
                              handleTranslationChange(
                                lang.code,
                                'description',
                                e.target.value,
                              )
                            }
                            height="120px"
                            resize="vertical"
                          />
                        )}
                      </Box>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </CardBody>
          </Card>

          {/* Category Selection Card */}
          <Card
            variant="outline"
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="md"
            overflow="hidden"
            mb={6}
          >
            <CardHeader bg="blue.50" py={4}>
              <Heading size="md">Category</Heading>
            </CardHeader>
            <CardBody px={6} pt={4} pb={5}>
              {isLoadingCategories ? (
                <Flex justify="center" align="center" height="100px">
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    color="blue.500"
                    size="lg"
                  />
                </Flex>
              ) : isViewer ? (
                renderReadOnlyField(
                  findCategoryName(categoriesTree, selectedCategory) ||
                    'No category selected',
                )
              ) : (
                <CategoryTreeSelect
                  categories={categoriesTree}
                  selectedCategoryUuid={selectedCategory}
                  onSelectCategory={handleCategoryChange}
                />
              )}
            </CardBody>
          </Card>

          {/* Product Settings Card */}
          <Card
            variant="outline"
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="md"
            overflow="hidden"
            mb={6}
          >
            <CardHeader bg="blue.50" py={4}>
              <Heading size="md">Product Settings</Heading>
            </CardHeader>
            <CardBody px={6} pt={4} pb={5}>
              <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4} mb={4}>
                {/* Product Type */}
                <Box>
                  <Text mb="10px" color="gray.500" fontSize="sm">
                    Product Type
                  </Text>
                  {isViewer ? (
                    renderReadOnlyField(
                      productType === 'physical'
                        ? 'Physical'
                        : productType === 'virtual'
                          ? 'Virtual'
                          : productType === 'downloadable'
                            ? 'Downloadable'
                            : '',
                    )
                  ) : (
                    <Select
                      placeholder="Select product type"
                      value={productType}
                      onChange={(e) => setProductType(e.target.value)}
                    >
                      <option value="physical">Physical</option>
                      <option value="virtual">Virtual</option>
                      <option value="downloadable">Downloadable</option>
                    </Select>
                  )}
                </Box>

                {/* Visibility */}
                <Box>
                  <Text mb="10px" color="gray.500" fontSize="sm">
                    Visibility
                  </Text>
                  {isViewer ? (
                    renderReadOnlyField(
                      visibility === 'public'
                        ? 'Public'
                        : visibility === 'private'
                          ? 'Private'
                          : visibility === 'hidden'
                            ? 'Hidden'
                            : '',
                    )
                  ) : (
                    <Select
                      value={visibility}
                      onChange={(e) => setVisibility(e.target.value)}
                    >
                      <option value="public">Public</option>
                      <option value="private">Private</option>
                      <option value="hidden">Hidden</option>
                    </Select>
                  )}
                </Box>
              </SimpleGrid>

              <Divider my={4} borderColor="gray.200" />

              {/* Stock Information */}
              <Box mb={4}>
                <Text fontWeight="medium" mb={3}>
                  Stock Information
                </Text>

                {isViewer ? (
                  <VStack align="flex-start" spacing={2}>
                    <Text>Unlimited: {unlimitedQuantity ? 'Yes' : 'No'}</Text>
                    {!unlimitedQuantity && (
                      <>
                        <Text>Total Quantity: {quantity}</Text>
                        <Text>
                          Available:{' '}
                          {getAvailableQuantity(
                            quantity,
                            soldQuantity,
                            bookedQuantity,
                            !unlimitedQuantity,
                          )}
                        </Text>
                        {bookedQuantity > 0 && (
                          <Text color="orange.600">
                            Reserved: {bookedQuantity}
                          </Text>
                        )}
                      </>
                    )}
                  </VStack>
                ) : (
                  <>
                    <Flex align="center" mb="3">
                      <Switch
                        isChecked={unlimitedQuantity}
                        onChange={(e) => setUnlimitedQuantity(e.target.checked)}
                        mr="3"
                        colorScheme="blue"
                      />
                      <Text>Unlimited Stock</Text>
                    </Flex>
                    {!unlimitedQuantity && (
                      <>
                        <Input
                          type="text"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          placeholder="Quantity"
                          value={quantity}
                          onChange={(e) => handleQuantityChange(e.target.value)}
                          mb={3}
                        />

                        {/* Display status of quantities */}
                        <Box
                          mt={2}
                          mb={3}
                          p={3}
                          borderWidth="1px"
                          borderRadius="md"
                          bg="gray.50"
                        >
                          <HStack spacing={4} flexWrap="wrap">
                            <Text fontSize="sm" color="green.600">
                              <Text as="span" fontWeight="bold">
                                {getAvailableQuantity(
                                  quantity,
                                  bookedQuantity,
                                  !unlimitedQuantity,
                                )}
                              </Text>{' '}
                              available
                            </Text>

                            {bookedQuantity > 0 && (
                              <Text fontSize="sm" color="orange.600">
                                <Text as="span" fontWeight="bold">
                                  {bookedQuantity}
                                </Text>{' '}
                                reserved
                              </Text>
                            )}
                          </HStack>
                        </Box>
                      </>
                    )}

                    {unlimitedQuantity && (
                      <Text fontSize="sm" color="gray.600" mb={3}>
                        {bookedQuantity > 0 && (
                          <>
                            {' '}
                            • Currently Reserved:{' '}
                            <Text as="span" fontWeight="bold">
                              {bookedQuantity}
                            </Text>
                          </>
                        )}
                      </Text>
                    )}
                  </>
                )}
              </Box>

              <Divider my={4} borderColor="gray.200" />

              {/* Featured Product */}
              <Flex align="center" mt={2}>
                {isViewer ? (
                  <HStack spacing={3}>
                    <Text fontWeight="medium">Featured Product:</Text>
                    <Badge colorScheme={isFeatured ? 'green' : 'gray'}>
                      {isFeatured ? 'Yes' : 'No'}
                    </Badge>
                  </HStack>
                ) : (
                  <>
                    <Switch
                      id="isFeatured"
                      isChecked={isFeatured}
                      onChange={(e) => setIsFeatured(e.target.checked)}
                      mr={3}
                      colorScheme="blue"
                    />
                    <Text fontWeight="medium">Featured Product</Text>
                  </>
                )}
              </Flex>
            </CardBody>
          </Card>
        </Flex>

        {/* Right Column */}
        <Flex direction="column" flex="1">
          {children}

          {/* Pricing Details */}
          <Box mb={6}>
            <Details
              productPrice={productPrice}
              setProductPrice={setProductPrice}
              isViewer={isViewer}
            />
          </Box>

          {afterDetails}

          {/* Additional Fields Card */}
          <Card
            variant="outline"
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="md"
            overflow="hidden"
            mb={6}
          >
            <CardHeader bg="blue.50" py={4}>
              <Flex justify="space-between" align="center">
                <Heading size="md">Additional Fields</Heading>
                {!isViewer && (
                  <IconButton
                    aria-label={isAdditionalFieldsOpen ? 'Collapse' : 'Expand'}
                    icon={
                      isAdditionalFieldsOpen ? (
                        <ChevronUpIcon />
                      ) : (
                        <ChevronDownIcon />
                      )
                    }
                    onClick={toggleAdditionalFields}
                    size="sm"
                    variant="ghost"
                  />
                )}
              </Flex>
            </CardHeader>
            <CardBody px={6} pt={4} pb={5}>
              {isViewer && !isAdditionalFieldsOpen ? (
                <Box
                  p={4}
                  bg="gray.50"
                  borderRadius="md"
                  display="flex"
                  alignItems="center"
                >
                  <Icon
                    as={MdLockOutline}
                    mr={3}
                    color="gray.500"
                    boxSize={5}
                  />
                  <Text color="gray.600" fontSize="sm">
                    You have view-only access. Click to view additional fields.
                  </Text>
                </Box>
              ) : null}

              <Collapse in={isAdditionalFieldsOpen || isViewer}>
                <SimpleGrid
                  columns={{ base: 1, md: 2 }}
                  spacing={4}
                  mt={isViewer ? 4 : 0}
                >
                  {[
                    'sku',
                    'isbn',
                    'ean_upc',
                    'external_id',
                    'weight',
                    'weight_unit',
                    'external_url',
                    'tax_class',
                    'country_of_origin',
                    'brand_name',
                    'dimensions',
                  ].map((field) => (
                    <Box key={field}>
                      <Text mb="10px" color="gray.500" fontSize="sm">
                        {field.toUpperCase().replace('_', ' ')}
                      </Text>
                      {isViewer ? (
                        renderReadOnlyField(additionalFields?.[field])
                      ) : (
                        <Input
                          placeholder={field.toUpperCase().replace('_', ' ')}
                          value={additionalFields?.[field] || ''}
                          onChange={(e) =>
                            setAdditionalFields((prev) => ({
                              ...prev,
                              [field]: e.target.value,
                            }))
                          }
                        />
                      )}
                    </Box>
                  ))}
                </SimpleGrid>
              </Collapse>
            </CardBody>
          </Card>

          {/* Action Buttons */}
          <Flex mt={2} gap={3}>
            <Button
              colorScheme="blue"
              onClick={saveAction}
              isDisabled={!productPrice || isViewer}
              size="md"
              px={6}
            >
              Save Changes
            </Button>

            <Button variant="outline" onClick={() => navigate(-1)} size="md">
              Cancel
            </Button>
          </Flex>
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
