import React, { useState, useEffect, useContext } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Text,
  Flex,
  Input,
  Image,
  Button,
  NumberInput,
  NumberInputField,
  Checkbox,
  Select,
  HStack,
  VStack,
  Switch,
  useToast,
  Spinner,
  Textarea,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  IconButton,
  SimpleGrid,
  useColorModeValue,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Container,
  Divider,
  Badge,
  Icon,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { StarIcon, DeleteIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  MdArrowBack,
  MdShoppingCart,
  MdCardMembership,
  MdAttachMoney,
  MdTimelapse,
  MdImage,
  MdComment,
} from 'react-icons/md';
import DropzoneLoyalty from 'components/loyalty/LoyaltyDropzone';
import LoyaltyImageModal from 'components/loyalty/LoyaltyImageModal';

import {
  handleImageUpload,
  handleSetFeaturedImage,
  handleDeleteImage,
  getProductDetails,
  updateProduct,
} from 'api/product';
import { getLoyaltyLevels, updateLoyaltyLevel } from 'api/loyalty';
import { convertToDays } from 'utils/loyaltyUtils';
import LoyaltyLevelModalAlert from 'components/loyalty/LoyaltyLevelModalAlert';
import { setTranslation } from 'utils/translationUtils';
import { getAllLoyaltyLevelArt } from 'api/loyalty';
import { addLoyaltyLevelArt } from 'api/loyalty';
import { AuthContext } from 'contexts/AuthContext';

const TIME_UNITS = [
  { days: 365, name: 'years' },
  { days: 30, name: 'months' },
  { days: 7, name: 'weeks' },
  { days: 1, name: 'days' },
];

const convertDaysToOptimalUnit = (days) => {
  if (!days) return { value: 0, unit: 'days' };

  for (const unit of TIME_UNITS) {
    if (days >= unit.days && days % unit.days === 0) {
      return { value: days / unit.days, unit: unit.name };
    }
  }
  return { value: days, unit: 'days' };
};

export default function LoyaltyLevelDetail() {
  const { levelUuid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [isUploading, setIsUploading] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const cancelRef = React.useRef();

  const headerBg = useColorModeValue('blue.50', 'navy.800');
  const labelColor = useColorModeValue('gray.500', 'gray.400');
  const borderColor = useColorModeValue('gray.200', 'navy.600');

  const [isLoading, setIsLoading] = useState(true);
  const [languages] = useState([
    { code: 'en', name: 'English' },
    { code: 'et', name: 'Estonian' },
  ]);
  const [artImages, setArtImages] = useState([]);
  const [isArtUploading, setIsArtUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const [levelData, setLevelData] = useState({
    product_uuid: '',
    loyalty_id: '',
    translations: languages.flatMap((lang) => [
      { language: lang.code, key: 'name', value: '' },
      { language: lang.code, key: 'description', value: '' },
    ]),
    price: 0,
    expirationTimePeriod: 0,
    expirationTimeUnit: 'days',
    is_archived: false,
    is_active: true,
    is_art_random: false,
    is_art_unique: false,
    quantity: 0,
    booked_quantity: 0,
    sold_quantity: 0,
    has_expiration: false,
    images: [],
  });

  const { userRole } = useContext(AuthContext);
  const isViewer = userRole === 'VIEWER';

  useEffect(() => {
    const fetchLevelData = async () => {
      setIsLoading(true);
      try {
        const levels = await getLoyaltyLevels();
        const level = levels.find((level) => level.uuid === levelUuid);
        if (level) {
          const productResponse = await getProductDetails(level.product_uuid);

          try {
            const loyaltyArt = await getAllLoyaltyLevelArt(levelUuid);
            setArtImages(Array.isArray(loyaltyArt) ? loyaltyArt : []);
          } catch (error) {
            console.error('Error fetching art images:', error);
            setArtImages([]);
          }

          const { value, unit } = convertDaysToOptimalUnit(
            level.expiration_period,
          );

          const translationsArray = languages.flatMap((language) => [
            { language: language.code, key: 'name', value: '' },
            { language: language.code, key: 'description', value: '' },
          ]);

          // Update translations with actual values if they exist
          if (level.translations && level.translations.length > 0) {
            level.translations.forEach((translation) => {
              const index = translationsArray.findIndex(
                (t) =>
                  t.language === translation.language &&
                  t.key === translation.key,
              );
              if (index !== -1) {
                translationsArray[index].value = translation.value;
              }
            });
          }

          setLevelData({
            product_uuid: level.product_uuid,
            loyalty_id: level.loyalty_id,
            translations: translationsArray,
            price: level.price,
            expirationTimePeriod: value,
            expirationTimeUnit: unit,
            is_archived: level.is_archived,
            is_active: level.is_active,
            is_art_random: level.is_art_random,
            is_art_unique: level.is_art_unique,
            quantity: level.quantity,
            booked_quantity: level.booked_quantity || 0,
            is_finite_quantity: level.is_finite_quantity,
            sold_quantity: level.sold_quantity || 0,
            has_expiration: !!level.expiration_period,
            images:
              productResponse?.images?.map((img) => ({
                uuid: img.uuid,
                image_url: img.image_url,
                thumb_url: img.thumb_url,
                is_featured: img.is_featured,
              })) || [],
          });
        }
      } catch (error) {
        console.error('Error fetching level data:', error);
        toast({
          title: 'Error',
          description: 'Failed to load loyalty level data',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchLevelData();
  }, [levelUuid, languages, toast]);

  // Preserve other useEffect hooks
  useEffect(() => {
    if (selectedImage && isImageModalOpen) {
      const updatedImage = artImages.find(
        (img) => img.uuid === selectedImage.uuid,
      );
      if (updatedImage) {
        setSelectedImage(updatedImage);
      }
    }
  }, [artImages, selectedImage, isImageModalOpen]);

  const getTranslationValue = (translations, language, key) => {
    if (!Array.isArray(translations)) {
      // Convert object format to array format if needed
      if (typeof translations === 'object') {
        return translations[language]?.[key] || '';
      }
      return '';
    }
    return (
      translations.find((t) => t.language === language && t.key === key)
        ?.value || ''
    );
  };

  const handleSave = async () => {
    if (isViewer) return;
    if (!levelData.translations || levelData.translations.length === 0) {
      toast({
        title: 'Error',
        description:
          'Please fill in the name and description for at least one language',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!levelData.price || levelData.price <= 0) {
      toast({
        title: 'Error',
        description: 'Please enter a valid price',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (
      levelData.is_finite_quantity &&
      (!levelData.quantity || levelData.quantity <= 0)
    ) {
      toast({
        title: 'Error',
        description: 'Please enter a valid quantity',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (
      levelData.has_expiration &&
      (!levelData.expirationTimePeriod || levelData.expirationTimePeriod <= 0)
    ) {
      toast({
        title: 'Error',
        description: 'Please enter a valid expiration time period',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const days = convertToDays(
        levelData.expirationTimePeriod,
        levelData.expirationTimeUnit,
      );

      await updateLoyaltyLevel(levelUuid, {
        expiration_period: levelData.has_expiration ? days : 0,
        is_active: levelData.is_active,
        is_archived: levelData.is_archived,
        is_art_random: levelData.is_art_random,
        is_art_unique: levelData.is_art_unique,
      });

      await updateProduct(levelData.product_uuid, {
        quantity: levelData.quantity,
        price: levelData.price,
        is_finite_quantity: levelData.is_finite_quantity,
        status: levelData.is_active ? 'active' : 'inactive',
        is_archived: levelData.is_archived,
      });

      for (const translation of levelData.translations) {
        if (translation.key === 'name' || translation.key === 'description') {
          await setTranslation(
            `product_${translation.key}_${levelData.product_uuid}`,
            translation.value,
            translation.language,
          );
        }
      }

      toast({
        title: 'Success',
        description: 'Loyalty level updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating loyalty level:', error);
      toast({
        title: 'Error',
        description: 'Failed to update loyalty level',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Keep all existing handlers and utility functions
  const handleImageUploadWrapper = (files) => {
    setIsUploading(true);
    handleImageUpload(files, levelData.product_uuid)
      .then((response) => {
        setTimeout(() => {
          setLevelData((prevData) => ({
            ...prevData,
            images: [...prevData.images, response],
          }));
          setIsUploading(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
        toast({
          title: 'Error',
          description: 'Failed to upload image',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsUploading(false);
      });
  };

  const handleSetFeaturedImageWrapper = (imageUuid) => {
    handleSetFeaturedImage(levelData.product_uuid, imageUuid)
      .then(() => {
        setLevelData((prevData) => ({
          ...prevData,
          images: prevData.images.map((image) => ({
            ...image,
            is_featured: image.uuid === imageUuid,
          })),
        }));
      })
      .catch((error) => {
        console.error('Error setting featured image:', error);
        toast({
          title: 'Error',
          description: 'Failed to set featured image',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleDeleteImageWrapper = (imageUuid) => {
    handleDeleteImage(levelData.product_uuid, imageUuid)
      .then(() => {
        setLevelData((prevData) => ({
          ...prevData,
          images: prevData.images.filter((image) => image.uuid !== imageUuid),
        }));
      })
      .catch((error) => {
        console.error('Error deleting image:', error);
        toast({
          title: 'Error',
          description: 'Failed to delete image',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleArtImageUpload = async (files) => {
    setIsArtUploading(true);

    const tempUrl = URL.createObjectURL(files[0]);
    const tempImage = {
      uuid: 'temp-' + Date.now(),
      image_url: tempUrl,
      occurrence_percentage: 0,
      isTemp: true,
    };

    setArtImages((prevImages) => [...prevImages, tempImage]);

    const formData = new FormData();
    formData.append('occurrence_percentage', 0);
    formData.append('file', files[0]);

    try {
      const response = await addLoyaltyLevelArt(levelUuid, formData);
      // Replace temporary image with actual response
      setArtImages((prevImages) =>
        prevImages.map((img) => (img.uuid === tempImage.uuid ? response : img)),
      );

      // If modal is open with the same level, update its state too
      if (isImageModalOpen) {
        setSelectedImage(response);
      }

      toast({
        title: 'Success',
        description: 'Art image uploaded successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      console.error(`Error uploading art image: ${error}`);
      toast({
        title: 'Error',
        description: 'Failed to upload art image',
        status: 'error',
        duration: 3000,
      });
    } finally {
      URL.revokeObjectURL(tempUrl);
      setIsArtUploading(false);
    }
  };

  const handleTranslationChange = (language, key, value) => {
    setLevelData((prev) => {
      const newTranslations = [...prev.translations];
      const translationIndex = newTranslations.findIndex(
        (translation) =>
          translation.language === language && translation.key === key,
      );

      if (translationIndex !== -1) {
        newTranslations[translationIndex].value = value;
      } else {
        newTranslations.push({
          language,
          key,
          value,
        });
      }

      return {
        ...prev,
        translations: newTranslations,
      };
    });
  };

  const handleArchive = async () => {
    try {
      await updateLoyaltyLevel(levelUuid, {
        is_archived: !levelData.is_archived,
      });
      await updateProduct(levelData.product_uuid, {
        is_archived: !levelData.is_archived,
      });
      setLevelData((prevData) => ({
        ...prevData,
        is_archived: !prevData.is_archived,
      }));
      setIsArchiveDialogOpen(false);
    } catch (error) {
      console.error('Error updating loyalty level:', error);
      toast({
        title: 'Error',
        description: 'Failed to update loyalty level',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleArtImageUpdate = (updatedImage) => {
    setArtImages((prevImages) =>
      prevImages.map((img) =>
        img.uuid === updatedImage.uuid
          ? {
              ...img,
              ...updatedImage,
              image_url: updatedImage.image_url,
            }
          : img,
      ),
    );

    if (selectedImage?.uuid === updatedImage.uuid) {
      setSelectedImage(updatedImage);
    }
  };

  const handleArtImageDelete = (imageUuid) => {
    setArtImages((prevImages) =>
      prevImages.filter((img) => img.uuid !== imageUuid),
    );
  };

  const calculateTotalOccurrence = (images) => {
    return images.reduce(
      (sum, image) => sum + (image.occurrence_percentage || 0),
      0,
    );
  };

  const getAvailableQuantity = (data) => {
    if (!data.is_finite_quantity) return 'Unlimited';

    const available = data.quantity - (data.booked_quantity || 0);
    return available > 0 ? available : 0;
  };

  // Loading state
  if (isLoading) {
    return (
      <Flex
        justify="center"
        align="center"
        h="100vh"
        pt={{ base: '130px', md: '80px', xl: '80px' }}
      >
        <Spinner size="xl" thickness="4px" color="blue.500" />
      </Flex>
    );
  }

  // Error state
  if (!levelData.product_uuid) {
    return (
      <Container centerContent pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Card p={8} textAlign="center" w="100%">
          <Icon
            as={MdComment}
            boxSize="50px"
            color="gray.300"
            mx="auto"
            mb={4}
          />
          <Heading size="md" mb={3}>
            No Loyalty Level Found
          </Heading>
          <Text color="gray.500">
            The loyalty level details could not be found.
          </Text>
          <Button
            mt={6}
            colorScheme="blue"
            onClick={() => navigate('/admin/loyalty-programs')}
          >
            Go To Loyalty Programs
          </Button>
        </Card>
      </Container>
    );
  }

  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      px={{ base: 4, md: 8 }}
      maxW="1600px"
      mx="auto"
    >
      {isViewer && (
        <Alert status="info" mb={4}>
          <AlertIcon />
          You are in view-only mode. Contact an administrator to make changes.
        </Alert>
      )}

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} mb={8}>
        {/* Level Information */}
        <Card
          variant="outline"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          overflow="hidden"
          height="fit-content"
        >
          <CardHeader bg={headerBg} py={4}>
            <Flex justify="space-between" align="center" width="100%">
              <HStack spacing={3}>
                <IconButton
                  icon={<MdArrowBack />}
                  variant="outline"
                  size="sm"
                  rounded="full"
                  onClick={() =>
                    navigate(`/admin/loyalty-programs/${levelData.loyalty_id}`)
                  }
                  aria-label="Back to loyalty program"
                />
                <Icon as={MdCardMembership} color="blue.500" boxSize="20px" />
                <Heading size="md">Basic Information</Heading>
              </HStack>

              <Flex align="center">
                <Badge
                  colorScheme={levelData.is_active ? 'green' : 'red'}
                  variant="solid"
                  fontSize="sm"
                  px={2}
                  py={0.5}
                  borderRadius="full"
                >
                  {levelData.is_active ? 'Active' : 'Inactive'}
                </Badge>

                {levelData.is_archived && (
                  <Badge
                    colorScheme="gray"
                    variant="solid"
                    fontSize="sm"
                    px={2}
                    py={0.5}
                    borderRadius="full"
                    mr={2}
                  >
                    Archived
                  </Badge>
                )}
                <Switch
                  isChecked={levelData.is_active}
                  onChange={(e) =>
                    !isViewer &&
                    setLevelData({
                      ...levelData,
                      is_active: e.target.checked,
                    })
                  }
                  colorScheme="blue"
                  size="md"
                  ml={2}
                  isDisabled={isViewer}
                />
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody p={6}>
            <VStack spacing={4} align="stretch">
              {/* Language Tabs */}
              <Box>
                <Text color={labelColor} fontSize="sm" mb={1}>
                  Level Details
                </Text>
                <Tabs colorScheme="blue" variant="enclosed" size="md">
                  <TabList>
                    {languages?.map((language) => (
                      <Tab key={language.code}>
                        {language.code === 'en' ? 'English' : 'Estonian'}
                      </Tab>
                    ))}
                  </TabList>
                  <TabPanels>
                    {languages?.map((lang) => (
                      <TabPanel key={lang.code} px={0} pt={4}>
                        <VStack spacing={4} align="stretch">
                          <Box>
                            <Text color={labelColor} fontSize="sm" mb={1}>
                              Name
                            </Text>
                            {isViewer ? (
                              <Box
                                p={3}
                                borderWidth="1px"
                                borderRadius="md"
                                bg="gray.50"
                              >
                                {getTranslationValue(
                                  levelData.translations,
                                  lang.code,
                                  'name',
                                ) || (
                                  <Text color="gray.400">
                                    No data available
                                  </Text>
                                )}
                              </Box>
                            ) : (
                              <Input
                                placeholder="Name"
                                value={getTranslationValue(
                                  levelData.translations,
                                  lang.code,
                                  'name',
                                )}
                                onChange={(e) =>
                                  handleTranslationChange(
                                    lang.code,
                                    'name',
                                    e.target.value,
                                  )
                                }
                              />
                            )}
                          </Box>

                          <Box>
                            <Text color={labelColor} fontSize="sm" mb={1}>
                              Description
                            </Text>
                            {isViewer ? (
                              <Box
                                p={3}
                                borderWidth="1px"
                                borderRadius="md"
                                bg="gray.50"
                                minH="100px"
                                overflowY="auto"
                              >
                                {getTranslationValue(
                                  levelData.translations,
                                  lang.code,
                                  'description',
                                ) || (
                                  <Text color="gray.400">
                                    No description available
                                  </Text>
                                )}
                              </Box>
                            ) : (
                              <Textarea
                                placeholder="Description"
                                value={getTranslationValue(
                                  levelData.translations,
                                  lang.code,
                                  'description',
                                )}
                                onChange={(e) =>
                                  handleTranslationChange(
                                    lang.code,
                                    'description',
                                    e.target.value,
                                  )
                                }
                                minH="100px"
                              />
                            )}
                          </Box>
                        </VStack>
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              </Box>

              <Divider borderColor={borderColor} />

              {/* Price and Quantity - Stacked Vertically */}
              <VStack spacing={4} align="stretch">
                {/* Price */}
                <Box>
                  <Text color={labelColor} fontSize="sm" mb={1}>
                    Price
                  </Text>
                  <HStack>
                    <Icon as={MdAttachMoney} color="green.500" />
                    {isViewer ? (
                      <Text fontSize="md" fontWeight="medium">
                        {levelData.price}€
                      </Text>
                    ) : (
                      <NumberInput
                        defaultValue={levelData.price}
                        min={0}
                        precision={2}
                        step={0.1}
                        onChange={(value) =>
                          setLevelData({
                            ...levelData,
                            price: value,
                          })
                        }
                      >
                        <NumberInputField placeholder="Price" />
                      </NumberInput>
                    )}
                  </HStack>
                </Box>

                {/* Quantity */}
                <Box>
                  <Text color={labelColor} fontSize="sm" mb={1}>
                    Quantity
                  </Text>
                  <HStack>
                    <Icon as={MdShoppingCart} color="purple.500" />
                    {isViewer ? (
                      <VStack align="start" spacing={0}>
                        <Text fontSize="md" fontWeight="medium">
                          {levelData.is_finite_quantity
                            ? `${getAvailableQuantity(levelData)} available of ${levelData.quantity} total`
                            : 'Unlimited'}
                        </Text>
                        {levelData.is_finite_quantity &&
                          levelData.booked_quantity > 0 && (
                            <Text fontSize="sm" color="orange.500">
                              ({levelData.booked_quantity} reserved but not
                              claimed)
                            </Text>
                          )}
                      </VStack>
                    ) : (
                      <Flex align="center" width="100%">
                        <NumberInput
                          isDisabled={!levelData.is_finite_quantity}
                          value={levelData.quantity ?? 0}
                          precision={0}
                          step={1}
                          min={0}
                          onChange={(value) =>
                            setLevelData({
                              ...levelData,
                              quantity: parseInt(value, 10) || 0,
                            })
                          }
                          width="120px"
                        >
                          <NumberInputField />
                        </NumberInput>
                        <Checkbox
                          ml={4}
                          isChecked={!levelData.is_finite_quantity}
                          onChange={(e) =>
                            setLevelData({
                              ...levelData,
                              is_finite_quantity: !e.target.checked,
                              quantity: e.target.checked
                                ? 0
                                : levelData.quantity,
                            })
                          }
                        >
                          Unlimited
                        </Checkbox>
                      </Flex>
                    )}
                  </HStack>

                  {levelData.is_finite_quantity && (
                    <Box mt={2}>
                      <HStack spacing={4}>
                        {levelData.booked_quantity > 0 && (
                          <Text fontSize="sm" color="orange.600">
                            <Text as="span" fontWeight="bold">
                              {levelData.booked_quantity}
                            </Text>{' '}
                            reserved
                          </Text>
                        )}
                        <Text fontSize="sm" color="green.600">
                          <Text as="span" fontWeight="bold">
                            {getAvailableQuantity(levelData)}
                          </Text>{' '}
                          available
                        </Text>
                      </HStack>
                    </Box>
                  )}
                </Box>
              </VStack>

              <Divider borderColor={borderColor} />

              {/* Expiration */}
              <Box>
                <Text color={labelColor} fontSize="sm" mb={1}>
                  Expiration Time
                </Text>
                <HStack>
                  <Icon as={MdTimelapse} color="blue.500" />
                  {isViewer ? (
                    <Text fontSize="md" fontWeight="medium">
                      {levelData.has_expiration
                        ? `${levelData.expirationTimePeriod} ${levelData.expirationTimeUnit}`
                        : 'No expiration'}
                    </Text>
                  ) : (
                    <Flex align="center" width="100%">
                      <NumberInput
                        isDisabled={!levelData.has_expiration}
                        value={levelData.expirationTimePeriod}
                        min={0}
                        onChange={(value) =>
                          setLevelData({
                            ...levelData,
                            expirationTimePeriod: value,
                          })
                        }
                        width="120px"
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Select
                        ml={2}
                        isDisabled={!levelData.has_expiration}
                        value={levelData.expirationTimeUnit}
                        onChange={(e) =>
                          setLevelData({
                            ...levelData,
                            expirationTimeUnit: e.target.value,
                          })
                        }
                        width="120px"
                      >
                        <option value="days">Days</option>
                        <option value="weeks">Weeks</option>
                        <option value="months">Months</option>
                        <option value="years">Years</option>
                      </Select>
                      <Checkbox
                        ml={4}
                        isChecked={levelData.has_expiration}
                        onChange={(e) =>
                          setLevelData({
                            ...levelData,
                            has_expiration: e.target.checked,
                          })
                        }
                      >
                        Expires?
                      </Checkbox>
                    </Flex>
                  )}
                </HStack>
              </Box>

              {/* Footer actions */}
              {!isViewer && (
                <Flex mt={6} justify="space-between">
                  <Button
                    leftIcon={
                      levelData.is_archived ? undefined : <DeleteIcon />
                    }
                    colorScheme={levelData.is_archived ? 'blue' : 'red'}
                    variant="outline"
                    onClick={() => setIsArchiveDialogOpen(true)}
                  >
                    {levelData.is_archived
                      ? 'Unarchive Level'
                      : 'Archive Level'}
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={handleSave}
                    isDisabled={isViewer}
                  >
                    Save Changes
                  </Button>
                </Flex>
              )}
            </VStack>
          </CardBody>
        </Card>

        {/* Images and Art Settings */}
        <VStack spacing={8}>
          {/* Level Images */}
          <Card
            variant="outline"
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="md"
            overflow="hidden"
            width="100%"
          >
            <CardHeader bg={headerBg} py={4}>
              <HStack>
                <Icon as={MdImage} color="blue.500" boxSize="20px" />
                <Heading size="md">Level Images</Heading>
              </HStack>
            </CardHeader>
            <CardBody p={6}>
              {!isViewer && (
                <Box mb={6}>
                  <DropzoneLoyalty
                    onDrop={handleImageUploadWrapper}
                    height="120px"
                    width="100%"
                    iconSize="30px"
                  />
                </Box>
              )}

              {levelData.images.length > 0 ? (
                <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4}>
                  {levelData.images.map((image) => (
                    <Box
                      key={image.uuid}
                      position="relative"
                      borderWidth="1px"
                      borderRadius="md"
                      overflow="hidden"
                    >
                      <Image
                        src={image.thumb_url || image.image_url}
                        alt="Level image"
                        objectFit="cover"
                        w="100%"
                        h="120px"
                      />
                      {!isViewer && (
                        <HStack
                          position="absolute"
                          top={2}
                          right={2}
                          spacing={1}
                        >
                          <IconButton
                            icon={
                              image.is_featured ? (
                                <StarIcon color="yellow.500" />
                              ) : (
                                <StarIcon />
                              )
                            }
                            size="sm"
                            onClick={() =>
                              handleSetFeaturedImageWrapper(image.uuid)
                            }
                            bg="whiteAlpha.800"
                            _hover={{ bg: 'whiteAlpha.900' }}
                          />
                          <IconButton
                            icon={<DeleteIcon />}
                            size="sm"
                            onClick={() => handleDeleteImageWrapper(image.uuid)}
                            bg="whiteAlpha.800"
                            _hover={{ bg: 'whiteAlpha.900' }}
                          />
                        </HStack>
                      )}
                      {image.is_featured && (
                        <Badge
                          position="absolute"
                          bottom={2}
                          left={2}
                          colorScheme="yellow"
                          borderRadius="full"
                          px={2}
                        >
                          Featured
                        </Badge>
                      )}
                    </Box>
                  ))}
                </SimpleGrid>
              ) : (
                <Flex justify="center" align="center" py={8} direction="column">
                  <Icon as={MdComment} boxSize="30px" color="gray.300" mb={2} />
                  <Text color="gray.500">No images uploaded yet</Text>
                </Flex>
              )}

              {isUploading && (
                <Flex justify="center" mt={4}>
                  <Spinner />
                </Flex>
              )}
            </CardBody>
          </Card>

          {/* Art Images */}
          <Card
            variant="outline"
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="md"
            overflow="hidden"
            width="100%"
          >
            <CardHeader bg={headerBg} py={4}>
              <HStack>
                <Icon as={MdImage} color="purple.500" boxSize="20px" />
                <Heading size="md">Art Images</Heading>
              </HStack>
            </CardHeader>
            <CardBody p={6}>
              <VStack spacing={4} align="stretch">
                <HStack spacing={8}>
                  <HStack>
                    <Checkbox
                      isChecked={levelData.is_art_random}
                      onChange={(e) =>
                        !isViewer &&
                        setLevelData({
                          ...levelData,
                          is_art_random: e.target.checked,
                          is_art_unique: e.target.checked
                            ? false
                            : levelData.is_art_unique,
                        })
                      }
                      isDisabled={isViewer}
                      colorScheme="blue"
                    >
                      Random Distribution
                    </Checkbox>
                    <Tooltip
                      label="Images will be randomly distributed to users when checked"
                      fontSize="xs"
                    >
                      <InfoOutlineIcon
                        boxSize={4}
                        color="gray.500"
                        cursor="help"
                      />
                    </Tooltip>
                  </HStack>

                  <HStack>
                    <Checkbox
                      isChecked={levelData.is_art_unique}
                      onChange={(e) =>
                        !isViewer &&
                        setLevelData({
                          ...levelData,
                          is_art_unique: e.target.checked,
                          is_art_random: e.target.checked
                            ? false
                            : levelData.is_art_random,
                        })
                      }
                      isDisabled={isViewer}
                      colorScheme="blue"
                    >
                      Unique Distribution
                    </Checkbox>
                    <Tooltip
                      label="Each image will be used only once when checked"
                      fontSize="xs"
                    >
                      <InfoOutlineIcon
                        boxSize={4}
                        color="gray.500"
                        cursor="help"
                      />
                    </Tooltip>
                  </HStack>
                </HStack>

                {calculateTotalOccurrence(artImages) > 100 && (
                  <Alert status="warning" borderRadius="md">
                    <AlertIcon />
                    <Text fontSize="sm">
                      Total occurrence percentage (
                      {calculateTotalOccurrence(artImages)}%) exceeds 100%.
                      Adjust to avoid calculation errors.
                    </Text>
                  </Alert>
                )}

                {!isViewer && (
                  <Box mb={4}>
                    <DropzoneLoyalty
                      onDrop={handleArtImageUpload}
                      height="120px"
                      width="100%"
                      iconSize="30px"
                    />
                  </Box>
                )}

                {artImages.length > 0 ? (
                  <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4}>
                    {artImages.map((image) => (
                      <Box
                        key={image.uuid}
                        position="relative"
                        borderWidth="1px"
                        borderRadius="md"
                        overflow="hidden"
                        cursor="pointer"
                        onClick={() => {
                          setSelectedImage(image);
                          setIsImageModalOpen(true);
                        }}
                        _hover={{
                          transform: 'scale(1.02)',
                          transition: '0.2s',
                        }}
                      >
                        <Image
                          src={image.image_url}
                          alt="Art image"
                          objectFit="cover"
                          w="100%"
                          h="120px"
                        />
                        {image.occurrence_percentage > 0 && (
                          <Badge
                            position="absolute"
                            bottom={2}
                            right={2}
                            colorScheme="purple"
                            borderRadius="full"
                            px={2}
                          >
                            {image.occurrence_percentage}%
                          </Badge>
                        )}
                      </Box>
                    ))}
                  </SimpleGrid>
                ) : (
                  <Flex
                    justify="center"
                    align="center"
                    py={8}
                    direction="column"
                  >
                    <Icon
                      as={MdComment}
                      boxSize="30px"
                      color="gray.300"
                      mb={2}
                    />
                    <Text color="gray.500">No art images uploaded yet</Text>
                  </Flex>
                )}

                {isArtUploading && (
                  <Flex justify="center" mt={4}>
                    <Spinner />
                  </Flex>
                )}
              </VStack>
            </CardBody>
          </Card>
        </VStack>
      </SimpleGrid>

      <LoyaltyLevelModalAlert
        isArchiveDialogOpen={isArchiveDialogOpen}
        setIsArchiveDialogOpen={setIsArchiveDialogOpen}
        levelModalData={levelData}
        handleArchive={handleArchive}
        cancelRef={cancelRef}
      />

      <LoyaltyImageModal
        isOpen={isImageModalOpen}
        onClose={() => {
          setIsImageModalOpen(false);
          setSelectedImage(null);
        }}
        image={selectedImage}
        setSelectedImage={setSelectedImage}
        levelUuid={levelUuid}
        onImageUpdate={!isViewer ? handleArtImageUpdate : undefined}
        onImageDelete={!isViewer ? handleArtImageDelete : undefined}
      />
    </Box>
  );
}
