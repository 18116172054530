import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import React from 'react';

export function LoyaltyArchiveAlert({
  isArchiveDialogOpen,
  setIsArchiveDialogOpen,
  selectedProgramId,
  loyaltyPrograms,
  handleArchive,
  cancelRef,
}) {
  return (
    <AlertDialog
      isOpen={isArchiveDialogOpen}
      leastDestructiveRef={cancelRef}
      onClose={() => setIsArchiveDialogOpen(false)}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Archive Loyalty Program
          </AlertDialogHeader>
          <AlertDialogBody>
            {selectedProgramId && loyaltyPrograms?.length > 0
              ? `Are you sure you want to ${
                  loyaltyPrograms.find(
                    (program) => program.uuid === selectedProgramId,
                  )?.is_archived
                    ? 'unarchive'
                    : 'archive'
                } this loyalty program?`
              : 'Loading...'}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => setIsArchiveDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleArchive} ml={3}>
              Archive
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export function LoyaltyDeleteAlert({
  isDeleteDialogOpen,
  onDeleteDialogClose,
  handleDeleteConfirm,
  cancelRef,
}) {
  return (
    <AlertDialog
      isOpen={isDeleteDialogOpen}
      leastDestructiveRef={cancelRef}
      onClose={onDeleteDialogClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Loyalty Program
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to delete this loyalty program? This will also
            delete all associated levels. You can't undo this action afterward.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onDeleteDialogClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export function LoyaltyPublishAlert({
  isOpen,
  onClose,
  handleConfirmPublish,
  cancelRef,
}) {
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Publish Loyalty Program
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to publish this loyalty program?
            <br />
            <br />
            Publishing will make the program available to customers and will
            automatically
            <strong> activate</strong> the program.
            <br />
            <br />
            This action cannot be undone.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={handleConfirmPublish} ml={3}>
              Publish
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
