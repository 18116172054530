import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import {
  Button,
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
  Badge,
  Tooltip,
  HStack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
  Radio,
  RadioGroup,
  VStack,
  Divider,
  Box,
  Checkbox,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { DeleteIcon } from '@chakra-ui/icons';
import {
  MdChevronRight,
  MdChevronLeft,
  MdPerson,
  MdShoppingCart,
  MdCardMembership,
  MdVisibility,
} from 'react-icons/md';
import { FiFilter } from 'react-icons/fi';
import { getAllVouchers, deleteVoucher } from 'api/voucher';
import VoucherAnalyticsWidget from 'components/vouchers/VoucherAnalyticsWidget';
import DebouncedInput from 'components/DebouncedInput';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';

export default function VoucherList() {
  const [vouchers, setVouchers] = useState([]);
  const [filteredVouchers, setFilteredVouchers] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [rowSelection, setRowSelection] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const cancelRef = useRef();
  const { userRole } = useContext(AuthContext);
  const isViewer = userRole === 'VIEWER';

  // Filter state
  const [loyaltyProgramUuid, setLoyaltyProgramUuid] = useState('');
  const [loyaltyLevelUuid, setLoyaltyLevelUuid] = useState('');
  const [userUuid, setUserUuid] = useState('');
  const [productUuid, setProductUuid] = useState('');
  const [expirationPeriod, setExpirationPeriod] = useState('all');
  const [status, setStatus] = useState('all');

  // For filter drawer
  const { isOpen, onOpen, onClose } = useDisclosure();

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const toast = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchVouchers = async () => {
      try {
        const allVouchers = await getAllVouchers();
        setVouchers(allVouchers);
        setFilteredVouchers(allVouchers);
      } catch (error) {
        console.error('Error getting vouchers:', error);
      }
    };
    fetchVouchers();
  }, []);

  const performDelete = async () => {
    try {
      setIsDeleting(true);
      const selectedRows = table.getSelectedRowModel().rows;
      const deletePromises = selectedRows.map((row) =>
        deleteVoucher(row.original.reward_uuid),
      );

      await Promise.all(deletePromises);

      toast({
        title: 'Vouchers deleted',
        description: `${selectedRows.length} voucher(s) successfully deleted.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Refresh the voucher list
      const allVouchers = await getAllVouchers();
      setVouchers(allVouchers);
      setRowSelection({});
    } catch (error) {
      toast({
        title: 'Error deleting vouchers',
        description: error.message || 'An unexpected error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
      onDeleteClose();
    }
  };

  // Only allow row selection for non-viewers
  useEffect(() => {
    if (isViewer && Object.keys(rowSelection).length > 0) {
      setRowSelection({});
    }
  }, [isViewer, rowSelection]);

  // Extract unique values for filter dropdowns
  const uniqueLoyaltyPrograms = useMemo(() => {
    const programs = [
      ...new Map(
        vouchers.map((v) => [
          v.loyalty_program_uuid,
          { uuid: v.loyalty_program_uuid, name: v.loyalty_program_name },
        ]),
      ).values(),
    ];
    return programs.filter((p) => p.name); // Filter out null names
  }, [vouchers]);

  const uniqueLoyaltyLevels = useMemo(() => {
    const levels = [
      ...new Map(
        vouchers.map((v) => [
          v.loyalty_level_uuid,
          { uuid: v.loyalty_level_uuid, name: v.loyalty_level_name },
        ]),
      ).values(),
    ];
    return levels.filter((l) => l.name); // Filter out null names
  }, [vouchers]);

  const uniqueProducts = useMemo(() => {
    const products = [
      ...new Map(
        vouchers.map((v) => [
          v.product_uuid,
          { uuid: v.product_uuid, name: v.product_name },
        ]),
      ).values(),
    ];
    return products.filter((p) => p.name); // Filter out null names
  }, [vouchers]);

  const uniqueUsers = useMemo(() => {
    const users = [
      ...new Map(
        vouchers.map((v) => [
          v.user_uuid,
          { uuid: v.user_uuid, name: v.user_name || v.user_email },
        ]),
      ).values(),
    ];
    return users.filter((u) => u.name); // Filter out null names
  }, [vouchers]);

  // Apply filters
  useEffect(() => {
    let result = [...vouchers];

    // Filter by loyalty program
    if (loyaltyProgramUuid) {
      result = result.filter(
        (v) => v.loyalty_program_uuid === loyaltyProgramUuid,
      );
    }

    // Filter by loyalty level
    if (loyaltyLevelUuid) {
      result = result.filter((v) => v.loyalty_level_uuid === loyaltyLevelUuid);
    }

    // Filter by product
    if (productUuid) {
      result = result.filter((v) => v.product_uuid === productUuid);
    }

    // Filter by user
    if (userUuid) {
      result = result.filter((v) => v.user_uuid === userUuid);
    }

    // Filter by expiration period
    const now = new Date();
    if (expirationPeriod === 'expired') {
      result = result.filter((v) => new Date(v.expiration_date) < now);
    } else if (expirationPeriod === 'active') {
      result = result.filter((v) => new Date(v.expiration_date) >= now);
    } else if (expirationPeriod === '7days') {
      const sevenDaysLater = new Date();
      sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);
      result = result.filter((v) => {
        const expDate = new Date(v.expiration_date);
        return expDate >= now && expDate <= sevenDaysLater;
      });
    } else if (expirationPeriod === '30days') {
      const thirtyDaysLater = new Date();
      thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);
      result = result.filter((v) => {
        const expDate = new Date(v.expiration_date);
        return expDate >= now && expDate <= thirtyDaysLater;
      });
    }

    // Filter by status
    if (status === 'claimed') {
      result = result.filter((v) => v.is_claimed);
    } else if (status === 'unclaimed') {
      result = result.filter((v) => !v.is_claimed);
    } else if (status === 'expired') {
      result = result.filter(
        (v) => new Date(v.expiration_date) < now && !v.is_claimed,
      );
    }

    setFilteredVouchers(result);
    // Reset to first page when filters change
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [
    vouchers,
    loyaltyProgramUuid,
    loyaltyLevelUuid,
    productUuid,
    userUuid,
    expirationPeriod,
    status,
  ]);

  const resetFilters = () => {
    setLoyaltyProgramUuid('');
    setLoyaltyLevelUuid('');
    setProductUuid('');
    setUserUuid('');
    setExpirationPeriod('all');
    setStatus('all');
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('select', {
      id: 'select',
      header: ({ table }) =>
        !isViewer && (
          <Checkbox
            isChecked={table.getIsAllRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            colorScheme="blue"
            isDisabled={isViewer}
          />
        ),
      cell: ({ row }) =>
        !isViewer && (
          <Checkbox
            isChecked={row.getIsSelected()}
            isIndeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
            colorScheme="blue"
            isDisabled={isViewer}
          />
        ),
      enableSorting: false,
    }),
    columnHelper.accessor('product_name', {
      id: 'product_name',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Voucher Name
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('loyalty_program_name', {
      id: 'loyalty_program_name',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Program Name
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('user_name', {
      id: 'user_name',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Customer
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue() || info.row.original.user_email}
        </Text>
      ),
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Created Date
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor('expiration_date', {
      id: 'expiration_date',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Expiration Date
        </Text>
      ),
      cell: (info) => {
        const expirationDate = new Date(info.getValue());
        const isExpired = expirationDate < new Date();

        return (
          <Text
            color={isExpired ? 'red.500' : textColor}
            fontSize="md"
            fontWeight="500"
            textDecoration={isExpired ? 'line-through' : 'none'}
          >
            {expirationDate.toLocaleDateString()}
            {isExpired && ' (Expired)'}
          </Text>
        );
      },
    }),
    columnHelper.accessor('is_claimed', {
      id: 'is_claimed',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Status
        </Text>
      ),
      cell: (info) => {
        const claimed = info.getValue();
        const expirationDate = new Date(info.row.original.expiration_date);
        const isExpired = expirationDate < new Date();

        let status = claimed ? 'Claimed' : 'Unclaimed';
        let colorScheme = claimed ? 'green' : 'blue';

        if (isExpired && !claimed) {
          status = 'Expired';
          colorScheme = 'red';
        }

        return (
          <Badge colorScheme={colorScheme} px={2} py={1} borderRadius="md">
            {status}
          </Badge>
        );
      },
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Actions
        </Text>
      ),
      cell: (info) => {
        // Generate all URLs upfront for clarity
        const voucherUrl = `/admin/vouchers/${info.row.original.reward_uuid}`;
        const productUrl = `/admin/products/edit/${info.row.original.product_uuid}`;
        const programUrl = `/admin/loyalty-programs/${info.row.original.loyalty_program_uuid}`;
        const userUrl = `/admin/users/user/${info.row.original.user_uuid}`;

        return (
          <HStack spacing={3} justify="center" width="100%">
            <Tooltip label="View Voucher Details">
              <Box
                as="a"
                href={voucherUrl}
                onClick={(e) => {
                  if (e.button === 0) {
                    e.preventDefault();
                    navigate(voucherUrl);
                  }
                }}
                display="inline-block"
              >
                <Icon
                  w="20px"
                  h="20px"
                  as={MdVisibility}
                  cursor="pointer"
                  color={brandColor}
                />
              </Box>
            </Tooltip>

            <Tooltip label="View Product">
              <Box
                as="a"
                href={productUrl}
                onClick={(e) => {
                  if (e.button === 0) {
                    e.preventDefault();
                    navigate(productUrl);
                  }
                }}
                display="inline-block"
              >
                <Icon
                  w="20px"
                  h="20px"
                  as={MdShoppingCart}
                  cursor="pointer"
                  color={brandColor}
                />
              </Box>
            </Tooltip>

            <Tooltip label="View Loyalty Program">
              <Box
                as="a"
                href={programUrl}
                onClick={(e) => {
                  if (e.button === 0) {
                    e.preventDefault();
                    navigate(programUrl);
                  }
                }}
                display="inline-block"
              >
                <Icon
                  w="20px"
                  h="20px"
                  as={MdCardMembership}
                  cursor="pointer"
                  color={brandColor}
                />
              </Box>
            </Tooltip>

            <Tooltip label="View User">
              <Box
                as="a"
                href={userUrl}
                onClick={(e) => {
                  if (e.button === 0) {
                    e.preventDefault();
                    navigate(userUrl);
                  }
                }}
                display="inline-block"
              >
                <Icon
                  w="20px"
                  h="20px"
                  as={MdPerson}
                  cursor="pointer"
                  color={brandColor}
                />
              </Box>
            </Tooltip>
          </HStack>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: filteredVouchers,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      rowSelection,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: isViewer ? () => {} : setRowSelection,
    enableRowSelection: !isViewer,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  // Calculate filter indicator - how many filters are active
  const activeFiltersCount = [
    loyaltyProgramUuid,
    loyaltyLevelUuid,
    userUuid,
    productUuid,
    expirationPeriod !== 'all',
    status !== 'all',
  ].filter(Boolean).length;

  return (
    <Flex direction="column" mt={{ sm: '150px', md: '125px', lg: '75px' }}>
      <VoucherAnalyticsWidget vouchers={filteredVouchers} />
      <Card
        w="100%"
        px={{ base: '16px', md: '24px' }}
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify="space-between"
          mb="36px"
          mt="24px"
        >
          <DebouncedInput
            placeholder="Search vouchers"
            onChange={(value) => setGlobalFilter(value)}
          />

          <HStack spacing={2}>
            <Button
              leftIcon={<FiFilter />}
              onClick={onOpen}
              colorScheme={activeFiltersCount > 0 ? 'blue' : 'gray'}
              variant={activeFiltersCount > 0 ? 'solid' : 'outline'}
            >
              Filter
              {activeFiltersCount > 0 && (
                <Badge ml={2} colorScheme="blue" borderRadius="full">
                  {activeFiltersCount}
                </Badge>
              )}
            </Button>
            {!isViewer && Object.keys(rowSelection).length > 0 && (
              <Button
                leftIcon={<DeleteIcon />}
                colorScheme="red"
                variant="outline"
                onClick={onDeleteOpen}
                isLoading={isDeleting}
              >
                Delete ({Object.keys(rowSelection).length})
              </Button>
            )}
          </HStack>
        </Flex>

        <Table variant="simple" color="gray.500">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    borderColor={borderColor}
                    key={header.id}
                    colSpan={header.colSpan}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {header.isPlaceholder ? null : (
                      <Flex
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        justify="center"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              const isExpired =
                new Date(row.original.expiration_date) < new Date() &&
                !row.original.is_claimed;
              const isClaimed = row.original.is_claimed;

              return (
                <Tr
                  px="20px"
                  key={row.id}
                  bg={
                    isExpired
                      ? 'red.50'
                      : isClaimed
                        ? 'green.50'
                        : 'transparent'
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: '14px' }}
                      borderColor={borderColor}
                      textAlign="center"
                      py={4}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        {filteredVouchers.length === 0 && (
          <Flex justify="center" align="center" py={10}>
            <Text color="gray.500">No vouchers match the current filters</Text>
          </Flex>
        )}

        <Flex
          w="100%"
          justify="space-between"
          align="center"
          px="20px"
          py="24px"
        >
          <Text fontSize="sm" color="gray.500" fontWeight="normal">
            Showing {pagination.pageSize * pagination.pageIndex + 1} to{' '}
            {pagination.pageSize * (pagination.pageIndex + 1) <=
            filteredVouchers.length
              ? pagination.pageSize * (pagination.pageIndex + 1)
              : filteredVouchers.length}{' '}
            of {filteredVouchers.length} entries
          </Text>
          <Stack direction="row" spacing="4px" ms="auto" align="center">
            <Button
              variant="no-effects"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={table.getCanPreviousPage() ? 'flex' : 'none'}
              _hover={{
                bg: 'whiteAlpha.100',
                opacity: '0.7',
              }}
            >
              <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
            </Button>

            {/* Pagination Buttons */}
            {/* ... existing pagination code */}

            <Button
              variant="no-effects"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={table.getCanNextPage() ? 'flex' : 'none'}
              _hover={{
                bg: 'whiteAlpha.100',
                opacity: '0.7',
              }}
            >
              <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
            </Button>
          </Stack>
        </Flex>
      </Card>

      {/* Filter Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Filter Vouchers</DrawerHeader>

          <DrawerBody>
            <VStack spacing={6} align="start" mt={4}>
              {/* Loyalty Program Filter */}
              <FormControl>
                <FormLabel fontWeight="semibold">Loyalty Program</FormLabel>
                <Select
                  placeholder="All loyalty programs"
                  value={loyaltyProgramUuid}
                  onChange={(e) => setLoyaltyProgramUuid(e.target.value)}
                >
                  {uniqueLoyaltyPrograms.map((program) => (
                    <option key={program.uuid} value={program.uuid}>
                      {program.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* Loyalty Level Filter */}
              <FormControl>
                <FormLabel fontWeight="semibold">Loyalty Level</FormLabel>
                <Select
                  placeholder="All loyalty levels"
                  value={loyaltyLevelUuid}
                  onChange={(e) => setLoyaltyLevelUuid(e.target.value)}
                >
                  {uniqueLoyaltyLevels.map((level) => (
                    <option key={level.uuid} value={level.uuid}>
                      {level.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* Product Filter */}
              <FormControl>
                <FormLabel fontWeight="semibold">Product</FormLabel>
                <Select
                  placeholder="All products"
                  value={productUuid}
                  onChange={(e) => setProductUuid(e.target.value)}
                >
                  {uniqueProducts.map((product) => (
                    <option key={product.uuid} value={product.uuid}>
                      {product.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* Customer Filter */}
              <FormControl>
                <FormLabel fontWeight="semibold">Customer</FormLabel>
                <Select
                  placeholder="All customers"
                  value={userUuid}
                  onChange={(e) => setUserUuid(e.target.value)}
                >
                  {uniqueUsers.map((user) => (
                    <option key={user.uuid} value={user.uuid}>
                      {user.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* Expiration Period Filter */}
              <FormControl>
                <FormLabel fontWeight="semibold">Expiration Period</FormLabel>
                <RadioGroup
                  value={expirationPeriod}
                  onChange={setExpirationPeriod}
                >
                  <VStack align="start" spacing={2}>
                    <Radio value="all">All vouchers</Radio>
                    <Radio value="active">Active vouchers</Radio>
                    <Radio value="expired">Expired vouchers</Radio>
                    <Radio value="7days">Expiring in 7 days</Radio>
                    <Radio value="30days">Expiring in 30 days</Radio>
                  </VStack>
                </RadioGroup>
              </FormControl>

              <Divider />

              {/* Status Filter */}
              <FormControl>
                <FormLabel fontWeight="semibold">Status</FormLabel>
                <RadioGroup value={status} onChange={setStatus}>
                  <VStack align="start" spacing={2}>
                    <Radio value="all">All</Radio>
                    <Radio value="claimed">Claimed</Radio>
                    <Radio value="unclaimed">Unclaimed</Radio>
                    <Radio value="expired">Expired & Unclaimed</Radio>
                  </VStack>
                </RadioGroup>
              </FormControl>

              <Divider />

              {/* Filter Actions */}
              <Flex width="100%" justify="space-between" mt={4}>
                <Button
                  colorScheme="red"
                  variant="outline"
                  onClick={resetFilters}
                >
                  Clear Filters
                </Button>
                <Button colorScheme="blue" onClick={onClose}>
                  Apply Filters
                </Button>
              </Flex>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Selected Vouchers
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete {Object.keys(rowSelection).length}{' '}
              selected voucher
              {Object.keys(rowSelection).length !== 1 ? 's' : ''}? This action
              cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={performDelete}
                ml={3}
                isLoading={isDeleting}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}
