import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  useToast,
  Card,
  FormControl,
  FormLabel,
  SimpleGrid,
  Divider,
  Select,
} from '@chakra-ui/react';
import { getCompanyBillingDetails, updateCompany } from 'api/company';
import countryList from 'react-select-country-list';

export default function InvoiceSettings() {
  const [companyUuid, setCompanyUuid] = useState('');
  const [legalName, setLegalName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [registryNumber, setRegistryNumber] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [iban, setIban] = useState('');
  const [swift, setSwift] = useState('');

  const countries = useMemo(() => countryList().getData(), []);

  const toast = useToast();

  const fetchInvoiceDetails = useCallback(async () => {
    try {
      const details = await getCompanyBillingDetails();

      setCompanyUuid(details.uuid);
      setLegalName(details.legal_name || '');
      setStreetAddress(details.street_address || '');
      setCity(details.city || '');
      setPostalCode(details.postal_code || '');
      setCountry(details.country || '');
      setRegistryNumber(details.registry_number || '');
      setVatNumber(details.vat_number || '');
      setIban(details.iban || '');
      setSwift(details.swift || '');
    } catch (error) {
      console.error('Error fetching invoice details:', error);

      setLegalName('');
      setStreetAddress('');
      setCity('');
      setPostalCode('');
      setCountry('');
      setRegistryNumber('');
      setVatNumber('');
      setIban('');
      setSwift('');
    }
  }, []);

  useEffect(() => {
    fetchInvoiceDetails();
  }, [fetchInvoiceDetails]);

  const handleSave = async () => {
    try {
      await updateCompany(companyUuid, {
        legal_name: legalName,
        street_address: streetAddress,
        city,
        postal_code: postalCode,
        country,
        registry_number: registryNumber,
        vat_number: vatNumber,
        iban,
        swift,
      });

      toast({
        title: 'Invoice details updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating invoice details:', error);
      toast({
        title: 'Error updating invoice details.',
        description:
          error.response?.data?.message || 'An error occurred while updating.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Card mb="20px" w="100%">
      <Flex alignItems="center" direction="column" p={5}>
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify={{ sm: 'flex-start', lg: 'flex-start' }}
          w="100%"
        >
          <Text fontSize="2xl" fontWeight="bold" mb={5}>
            Invoice Details
          </Text>
          <Button ml="auto" size="md" colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
        </Flex>

        {/* Company Information */}
        <Box width="100%" mb={6}>
          <Text fontSize="lg" fontWeight="semibold" mb={4}>
            Company Information
          </Text>

          <FormControl mb={4}>
            <FormLabel>Company Legal Name</FormLabel>
            <Input
              value={legalName}
              onChange={(e) => setLegalName(e.target.value)}
              placeholder="Enter legal company name"
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Street Address</FormLabel>
            <Input
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
              placeholder="Enter street address"
            />
          </FormControl>

          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
            <FormControl>
              <FormLabel>City</FormLabel>
              <Input
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Enter city"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Postal Code</FormLabel>
              <Input
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                placeholder="Enter postal code"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Country</FormLabel>
              <Select
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder="Select country"
              >
                {countries.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </SimpleGrid>
        </Box>

        <Divider mb={6} />

        {/* Registration Details */}
        <Box width="100%" mb={6}>
          <Text fontSize="lg" fontWeight="semibold" mb={4}>
            Registration Details
          </Text>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            <FormControl>
              <FormLabel>Registry/Business Number</FormLabel>
              <Input
                value={registryNumber}
                onChange={(e) => setRegistryNumber(e.target.value)}
                placeholder="Enter registry number"
              />
            </FormControl>

            <FormControl>
              <FormLabel>VAT Number</FormLabel>
              <Input
                value={vatNumber}
                onChange={(e) => setVatNumber(e.target.value)}
                placeholder="Enter VAT number (if applicable)"
              />
            </FormControl>
          </SimpleGrid>
        </Box>

        <Divider mb={6} />

        {/* Banking Details */}
        <Box width="100%" mb={3}>
          <Text fontSize="lg" fontWeight="semibold" mb={4}>
            Banking Details
          </Text>

          <FormControl mb={4}>
            <FormLabel>IBAN</FormLabel>
            <Input
              value={iban}
              onChange={(e) => setIban(e.target.value)}
              placeholder="Enter IBAN (International Bank Account Number)"
            />
          </FormControl>

          <FormControl>
            <FormLabel>SWIFT/BIC Code</FormLabel>
            <Input
              value={swift}
              onChange={(e) => setSwift(e.target.value)}
              placeholder="Enter SWIFT/BIC code"
            />
          </FormControl>
        </Box>
      </Flex>
    </Card>
  );
}
