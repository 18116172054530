import React from 'react';
import {
  Box,
  Flex,
  Text,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Divider,
  Badge,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import CustomAvatar from '../CustomAvatar';
import {
  MdAccessTime,
  MdEmail,
  MdShoppingCart,
  MdAttachMoney,
  MdCardGiftcard,
} from 'react-icons/md';

export default function Banner({
  banner,
  avatar,
  name,
  email,
  lastlogin,
  purchases,
  moneySpent,
  claimable,
  claimed,
}) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue('gray.600', 'gray.400');
  const borderColor = useColorModeValue('white', '#111C44');
  const cardBg = useColorModeValue('white', 'navy.700');
  const statsBg = useColorModeValue('blue.50', 'navy.800');
  const dividerColor = useColorModeValue('gray.200', 'gray.700');

  const formattedLastLogin = lastlogin
    ? format(new Date(lastlogin), 'dd.MM.yyyy HH:mm')
    : 'Never';

  return (
    <Box position="relative" overflow="hidden">
      {/* Banner Image with gradient overlay */}
      <Box
        h={{ base: '120px', md: '160px' }}
        w="100%"
        backgroundImage={`linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.2)), url(${banner})`}
        backgroundSize="cover"
        backgroundPosition="center"
        position="relative"
      />

      {/* Content Container */}
      <Box px={{ base: 4, md: 8 }} pb={6} pt={4}>
        {/* Profile Section with Avatar */}
        <Flex
          direction={{ base: 'column', md: 'row' }}
          align={{ base: 'center', md: 'flex-end' }}
          mb={6}
        >
          {/* Avatar - Positioned to overlap with banner */}
          <Box
            mt={{ base: '-50px', md: '-70px' }}
            mr={{ base: 0, md: 6 }}
            mb={{ base: 4, md: 0 }}
          >
            <CustomAvatar
              name={name}
              src={avatar}
              size="xl"
              h={{ base: '100px', md: '130px' }}
              w={{ base: '100px', md: '130px' }}
              border="4px solid"
              borderColor={borderColor}
              bg={cardBg}
            />
          </Box>

          {/* User Details */}
          <VStack
            align={{ base: 'center', md: 'flex-start' }}
            spacing={1}
            flex="1"
          >
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              fontSize={{ base: 'xl', md: '2xl' }}
              textAlign={{ base: 'center', md: 'left' }}
              lineHeight="1.2"
            >
              {name}
            </Text>

            <HStack spacing={2}>
              <Icon as={MdEmail} color="blue.500" boxSize={4} />
              <Text
                color={textColorSecondary}
                fontSize="md"
                textAlign={{ base: 'center', md: 'left' }}
              >
                {email}
              </Text>
            </HStack>

            <HStack spacing={2} mt={1}>
              <Icon as={MdAccessTime} color="blue.500" boxSize={4} />
              <Text color={textColorSecondary} fontSize="sm">
                Last login: {formattedLastLogin}
              </Text>
            </HStack>
          </VStack>
        </Flex>

        <Divider borderColor={dividerColor} mb={4} />

        {/* Statistics Cards */}
        <Flex justify="space-between" wrap="wrap" mx={{ base: -2, md: -4 }}>
          {/* Purchases Card */}
          <Box
            flex={{ base: '1 0 100%', sm: '1 0 30%' }}
            p={{ base: 2, md: 4 }}
            mb={{ base: 4, sm: 0 }}
          >
            <Box
              bg={statsBg}
              p={4}
              borderRadius="lg"
              boxShadow="sm"
              height="100%"
            >
              <VStack spacing={1} align="flex-start">
                <HStack spacing={2}>
                  <Icon as={MdShoppingCart} color="purple.500" boxSize={5} />
                  <Text fontSize="md" fontWeight="500" color={textColorPrimary}>
                    Purchases
                  </Text>
                </HStack>
                <Text
                  color="purple.500"
                  fontSize={{ base: '2xl', md: '3xl' }}
                  fontWeight="700"
                  pt={2}
                >
                  {purchases || 0}
                </Text>
              </VStack>
            </Box>
          </Box>

          {/* Money Spent Card */}
          <Box
            flex={{ base: '1 0 100%', sm: '1 0 30%' }}
            p={{ base: 2, md: 4 }}
            mb={{ base: 4, sm: 0 }}
          >
            <Box
              bg={statsBg}
              p={4}
              borderRadius="lg"
              boxShadow="sm"
              height="100%"
            >
              <VStack spacing={1} align="flex-start">
                <HStack spacing={2}>
                  <Icon as={MdAttachMoney} color="green.500" boxSize={5} />
                  <Text fontSize="md" fontWeight="500" color={textColorPrimary}>
                    Money Spent
                  </Text>
                </HStack>
                <Text
                  color="green.500"
                  fontSize={{ base: '2xl', md: '3xl' }}
                  fontWeight="700"
                  pt={2}
                >
                  {moneySpent || 0}€
                </Text>
              </VStack>
            </Box>
          </Box>

          {/* Claims Card */}
          <Box
            flex={{ base: '1 0 100%', sm: '1 0 30%' }}
            p={{ base: 2, md: 4 }}
          >
            <Box
              bg={statsBg}
              p={4}
              borderRadius="lg"
              boxShadow="sm"
              height="100%"
            >
              <VStack spacing={1} align="flex-start">
                <HStack spacing={2}>
                  <Icon as={MdCardGiftcard} color="orange.500" boxSize={5} />
                  <Text fontSize="md" fontWeight="500" color={textColorPrimary}>
                    Claims
                  </Text>
                </HStack>
                <Flex align="center" pt={2}>
                  <Text
                    color="orange.500"
                    fontSize={{ base: '2xl', md: '3xl' }}
                    fontWeight="700"
                    mr={2}
                  >
                    {claimed || 0}/{claimable || 0}
                  </Text>
                  {claimable > 0 && (
                    <Badge
                      colorScheme={claimed === claimable ? 'green' : 'orange'}
                      borderRadius="full"
                      px={2}
                    >
                      {claimed === claimable
                        ? 'All Claimed'
                        : `${claimable - claimed} Available`}
                    </Badge>
                  )}
                </Flex>
              </VStack>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
