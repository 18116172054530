import React, { useEffect, useState } from 'react';
import {
  Box,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useToast,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Tooltip,
  HStack,
  Card,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Text,
  VStack,
  Select,
} from '@chakra-ui/react';
import apiClient from 'api/axios';
import { Global, css } from '@emotion/react';
import { format, isAfter, startOfMonth, subMonths } from 'date-fns';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { ViewIcon, DownloadIcon, SearchIcon } from '@chakra-ui/icons';
import { FiFilter } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { getCreditBalance } from 'api/dashboard';

const editorStyles = {
  '.editor-input': {
    minHeight: '200px',
    padding: '10px',
    outline: 'none',
    width: '100%',
    cursor: 'text',
    fontSize: '16px',
    lineHeight: '1.5',
    '& h1': { fontSize: '2em', fontWeight: 'bold', margin: '0.67em 0' },
    '& h2': { fontSize: '1.5em', fontWeight: 'bold', margin: '0.83em 0' },
    '& h3': { fontSize: '1.17em', fontWeight: 'bold', margin: '1em 0' },
    '& strong': { fontWeight: 'bold' },
    '& em': { fontStyle: 'italic' },
    '& u': { textDecoration: 'underline' },
    '& ul': { listStyle: 'disc', marginLeft: '20px' },
    '& ol': { listStyle: 'decimal', marginLeft: '20px' },
  },
};

export default function Payouts() {
  const [payouts, setPayouts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableMonths, setAvailableMonths] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [creditBalance, setCreditBalance] = useState(0);
  const [isLoadingBalance, setIsLoadingBalance] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [companyCreationDate, setCompanyCreationDate] = useState(null);
  const [monthlySums, setMonthlySums] = useState({});
  const toast = useToast();
  const navigate = useNavigate();
  
  // Separate useDisclosure hooks for modal and drawer
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();

  // Fetch available months
  useEffect(() => {
    const fetchAvailableMonths = async () => {
      try {
        const response = await apiClient.get(
          '/company/available-invoice-months',
        );
        setAvailableMonths(response.data.months.map((date) => new Date(date)));
      } catch (error) {
        console.error('Error fetching available months:', error);
        toast({
          title: 'Error fetching available months',
          description: error.response?.data?.error || 'An error occurred',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchAvailableMonths();
  }, [toast]);

  // Fetch credit balance data
  useEffect(() => {
    const fetchCreditBalance = async () => {
      setIsLoadingBalance(true);
      try {
        const data = await getCreditBalance();
        setCreditBalance(data.credit_balance || 0);
      } catch (error) {
        console.error('Error fetching credit balance:', error);
        setCreditBalance(0);
      } finally {
        setIsLoadingBalance(false);
      }
    };

    fetchCreditBalance();
  }, []);

  // Add effect to fetch company creation date
  useEffect(() => {
    const fetchCompanyCreationDate = async () => {
      try {
        const response = await apiClient.get('/companies');
        // Get the current company's data
        // Since we're in the company's payout page, we'll use the first company from the response
        // that matches our current context
        const company = response.data[0];
        if (company && company.created_at) {
          setCompanyCreationDate(new Date(company.created_at));
        } else {
          // Fallback to 1 year ago if no creation date is found
          const oneYearAgo = new Date();
          oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
          setCompanyCreationDate(oneYearAgo);
        }
      } catch (error) {
        console.error('Error fetching company creation date:', error);
        // Default to 1 year ago if fetch fails
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        setCompanyCreationDate(oneYearAgo);
      }
    };

    fetchCompanyCreationDate();
  }, []);

  // Fetch monthly sums
  useEffect(() => {
    const fetchMonthlySums = async () => {
      try {
        const response = await apiClient.get('/company/monthly-sums');
        // Convert the response data into a map of YYYY-MM -> sum
        const sumsMap = {};
        response.data.forEach(item => {
          sumsMap[format(new Date(item.date), 'yyyy-MM')] = item.sum;
        });
        setMonthlySums(sumsMap);
      } catch (error) {
        console.error('Error fetching monthly sums:', error);
      }
    };

    fetchMonthlySums();
  }, []);

  // Fetch payouts
  const fetchPayouts = async (date) => {
    try {
      const formattedDate = format(date, 'yyyy-MM');
      const response = await apiClient.get(
        `/company/invoices?date=${formattedDate}`,
      );
      setPayouts(response.data);
    } catch (error) {
      console.error('Error fetching payouts:', error);
      toast({
        title: 'Error fetching payouts',
        description: error.response?.data?.error || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPayouts(selectedDate);
  }, [selectedDate, toast]);

  // Custom calendar component
  const CustomCalendar = () => {
    return (
      <Calendar
        onChange={handleDateChange}
        value={selectedDate}
        maxDate={new Date()}
        minDate={companyCreationDate}
        minDetail="year"
        maxDetail="year"
        defaultView="year"
        showNeighboringMonth={false}
        formatMonth={(locale, date) => format(date, 'MMM yyyy')}
        tileDisabled={({ date }) => {
          const monthStart = startOfMonth(date);
          return !availableMonths.some(
            (availableDate) =>
              monthStart.getTime() ===
              startOfMonth(new Date(availableDate)).getTime(),
          );
        }}
        tileClassName={({ date }) => {
          const monthStart = startOfMonth(date);
          const isAvailable = availableMonths.some(
            (availableDate) =>
              monthStart.getTime() ===
              startOfMonth(new Date(availableDate)).getTime(),
          );
          return isAvailable ? 'available-month' : '';
        }}
        tileContent={({ date, view }) => {
          if (view === 'year') {
            const monthKey = format(date, 'yyyy-MM');
            const sum = monthlySums[monthKey];
            const monthStart = startOfMonth(date);
            const isAvailable = availableMonths.some(
              (availableDate) =>
                monthStart.getTime() ===
                startOfMonth(new Date(availableDate)).getTime(),
            );
            return (
              <div style={{ 
                fontSize: '0.8em', 
                marginTop: '4px',
                color: isAvailable ? 'white' : 'gray.400'
              }}>
                {sum ? `€${sum.toLocaleString()}` : '€0'}
              </div>
            );
          }
          return null;
        }}
        className="custom-calendar"
      />
    );
  };

  // Add custom styles for the calendar
  const calendarStyles = css`
    .react-calendar {
      width: 100%;
      max-width: 100%;
      background: white;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      padding: 16px;
    }
    .react-calendar__tile {
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      min-height: 70px;
      aspect-ratio: 1.6;
      transition: all 0.2s;
    }
    .react-calendar__year-view__months {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr) !important;
      gap: 12px !important;
    }
    .react-calendar__year-view__months__month {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      width: 100%;
      margin: 0 !important;
      padding: 12px !important;
      border-radius: 8px;
    }
    .react-calendar__tile:disabled {
      background-color: #f7fafc;
    }
    .react-calendar__tile.available-month {
      background-color: #48BB78;
      color: white;
    }
    .react-calendar__tile.available-month:hover {
      background-color: #38A169;
    }
    .react-calendar__tile.available-month div {
      color: white !important;
    }
    .react-calendar__tile--active {
      background-color: #3182ce !important;
      color: white !important;
      border-radius: 8px;
    }
    .react-calendar__tile--active div {
      color: white !important;
    }
    .react-calendar__year-view .react-calendar__tile {
      font-size: 0.9em;
      font-weight: 500;
    }
    /* Override default abbr styles */
    .react-calendar__year-view .react-calendar__tile abbr {
      text-decoration: none;
    }
  `;

  // Handle date selection and invoice creation
  const handleDateChange = async (date) => {
    const currentDate = new Date();

    // Check if selected date is within valid range
    if (isAfter(startOfMonth(date), currentDate) || 
        (companyCreationDate && date < companyCreationDate)) {
      return;
    }

    try {
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const response = await apiClient.post('/company/create-invoice', {
        month,
        year,
      });

      setPayouts((prev) => [response.data.invoice, ...prev]);
      setAvailableMonths((prev) =>
        prev.filter(
          (availableDate) =>
            !(
              date.getMonth() === new Date(availableDate).getMonth() &&
              date.getFullYear() === new Date(availableDate).getFullYear()
            ),
        ),
      );
      onModalClose();

      toast({
        title: 'Success',
        description: response.data.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error creating invoice:', error);
      toast({
        title: 'Error creating invoice',
        description: error.response?.data?.error || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Get status badge color
  const getStatusBadge = (status) => {
    const statusConfig = {
      created: { color: 'blue', text: 'Created' },
      paid: { color: 'green', text: 'Paid' },
      declined: { color: 'red', text: 'Declined' },
      overdue: { color: 'orange', text: 'Overdue' },
    };

    const config = statusConfig[status] || { color: 'gray', text: status };
    return (
      <Badge colorScheme={config.color} variant="subtle">
        {config.text}
      </Badge>
    );
  };

  // Filter payouts based on search query, year, and month
  const filteredPayouts = payouts.filter((payout) => {
    const searchLower = searchQuery.toLowerCase();
    
    // Search filter
    const matchesSearch = 
      payout.user_name?.toLowerCase().includes(searchLower) ||
      payout.status?.toLowerCase().includes(searchLower) ||
      payout.total_amount?.toString().includes(searchLower) ||
      payout.period?.toLowerCase().includes(searchLower) ||
      format(new Date(payout.invoice_date), 'dd/MM/yyyy').includes(searchLower);

    // Extract year and month from period (e.g., "2024-02-29 - 2024-03-30")
    const periodDates = payout.period?.split(' - ') || [];
    const startDate = periodDates[0] ? new Date(periodDates[0]) : null;
    
    if (!startDate) return matchesSearch;

    const periodYear = startDate.getFullYear().toString();
    const periodMonth = (startDate.getMonth() + 1).toString();

    // Apply year and month filters based on period start date
    const yearMatch = !selectedYear || periodYear === selectedYear;
    const monthMatch = !selectedMonth || periodMonth === selectedMonth;

    return matchesSearch && yearMatch && monthMatch;
  });

  return (
    <>
      <Global styles={[editorStyles, calendarStyles]} />
      <Box mt={{ sm: '150px', md: '125px', lg: '80px' }}>
        <Card
          w="100%"
          px={{ base: '16px', md: '24px' }}
          overflowX="auto"
          minHeight={{ sm: 'auto', lg: 'calc(100vh - 200px)' }}
        >
          
          {/* Credit Balance Display */}
          <Flex direction="column" mb={4} mt={6} align="center" justify="center">
            <Text fontSize="md" color="gray.600">
              Uninvoiced Credit Balance
            </Text>
            <Text fontSize="2xl" fontWeight="bold">
              €{isLoadingBalance ? '...' : creditBalance?.toFixed(2) || '0.00'}
            </Text>
          </Flex>

          {/* Header Section with Search and Buttons */}
          <Flex
            direction={{ base: 'column', md: 'row' }}
            align={{ base: 'stretch', md: 'center' }}
            justify="space-between"
            gap={4}
            mb="36px"
            mt="24px"
          >
            {/* Search Bar */}
            <Flex align="center" flex={1} maxW={{ base: '100%', md: '600px' }}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.800" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search payouts..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  borderRadius="full"
                  bg="rgba(234, 239, 242, 0.6)"
                  border="none"
                  _focus={{ boxShadow: 'none' }}
                  _placeholder={{ color: 'gray.800' }}
                />
              </InputGroup>
            </Flex>

            {/* Action Buttons */}
            <Flex gap={2} justify={{ base: 'flex-end', md: 'flex-end' }}>
              <Button
                leftIcon={<FiFilter />}
                colorScheme="blue"
                variant="outline"
                onClick={onDrawerOpen}
              >
                Filter
              </Button>
              <Button
                colorScheme="blackAlpha"
                bg="black"
                color="white"
                variant="solid"
                onClick={onModalOpen}
              >
                + Create Invoice
              </Button>
            </Flex>
          </Flex>

          {/* Table Section */}
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Invoice Date</Th>
                <Th>Due Date</Th>
                <Th>Period</Th>
                <Th>Status</Th>
                <Th>Amount</Th>
                <Th>Requested by</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr>
                  <Td colSpan={6} textAlign="center">
                    Loading payouts...
                  </Td>
                </Tr>
              ) : filteredPayouts.length > 0 ? (
                filteredPayouts.map((payout) => (
                  <Tr key={payout.uuid}>
                    <Td>
                      {format(new Date(payout.invoice_date), 'dd/MM/yyyy')}
                    </Td>
                    <Td>
                      {format(new Date(payout.invoice_deadline), 'dd/MM/yyyy')}
                    </Td>
                    <Td>
                      {payout.period || "N/A"}
                    </Td>
                    <Td>{getStatusBadge(payout.status)}</Td>
                    <Td>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(payout.total_amount)}
                    </Td>
                    <Td>{payout.user_name}</Td>
                    <Td>
                      <HStack spacing={2}>
                        <Tooltip label="View Details" placement="top">
                          <IconButton
                            icon={<ViewIcon />}
                            variant="ghost"
                            colorScheme="gray"
                            aria-label="View payout details"
                            size="sm"
                            onClick={() => {
                              const payoutId = payout.uuid;
                              if (payoutId) {
                                navigate(`/admin/payouts/${payoutId}`);
                              }
                            }}
                          />
                        </Tooltip>
                      </HStack>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={6} textAlign="center">
                    No payouts found.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Card>

        {/* Create Invoice Modal */}
        <Modal isOpen={isModalOpen} onClose={onModalClose} isCentered size="sm">
          <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
          <ModalContent>
            <ModalHeader>Select Invoice Month</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <CustomCalendar />
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* Filter Drawer */}
        <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size="sm">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px">Filter Payouts</DrawerHeader>
            <DrawerBody>
              <VStack spacing={4} align="stretch">
                {/* Year Filter */}
                <Box>
                  <Text mb={2} fontWeight="medium">Year</Text>
                  <Select
                    placeholder="Select year"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                  >
                    {Array.from(new Set(payouts.map(payout => {
                      const periodDates = payout.period?.split(' - ') || [];
                      const startDate = periodDates[0] ? new Date(periodDates[0]) : null;
                      return startDate ? startDate.getFullYear() : null;
                    })))
                      .filter(Boolean)
                      .sort((a, b) => b - a)
                      .map(year => (
                        <option key={year} value={year.toString()}>
                          {year}
                        </option>
                      ))
                    }
                  </Select>
                </Box>

                {/* Month Filter */}
                <Box>
                  <Text mb={2} fontWeight="medium">Month</Text>
                  <Select
                    placeholder="Select month"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    {[
                      { value: '1', label: 'January' },
                      { value: '2', label: 'February' },
                      { value: '3', label: 'March' },
                      { value: '4', label: 'April' },
                      { value: '5', label: 'May' },
                      { value: '6', label: 'June' },
                      { value: '7', label: 'July' },
                      { value: '8', label: 'August' },
                      { value: '9', label: 'September' },
                      { value: '10', label: 'October' },
                      { value: '11', label: 'November' },
                      { value: '12', label: 'December' }
                    ].map(month => (
                      <option key={month.value} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                  </Select>
                </Box>

                {/* Action Buttons */}
                <Flex mt={6} justify="space-between">
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      setSelectedYear('');
                      setSelectedMonth('');
                      onDrawerClose();
                    }}
                  >
                    Clear Filters
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={onDrawerClose}
                  >
                    Apply Filters
                  </Button>
                </Flex>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
}

