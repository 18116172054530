// UsersOverview.js
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUsers } from 'api/user';
import { MdChevronRight, MdChevronLeft, MdVisibility } from 'react-icons/md';
import { FaDownload } from 'react-icons/fa';
import {
  Button,
  Box,
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import CustomAvatar from '../../components/CustomAvatar'; // Import CustomAvatar
import { createPages } from 'utils/helpers';
import DebouncedInput from 'components/DebouncedInput';
import exportToCSV from 'utils/exportUtils';

export default function UsersOverview() {
  const [users, setUsers] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersData = await getUsers();
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const columnHelper = createColumnHelper();

  // Memoized cell render functions
  const renderAvatarCell = useCallback(
    (info) => (
      <Flex align="center" justify="center">
        <CustomAvatar
          size="md"
          name={info.row.original.full_name}
          src={info.row.original.avatar_url}
        />
      </Flex>
    ),
    [],
  );

  const renderFullNameCell = useCallback(
    (info) => (
      <Flex align="center" justify="center">
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      </Flex>
    ),
    [textColor],
  );

  const renderEmailCell = useCallback(
    (info) => (
      <Flex align="center" justify="center">
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      </Flex>
    ),
    [textColor],
  );

  const renderTotalPurchasesCell = useCallback(
    (info) => (
      <Flex align="center" justify="center">
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      </Flex>
    ),
    [textColor],
  );

  const renderCreatedAtCell = useCallback(
    (info) => (
      <Flex align="center" justify="center">
        <Text color={textColor} fontSize="md" fontWeight="500">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      </Flex>
    ),
    [textColor],
  );

  const renderWalletAddressCell = useCallback(
    (info) => (
      <Flex align="center" justify="center">
        <Popover>
          <PopoverTrigger>
            <Button size="sm" colorScheme="blue">
              Show Address
            </Button>
          </PopoverTrigger>
          <PopoverContent width="350px">
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader textAlign="center" fontSize={'md'}>
              {info.row.original.default_wallet?.wallet_name ||
                'Wallet Address'}
            </PopoverHeader>
            <PopoverBody>
              <Text>{info.getValue() || 'No Address'}</Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    ),
    [],
  );

  const renderActionsCell = useCallback(
    (info) => {
      const userUrl = `/admin/users/user/${info.row.original.uuid}`;

      return (
        <Flex align="center" justify="center" width="100%">
          <Box
            as="a"
            href={userUrl}
            onClick={(e) => {
              if (e.button === 0) {
                e.preventDefault();
                const userUuid = info.row.original.uuid;
                if (userUuid) {
                  navigate(userUrl);
                } else {
                  console.error('userUuid is undefined');
                }
              }
            }}
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              as={MdVisibility}
              w="16px"
              h="16px"
              cursor="pointer"
              color={brandColor}
            />
          </Box>
        </Flex>
      );
    },
    [brandColor, navigate],
  );

  // Memoized columns array
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.avatar_url, {
        id: 'avatar',
        header: () => (
          <Text justifyContent="center" align="center" color="gray.400">
            AVATAR
          </Text>
        ),
        cell: renderAvatarCell,
      }),
      columnHelper.accessor('full_name', {
        id: 'full_name',
        header: () => (
          <Text justifyContent="center" align="center" color="gray.400">
            FULL NAME
          </Text>
        ),
        cell: renderFullNameCell,
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: () => (
          <Text justifyContent="center" align="center" color="gray.400">
            EMAIL
          </Text>
        ),
        cell: renderEmailCell,
      }),
      columnHelper.accessor('total_purchases', {
        id: 'total_purchases',
        header: () => (
          <Text justifyContent="center" align="center" color="gray.400">
            TOTAL PURCHASES
          </Text>
        ),
        cell: renderTotalPurchasesCell,
      }),
      columnHelper.accessor('created_at', {
        id: 'created_at',
        header: () => (
          <Text justifyContent="center" align="center" color="gray.400">
            JOIN DATE
          </Text>
        ),
        cell: renderCreatedAtCell,
      }),
      columnHelper.accessor((row) => row.default_wallet?.wallet_address, {
        id: 'wallet_address',
        header: () => (
          <Text justifyContent="center" align="center" color="gray.400">
            WALLET ADDRESS
          </Text>
        ),
        cell: renderWalletAddressCell,
      }),
      columnHelper.accessor('actions', {
        id: 'actions',
        header: () => (
          <Text
            justifyContent="center"
            align="center"
            color="gray.400"
            textAlign="center"
            width="100%"
          >
            ACTIONS
          </Text>
        ),
        cell: renderActionsCell,
      }),
    ],
    [
      columnHelper,
      renderAvatarCell,
      renderFullNameCell,
      renderEmailCell,
      renderTotalPurchasesCell,
      renderCreatedAtCell,
      renderWalletAddressCell,
      renderActionsCell,
    ],
  );

  // React Table instance
  const table = useReactTable({
    data: users,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleExportCSV = () => {
    // Define the columns for export
    const exportColumns = [
      { header: 'Full Name', accessor: 'full_name' },
      { header: 'Email', accessor: 'email' },
      { header: 'Total Purchases', accessor: 'total_purchases' },
      {
        header: 'Join Date',
        accessor: (row) => new Date(row.created_at).toLocaleDateString(),
      },
      {
        header: 'Wallet Address',
        accessor: (row) => row.default_wallet?.wallet_address || 'Not set',
      },
      {
        header: 'Wallet Name',
        accessor: (row) => row.default_wallet?.wallet_name || 'Not set',
      },
    ];

    // Get filtered data based on the global filter
    let exportData = users;
    if (globalFilter) {
      exportData = users.filter(
        (user) =>
          user.full_name?.toLowerCase().includes(globalFilter.toLowerCase()) ||
          user.email?.toLowerCase().includes(globalFilter.toLowerCase()),
      );
    }

    // Export to CSV
    exportToCSV(exportData, exportColumns, 'users-data');
  };

  return (
    <Flex direction="column" mt={{ sm: '150px', md: '125px', lg: '75px' }}>
      <Card
        w="100%"
        px={{ base: '16px', md: '24px' }}
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify="space-between" // Changed from flex-start to space-between
          mb="36px"
          mt="24px"
        >
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-block"
            placeholder="Search users..."
          />

          {/* Add Export Button */}
          <Button
            leftIcon={<FaDownload />}
            onClick={handleExportCSV}
            colorScheme="green"
            variant="outline"
          >
            Export CSV
          </Button>
        </Flex>

        {/* Table */}
        <Table variant="simple" color="gray.500">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    borderColor={borderColor}
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <Flex
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        justify="center"
                        align="center"
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: '🔼',
                          desc: '🔽',
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr px="20px" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    borderColor={borderColor}
                    py="12px"
                    textAlign="center"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>

        {/* Pagination */}
        <Flex
          w="100%"
          justify="space-between"
          alignItems="center"
          px="20px"
          py="24px"
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            mb={{ sm: '24px', md: '0px' }}
          >
            Showing {pagination.pageSize * pagination.pageIndex + 1} to{' '}
            {Math.min(
              pagination.pageSize * (pagination.pageIndex + 1),
              users.length,
            )}{' '}
            of {users.length} entries
          </Text>
          <div className="flex items-center gap-2">
            <Stack direction="row" spacing="4px" ms="auto">
              <Button
                variant="no-effects"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="50%"
                bg="transparent"
                border="1px solid"
                borderColor={useColorModeValue('gray.200', 'white')}
                display={
                  pagination.pageSize === 5
                    ? 'none'
                    : table.getCanPreviousPage()
                      ? 'flex'
                      : 'none'
                }
                _hover={{
                  bg: 'whiteAlpha.100',
                  opacity: '0.7',
                }}
              >
                <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
              </Button>
              {createPages(table.getPageCount()).map((pageNumber, index) => (
                <Button
                  variant="no-effects"
                  transition="all .5s ease"
                  onClick={() => table.setPageIndex(pageNumber - 1)}
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                  bg={
                    pageNumber === pagination.pageIndex + 1
                      ? brandColor
                      : 'transparent'
                  }
                  border={
                    pageNumber === pagination.pageIndex + 1
                      ? 'none'
                      : '1px solid lightgray'
                  }
                  _hover={
                    pageNumber === pagination.pageIndex + 1
                      ? {
                          opacity: '0.7',
                        }
                      : {
                          bg: 'whiteAlpha.100',
                        }
                  }
                  key={index}
                >
                  <Text
                    fontSize="sm"
                    color={
                      pageNumber === pagination.pageIndex + 1
                        ? '#fff'
                        : textColor
                    }
                  >
                    {pageNumber}
                  </Text>
                </Button>
              ))}
              <Button
                variant="no-effects"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="50%"
                bg="transparent"
                border="1px solid"
                borderColor={useColorModeValue('gray.200', 'white')}
                display={
                  pagination.pageSize === 5
                    ? 'none'
                    : table.getCanNextPage()
                      ? 'flex'
                      : 'none'
                }
                _hover={{
                  bg: 'whiteAlpha.100',
                  opacity: '0.7',
                }}
              >
                <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
              </Button>
            </Stack>
          </div>
        </Flex>
      </Card>
    </Flex>
  );
}
