import React from 'react';
import {
  Box,
  IconButton,
  Image,
  Text,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Flex,
  VStack,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  StarIcon,
} from '@chakra-ui/icons';
import { MdComment } from 'react-icons/md';

function ProductImagesCard({
  combinedImages,
  currentImageIndex,
  onPrev,
  onNext,
  onDelete,
  onSetFeatured,
  onSelectImage,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Card
      variant="outline"
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="md"
      overflow="hidden"
      height="fit-content"
      flexDirection="column"
    >
      <CardHeader bg="blue.50" py={4}>
        <Heading size="md">Product Images</Heading>
      </CardHeader>
      <CardBody p={0}>
        {combinedImages && combinedImages.length > 0 ? (
          <Box position="relative">
            <Image
              src={combinedImages[currentImageIndex].image_url}
              alt="Product Image"
              objectFit="contain"
              w="100%"
              h="350px"
              cursor="pointer"
              onClick={onOpen}
            />

            {/* Navigation controls */}
            <IconButton
              icon={<ChevronLeftIcon />}
              position="absolute"
              left="10px"
              top="50%"
              transform="translateY(-50%)"
              zIndex="1"
              variant="ghost"
              onClick={onPrev}
              aria-label="Previous Image"
            />
            <IconButton
              icon={<ChevronRightIcon />}
              position="absolute"
              right="10px"
              top="50%"
              transform="translateY(-50%)"
              zIndex="1"
              variant="ghost"
              onClick={onNext}
              aria-label="Next Image"
            />

            {/* Modal for full-size image view */}
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
              <ModalOverlay backdropFilter="blur(3px)" />
              <ModalContent>
                <ModalCloseButton zIndex={2} />
                <ModalBody p={0}>
                  <Image
                    src={combinedImages[currentImageIndex].image_url}
                    alt="Product Image"
                    w="100%"
                    borderRadius="md"
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        ) : (
          <Flex
            justify="center"
            align="center"
            bg="gray.100"
            h="350px"
            w="100%"
          >
            <VStack spacing={2}>
              <Icon as={MdComment} boxSize="40px" color="gray.300" />
              <Text color="gray.500">No images available</Text>
            </VStack>
          </Flex>
        )}
      </CardBody>

      {/* Image thumbnails section */}
      {combinedImages && combinedImages.length > 0 && (
        <Box p={4} borderTop="1px solid" borderColor="gray.200">
          <Text fontSize="sm" fontWeight="medium" mb={3} color="gray.600">
            Image Gallery
          </Text>
          <Flex gap={3} overflowX="auto" py={1}>
            {combinedImages.map((image, index) => (
              <Box
                key={image.uuid}
                position="relative"
                borderWidth={index === currentImageIndex ? '2px' : '1px'}
                borderColor={
                  index === currentImageIndex ? 'blue.500' : 'gray.200'
                }
                borderRadius="md"
                transition="all 0.2s"
              >
                <Image
                  src={image.image_url}
                  alt="Product thumbnail"
                  objectFit="cover"
                  boxSize="70px"
                  borderRadius="md"
                  onClick={() => onSelectImage && onSelectImage(index)}
                  opacity={index === currentImageIndex ? 1 : 0.7}
                  _hover={{ opacity: 1 }}
                />
                {onDelete && onSetFeatured && (
                  <Flex
                    position="absolute"
                    top={0}
                    right={0}
                    direction="column"
                    bg="blackAlpha.300"
                    borderRadius="0 0 0 md"
                  >
                    <IconButton
                      icon={<DeleteIcon />}
                      size="xs"
                      variant="ghost"
                      colorScheme="red"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(image.uuid);
                      }}
                      aria-label="Delete image"
                    />
                    <IconButton
                      icon={<StarIcon />}
                      size="xs"
                      variant="ghost"
                      colorScheme={image.is_featured ? 'yellow' : 'gray'}
                      color={image.is_featured ? 'yellow.500' : 'gray.100'}
                      onClick={(e) => {
                        e.stopPropagation();
                        onSetFeatured(image.uuid);
                      }}
                      aria-label="Set as featured image"
                    />
                  </Flex>
                )}
              </Box>
            ))}
          </Flex>
        </Box>
      )}
    </Card>
  );
}

export default ProductImagesCard;
