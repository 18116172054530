import React from 'react';
import {
  Box,
  Heading,
  Button,
  Flex,
  ButtonGroup,
  IconButton,
  Badge,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  AddIcon,
  EditIcon,
  DeleteIcon,
  RepeatIcon,
} from '@chakra-ui/icons';
import { FaTable, FaThLarge, FaFileImport } from 'react-icons/fa';

const VariationsHeader = ({
  variationType,
  viewMode,
  setViewMode,
  productUuid,
  isViewer,
  availableAttributes,
  selectedRows,
  handleGenerateVariationsOpen,
  isCalculatingCombinations,
  syncSelectedWithProductPrice,
  syncSelectedWithProductStock,
  handleDeleteVariations,
  setBulkEditMode,
}) => {
  return (
    <>
      <Flex justify="space-between" align="center" mb="4">
        <Heading size="md">
          {variationType === 'category'
            ? 'Category Variations'
            : 'Product Variations'}
        </Heading>

        <ButtonGroup size="sm" isAttached variant="outline">
          <IconButton
            aria-label="Table view"
            icon={<FaTable />}
            isActive={viewMode === 'table'}
            onClick={() => setViewMode('table')}
          />
          <IconButton
            aria-label="Grid view"
            icon={<FaThLarge />}
            isActive={viewMode === 'grid'}
            onClick={() => setViewMode('grid')}
          />
        </ButtonGroup>
      </Flex>

      {!productUuid && (
        <Alert status="warning" mb="4">
          <AlertIcon />
          You need to save the product first before managing variations.
        </Alert>
      )}

      {isViewer && (
        <Alert status="info" mb="4">
          <AlertIcon />
          You are in view-only mode. Contact an administrator to manage
          variations.
        </Alert>
      )}

      <Box mb="5">
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          align={{ base: 'stretch', md: 'flex-start' }}
          wrap="wrap"
        >
          {/* Left side - primary actions and selection count */}
          <Box>
            <ButtonGroup spacing="3" mb={{ base: '3', md: '0' }}>
              <Box>
                <Button
                  colorScheme="blue"
                  onClick={handleGenerateVariationsOpen}
                  isDisabled={
                    isViewer ||
                    !productUuid ||
                    availableAttributes.length === 0 ||
                    isCalculatingCombinations
                  }
                  leftIcon={<AddIcon />}
                  isLoading={isCalculatingCombinations}
                  size="md"
                  width="100%"
                >
                  Generate Variations
                </Button>
                {selectedRows.length > 0 && (
                  <Box mt="2">
                    <Badge
                      colorScheme="blue"
                      fontSize="sm"
                      display="inline-block"
                      px="2"
                    >
                      {selectedRows.length} selected
                    </Badge>
                  </Box>
                )}
              </Box>

              <Tooltip label={!productUuid ? 'Save product first' : ''}>
                <Button
                  leftIcon={<FaFileImport />}
                  isDisabled={!productUuid || isViewer}
                  variant="outline"
                >
                  Import
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>

          {/* Right side - always show actions button */}
          <Menu closeOnSelect={true}>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              isDisabled={selectedRows.length === 0 || isViewer}
              variant="outline"
            >
              Actions
            </MenuButton>
            <MenuList zIndex={1000}>
              <MenuItem icon={<EditIcon />} onClick={setBulkEditMode}>
                Edit Selected
              </MenuItem>
              <MenuItem
                icon={<RepeatIcon />}
                onClick={syncSelectedWithProductPrice}
              >
                Sync with Product Price
              </MenuItem>
              <MenuItem
                icon={<RepeatIcon />}
                onClick={syncSelectedWithProductStock}
              >
                Sync with Product Stock
              </MenuItem>
              <MenuItem
                icon={<DeleteIcon />}
                onClick={handleDeleteVariations}
                color="red.500"
              >
                Delete Selected
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
    </>
  );
};

export default VariationsHeader;
