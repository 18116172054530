// utils.js
export const getAttributeKey = (attribute) =>
  (
    attribute.translations?.find((tr) => tr.language === 'en')?.value ||
    attribute.slug
  ).toLowerCase();

export const getAttributeValueName = (valueObj) =>
  valueObj?.translations?.find((tr) => tr.language === 'en')?.value ||
  valueObj?.slug ||
  valueObj?.id;

export const getCombinationKey = (combo) => {
  if (!combo || typeof combo !== 'object') return '';
  // Sort keys alphabetically to ensure consistent string representation
  const sortedKeys = Object.keys(combo).sort();
  const sortedCombo = {};
  sortedKeys.forEach((key) => {
    sortedCombo[key] = combo[key];
  });
  try {
    return JSON.stringify(sortedCombo);
  } catch (e) {
    console.error('Error stringifying combination:', combo, e);
    return ''; // Or handle error appropriately
  }
};
