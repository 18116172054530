// src/pages/discounts/EditDiscount.js

import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  Checkbox,
  VStack,
  Alert,
  AlertIcon,
  Box,
  Spinner,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  SimpleGrid,
  Skeleton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getDiscount,
  updateProductDiscount,
  deleteProductDiscount,
} from '../../api/discount';
import { addProductCounts } from '../../utils/CategoryUtils';
import useProducts from '../../hooks/useProducts';
import useLoyaltyPrograms from '../../hooks/useLoyaltyPrograms';
import useLoyaltyLevels from '../../hooks/useLoyaltyLevels';
import ProductCheckboxCard from './ProductCheckboxCard';
import { sortProductsByAvailability } from '../../utils/discountUtil';
import { CategoriesContext } from '../../contexts/CategoriesContext';
import CategoryTreeSelect from '../../components/products/CategoryTreeSelect';
import { debounce } from 'lodash';
import { AuthContext } from 'contexts/AuthContext';

const EditDiscount = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const { transformedCategories, loadingCategories, errorCategories } =
    useContext(CategoriesContext);

  const [discountKind, setDiscountKind] = useState('percentage');
  const [discountScope, setDiscountScope] = useState('all_products');
  const [formData, setFormData] = useState({
    product_uuid: '',
    product_uuids: [],
    category_uuid: '',
    discount_price: '',
    discount_percentage: '',
    loyalty_program_uuid: '',
    loyalty_level_uuid: '',
    setPeriod: false,
    start_time: '',
    end_time: '',
    is_active: true,
  });

  const { userRole } = useContext(AuthContext);
  const isViewer = userRole === 'VIEWER';

  const {
    products,
    loading: productsLoading,
    error: productsError,
  } = useProducts(
    discountScope === 'category' && formData.category_uuid
      ? { category_uuid: formData.category_uuid, programType: 'regular' }
      : discountScope === 'selected_products'
        ? { programType: 'regular' }
        : {},
  );

  const {
    loyaltyPrograms,
    loading: loyaltyProgramsLoading,
    error: loyaltyProgramsError,
  } = useLoyaltyPrograms();
  const {
    loyaltyLevels,
    loading: loyaltyLevelsLoading,
    error: loyaltyLevelsError,
  } = useLoyaltyLevels();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const {
    products: allProducts,
    loading: allProductsLoading,
    error: allProductsError,
  } = useProducts({});

  // State for managing the initial load
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // Fetch discount details
  useEffect(() => {
    const fetchDiscount = async () => {
      try {
        const data = await getDiscount(uuid);

        const isEditingParent =
          data.parent_discount && data.parent_discount.uuid === uuid;

        let categoryUuidToSet = data.parent_discount.category_uuid || '';

        if (isEditingParent) {
          const childCategories = data.child_discounts
            .map((child) => child.category_uuid)
            .filter(Boolean);

          const uniqueCategoryUuids = new Set(childCategories);
          if (uniqueCategoryUuids.size === 1) {
            categoryUuidToSet = [...uniqueCategoryUuids][0];
          }
        } else {
          // If editing a child discount
          const childDiscount = data.child_discounts.find(
            (child) => child.uuid === uuid,
          );
          if (childDiscount && childDiscount.category_uuid) {
            categoryUuidToSet = childDiscount.category_uuid;
          }
        }

        // Determine if there are child discounts
        const hasChildDiscounts =
          data.child_discounts && data.child_discounts.length > 0;

        // Set product_uuids based on whether it's a parent discount with children
        const productUuids = isEditingParent
          ? hasChildDiscounts
            ? data.child_discounts.map((child) => child.product_uuid)
            : [data.parent_discount.product_uuid]
          : [];

        // Update formData
        setFormData((prev) => ({
          ...prev,
          product_uuids: productUuids,
          category_uuid: categoryUuidToSet,
          discount_price: data.parent_discount.discount_price || '',
          discount_percentage: data.parent_discount.discount_percentage || '',
          loyalty_program_uuid: data.parent_discount.loyalty_program_uuid || '',
          loyalty_level_uuid: data.parent_discount.loyalty_level_uuid || '',
          setPeriod: !!(
            data.parent_discount.start_time || data.parent_discount.end_time
          ),
          start_time: data.parent_discount.start_time
            ? new Date(data.parent_discount.start_time)
                .toISOString()
                .slice(0, 16)
            : '',
          end_time: data.parent_discount.end_time
            ? new Date(data.parent_discount.end_time).toISOString().slice(0, 16)
            : '',
          is_active: data.parent_discount.is_active,
        }));

        // Now that category_uuid is set, update discountScope and discountKind
        setDiscountScope(data.parent_discount.discount_type || 'all_products');
        setDiscountKind(
          data.parent_discount.discount_price !== null
            ? 'fixed_price'
            : 'percentage',
        );

        setLoading(false);
        setIsInitialLoad(false);
      } catch (err) {
        console.error('Error fetching discount:', err);
        setError('Failed to fetch discount details.');
        setLoading(false);
      }
    };

    fetchDiscount();
  }, [uuid]);

  const categoryProductMap = useMemo(() => {
    const map = {};
    allProducts.forEach((product) => {
      if (product.category_uuid) {
        map[product.category_uuid] = (map[product.category_uuid] || 0) + 1;
      }
    });
    return map;
  }, [allProducts]);

  const categoriesWithCounts = useMemo(() => {
    return addProductCounts(transformedCategories, categoryProductMap);
  }, [transformedCategories, categoryProductMap]);

  // Debounced category selection to prevent rapid state updates
  const debouncedSetCategory = useMemo(
    () =>
      debounce((selectedUuid) => {
        setFormData((prev) => ({
          ...prev,
          category_uuid: selectedUuid,
          product_uuids: [],
        }));
      }, 300),
    [],
  );

  useEffect(() => {
    return () => {
      debouncedSetCategory.cancel();
    };
  }, [debouncedSetCategory]);

  // Memoize filtered loyalty levels
  const filteredLoyaltyLevels = useMemo(() => {
    if (formData.loyalty_program_uuid) {
      return loyaltyLevels.filter(
        (level) => level.loyalty_program_uuid === formData.loyalty_program_uuid,
      );
    }
    return loyaltyLevels;
  }, [formData.loyalty_program_uuid, loyaltyLevels]);

  const categoryProducts = useMemo(() => {
    return products.filter(
      (product) =>
        !product.loyalty_program_uuid &&
        !product.loyalty_level_uuid &&
        product.category_uuid === formData.category_uuid,
    );
  }, [products, formData.category_uuid]);

  // Memoize published loyalty programs
  const publishedPrograms = useMemo(() => {
    return loyaltyPrograms.filter((program) => program.is_published === 1);
  }, [loyaltyPrograms]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Toggle product selection
  const handleProductToggle = (productUuid) => {
    setFormData((prev) => {
      const newProductUuids = prev.product_uuids.includes(productUuid)
        ? prev.product_uuids.filter((uuid) => uuid !== productUuid)
        : [...prev.product_uuids, productUuid];
      return {
        ...prev,
        product_uuids: newProductUuids,
      };
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isViewer) return;

    setError(null);
    setSuccess(null);

    // Validate discount scope
    if (discountScope === 'selected_products') {
      if (!formData.product_uuids || formData.product_uuids.length === 0) {
        setError(
          'At least one product must be selected for Selected Products scope.',
        );
        return;
      }
    } else if (discountScope === 'category') {
      if (!formData.category_uuid) {
        setError('Please select a category for the discount.');
        return;
      }
    } else if (discountScope !== 'all_products') {
      setError('Invalid discount scope selected.');
      return;
    }

    // Validate Period
    if (formData.setPeriod) {
      if (formData.start_time && formData.end_time) {
        if (new Date(formData.start_time) > new Date(formData.end_time)) {
          setError('Start Time must be before End Time.');
          return;
        }
      }
    }

    // Additional Validation: Ensure discountKind fields are filled
    if (discountKind === 'fixed_price' && !formData.discount_price) {
      setError('Discount Amount is required for Fixed Price Discount.');
      return;
    }

    if (discountKind === 'percentage' && !formData.discount_percentage) {
      setError('Discount Percentage is required for Percentage Discount.');
      return;
    }

    try {
      const updateData = {
        discount_type: discountScope,
        product_uuids:
          discountScope === 'selected_products' ? formData.product_uuids : [],
        category_uuid:
          discountScope === 'category' ? formData.category_uuid : null,
        discount_price:
          discountKind === 'fixed_price'
            ? parseFloat(formData.discount_price)
            : null,
        discount_percentage:
          discountKind === 'percentage'
            ? parseFloat(formData.discount_percentage)
            : null,
        start_time: formData.setPeriod
          ? new Date(formData.start_time).toISOString()
          : null,
        end_time: formData.setPeriod
          ? new Date(formData.end_time).toISOString()
          : null,
        is_active: formData.is_active,
        loyalty_program_uuid: formData.loyalty_program_uuid || null,
        loyalty_level_uuid: formData.loyalty_level_uuid || null,
      };

      await updateProductDiscount(uuid, updateData);
      setSuccess('Discount updated successfully!');
      navigate('/admin/discounts');
    } catch (err) {
      console.error('Error updating discount:', err);
      setError(err.message || 'Failed to update discount.');
    }
  };

  // Handle discount deletion
  const handleDelete = async () => {
    try {
      await deleteProductDiscount(uuid);
      setSuccess('Discount deleted successfully!');
      navigate('/admin/discounts');
    } catch (err) {
      console.error('Error deleting discount:', err);
      if (err.response && err.response.data && err.response.data.error) {
        setError(`Failed to delete discount: ${err.response.data.error}`);
      } else {
        setError(err.message || 'Failed to delete discount.');
      }
    } finally {
      onClose();
    }
  };

  const renderReadOnlyField = (value) => {
    return (
      <Box
        p={2}
        borderWidth="1px"
        borderRadius="md"
        bg="gray.50"
        minHeight="40px"
      >
        {value || <Text color="gray.400">No data</Text>}
      </Box>
    );
  };

  // Enhanced Filtering: Exclude loyalty products
  const filteredProducts = useMemo(() => {
    return products.filter(
      (product) => !product.loyalty_program_uuid && !product.loyalty_level_uuid,
    );
  }, [products]);

  const sortedFilteredProducts = useMemo(
    () => sortProductsByAvailability(filteredProducts),
    [filteredProducts],
  );

  if (
    loading ||
    productsLoading ||
    loyaltyProgramsLoading ||
    loyaltyLevelsLoading ||
    loadingCategories
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {isViewer && (
        <Alert status="info" mb={4}>
          <AlertIcon />
          You are viewing this discount in read-only mode. Contact an
          administrator to make changes.
        </Alert>
      )}

      <form onSubmit={handleSubmit} noValidate>
        <VStack spacing={4} align="stretch">
          {/* Discount Kind Selection as Tabs */}
          <FormControl id="discountKind" isRequired>
            <FormLabel>Discount Kind</FormLabel>
            {isViewer ? (
              <Text fontWeight="medium" ml={2}>
                {discountKind === 'percentage'
                  ? 'Percentage'
                  : 'Discount Amount'}
              </Text>
            ) : (
              <Tabs
                variant="soft-rounded"
                colorScheme="blue"
                onChange={(index) => {
                  setDiscountKind(index === 0 ? 'percentage' : 'fixed_price');
                  setFormData((prev) => ({
                    ...prev,
                    discount_price: '',
                    discount_percentage: '',
                  }));
                }}
                index={discountKind === 'percentage' ? 0 : 1}
              >
                <TabList>
                  <Tab>Percentage</Tab>
                  <Tab>Discount Amount</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <FormControl id="discount_percentage" isRequired>
                      <FormLabel>Discount Percentage</FormLabel>
                      <Input
                        type="decimal" // Changed from number to text
                        name="discount_percentage"
                        value={formData.discount_percentage}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Only allow numbers and one decimal point up to 100
                          if (
                            value === '' ||
                            (/^\d*\.?\d{0,1}$/.test(value) &&
                              parseFloat(value) <= 100)
                          ) {
                            setFormData((prev) => ({
                              ...prev,
                              discount_percentage: value,
                            }));
                          }
                        }}
                        onBlur={(e) => {
                          // Format on blur to ensure consistent decimal place
                          const value = e.target.value;
                          if (value) {
                            const formatted = parseFloat(value).toFixed(1);
                            setFormData((prev) => ({
                              ...prev,
                              discount_percentage: formatted,
                            }));
                          }
                        }}
                        placeholder="Enter Discount Percentage"
                      />
                    </FormControl>
                  </TabPanel>
                  <TabPanel>
                    <FormControl id="discount_price" isRequired>
                      <FormLabel>Discount Amount</FormLabel>
                      <Input
                        type="decimal" // Changed from number to text
                        name="discount_price"
                        value={formData.discount_price}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Only allow numbers and one decimal point
                          if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
                            setFormData((prev) => ({
                              ...prev,
                              discount_price: value,
                            }));
                          }
                        }}
                        onBlur={(e) => {
                          // Format on blur to ensure consistent decimal places
                          const value = e.target.value;
                          if (value) {
                            const formatted = parseFloat(value).toFixed(2);
                            setFormData((prev) => ({
                              ...prev,
                              discount_price: formatted,
                            }));
                          }
                        }}
                        placeholder="Enter Discount Amount"
                      />
                    </FormControl>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
          </FormControl>

          {/* Discount Scope Selection as Tabs */}
          <FormControl id="discountScope" isRequired>
            <FormLabel>Discount Scope</FormLabel>
            {isViewer ? (
              <Text fontWeight="medium" ml={2}>
                {discountScope === 'selected_products'
                  ? 'Selected Products'
                  : discountScope === 'all_products'
                    ? 'All Products'
                    : 'Category'}
              </Text>
            ) : (
              <Tabs
                variant="soft-rounded"
                colorScheme="green"
                onChange={(index) => {
                  const scopes = [
                    'selected_products',
                    'all_products',
                    'category',
                  ];
                  const selectedScope = scopes[index];
                  setDiscountScope(selectedScope);
                  setFormData((prev) => ({
                    ...prev,
                    product_uuid: '',
                    product_uuids: [],
                    // Only reset category_uuid if the new scope isn't 'category'
                    category_uuid:
                      selectedScope !== 'category' ? '' : prev.category_uuid,
                    loyalty_program_uuid: '',
                    loyalty_level_uuid: '',
                  }));
                }}
                index={
                  discountScope === 'selected_products'
                    ? 0
                    : discountScope === 'all_products'
                      ? 1
                      : 2
                }
              >
                <TabList>
                  <Tab>Selected Products</Tab>
                  <Tab>All Products</Tab>
                  <Tab>Category</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Alert status="info">
                      <AlertIcon />
                      You have selected to apply the discount to selected
                      products.
                    </Alert>
                  </TabPanel>
                  <TabPanel>
                    <Alert status="info">
                      <AlertIcon />
                      The discount will be applied to all products.
                    </Alert>
                  </TabPanel>
                  <TabPanel>
                    <Alert status="info">
                      <AlertIcon />
                      The discount will be applied to a specific category.
                    </Alert>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
          </FormControl>

          {/* Multiple Product Selection */}
          {discountScope === 'selected_products' && sortedFilteredProducts && (
            <FormControl id="product_uuids" isRequired>
              <FormLabel>Select Products</FormLabel>
              {productsLoading ? (
                <Spinner size="sm" />
              ) : productsError ? (
                <Alert status="error">
                  <AlertIcon />
                  {productsError}
                </Alert>
              ) : sortedFilteredProducts.length === 0 ? (
                <Alert status="warning">
                  <AlertIcon />
                  No regular products available for selection.
                </Alert>
              ) : (
                <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                  {sortedFilteredProducts.map((product) => (
                    <ProductCheckboxCard
                      key={product.uuid}
                      product={product}
                      isChecked={formData.product_uuids.includes(product.uuid)}
                      onChange={handleProductToggle}
                      discountKind={discountKind}
                      discountPrice={formData.discount_price}
                      discountPercentage={formData.discount_percentage}
                      isReadOnly={isViewer}
                    />
                  ))}
                </SimpleGrid>
              )}
            </FormControl>
          )}

          {/* Category Selection with Tree */}
          {discountScope === 'category' && (
            <>
              <FormControl id="category_uuid">
                <FormLabel>Select Category</FormLabel>
                {isViewer ? (
                  <Text ml={2}>
                    {categoriesWithCounts.find(
                      (c) => c.uuid === formData.category_uuid,
                    )?.name || 'No category selected'}
                  </Text>
                ) : (
                  <>
                    {loadingCategories ? (
                      <Skeleton height="40px" />
                    ) : errorCategories ? (
                      <Alert status="error">
                        <AlertIcon />
                        {errorCategories}
                      </Alert>
                    ) : transformedCategories.length > 0 ? (
                      <CategoryTreeSelect
                        categories={categoriesWithCounts}
                        selectedCategoryUuid={formData.category_uuid}
                        onSelectCategory={(selectedUuid) => {
                          debouncedSetCategory(selectedUuid);
                        }}
                      />
                    ) : (
                      <Alert status="warning">
                        <AlertIcon />
                        No categories available.
                      </Alert>
                    )}
                  </>
                )}
              </FormControl>

              {/* Display Products in Selected Category */}
              {formData.category_uuid && (
                <FormControl id="category_products">
                  <FormLabel>Products in Selected Category</FormLabel>
                  {productsLoading ? (
                    <Spinner size="sm" />
                  ) : productsError ? (
                    <Alert status="error">
                      <AlertIcon />
                      {productsError}
                    </Alert>
                  ) : categoryProducts.length > 0 ? (
                    <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                      {categoryProducts.map((product) => (
                        <MemoizedProductCheckboxCard
                          key={product.uuid}
                          product={product}
                          isChecked={formData.product_uuids.includes(
                            product.uuid,
                          )}
                          onChange={handleProductToggle}
                          discountKind={discountKind}
                          discountPrice={formData.discount_price}
                          discountPercentage={formData.discount_percentage}
                          isReadOnly={isViewer}
                        />
                      ))}
                    </SimpleGrid>
                  ) : (
                    <Alert status="info">
                      <AlertIcon />
                      No products found in this category.
                    </Alert>
                  )}
                </FormControl>
              )}
            </>
          )}

          {/* Loyalty Program Selection */}
          {['selected_products', 'all_products', 'category'].includes(
            discountScope,
          ) && (
            <FormControl id="loyalty_program_uuid" isRequired>
              <FormLabel>Loyalty Program</FormLabel>
              {isViewer ? (
                renderReadOnlyField(
                  publishedPrograms.find(
                    (p) => p.uuid === formData.loyalty_program_uuid,
                  )?.name || 'None selected',
                )
              ) : (
                <>
                  {loyaltyProgramsLoading ? (
                    <Spinner size="sm" />
                  ) : loyaltyProgramsError ? (
                    <Alert status="error">
                      <AlertIcon />
                      {loyaltyProgramsError}
                    </Alert>
                  ) : publishedPrograms.length > 0 ? (
                    <Select
                      name="loyalty_program_uuid"
                      value={formData.loyalty_program_uuid}
                      onChange={handleChange}
                      placeholder="Select Loyalty Program"
                    >
                      {publishedPrograms.map((program) => (
                        <option key={program.uuid} value={program.uuid}>
                          {program.name}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Alert status="warning">
                      <AlertIcon />
                      No published loyalty programs available.
                    </Alert>
                  )}
                </>
              )}
            </FormControl>
          )}

          {/* Loyalty Level Selection (Optional) */}
          {formData.loyalty_program_uuid && (
            <FormControl id="loyalty_level_uuid">
              <FormLabel>Loyalty Level (Optional)</FormLabel>
              {isViewer ? (
                renderReadOnlyField(
                  filteredLoyaltyLevels.find(
                    (l) => l.uuid === formData.loyalty_level_uuid,
                  )?.name || 'None selected',
                )
              ) : (
                <>
                  {loyaltyLevelsLoading ? (
                    <Spinner size="sm" />
                  ) : loyaltyLevelsError ? (
                    <Alert status="error">
                      <AlertIcon />
                      {loyaltyLevelsError}
                    </Alert>
                  ) : filteredLoyaltyLevels.length > 0 ? (
                    <Select
                      name="loyalty_level_uuid"
                      value={formData.loyalty_level_uuid}
                      onChange={handleChange}
                      placeholder="Select Loyalty Level (Optional)"
                    >
                      {filteredLoyaltyLevels.map((level) => (
                        <option key={level.uuid} value={level.uuid}>
                          {level.name}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Alert status="info">
                      <AlertIcon />
                      No loyalty levels available for the selected program.
                    </Alert>
                  )}
                </>
              )}
            </FormControl>
          )}

          {/* Set Period Checkbox */}
          <FormControl id="setPeriod">
            {isViewer ? (
              <Box>
                <FormLabel>Period</FormLabel>
                {formData.setPeriod ? (
                  <Box>
                    <Text>
                      Start Date:{' '}
                      {formData.start_time
                        ? new Date(formData.start_time).toLocaleString()
                        : 'Not set'}
                    </Text>
                    <Text>
                      End Date:{' '}
                      {formData.end_time
                        ? new Date(formData.end_time).toLocaleString()
                        : 'Not set'}
                    </Text>
                  </Box>
                ) : (
                  <Text>No period set (always active)</Text>
                )}
              </Box>
            ) : (
              <Checkbox
                name="setPeriod"
                isChecked={formData.setPeriod}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setFormData((prev) => ({
                    ...prev,
                    setPeriod: isChecked,
                    start_time: isChecked ? prev.start_time : '',
                    end_time: isChecked ? prev.end_time : '',
                  }));
                }}
              >
                Period (optional, if not set then always on)
              </Checkbox>
            )}
          </FormControl>

          {/* Start Time */}
          {formData.setPeriod && (
            <FormControl id="start_time">
              <FormLabel>Start Date (starting from)</FormLabel>
              <Input
                type="datetime-local"
                name="start_time"
                value={formData.start_time}
                onChange={handleChange}
                placeholder="Set Start Date"
              />
            </FormControl>
          )}

          {/* End Time */}
          {formData.setPeriod && (
            <FormControl id="end_time">
              <FormLabel>End Date (up to)</FormLabel>
              <Input
                type="datetime-local"
                name="end_time"
                value={formData.end_time}
                onChange={handleChange}
                placeholder="Set End Date"
              />
            </FormControl>
          )}

          {/* is_active Checkbox */}
          <FormControl id="is_active">
            {isViewer ? (
              <Text>Active: {formData.is_active ? 'Yes' : 'No'}</Text>
            ) : (
              <Checkbox
                name="is_active"
                isChecked={formData.is_active}
                onChange={handleChange}
              >
                Active
              </Checkbox>
            )}
          </FormControl>

          {/* Error Alert */}
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          {/* Success Alert */}
          {success && (
            <Alert status="success">
              <AlertIcon />
              {success}
            </Alert>
          )}

          {/* Submit and Delete Buttons */}
          {!isViewer && (
            <>
              <Button type="submit" colorScheme="blue">
                Update Discount
              </Button>
              <Button colorScheme="red" onClick={onOpen}>
                Delete Discount
              </Button>
            </>
          )}
        </VStack>
      </form>

      {/* Confirmation Dialog for Deletion */}
      <AlertDialog
        isOpen={isOpen && !isViewer}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Discount
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this discount? This action cannot
              be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDelete}
                ml={3}
                isLoading={loading}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

// Memoize ProductCheckboxCard to prevent unnecessary re-renders
const MemoizedProductCheckboxCard = React.memo(ProductCheckboxCard);

// Update the usage in the component if needed
// For example, replace <ProductCheckboxCard /> with <MemoizedProductCheckboxCard />

export default EditDiscount;
