// Details.js

import {
  Flex,
  FormControl,
  Text,
  useColorModeValue,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Card as ChakraCard,
  CardHeader,
  CardBody,
  Heading,
  Divider,
  FormLabel,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { MdEuro } from 'react-icons/md';
import React from 'react';

export default function Details({ productPrice, setProductPrice, isViewer }) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  const handlePriceChange = (e) => {
    const value = e.target.value.replace(',', '.');
    if (value === '' || /^[0-9]*\.?[0-9]*$/.test(value)) {
      setProductPrice(value);
    }
  };

  const renderReadOnlyField = (value) => {
    return (
      <Box
        p="12px 16px"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        bg="gray.50"
        boxShadow="sm"
      >
        <Flex align="center">
          <Icon as={MdEuro} color="gray.500" mr={2} />
          <Text color={textColorPrimary} fontSize="md" fontWeight="500">
            {value || '-'}
          </Text>
        </Flex>
      </Box>
    );
  };

  return (
    <FormControl>
      <ChakraCard
        variant="outline"
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="md"
        overflow="hidden"
        mb="20px"
      >
        <CardHeader bg="blue.50" py={4}>
          <Heading size="md">Pricing Details</Heading>
        </CardHeader>

        <CardBody px={6} py={5}>
          <Text fontSize="sm" color={textColorSecondary} mb={4}>
            {isViewer
              ? 'View your product pricing details'
              : 'Here you can change your product pricing details'}
          </Text>

          <Divider my={4} borderColor="gray.200" />

          <Box mb={4}>
            <FormLabel fontWeight="medium" color={textColorPrimary}>
              Price
            </FormLabel>

            {isViewer ? (
              renderReadOnlyField(productPrice)
            ) : (
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.500"
                  children={<MdEuro />}
                />
                <Input
                  id="price"
                  placeholder="99.99"
                  type="text"
                  pattern="[0-9]*\.?[0-9]*"
                  inputMode="decimal"
                  value={productPrice}
                  onChange={handlePriceChange}
                  borderColor="gray.300"
                  _hover={{ borderColor: 'gray.400' }}
                  _focus={{
                    borderColor: 'blue.500',
                    boxShadow: '0 0 0 1px blue.500',
                  }}
                />
              </InputGroup>
            )}
          </Box>

          {/* Additional information could be placed here */}
          {productPrice > 0 && (
            <HStack spacing={1} color="gray.500" fontSize="sm" mt={2}>
              <Text>Current price:</Text>
              <Text fontWeight="bold" color="green.600">
                €{Number(productPrice).toFixed(2)}
              </Text>
            </HStack>
          )}
        </CardBody>
      </ChakraCard>
    </FormControl>
  );
}
