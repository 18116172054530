// LinkedAttributesTags.jsx
import React from 'react';
import {
  Wrap,
  WrapItem,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';

const LinkedAttributesTags = ({ attributes, onRemove, isViewer = false }) => {
  return (
    <Wrap spacing="2" mb="4">
      {attributes.map((attr) => {
        const englishName =
          attr.translations?.find((tr) => tr.language === 'en')?.value ||
          attr.slug;
        return (
          <WrapItem key={attr.id}>
            <Tag size="lg" variant="solid" colorScheme="purple">
              <TagLabel>{englishName}</TagLabel>
              {!isViewer && (
                <TagCloseButton onClick={() => onRemove(attr.id)} />
              )}
            </Tag>
          </WrapItem>
        );
      })}
    </Wrap>
  );
};

export default LinkedAttributesTags;
