import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  SimpleGrid,
  Image,
  Text,
  Progress,
  Flex,
  IconButton,
  Icon,
  Badge,
  Button,
  useColorModeValue,
  VStack,
  HStack,
  Tooltip,
  Center,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import {
  MdAddPhotoAlternate,
  MdDelete,
  MdStar,
  MdFileUpload,
  MdOutlineCloudUpload,
  MdImage,
  MdCheck,
  MdError,
  MdChevronLeft,
  MdChevronRight,
  MdExpandMore,
  MdExpandLess,
} from 'react-icons/md';

const ProductImageUploader = ({
  images,
  onUpload,
  onDelete,
  onSetFeatured,
  isViewer,
}) => {
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadErrors, setUploadErrors] = useState({});
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isGalleryOpen, onToggle: onGalleryToggle } = useDisclosure({
    defaultIsOpen: false,
  });

  const maxFileSizeMB = 5;
  const acceptedFileTypes = 'PNG, JPG, GIF';

  useEffect(() => {
    if (images.length > 0 && currentImageIndex >= images.length) {
      setCurrentImageIndex(0);
    }
  }, [images, currentImageIndex]);

  const handlePrevImage = () => {
    if (images.length === 0) return;
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    );
  };

  const handleNextImage = () => {
    if (images.length === 0) return;
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        const newErrors = { ...uploadErrors };
        rejectedFiles.forEach((file) => {
          const errorMsg =
            file.errors[0]?.code === 'file-too-large'
              ? `File exceeds ${maxFileSizeMB}MB limit`
              : 'Invalid file type';
          newErrors[file.file.name] = errorMsg;
        });
        setUploadErrors(newErrors);
      }

      if (acceptedFiles && acceptedFiles.length > 0) {
        acceptedFiles.forEach((file) => {
          setUploadProgress((prev) => ({ ...prev, [file.name]: 0 }));
        });

        simulateUploadProgress(acceptedFiles);

        onUpload(acceptedFiles);
      }
    },
    [onUpload, uploadErrors, maxFileSizeMB],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
    },
    onDrop,
    disabled: isViewer,
    maxSize: maxFileSizeMB * 1024 * 1024,
    onDragEnter: () => setIsDraggingOver(true),
    onDragLeave: () => setIsDraggingOver(false),
    onDropAccepted: () => setIsDraggingOver(false),
    onDropRejected: () => setIsDraggingOver(false),
  });

  const handleImageClick = (image) => {
    setSelectedImage(image);
    onOpen();
  };

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };

  const simulateUploadProgress = (files) => {
    files.forEach((file) => {
      const timer = setInterval(() => {
        setUploadProgress((prev) => {
          const current = prev[file.name] || 0;
          const newValue = current + 5;
          if (newValue >= 100) clearInterval(timer);
          return { ...prev, [file.name]: Math.min(newValue, 100) };
        });
      }, 200);
    });
  };

  const dropzoneColor = useColorModeValue('gray.50', 'gray.700');
  const hoverColor = useColorModeValue('blue.50', 'blue.900');
  const activeColor = useColorModeValue('blue.100', 'blue.800');
  const rejectColor = useColorModeValue('red.50', 'red.900');

  const bgColor = isDragAccept
    ? activeColor
    : isDragReject
      ? rejectColor
      : isDraggingOver
        ? hoverColor
        : dropzoneColor;

  const borderColor = isDragAccept
    ? 'green.400'
    : isDragReject
      ? 'red.400'
      : isDraggingOver
        ? 'blue.400'
        : 'gray.200';

  return (
    <Card
      variant="outline"
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="md"
      overflow="hidden"
      mb={6}
    >
      <CardHeader bg="blue.50" py={4}>
        <Heading size="md">Product Images</Heading>
      </CardHeader>
      <CardBody p={4}>
        {/* Main image preview section */}
        {images.length > 0 && (
          <Box
            position="relative"
            mb={6}
            borderWidth="1px"
            borderRadius="md"
            overflow="hidden"
          >
            <Image
              src={images[currentImageIndex]?.image_url}
              alt="Product image"
              objectFit="contain"
              w="100%"
              h="300px"
              cursor="pointer"
              onClick={() => handleImageClick(images[currentImageIndex])}
            />

            {/* Navigation arrows */}
            {images.length > 1 && (
              <>
                <IconButton
                  icon={<MdChevronLeft />}
                  aria-label="Previous image"
                  position="absolute"
                  left="10px"
                  top="50%"
                  transform="translateY(-50%)"
                  size="md"
                  variant="ghost"
                  colorScheme="blue"
                  onClick={handlePrevImage}
                />
                <IconButton
                  icon={<MdChevronRight />}
                  aria-label="Next image"
                  position="absolute"
                  right="10px"
                  top="50%"
                  transform="translateY(-50%)"
                  size="md"
                  variant="ghost"
                  colorScheme="blue"
                  onClick={handleNextImage}
                />
              </>
            )}

            {/* Featured badge */}
            {images[currentImageIndex]?.is_featured && (
              <Badge
                position="absolute"
                top={2}
                left={2}
                colorScheme="yellow"
                borderRadius="full"
                display="flex"
                alignItems="center"
                px={2}
                py={1}
              >
                <Icon as={MdStar} mr={1} />
                Featured
              </Badge>
            )}

            {/* Action buttons */}
            {!isViewer && (
              <Flex position="absolute" bottom={2} right={2} gap={2}>
                <Tooltip label="Set as featured" placement="top">
                  <IconButton
                    size="sm"
                    colorScheme={
                      images[currentImageIndex]?.is_featured ? 'yellow' : 'gray'
                    }
                    icon={<MdStar />}
                    onClick={() =>
                      onSetFeatured(images[currentImageIndex]?.uuid)
                    }
                    aria-label="Set as featured"
                  />
                </Tooltip>
                <Tooltip label="Delete image" placement="top">
                  <IconButton
                    size="sm"
                    colorScheme="red"
                    icon={<MdDelete />}
                    onClick={() => onDelete(images[currentImageIndex]?.uuid)}
                    aria-label="Delete image"
                  />
                </Tooltip>
              </Flex>
            )}
          </Box>
        )}

        {!isViewer && (
          <Box
            {...getRootProps()}
            border="2px dashed"
            borderColor={borderColor}
            borderRadius="md"
            bg={bgColor}
            p={6}
            mb={6}
            transition="all 0.3s ease"
            _hover={{ borderColor: 'blue.300', bg: hoverColor }}
            position="relative"
            height="180px"
          >
            <input {...getInputProps()} />

            <Flex
              direction="column"
              align="center"
              justify="center"
              h="100%"
              opacity={isDraggingOver ? 0.7 : 1}
              transition="opacity 0.2s ease"
            >
              <Icon
                as={isDragActive ? MdOutlineCloudUpload : MdAddPhotoAlternate}
                boxSize={12}
                color={isDragReject ? 'red.500' : 'blue.400'}
                mb={3}
                transition="transform 0.2s ease"
                transform={isDragActive ? 'scale(1.1)' : 'scale(1)'}
              />

              <Text fontWeight="bold" mb={2} fontSize="lg">
                {isDragActive
                  ? isDragReject
                    ? 'File type not supported'
                    : 'Drop to upload'
                  : 'Drag & drop product images here'}
              </Text>

              <HStack spacing={2} mb={1}>
                <Divider flex="1" />
                <Text color="gray.500">OR</Text>
                <Divider flex="1" />
              </HStack>

              <Button
                size="sm"
                colorScheme="blue"
                leftIcon={<MdFileUpload />}
                variant="outline"
                onClick={(e) => e.stopPropagation()}
              >
                Browse Files
              </Button>

              <Text fontSize="xs" color="gray.500" mt={2} textAlign="center">
                Accepts {acceptedFileTypes} up to {maxFileSizeMB}MB
              </Text>
            </Flex>
          </Box>
        )}

        {/* Upload preview section */}
        {Object.keys(uploadProgress).length > 0 && (
          <Box mb={6} borderWidth="1px" borderRadius="md" p={4} bg="gray.50">
            <Text fontWeight="medium" mb={3}>
              Uploading Files
            </Text>
            <VStack spacing={4} align="stretch">
              {Object.entries(uploadProgress).map(([filename, progress]) => {
                // Set color based on progress
                const colorScheme =
                  progress < 30
                    ? 'blue'
                    : progress < 70
                      ? 'cyan'
                      : progress < 100
                        ? 'green'
                        : 'green';

                // Get status text
                const statusText =
                  progress < 25
                    ? 'Starting upload...'
                    : progress < 50
                      ? 'Uploading...'
                      : progress < 75
                        ? 'Processing...'
                        : progress < 100
                          ? 'Finalizing...'
                          : 'Complete';

                // Calculate file size (placeholder - in real implementation this would come from the file)
                const fileSize = filename.length * 10; // Just a dummy calculation
                const fileSizeText =
                  fileSize > 1000
                    ? `${(fileSize / 1000).toFixed(1)} MB`
                    : `${fileSize} KB`;

                return (
                  <Box
                    key={filename}
                    p={3}
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow="sm"
                    bg="white"
                  >
                    <Flex align="center" mb={2}>
                      <Icon as={MdImage} color="blue.500" boxSize={6} mr={3} />
                      <Box flex="1">
                        <Text fontWeight="medium" noOfLines={1}>
                          {filename}
                        </Text>
                        <Text fontSize="xs" color="gray.500">
                          {fileSizeText} • {statusText}
                        </Text>
                      </Box>
                      <Badge
                        colorScheme={progress === 100 ? 'green' : 'blue'}
                        fontSize="sm"
                        px={2}
                      >
                        {Math.round(progress)}%
                      </Badge>
                    </Flex>

                    <Progress
                      value={progress}
                      size="md"
                      colorScheme={colorScheme}
                      borderRadius="full"
                      height="8px"
                      hasStripe={progress < 100}
                      isAnimated={progress < 100}
                    />

                    {progress === 100 && (
                      <Flex mt={2} justify="flex-end">
                        <HStack color="green.500" spacing={1}>
                          <Icon as={MdCheck} />
                          <Text fontSize="sm" fontWeight="medium">
                            Upload complete
                          </Text>
                        </HStack>
                      </Flex>
                    )}
                  </Box>
                );
              })}
            </VStack>
          </Box>
        )}

        {/* Gallery section */}
        {images.length > 0 && (
          <Box>
            <Flex
              justify="space-between"
              align="center"
              mb={3}
              cursor="pointer"
            >
              <Text fontWeight="medium">
                Gallery ({images.length}{' '}
                {images.length === 1 ? 'image' : 'images'})
              </Text>
              <Button
                size="sm"
                variant="ghost"
                rightIcon={isGalleryOpen ? <MdExpandLess /> : <MdExpandMore />}
                onClick={onGalleryToggle}
              >
                {isGalleryOpen ? 'Hide' : 'Show'}
              </Button>
            </Flex>

            <Collapse in={isGalleryOpen} animateOpacity>
              <SimpleGrid columns={{ base: 2, md: 4 }} spacing={4}>
                {images.map((img, index) => (
                  <Box
                    key={img.uuid}
                    position="relative"
                    borderRadius="md"
                    overflow="hidden"
                    borderWidth={index === currentImageIndex ? '2px' : '1px'}
                    borderColor={
                      index === currentImageIndex ? 'blue.500' : 'gray.200'
                    }
                    boxShadow="sm"
                    transition="transform 0.2s, box-shadow 0.2s"
                    _hover={{
                      transform: 'translateY(-2px)',
                      boxShadow: 'md',
                    }}
                    cursor="pointer"
                    onClick={() => handleThumbnailClick(index)}
                  >
                    <Image
                      src={img.image_url}
                      alt="Product image"
                      objectFit="cover"
                      w="100%"
                      h="150px"
                      opacity={index === currentImageIndex ? 1 : 0.7}
                    />

                    {img.is_featured && (
                      <Badge
                        position="absolute"
                        top={2}
                        left={2}
                        colorScheme="yellow"
                        borderRadius="full"
                        display="flex"
                        alignItems="center"
                        px={2}
                        py={1}
                      >
                        <Icon as={MdStar} mr={1} />
                        Featured
                      </Badge>
                    )}

                    {!isViewer && (
                      <Flex
                        position="absolute"
                        bottom={0}
                        left={0}
                        right={0}
                        p={2}
                        bg="blackAlpha.600"
                        justifyContent="space-between"
                        opacity="0.4"
                        transition="opacity 0.2s"
                        _groupHover={{ opacity: 1 }}
                      >
                        <Tooltip label="Set as featured" placement="top">
                          <IconButton
                            size="xs"
                            colorScheme={img.is_featured ? 'yellow' : 'gray'}
                            icon={<MdStar />}
                            onClick={(e) => {
                              e.stopPropagation();
                              onSetFeatured(img.uuid);
                            }}
                            aria-label="Set as featured"
                          />
                        </Tooltip>
                        <Tooltip label="Delete image" placement="top">
                          <IconButton
                            size="xs"
                            colorScheme="red"
                            icon={<MdDelete />}
                            onClick={(e) => {
                              e.stopPropagation();
                              onDelete(img.uuid);
                            }}
                            aria-label="Delete image"
                          />
                        </Tooltip>
                      </Flex>
                    )}

                    {uploadProgress[img.uuid] !== undefined &&
                      uploadProgress[img.uuid] < 100 && (
                        <Box position="absolute" bottom={0} left={0} right={0}>
                          <Text
                            bg="blackAlpha.700"
                            color="white"
                            fontSize="xs"
                            px={2}
                            textAlign="center"
                          >
                            Uploading: {Math.round(uploadProgress[img.uuid])}%
                          </Text>
                          <Progress
                            value={uploadProgress[img.uuid]}
                            height="6px"
                            colorScheme="blue"
                            hasStripe
                            isAnimated
                          />
                        </Box>
                      )}
                  </Box>
                ))}
              </SimpleGrid>
            </Collapse>
          </Box>
        )}

        {/* Empty state */}
        {images.length === 0 && !isViewer && (
          <Center
            p={10}
            borderWidth="1px"
            borderRadius="md"
            borderStyle="dashed"
            borderColor="gray.200"
          >
            <VStack spacing={3}>
              <Icon as={MdImage} boxSize={10} color="gray.300" />
              <Text color="gray.500">No images uploaded yet</Text>
            </VStack>
          </Center>
        )}

        {/* Image preview modal */}
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay backdropFilter="blur(3px)" />
          <ModalContent>
            <ModalCloseButton zIndex={2} color="white" />
            <ModalBody p={0}>
              {selectedImage && (
                <Image
                  src={selectedImage.image_url}
                  alt="Product Image"
                  w="100%"
                  borderRadius="md"
                  objectFit="contain"
                  maxH="80vh"
                />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </CardBody>
    </Card>
  );
};

export default ProductImageUploader;
