// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { login as authServiceLogin } from '../services/authService';
import { getUserPermissions } from '../api/user';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();

  const loadUserPermissions = useCallback(async () => {
    try {
      console.log('Loading user permissions...');

      // Verify cookies exist before making the request
      const tokenCookie = Cookies.get('token');
      const companyCookie = Cookies.get('x-company');

      if (!tokenCookie) {
        console.error('Token cookie not found when loading permissions');
        return;
      }

      if (!companyCookie) {
        console.error('Company cookie not found when loading permissions');
        return;
      }

      const permissions = await getUserPermissions();

      if (permissions && permissions.role) {
        setUserRole(permissions.role);
        setIsAuthenticated(true);
      } else {
        console.error('Permissions response missing role:', permissions);
      }
    } catch (error) {
      console.error('Error loading user permissions:', error);
    }
  }, []);

  const hasRole = useCallback(
    (requiredRole) => {
      if (!userRole) return false;

      switch (requiredRole) {
        case 'SUPER_ADMIN':
          return userRole === 'SUPER_ADMIN';
        case 'COMPANY_ADMIN':
          return userRole === 'SUPER_ADMIN' || userRole === 'COMPANY_ADMIN';
        case 'EDITOR':
          return (
            userRole === 'SUPER_ADMIN' ||
            userRole === 'COMPANY_ADMIN' ||
            userRole === 'EDITOR'
          );
        case 'VIEWER':
          return true;
        default:
          return false;
      }
    },
    [userRole],
  );

  useEffect(() => {
    const checkLoginState = async () => {
      try {
        const token = Cookies.get('token');
        if (token) {
          setIsAuthenticated(true);

          await loadUserPermissions();
        } else {
          navigate('/auth/login');
        }
      } catch (error) {
        console.error('Error checking login state', error);
      } finally {
        setLoading(false);
      }
    };
    checkLoginState();
  }, [navigate, loadUserPermissions]);

  const login = async (email, password) => {
    try {
      await authServiceLogin(email, password);
      setIsAuthenticated(true);
      navigate('/');
    } catch (error) {
      console.error('Login error', error);
    }
  };

  const logout = () => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name =
        eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim();

      // Remove cookie with different path combinations to ensure all instances are cleared
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;

      // Also use js-cookie to ensure compatibility
      Cookies.remove(name, { path: '/' });
      Cookies.remove(name);
    }

    Cookies.remove('token', {
      secure: process.env.NODE_ENV !== 'development',
      sameSite: 'Strict',
    });
    Cookies.remove('x-company', {
      secure: process.env.NODE_ENV !== 'development',
      sameSite: 'Strict',
    });

    localStorage.clear();
    sessionStorage.clear();

    setUserRole(null);
    setIsAuthenticated(false);
    navigate('/auth/sign-in');
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        loading,
        userRole,
        hasRole,
        loadUserPermissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
