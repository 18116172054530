import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  useToast,
  Card,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  SimpleGrid,
  Tag,
  TagLabel,
  TagCloseButton,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
} from '@chakra-ui/react';
import { languages as countryLanguages } from 'countries-list';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getCompany, updateCompany, uploadCompanyImage } from 'api/company';
import CompanyImageUploader from './CompanyImageUploader';
import HeroImage from 'assets/img/company/hero.svg';
import LogoImage from 'assets/img/company/logo.svg';
import HeaderImage from 'assets/img/company/header.svg';
import { getTranslation, setTranslation } from 'utils/translationUtils';
import { AuthContext } from 'contexts/AuthContext';

export default function GeneralSettings() {
  const { userRole } = useContext(AuthContext);
  const hasAdminAccess =
    userRole === 'COMPANY_ADMIN' || userRole === 'SUPER_ADMIN';

  const [companyUuid, setCompanyUuid] = useState('');
  const [logo, setLogo] = useState(null);
  const [headerImage, setHeaderImage] = useState(null);
  const [heroImage, setHeroImage] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [languageToDelete, setLanguageToDelete] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [translations, setTranslations] = useState({
    en: {
      name: '',
      description: '',
      program_title: '',
    },
  });

  const cancelRef = useRef();
  const toast = useToast();

  const allLanguages = Object.entries(countryLanguages).map(([code, data]) => {
    return {
      code: code,
      name: data.name,
      nativeName: data.native,
    };
  });
  const handleOnDragEnd = (result) => {
    if (!hasAdminAccess || !result.destination) return;

    const items = Array.from(languages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLanguages(items);
  };

  const fetchCompanyDetails = useCallback(async () => {
    try {
      const company = await getCompany();
      setCompanyUuid(company.uuid);
      setLogo(company.logo_url || null);
      setHeaderImage(company.header_image_url || null);
      setHeroImage(company.hero_image_url || null);

      if (company.languages) {
        const languageCodes = Array.isArray(company.languages)
          ? company.languages
          : Object.keys(company.languages);

        const translationsObj = {
          en: {
            name: company.name,
            description: company.description,
            program_title: company.program_title,
          },
        };

        for (const lang of languageCodes) {
          if (lang !== 'en') {
            translationsObj[lang] = {
              name: (await getTranslation('company_name', lang)) || '',
              description:
                (await getTranslation('company_description', lang)) || '',
              program_title:
                (await getTranslation('company_program_title', lang)) || '',
            };
          }
        }

        setTranslations(translationsObj);

        // Filter out invalid language codes and map to language objects
        const validLanguages = languageCodes
          .filter((code) => countryLanguages[code.toLowerCase()])
          .map((code) => {
            const langCode = code.toLowerCase();
            return {
              code: langCode,
              name: countryLanguages[langCode]?.name || code,
              nativeName: countryLanguages[langCode]?.native || code,
            };
          });

        setLanguages(validLanguages);
      }
    } catch (error) {
      console.error('Error fetching company details:', error);
      toast({
        title: 'Error fetching company details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchCompanyDetails();
  }, [fetchCompanyDetails]);

  const handleDeleteLanguage = (lang) => {
    if (!hasAdminAccess) return;
    setLanguageToDelete(lang);
    onOpen();
  };

  const confirmDelete = () => {
    if (!languageToDelete) return;
    setLanguages((langs) =>
      langs.filter((l) => l.code !== languageToDelete.code),
    );
    onClose();
  };

  const handleLogoChange = (acceptedFiles) => {
    if (!hasAdminAccess) return;
    if (acceptedFiles && acceptedFiles.length > 0) {
      setLogo(acceptedFiles[0]);
    }
  };

  const handleHeaderImageChange = (acceptedFiles) => {
    if (!hasAdminAccess) return;
    if (acceptedFiles && acceptedFiles.length > 0) {
      setHeaderImage(acceptedFiles[0]);
    }
  };

  const handleHeroImageChange = (acceptedFiles) => {
    if (!hasAdminAccess) return;
    if (acceptedFiles && acceptedFiles.length > 0) {
      setHeroImage(acceptedFiles[0]);
    }
  };

  const handleTranslationChange = (lang, field, value) => {
    if (!hasAdminAccess) return;
    setTranslations((prev) => ({
      ...prev,
      [lang]: {
        ...prev[lang],
        [field]: value,
      },
    }));
  };

  const handleSave = async () => {
    if (!hasAdminAccess) return;

    try {
      const languageCodes = languages.map((lang) => lang.code);

      // Create languages object with language codes as keys
      const languagesObject = languageCodes.reduce((acc, code) => {
        acc[code] = code; // Using code as value instead of true
        return acc;
      }, {});

      // Update company with English (default) values
      await updateCompany(companyUuid, {
        uuid: companyUuid,
        name: translations.en.name || '',
        description: translations.en.description || '',
        program_title: translations.en.program_title || '',
        languages: languagesObject, // Send as object with language codes
      });

      // Save translations for ALL languages, including English
      for (const lang of languageCodes) {
        if (translations[lang]) {
          if (translations[lang].name?.trim()) {
            await setTranslation('company_name', translations[lang].name, lang);
          }

          if (translations[lang].description?.trim()) {
            await setTranslation(
              'company_description',
              translations[lang].description,
              lang,
            );
          }

          if (translations[lang].program_title?.trim()) {
            await setTranslation(
              'company_program_title',
              translations[lang].program_title,
              lang,
            );
          }
        }
      }

      // Handle image uploads
      if (logo && typeof logo !== 'string') {
        await uploadCompanyImage('logo', logo);
      }

      if (headerImage && typeof headerImage !== 'string') {
        await uploadCompanyImage('header', headerImage);
      }

      if (heroImage && typeof heroImage !== 'string') {
        await uploadCompanyImage('hero', heroImage);
      }

      toast({
        title: 'Company details updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      await fetchCompanyDetails();
    } catch (error) {
      console.error('Error updating company details:', error);
      toast({
        title: 'Error updating company details.',
        description:
          error.response?.data?.message || 'An error occurred while updating.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const renderFormElement = (
    fieldName,
    value,
    onChange,
    placeholder,
    isTextarea = false,
  ) => {
    if (hasAdminAccess) {
      return isTextarea ? (
        <Textarea value={value} onChange={onChange} placeholder={placeholder} />
      ) : (
        <Input value={value} onChange={onChange} placeholder={placeholder} />
      );
    } else {
      // Read-only view
      return (
        <Box
          p={2}
          borderWidth="1px"
          borderRadius="md"
          bg="gray.50"
          minHeight={isTextarea ? '100px' : 'auto'}
          overflowY="auto"
        >
          {value || <Text color="gray.400">{placeholder}</Text>}
        </Box>
      );
    }
  };

  return (
    <Card mb="20px" w="100%">
      <Flex alignItems="center" direction="column" p={5}>
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify={{ sm: 'flex-start', lg: 'flex-start' }}
          w="100%"
        >
          <Text fontSize="2xl" fontWeight="bold" mb={5}>
            General Settings
          </Text>
          {hasAdminAccess && (
            <Button ml="auto" size="md" colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          )}
        </Flex>

        <Tabs width="100%">
          <TabList>
            {languages.map((lang) => (
              <Tab key={lang.code}>{lang.name}</Tab>
            ))}
          </TabList>

          <TabPanels>
            {languages.map((lang) => (
              <TabPanel key={lang.code}>
                <Box width="100%" mb={3}>
                  <Text mb={1}>Company Name</Text>
                  {renderFormElement(
                    'name',
                    translations[lang.code]?.name || '',
                    (e) =>
                      handleTranslationChange(
                        lang.code,
                        'name',
                        e.target.value,
                      ),
                    'Enter company name',
                  )}
                </Box>
                <Box width="100%" mb={3}>
                  <Text mb={1}>Description</Text>
                  {renderFormElement(
                    'description',
                    translations[lang.code]?.description || '',
                    (e) =>
                      handleTranslationChange(
                        lang.code,
                        'description',
                        e.target.value,
                      ),
                    'Enter company description',
                    true,
                  )}
                </Box>
                <Box width="100%" mb={3}>
                  <Text mb={1}>Program Title</Text>
                  {renderFormElement(
                    'program_title',
                    translations[lang.code]?.program_title || '',
                    (e) =>
                      handleTranslationChange(
                        lang.code,
                        'program_title',
                        e.target.value,
                      ),
                    'Enter program title',
                  )}
                </Box>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>

        <Box width="100%" mb={3}>
          <Flex justifyContent="space-between" alignItems="center" mb={1}>
            <Text>Languages</Text>
            {hasAdminAccess && (
              <Button
                size="sm"
                colorScheme="blue"
                onClick={() => setIsLanguageModalOpen(true)}
              >
                Add Language
              </Button>
            )}
          </Flex>

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="languages" direction="horizontal">
              {(provided) => (
                <Flex
                  wrap="wrap"
                  gap={2}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {languages.map((lang, index) => (
                    <Draggable
                      key={lang.code}
                      draggableId={lang.code}
                      index={index}
                      isDragDisabled={!hasAdminAccess}
                    >
                      {(provided) => (
                        <Tag
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          size="lg"
                          borderRadius="8px"
                          variant="solid"
                          colorScheme="blue"
                        >
                          <TagLabel>{lang.name}</TagLabel>
                          {hasAdminAccess && (
                            <TagCloseButton
                              onClick={() => handleDeleteLanguage(lang)}
                            />
                          )}
                        </Tag>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>

          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Delete Language</AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure you want to remove {languageToDelete?.name}?
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" ml={3} onClick={confirmDelete}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          <Modal
            isOpen={isLanguageModalOpen}
            onClose={() => setIsLanguageModalOpen(false)}
            size="xl"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Select Languages</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Input
                  placeholder="Search languages..."
                  mb={4}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <SimpleGrid
                  columns={2}
                  spacing={4}
                  maxH="60vh"
                  overflowY="auto"
                >
                  {allLanguages
                    .filter(
                      (lang) =>
                        lang.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        lang.nativeName
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        lang.code
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()),
                    )
                    .map((lang) => (
                      <Button
                        key={lang.code}
                        onClick={() => {
                          setLanguages((prev) => [...prev, lang]);
                          setIsLanguageModalOpen(false);
                        }}
                        isDisabled={languages.some((l) => l.code === lang.code)}
                        justifyContent="flex-start"
                      >
                        {lang.name} ({lang.nativeName})
                      </Button>
                    ))}
                </SimpleGrid>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>

        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={4}
          width="100%"
          mt={3}
        >
          <CompanyImageUploader
            title="Company Logo"
            image={logo}
            defaultImage={LogoImage}
            onUpload={handleLogoChange}
            isDisabled={!hasAdminAccess}
          />
          <CompanyImageUploader
            title="Header Image"
            image={headerImage}
            defaultImage={HeaderImage}
            onUpload={handleHeaderImageChange}
            isDisabled={!hasAdminAccess}
          />
          <CompanyImageUploader
            title="Hero Image"
            image={heroImage}
            defaultImage={HeroImage}
            onUpload={handleHeroImageChange}
            isDisabled={!hasAdminAccess}
          />
        </SimpleGrid>
      </Flex>
    </Card>
  );
}
