import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Checkbox,
  Flex,
  Heading,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Alert,
  AlertIcon,
  useToast,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepDescription,
  StepSeparator,
  StepIcon,
  InputGroup,
  InputLeftAddon,
  Input,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import { AddIcon, CheckIcon } from '@chakra-ui/icons';
import {
  getAttributeKey,
  getAttributeValueName,
  getCombinationKey,
} from '../../../utils/utils';
import {
  createProductVariation,
  createCategoryVariation,
} from '../../../api/attributesVariations';

const GenerateVariationsModal = ({
  isOpen,
  onClose,
  availableAttributes,
  productUuid,
  categoryId,
  variationType,
  productPrice,
  productQuantity,
  unlimitedQuantity,
  onVariationsGenerated,
  existingVariations = [],
}) => {
  const toast = useToast();
  const [generateStep, setGenerateStep] = useState(0);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [potentialCombinations, setPotentialCombinations] = useState([]);
  const [selectedCombinations, setSelectedCombinations] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [defaultPrice, setDefaultPrice] = useState('');
  const [defaultStock, setDefaultStock] = useState('');
  const [useProductPrice, setUseProductPrice] = useState(true);
  const [useProductStock, setUseProductStock] = useState(true);

  useEffect(() => {
    if (isOpen && availableAttributes?.length > 0) {
      setSelectedAttributes(availableAttributes.map((attr) => attr.id));
      setDefaultPrice(productPrice || '');
      setDefaultStock(productQuantity || '');
      setUseProductPrice(true);
      setUseProductStock(true);
    }
  }, [isOpen, availableAttributes, productPrice, productQuantity]);

  const toggleAttributeSelection = (attributeId) => {
    if (selectedAttributes.includes(attributeId)) {
      setSelectedAttributes(
        selectedAttributes.filter((id) => id !== attributeId),
      );
    } else {
      setSelectedAttributes([...selectedAttributes, attributeId]);
    }
  };

  const calculateCombinations = () => {
    const allCombinations = generateAttributeCombinations(availableAttributes);

    const existingCombinationKeys = new Set();
    existingVariations.forEach((variation) => {
      if (variation.attribute_values_json) {
        const key = getCombinationKey(variation.attribute_values_json);
        existingCombinationKeys.add(key);
      }
    });

    const newCombinations = allCombinations.filter((combo) => {
      const comboKey = getCombinationKey(combo);
      return !existingCombinationKeys.has(comboKey);
    });

    setPotentialCombinations(newCombinations);
    setSelectedCombinations(newCombinations.map((_, index) => index));
    return newCombinations.length;
  };

  const generateAttributeCombinations = (attributes) => {
    const results = [];

    const filteredAttributes = attributes.filter((attr) =>
      selectedAttributes.includes(attr.id),
    );

    function generateCombos(index, current) {
      if (index === filteredAttributes.length) {
        results.push({ ...current });
        return;
      }

      const attribute = filteredAttributes[index];
      const attributeKey = getAttributeKey(attribute);

      for (const value of attribute.values) {
        const valueName = getAttributeValueName(value);
        current[attributeKey] = valueName;
        generateCombos(index + 1, current);
      }
    }

    generateCombos(0, {});
    return results;
  };

  const toggleCombination = (index) => {
    if (selectedCombinations.includes(index)) {
      setSelectedCombinations(selectedCombinations.filter((i) => i !== index));
    } else {
      setSelectedCombinations([...selectedCombinations, index]);
    }
  };

  const toggleAllCombinations = () => {
    if (selectedCombinations.length === potentialCombinations.length) {
      setSelectedCombinations([]);
    } else {
      setSelectedCombinations(potentialCombinations.map((_, index) => index));
    }
  };

  const generateAllVariations = async () => {
    if (selectedCombinations.length === 0) {
      toast({
        title: 'No combinations selected',
        status: 'warning',
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      const successfulVariations = [];

      for (const index of selectedCombinations) {
        const combination = potentialCombinations[index];

        const variationData = {
          attribute_values_json: combination,
          is_active: true,
        };

        // Set price based on user selection
        if (useProductPrice) {
          // Use null to indicate sync with product price
          variationData.price_override = null;
        } else if (defaultPrice) {
          variationData.price_override = Number(defaultPrice);
        }

        // Set stock based on user selection
        if (useProductStock) {
          // Use null to indicate sync with product stock
          variationData.stock_quantity = null;
        } else if (defaultStock) {
          variationData.stock_quantity = Number(defaultStock);
        }

        // Use the appropriate API call based on variation type
        let response;
        if (variationType === 'product') {
          variationData.product_uuid = productUuid;
          response = await createProductVariation(variationData);
        } else {
          variationData.category_uuid = categoryId;
          response = await createCategoryVariation(variationData);
        }

        successfulVariations.push(response);
      }

      toast({
        title: 'Success',
        description: `Created ${successfulVariations.length} variations.`,
        status: 'success',
        isClosable: true,
      });

      onVariationsGenerated();
      resetModal();
    } catch (error) {
      console.error('Error generating variations:', error);
      toast({
        title: 'Error',
        description: 'Failed to generate variations.',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const resetModal = () => {
    setPotentialCombinations([]);
    setSelectedCombinations([]);
    setGenerateStep(0);
    setDefaultPrice('');
    setDefaultStock('');
    setUseProductPrice(true);
    setUseProductStock(true);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={resetModal}
      isCentered
      size="xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Generate {variationType === 'product' ? 'Product' : 'Category'}{' '}
          Variations
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stepper index={generateStep} mb="5">
            <Step>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<AddIcon />}
                  active={<AddIcon />}
                />
              </StepIndicator>
              <Box flexShrink="0">
                <StepTitle>Select Attributes</StepTitle>
                <StepDescription>
                  Choose which attributes to include
                </StepDescription>
              </Box>
              <StepSeparator />
            </Step>
            <Step>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<CheckIcon />}
                  active={<CheckIcon />}
                />
              </StepIndicator>
              <Box flexShrink="0">
                <StepTitle>Select Combinations</StepTitle>
                <StepDescription>
                  Choose which combinations to generate
                </StepDescription>
              </Box>
            </Step>
          </Stepper>

          {generateStep === 0 ? (
            <Box>
              <Text mb="4">
                Select which attributes you want to include in the generated
                variations:
              </Text>
              {availableAttributes.map((attr) => (
                <Flex
                  key={attr.id}
                  p="3"
                  borderWidth="1px"
                  borderRadius="md"
                  mb="3"
                  align="center"
                  justify="space-between"
                  cursor="pointer"
                  onClick={() => toggleAttributeSelection(attr.id)}
                  _hover={{ bg: 'gray.50' }}
                  transition="background-color 0.2s"
                  bg={
                    selectedAttributes.includes(attr.id) ? 'blue.50' : undefined
                  }
                >
                  <Box>
                    <Heading size="sm">
                      {attr.translations?.find((tr) => tr.language === 'en')
                        ?.value || attr.slug}
                    </Heading>
                    <Flex mt="2" flexWrap="wrap" gap="2">
                      {attr.values?.map((val) => (
                        <Badge
                          key={val.id}
                          colorScheme="blue"
                          variant="solid"
                          py="1"
                          px="2"
                        >
                          {val.translations?.find((tr) => tr.language === 'en')
                            ?.value || val.slug}
                        </Badge>
                      ))}
                    </Flex>
                  </Box>
                  <Checkbox
                    size="lg"
                    colorScheme="blue"
                    isChecked={selectedAttributes.includes(attr.id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      toggleAttributeSelection(attr.id);
                    }}
                  />
                </Flex>
              ))}

              <Box mt={6} p={4} borderWidth="1px" borderRadius="md">
                <Heading size="sm" mb={3}>
                  Variation Settings
                </Heading>

                <FormControl display="flex" alignItems="center" mb={3}>
                  <FormLabel htmlFor="use-product-price" mb="0">
                    Use product price
                  </FormLabel>
                  <Switch
                    id="use-product-price"
                    isChecked={useProductPrice}
                    onChange={(e) => setUseProductPrice(e.target.checked)}
                  />
                </FormControl>
                {!useProductPrice && (
                  <InputGroup size="sm" mb={4}>
                    <InputLeftAddon children="€" />
                    <Input
                      type="number"
                      placeholder="Default variation price"
                      value={defaultPrice}
                      onChange={(e) => setDefaultPrice(e.target.value)}
                    />
                  </InputGroup>
                )}
                <FormControl display="flex" alignItems="center" mb={3}>
                  <FormLabel htmlFor="use-product-stock" mb="0">
                    Use product stock
                  </FormLabel>
                  <Switch
                    id="use-product-stock"
                    isChecked={useProductStock}
                    onChange={(e) => setUseProductStock(e.target.checked)}
                  />
                </FormControl>
                {!useProductStock && (
                  <InputGroup size="sm">
                    <Input
                      type="number"
                      placeholder="Default stock quantity"
                      value={defaultStock}
                      onChange={(e) => setDefaultStock(e.target.value)}
                    />
                  </InputGroup>
                )}
              </Box>
            </Box>
          ) : (
            <Box>
              <Flex justifyContent="space-between" alignItems="center" mb="3">
                <Heading size="sm">
                  Combinations to Generate ({selectedCombinations.length}/
                  {potentialCombinations.length}):
                </Heading>
                <Button
                  size="sm"
                  onClick={toggleAllCombinations}
                  colorScheme="blue"
                  variant="outline"
                >
                  {selectedCombinations.length === potentialCombinations.length
                    ? 'Deselect All'
                    : 'Select All'}
                </Button>
              </Flex>

              {potentialCombinations.length === 0 ? (
                <Alert status="info" variant="left-accent">
                  <AlertIcon />
                  All possible combinations already exist.
                </Alert>
              ) : (
                <Box borderWidth="1px" borderRadius="md">
                  <Table size="sm">
                    <Thead
                      position="sticky"
                      top={0}
                      bg="white"
                      zIndex={1}
                      boxShadow="xs"
                    >
                      <Tr>
                        <Th width="40px">
                          <Checkbox
                            isChecked={
                              selectedCombinations.length ===
                              potentialCombinations.length
                            }
                            onChange={toggleAllCombinations}
                            colorScheme="blue"
                          />
                        </Th>
                        {availableAttributes
                          .filter((attr) =>
                            selectedAttributes.includes(attr.id),
                          )
                          .map((attr) => (
                            <Th key={attr.id}>
                              {attr.translations?.find(
                                (tr) => tr.language === 'en',
                              )?.value || attr.slug}
                            </Th>
                          ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {potentialCombinations.map((combo, index) => (
                        <Tr
                          key={index}
                          _hover={{ bg: 'gray.50' }}
                          bg={
                            selectedCombinations.includes(index)
                              ? 'blue.50'
                              : undefined
                          }
                        >
                          <Td>
                            <Checkbox
                              isChecked={selectedCombinations.includes(index)}
                              onChange={() => toggleCombination(index)}
                              colorScheme="blue"
                            />
                          </Td>
                          {availableAttributes
                            .filter((attr) =>
                              selectedAttributes.includes(attr.id),
                            )
                            .map((attr) => {
                              const key = getAttributeKey(attr);
                              return (
                                <Td key={`combo-${index}-${attr.id}`}>
                                  <Badge variant="subtle" colorScheme="purple">
                                    {combo[key] || '-'}
                                  </Badge>
                                </Td>
                              );
                            })}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              )}
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          {generateStep === 0 ? (
            <>
              <Button variant="ghost" mr={3} onClick={resetModal}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => {
                  calculateCombinations();
                  setGenerateStep(1);
                }}
                isDisabled={selectedAttributes.length === 0}
              >
                Next
              </Button>
            </>
          ) : (
            <>
              <Button variant="ghost" mr={3} onClick={() => setGenerateStep(0)}>
                Back
              </Button>
              <Button
                colorScheme="blue"
                onClick={generateAllVariations}
                isLoading={isSubmitting}
                isDisabled={selectedCombinations.length === 0}
                leftIcon={<AddIcon />}
              >
                Generate{' '}
                {selectedCombinations.length > 0
                  ? `(${selectedCombinations.length})`
                  : ''}
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GenerateVariationsModal;
