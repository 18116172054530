import React, { useState, useEffect, useContext } from 'react';
import {
  Flex,
  Spinner,
  useToast,
  Box,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import CategoryTree from './CategoryTree';
import { getCategories } from '../../api/category';
import {
  flattenCategoryTree,
  transformCategories,
} from '../../utils/CategoryUtils';
import { AuthContext } from 'contexts/AuthContext';

export default function CategoriesOverview() {
  const [, setCategories] = useState([]);
  const [categoryTree, setCategoryTree] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userRole } = useContext(AuthContext);
  const isViewer = userRole === 'VIEWER';
  const toast = useToast();

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const categoriesData = await getCategories();
        const tree = transformCategories(categoriesData, 'en');
        setCategoryTree(tree);
        const flatList = flattenCategoryTree(tree);
        setCategories(flatList);
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast({
          title: 'Error fetching categories',
          description: 'There was an error fetching categories data.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [toast]);

  const handleSetCategoryTree = (updatedTree) => {
    setCategoryTree(updatedTree);
    const flatList = flattenCategoryTree(updatedTree);
    setCategories(flatList);
  };

  const handleMoveCategory = (newCategoryTree) => {
    setCategoryTree(newCategoryTree);
    const flatList = flattenCategoryTree(newCategoryTree);
    setCategories(flatList);
  };

  return (
    <Flex
      direction="column"
      mt={{ sm: '150px', md: '125px', lg: '75px' }}
      p={4}
    >
      {isViewer && (
        <Alert status="info" mb={4}>
          <AlertIcon />
          You are in view-only mode. Contact an administrator to make changes.
        </Alert>
      )}

      <Box>
        {loading ? (
          <Flex justify="center" align="center" h="200px">
            <Spinner size="lg" />
          </Flex>
        ) : (
          <CategoryTree
            initialData={categoryTree}
            onUpdateCategories={handleSetCategoryTree}
            onMoveCategory={handleMoveCategory}
            isViewer={isViewer}
          />
        )}
      </Box>
    </Flex>
  );
}
