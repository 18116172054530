import {
  Button,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
  Switch,
  VStack,
  Box,
  useToast,
  Image,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  useDisclosure,
  Alert,
  AlertIcon,
  Card,
  CardHeader,
  CardBody,
  Heading,
  HStack,
  Badge,
  Divider,
  Icon,
} from '@chakra-ui/react';
import { EditIcon, ViewIcon } from '@chakra-ui/icons';
import {
  MdAttachMoney,
  MdCardMembership,
  MdInfoOutline,
  MdSettings,
  MdLayers,
  MdTimelapse,
  MdShoppingCart,
} from 'react-icons/md';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { debounce } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getLoyaltyPrograms,
  updateLoyaltyProgram,
  createLoyaltyLevel,
  deleteLoyaltyProgram,
} from 'api/loyalty';
import { createProduct } from 'api/product';
import { convertFromDays } from 'utils/loyaltyUtils';
import {
  LoyaltyDeleteAlert,
  LoyaltyPublishAlert,
} from 'components/loyalty/LoyaltyAlerts';
import { getCompany } from 'api/company';
import { getTranslation, setTranslation } from 'utils/translationUtils';
import { AuthContext } from 'contexts/AuthContext';

export default function LoyaltyDetail() {
  const textColorSecondary = useColorModeValue('gray.600', 'gray.400');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const headerBg = useColorModeValue('blue.50', 'navy.800');
  const labelColor = useColorModeValue('gray.500', 'gray.400');

  const navigate = useNavigate();
  const toast = useToast();
  const { uuid } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const [isProductActive, setIsProductActive] = useState(true);
  const [isProductPublished, setIsProductPublished] = useState(0);
  const [useCustomContracts, setUseCustomContracts] = useState(true);
  const [programData, setProgramData] = useState({
    web3_loyalty_contract: '',
    web3_voucher_contract: '',
    products: [],
  });
  const [languages, setLanguages] = useState([
    { code: 'en', name: 'English' },
    { code: 'et', name: 'Estonian' },
  ]);

  const [translations, setTranslationsState] = useState({
    en: {
      name: '',
      description: '',
    },
    et: {
      name: '',
      description: '',
    },
  });

  const { userRole } = useContext(AuthContext);
  const isViewer = userRole === 'VIEWER';

  // Fetch program data if editing an existing program
  useEffect(() => {
    if (uuid) {
      getLoyaltyPrograms()
        .then(async (response) => {
          const filteredRespone = response.filter(
            (program) => program.uuid === uuid,
          );

          const company = await getCompany();
          if (company.langauges) {
            setLanguages(
              company.langauges.map((lang) => ({
                code: lang,
                name:
                  lang === 'en' ? 'English' : lang === 'et' ? 'Estonian' : lang,
              })),
            );
          }
          setProgramData(
            filteredRespone[0] || {
              web3_loyalty_contract: '',
              web3_voucher_contract: '',
              products: [],
            },
          );
          setIsProductActive(filteredRespone[0].is_active);
          setIsProductPublished(filteredRespone[0].is_published);
          setUseCustomContracts(false);

          let translationsObj = {};

          for (const lang of languages) {
            translationsObj[lang.code] = {
              name:
                (await getTranslation(`loyalty_name_${uuid}`, lang.code)) || '',
              description:
                (await getTranslation(
                  `loyalty_description_${uuid}`,
                  lang.code,
                )) || '',
            };
          }

          setTranslationsState(translationsObj);
        })

        .catch((error) => {
          console.error('Error fetching program data', error);
        });
    }
  }, [uuid, languages]);

  const handleSave = async () => {
    if (isViewer) return;
    try {
      const { products, translations, ...updatedProgramData } = {
        ...programData,
        is_active: isProductActive,
        is_published: isProductPublished,
      };
      await updateLoyaltyProgram(uuid, updatedProgramData);

      toast({
        title: 'Success',
        description: 'Loyalty program updated successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving loyalty program:', error);
      toast({
        title: 'Error',
        description: 'Error saving loyalty program',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleActiveStatusToggle = async (isActive) => {
    if (isViewer) return;
    setIsProductActive(isActive);

    try {
      const { products, translations, ...updatedProgramData } = {
        ...programData,
        is_active: isActive,
        is_published: isProductPublished,
      };

      await updateLoyaltyProgram(uuid, updatedProgramData);

      toast({
        title: 'Status updated',
        description: `Program ${isActive ? 'activated' : 'deactivated'} successfully`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating active status:', error);

      // Revert UI state if save failed
      setIsProductActive(!isActive);

      toast({
        title: 'Error',
        description: 'Failed to update program status',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = useCallback((e) => {
    const { id, value } = e.target;
    setProgramData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }, []);

  const handlePublish = () => {
    if (isViewer) return;
    const { products, translations, ...updatedProgramData } = {
      ...programData,
      is_active: true,
      is_published: 1,
    };
    updateLoyaltyProgram(uuid, updatedProgramData)
      .then(() => {
        setIsProductActive(true);
        setIsProductPublished(1);

        toast({
          title: 'Success',
          description:
            'Loyalty program has been scheduled for publishing. This will take a few minutes.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error('Error updating loyalty program:', error);
      });
  };

  const handleDelete = async (id) => {
    if (isViewer) return;

    try {
      await deleteLoyaltyProgram(id);
      navigate('/admin/loyalty-programs');
      toast({
        title: 'Loyalty program deleted',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Error deleting loyalty program',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleConfirmPublish = () => {
    onClose();
    handlePublish();
  };

  const handleDeleteConfirm = () => {
    onDeleteDialogClose();
    handleDelete(uuid);
  };

  const debouncedTranslationChange = useMemo(
    () =>
      debounce(async (lang, field, value) => {
        try {
          const fieldMapping = {
            name: 'loyalty_name',
            description: 'loyalty_description',
          };

          await setTranslation(`${fieldMapping[field]}_${uuid}`, value, lang);
        } catch (error) {
          console.error('Error saving translation:', error);
          toast({
            title: 'Error saving changes',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }, 1000),
    [uuid, toast],
  );

  const handleTranslationChange = (lang, field, value) => {
    setTranslationsState((prev) => ({
      ...prev,
      [lang]: {
        ...prev[lang],
        [field]: value,
      },
    }));

    debouncedTranslationChange(lang, field, value);
  };

  const handleLevelAction = async (productData) => {
    if (productData) {
      // Edit existing level
      navigate(
        `/admin/loyalty-programs/${uuid}/levels/${productData.loyalty_level_uuid}`,
      );
    } else {
      try {
        const levelResponse = await createLoyaltyLevel({
          expiration_period: 0,
          is_active: true,
          is_archived: false,
          loyalty_program_uuid: uuid,
        });

        await createProduct({
          is_active: true,
          is_archived: false,
          loyalty_program_uuid: uuid,
          loyalty_level_uuid: levelResponse.uuid,
          quantity: 0,
          sold_quantity: 0,
          price: 0,
          status: 'draft',
          product_type: 'virtual',
        });

        navigate(
          `/admin/loyalty-programs/${uuid}/levels/${levelResponse.uuid}`,
        );
      } catch (error) {
        console.error('Error creating loyalty level:', error);
        toast({
          title: 'Error',
          description: 'Failed to create new loyalty level',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      px={{ base: 4, md: 8 }}
      maxW="1600px"
      mx="auto"
    >
      {isViewer && (
        <Alert status="info" mb={4}>
          <AlertIcon />
          You are in view-only mode. Contact an administrator to make changes.
        </Alert>
      )}

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} mb={8}>
        {/* Program Information Card */}
        <Card
          variant="outline"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          overflow="hidden"
        >
          <CardHeader bg={headerBg} py={4}>
            <Flex justify="space-between" align="center">
              <HStack>
                <Icon as={MdInfoOutline} color="blue.500" boxSize="20px" />
                <Heading size="md">Program Information</Heading>
              </HStack>

              <HStack spacing={3}>
                <Badge
                  colorScheme={isProductActive ? 'green' : 'red'}
                  variant="solid"
                  fontSize="sm"
                  px={2}
                  py={0.5}
                  borderRadius="full"
                >
                  {isProductActive ? 'Active' : 'Inactive'}
                </Badge>

                {isProductPublished === 1 && (
                  <Badge
                    colorScheme="blue"
                    variant="solid"
                    fontSize="sm"
                    px={2}
                    py={0.5}
                    borderRadius="full"
                  >
                    Published
                  </Badge>
                )}

                <Switch
                  isChecked={isProductActive}
                  onChange={(e) => handleActiveStatusToggle(e.target.checked)}
                  colorScheme="blue"
                  size="md"
                  isDisabled={isProductPublished === 0 || isViewer}
                />
              </HStack>
            </Flex>
          </CardHeader>
          <CardBody p={6}>
            <VStack spacing={4} align="stretch">
              {/* Language Tabs */}
              <Box>
                <Text color={labelColor} fontSize="sm" mb={1}>
                  Program Details
                </Text>
                <Tabs colorScheme="blue" variant="enclosed" size="md">
                  <TabList>
                    {languages.map((lang) => (
                      <Tab key={lang.code}>{lang.name}</Tab>
                    ))}
                  </TabList>
                  <TabPanels>
                    {languages.map((lang) => (
                      <TabPanel key={lang.code} px={0} pt={4}>
                        <VStack spacing={4} align="stretch">
                          <Box>
                            <Text color={labelColor} fontSize="sm" mb={1}>
                              Program Name
                            </Text>
                            {isViewer ? (
                              <Box
                                p={3}
                                borderWidth="1px"
                                borderRadius="md"
                                bg="gray.50"
                              >
                                {translations[lang.code]?.name || (
                                  <Text color="gray.400">
                                    No name available
                                  </Text>
                                )}
                              </Box>
                            ) : (
                              <InputField
                                id={`name-${lang.code}`}
                                placeholder="Enter program name"
                                value={translations[lang.code]?.name || ''}
                                onChange={(e) =>
                                  handleTranslationChange(
                                    lang.code,
                                    'name',
                                    e.target.value,
                                  )
                                }
                                isReadOnly={isViewer}
                              />
                            )}
                          </Box>

                          <Box>
                            <Text color={labelColor} fontSize="sm" mb={1}>
                              Description
                            </Text>
                            {isViewer ? (
                              <Box
                                p={3}
                                borderWidth="1px"
                                borderRadius="md"
                                bg="gray.50"
                                minH="100px"
                                overflowY="auto"
                              >
                                {translations[lang.code]?.description || (
                                  <Text color="gray.400">
                                    No description available
                                  </Text>
                                )}
                              </Box>
                            ) : (
                              <TextField
                                id={`description-${lang.code}`}
                                placeholder="Enter program description"
                                h="150px"
                                value={
                                  translations[lang.code]?.description || ''
                                }
                                onChange={(e) =>
                                  handleTranslationChange(
                                    lang.code,
                                    'description',
                                    e.target.value,
                                  )
                                }
                                isReadOnly={isViewer}
                              />
                            )}
                          </Box>
                        </VStack>
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              </Box>
              <Divider borderColor={borderColor} />

              {/* Contract Settings - Made more compact and less prominent */}
              <Box>
                <Flex
                  align="center"
                  justify="space-between"
                  onClick={(e) => {
                    // Only handle clicks on the Flex itself, not when clicking the Switch
                    if (
                      e.target === e.currentTarget ||
                      !e.target.closest('button')
                    ) {
                      !isViewer && setUseCustomContracts(!useCustomContracts);
                    }
                  }}
                  cursor={isViewer ? 'default' : 'pointer'}
                  px={3}
                  py={2}
                  borderWidth="1px"
                  borderRadius="md"
                  borderColor={borderColor}
                  bg="gray.50"
                  _hover={!isViewer ? { bg: 'gray.100' } : {}}
                >
                  <HStack spacing={3}>
                    <Icon as={MdSettings} color="gray.500" boxSize={4} />
                    <Box>
                      <Text fontSize="sm" fontWeight="medium">
                        Contract Settings
                      </Text>
                      <Text fontSize="xs" color={textColorSecondary}>
                        {useCustomContracts
                          ? 'Using custom contracts'
                          : "Using Perxify's default contracts"}
                      </Text>
                    </Box>
                  </HStack>
                  <Switch
                    isChecked={useCustomContracts}
                    onChange={(e) => {
                      e.stopPropagation();
                      if (!isViewer) {
                        setUseCustomContracts((current) => !current);
                      }
                    }}
                    isDisabled={isViewer}
                    size="sm"
                  />
                </Flex>

                {useCustomContracts && (
                  <VStack spacing={3} align="stretch" mt={3} pl={3}>
                    <Box>
                      <Text color={labelColor} fontSize="xs" mb={1}>
                        Loyalty Contract Address
                      </Text>
                      {isViewer ? (
                        <Text fontSize="sm" fontFamily="mono" isTruncated>
                          {programData.web3_loyalty_contract || 'Not set'}
                        </Text>
                      ) : (
                        <InputField
                          id="web3_loyalty_contract"
                          placeholder="Enter loyalty contract address"
                          value={programData.web3_loyalty_contract}
                          onChange={handleInputChange}
                          isDisabled={!useCustomContracts || isViewer}
                          isReadOnly={isViewer}
                          size="sm"
                        />
                      )}
                    </Box>

                    <Box>
                      <Text color={labelColor} fontSize="xs" mb={1}>
                        Voucher Contract Address
                      </Text>
                      {isViewer ? (
                        <Text fontSize="sm" fontFamily="mono" isTruncated>
                          {programData.web3_voucher_contract || 'Not set'}
                        </Text>
                      ) : (
                        <InputField
                          id="web3_voucher_contract"
                          placeholder="Enter voucher contract address"
                          value={programData.web3_voucher_contract}
                          onChange={handleInputChange}
                          isDisabled={!useCustomContracts || isViewer}
                          isReadOnly={isViewer}
                          size="sm"
                        />
                      )}
                    </Box>
                  </VStack>
                )}
              </Box>

              {/* Action Buttons - Moved back to bottom */}
              {!isViewer && (
                <Flex mt={4} justify="space-between">
                  {isProductPublished === 0 ? (
                    <HStack spacing={3}>
                      <Button
                        leftIcon={<Icon as={MdCardMembership} />}
                        bgColor="#FFD600"
                        onClick={onOpen}
                      >
                        Publish
                      </Button>
                      <Button
                        colorScheme="red"
                        variant="outline"
                        onClick={onDeleteDialogOpen}
                      >
                        Delete
                      </Button>
                    </HStack>
                  ) : (
                    <Button colorScheme="blue" onClick={handleSave}>
                      Save Changes
                    </Button>
                  )}

                  {isViewer && (
                    <Button
                      onClick={() => navigate('/admin/loyalty-programs')}
                      colorScheme="blue"
                      variant="outline"
                    >
                      Back
                    </Button>
                  )}
                </Flex>
              )}
            </VStack>
          </CardBody>
        </Card>

        {/* Loyalty Levels Card */}
        <Card
          variant="outline"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          overflow="hidden"
        >
          <CardHeader bg={headerBg} py={4}>
            <HStack justify="space-between">
              <HStack>
                <Icon as={MdLayers} color="blue.500" boxSize="20px" />
                <Heading size="md">Loyalty Levels</Heading>
              </HStack>
              {!isViewer && (
                <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={() => handleLevelAction()}
                  leftIcon={
                    <Text fontSize="lg" fontWeight="bold">
                      +
                    </Text>
                  }
                >
                  Add Level
                </Button>
              )}
            </HStack>
          </CardHeader>
          <CardBody p={6}>
            <VStack spacing={4} align="stretch">
              {programData.products && programData.products.length > 0 ? (
                programData.products.map((product) => {
                  const featuredImage = product.images?.find(
                    (image) => image.is_featured,
                  );

                  const levelName =
                    product.translations?.find(
                      (t) => t.language === 'en' && t.key === 'name',
                    )?.value || 'Unnamed Level';

                  return (
                    <Box
                      key={product.product_uuid}
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      boxShadow="sm"
                      transition="all 0.2s"
                      _hover={{ boxShadow: 'md', borderColor: 'blue.200' }}
                    >
                      <Flex>
                        {/* Image - vertically centered with left margin */}
                        {featuredImage ? (
                          <Flex
                            align="center"
                            justify="center"
                            borderRightWidth="1px"
                            borderColor={borderColor}
                            width="160px"
                            minHeight="140px"
                            flexShrink={0}
                            bg="gray.50"
                            px={3}
                          >
                            <Image
                              src={featuredImage.image_url}
                              alt={levelName}
                              objectFit="cover"
                              boxSize="120px"
                              borderRadius="md"
                            />
                          </Flex>
                        ) : (
                          <Flex
                            align="center"
                            justify="center"
                            borderRightWidth="1px"
                            borderColor={borderColor}
                            bg="gray.50"
                            width="120px"
                            minHeight="140px"
                            flexShrink={0}
                            pl={3}
                          >
                            <Icon
                              as={MdLayers}
                              color="gray.400"
                              boxSize="40px"
                            />
                          </Flex>
                        )}

                        {/* Content */}
                        <Box flex="1" p={4}>
                          <Flex justify="space-between" align="center" mb={3}>
                            <HStack>
                              <Text fontSize="lg" fontWeight="bold" mr={2}>
                                {levelName}
                              </Text>
                              <Badge
                                colorScheme={
                                  product.is_active ? 'green' : 'red'
                                }
                                variant="solid"
                                fontSize="xs"
                                px={2}
                                py={0.5}
                                borderRadius="full"
                              >
                                {product.is_active ? 'Active' : 'Inactive'}
                              </Badge>
                            </HStack>

                            <Button
                              size="sm"
                              colorScheme="blue"
                              variant="ghost"
                              leftIcon={isViewer ? <ViewIcon /> : <EditIcon />}
                              onClick={() => handleLevelAction(product)}
                              _hover={{ bg: 'blue.50' }}
                            >
                              {isViewer ? 'View' : 'Edit'}
                            </Button>
                          </Flex>

                          <SimpleGrid columns={2} spacing={4}>
                            <HStack spacing={2}>
                              <Icon
                                as={MdTimelapse}
                                color="blue.500"
                                boxSize={4}
                              />
                              <Box>
                                <Text fontSize="xs" color={labelColor} mb={0.5}>
                                  Expiration
                                </Text>
                                <Text fontSize="sm" fontWeight="medium">
                                  {convertFromDays(product.expiration_period) ||
                                    'None'}
                                </Text>
                              </Box>
                            </HStack>

                            <HStack spacing={2}>
                              <Icon
                                as={MdAttachMoney}
                                color="green.500"
                                boxSize={4}
                              />
                              <Box>
                                <Text fontSize="xs" color={labelColor} mb={0.5}>
                                  Price
                                </Text>
                                <Text fontSize="sm" fontWeight="medium">
                                  {product.price || 0}€
                                </Text>
                              </Box>
                            </HStack>

                            <HStack spacing={2}>
                              <Icon
                                as={MdShoppingCart}
                                color="purple.500"
                                boxSize={4}
                              />
                              <Box>
                                <Text fontSize="xs" color={labelColor} mb={0.5}>
                                  Quantity
                                </Text>
                                <Text fontSize="sm" fontWeight="medium">
                                  {product.is_finite_quantity
                                    ? product.quantity === 0
                                      ? 'Sold Out'
                                      : product.quantity
                                    : 'Unlimited'}
                                </Text>
                              </Box>
                            </HStack>

                            <HStack spacing={2}>
                              <Icon
                                as={MdCardMembership}
                                color="orange.500"
                                boxSize={4}
                              />
                              <Box>
                                <Text fontSize="xs" color={labelColor} mb={0.5}>
                                  Sold
                                </Text>
                                <Text fontSize="sm" fontWeight="medium">
                                  {product.sold_quantity || 0}
                                </Text>
                              </Box>
                            </HStack>
                          </SimpleGrid>
                        </Box>
                      </Flex>
                    </Box>
                  );
                })
              ) : (
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  py={10}
                  borderWidth="1px"
                  borderRadius="md"
                  borderStyle="dashed"
                  borderColor={borderColor}
                  bg="gray.50"
                >
                  <Icon as={MdLayers} boxSize="50px" color="gray.300" mb={3} />
                  <Text fontWeight="medium" color="gray.500" mb={2}>
                    No loyalty levels added yet
                  </Text>
                  <Text
                    fontSize="sm"
                    color="gray.400"
                    textAlign="center"
                    maxW="sm"
                    mb={4}
                  >
                    Create your first loyalty level to start building your
                    program
                  </Text>
                  {!isViewer && (
                    <Button
                      colorScheme="blue"
                      leftIcon={<Icon as={MdCardMembership} />}
                      size="md"
                      onClick={() => handleLevelAction()}
                    >
                      Add Your First Level
                    </Button>
                  )}
                </Flex>
              )}
            </VStack>
          </CardBody>
        </Card>
      </SimpleGrid>

      {/* Alert Dialogs */}
      {!isViewer && (
        <>
          <LoyaltyDeleteAlert
            {...{
              isDeleteDialogOpen,
              onDeleteDialogClose,
              handleDeleteConfirm,
              cancelRef,
            }}
          />

          <LoyaltyPublishAlert
            {...{ isOpen, onClose, handleConfirmPublish, cancelRef }}
          />
        </>
      )}
    </Box>
  );
}
