// UserDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Text,
  Box,
  Image,
  Flex,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  Heading,
  SimpleGrid,
  HStack,
  VStack,
  Button,
  Icon,
  Container,
  Badge,
  useColorModeValue,
} from '@chakra-ui/react';
import { getUserDetails } from 'api/user';
import { format, addDays, isAfter } from 'date-fns';
import {
  MdShoppingCart,
  MdCardMembership,
  MdComment,
  MdCalendarToday,
  MdVerified,
  MdReceiptLong,
  MdCardGiftcard,
} from 'react-icons/md';

// Import custom components
import Banner from '../../components/users/Banner';

// Assets
import banner from 'assets/img/auth/banner.png';
import NftProfile from 'assets/img/nfts/NftProfile.png';

export default function UserDetail() {
  const { userUuid } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAllLoyalty, setShowAllLoyalty] = useState(false);
  const [showAllOther, setShowAllOther] = useState(false);
  const [showAllRewards, setShowAllRewards] = useState(false);

  const cardBg = useColorModeValue('white', 'navy.700');
  const headerBg = useColorModeValue('blue.50', 'navy.800');
  const borderColor = useColorModeValue('gray.200', 'navy.600');
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = useColorModeValue('gray.600', 'gray.400');

  const itemsToShow = 3;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await getUserDetails(userUuid);
        setUserData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userUuid]);

  if (loading) {
    return (
      <Flex
        justify="center"
        align="center"
        h="100vh"
        pt={{ base: '130px', md: '80px', xl: '80px' }}
      >
        <Spinner size="xl" thickness="4px" color="blue.500" />
      </Flex>
    );
  }

  if (!userData) {
    return (
      <Container centerContent pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Card p={8} textAlign="center" w="100%">
          <Icon
            as={MdComment}
            boxSize="50px"
            color="gray.300"
            mx="auto"
            mb={4}
          />
          <Heading size="md" mb={3}>
            No User Data Found
          </Heading>
          <Text color="gray.500">The user details could not be found.</Text>
          <Button
            mt={6}
            colorScheme="blue"
            onClick={() => navigate('/admin/users')}
          >
            Back to Users
          </Button>
        </Card>
      </Container>
    );
  }

  const sortByDate = (a, b) => {
    return new Date(b.purchase_date) - new Date(a.purchase_date);
  };

  const checkExpiration = (purchase) => {
    if (!purchase.expiration_period) return false;
    const expirationDate = addDays(
      new Date(purchase.purchase_date),
      purchase.expiration_period,
    );
    return isAfter(new Date(), expirationDate);
  };

  const sortByDateAndExpiration = (a, b) => {
    const aExpired = checkExpiration(a);
    const bExpired = checkExpiration(b);

    if (aExpired && !bExpired) return 1;
    if (!aExpired && bExpired) return -1;

    return new Date(b.purchase_date) - new Date(a.purchase_date);
  };

  const loyaltyPrograms = userData.loyalty
    .filter((purchase) => purchase.loyalty_program_uuid)
    .sort(sortByDateAndExpiration);

  const regularPrograms = userData.purchases
    .filter((purchase) => !purchase.loyalty_program_uuid)
    .sort(sortByDate);

  const rewards = userData.rewards.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  );

  const displayedRewards = showAllRewards
    ? rewards
    : rewards.slice(0, itemsToShow);

  const displayedLoyaltyPrograms = showAllLoyalty
    ? loyaltyPrograms
    : loyaltyPrograms.slice(0, itemsToShow);

  const displayedRegularPrograms = showAllOther
    ? regularPrograms
    : regularPrograms.slice(0, itemsToShow);

  const productSpent =
    userData.purchases.reduce(
      (total, purchase) => total + purchase.purchase_price,
      0,
    ) || 0;

  const loyaltySpent =
    userData.loyalty.reduce(
      (total, purchase) => total + purchase.purchase_price,
      0,
    ) || 0;

  const wasClaimedWithVoucher = (purchase) => {
    try {
      if (!purchase.purchase_details) return false;
      const details = JSON.parse(purchase.purchase_details);
      return details.claimedWithLoyalty === true;
    } catch (error) {
      return false;
    }
  };

  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      px={{ base: 4, md: 8 }}
      maxW="1600px"
      mx="auto"
    >
      {/* User Information */}
      <Card
        variant="outline"
        borderWidth="1px"
        boxShadow="md"
        overflow="hidden"
        mb={8}
      >
        <CardHeader bg={headerBg} py={4}>
          <Heading size="md">User Profile</Heading>
        </CardHeader>
        <CardBody p={0}>
          <Banner
            banner={banner}
            avatar={userData.avatar_url}
            name={userData.full_name}
            email={userData.email}
            lastlogin={userData.last_login}
            purchases={userData.purchases.length + userData.loyalty.length}
            moneySpent={
              Math.round((productSpent + Number.EPSILON) * 100) / 100 +
              Math.round((loyaltySpent + Number.EPSILON) * 100) / 100
            }
            claimable={rewards.filter((reward) => !reward.is_claimed).length}
            claimed={rewards.filter((reward) => reward.is_claimed).length}
          />
        </CardBody>
      </Card>

      {/* Main Content */}
      <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={8} mb={8}>
        {/* Loyalty Purchases */}
        <Card
          variant="outline"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          overflow="hidden"
          height="fit-content"
        >
          <CardHeader bg={headerBg} py={4}>
            <HStack>
              <Icon as={MdCardMembership} color="blue.500" boxSize="20px" />
              <Heading size="md">Loyalty Purchases</Heading>
            </HStack>
          </CardHeader>
          <CardBody px={4} py={4}>
            {displayedLoyaltyPrograms.length ? (
              <VStack spacing={4} align="stretch">
                {displayedLoyaltyPrograms.map((purchase) => (
                  <Box
                    key={purchase.uuid}
                    onClick={() =>
                      navigate(`/admin/sold-memberships/${purchase.uuid}`)
                    }
                    cursor="pointer"
                    p={4}
                    bg={cardBg}
                    boxShadow="sm"
                    borderRadius="xl"
                    borderWidth="1px"
                    borderColor={borderColor}
                    _hover={{
                      boxShadow: 'md',
                      transform: 'translateY(-2px)',
                      transition: 'all 0.2s ease-in-out',
                    }}
                  >
                    <HStack spacing={4} align="center">
                      <Image
                        boxSize="50px"
                        borderRadius="md"
                        objectFit="cover"
                        src={purchase.images?.[0]?.image_url || NftProfile}
                        alt={purchase.product_name}
                      />
                      <Box flex="1">
                        <Flex justify="space-between" align="center" mb={1}>
                          <Text fontWeight="bold" color={textColor}>
                            {purchase.product_name}
                          </Text>
                          {checkExpiration(purchase) && (
                            <Badge colorScheme="red" borderRadius="full" px={2}>
                              Expired
                            </Badge>
                          )}
                        </Flex>
                        <HStack spacing={4} mb={1}>
                          <HStack>
                            <Icon as={MdCalendarToday} color="blue.500" />
                            <Text fontSize="sm" color={textColorSecondary}>
                              {format(
                                new Date(purchase.purchase_date),
                                'dd.MM.yyyy',
                              )}
                            </Text>
                          </HStack>
                          <HStack>
                            <Icon as={MdReceiptLong} color="green.500" />
                            <Text fontSize="sm" color={textColorSecondary}>
                              {purchase.purchase_price}€
                            </Text>
                          </HStack>
                        </HStack>
                      </Box>
                    </HStack>
                  </Box>
                ))}
              </VStack>
            ) : (
              <Flex justify="center" align="center" py={8} direction="column">
                <Icon as={MdComment} boxSize="30px" color="gray.300" mb={2} />
                <Text color="gray.500">No loyalty purchases yet</Text>
              </Flex>
            )}
            {loyaltyPrograms.length > itemsToShow && (
              <Button
                variant="ghost"
                colorScheme="blue"
                size="sm"
                w="full"
                mt={4}
                onClick={() => setShowAllLoyalty(!showAllLoyalty)}
              >
                {showAllLoyalty
                  ? 'Show Less'
                  : `Show More (${loyaltyPrograms.length - itemsToShow} more)`}
              </Button>
            )}
          </CardBody>
        </Card>

        {/* Regular Purchases */}
        <Card
          variant="outline"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          overflow="hidden"
          height="fit-content"
        >
          <CardHeader bg={headerBg} py={4}>
            <HStack>
              <Icon as={MdShoppingCart} color="purple.500" boxSize="20px" />
              <Heading size="md">Regular Purchases</Heading>
            </HStack>
          </CardHeader>
          <CardBody px={4} py={4}>
            {displayedRegularPrograms.length ? (
              <VStack spacing={4} align="stretch">
                {displayedRegularPrograms.map((purchase) => (
                  <Box
                    key={purchase.uuid}
                    onClick={() => navigate(`/admin/orders/${purchase.uuid}`)}
                    cursor="pointer"
                    p={4}
                    bg={cardBg}
                    boxShadow="sm"
                    borderRadius="xl"
                    borderWidth="1px"
                    borderColor={borderColor}
                    _hover={{
                      boxShadow: 'md',
                      transform: 'translateY(-2px)',
                      transition: 'all 0.2s ease-in-out',
                    }}
                  >
                    <HStack spacing={4} align="center">
                      <Image
                        boxSize="50px"
                        borderRadius="md"
                        objectFit="cover"
                        src={purchase.images?.[0]?.image_url || NftProfile}
                        alt={purchase.product_name}
                      />
                      <Box flex="1">
                        <Flex justify="space-between" align="center" mb={1}>
                          <Text fontWeight="bold" color={textColor}>
                            {purchase.product_name}
                          </Text>
                          {wasClaimedWithVoucher(purchase) && (
                            <Badge
                              colorScheme="green"
                              borderRadius="full"
                              px={2}
                            >
                              Voucher
                            </Badge>
                          )}
                        </Flex>
                        <HStack spacing={4} mb={1}>
                          <HStack>
                            <Icon as={MdCalendarToday} color="blue.500" />
                            <Text fontSize="sm" color={textColorSecondary}>
                              {format(
                                new Date(purchase.purchase_date),
                                'dd.MM.yyyy',
                              )}
                            </Text>
                          </HStack>
                          <HStack>
                            <Icon as={MdReceiptLong} color="green.500" />
                            <Text fontSize="sm" color={textColorSecondary}>
                              {purchase.purchase_price}€
                            </Text>
                          </HStack>
                        </HStack>
                      </Box>
                    </HStack>
                  </Box>
                ))}
              </VStack>
            ) : (
              <Flex justify="center" align="center" py={8} direction="column">
                <Icon as={MdComment} boxSize="30px" color="gray.300" mb={2} />
                <Text color="gray.500">No regular purchases yet</Text>
              </Flex>
            )}
            {regularPrograms.length > itemsToShow && (
              <Button
                variant="ghost"
                colorScheme="blue"
                size="sm"
                w="full"
                mt={4}
                onClick={() => setShowAllOther(!showAllOther)}
              >
                {showAllOther
                  ? 'Show Less'
                  : `Show More (${regularPrograms.length - itemsToShow} more)`}
              </Button>
            )}
          </CardBody>
        </Card>

        {/* Rewards */}
        <Card
          variant="outline"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          overflow="hidden"
          height="fit-content"
        >
          <CardHeader bg={headerBg} py={4}>
            <HStack>
              <Icon as={MdCardGiftcard} color="orange.500" boxSize="20px" />
              <Heading size="md">Rewards</Heading>
            </HStack>
          </CardHeader>
          <CardBody px={4} py={4}>
            {displayedRewards.length ? (
              <VStack spacing={4} align="stretch">
                {displayedRewards.map((reward) => (
                  <Box
                    key={reward.uuid}
                    onClick={() => navigate(`/admin/vouchers/${reward.uuid}`)}
                    cursor="pointer"
                    p={4}
                    bg={cardBg}
                    boxShadow="sm"
                    borderRadius="xl"
                    borderWidth="1px"
                    borderColor={borderColor}
                    _hover={{
                      boxShadow: 'md',
                      transform: 'translateY(-2px)',
                      transition: 'all 0.2s ease-in-out',
                    }}
                  >
                    <HStack spacing={4} align="flex-start">
                      <Image
                        boxSize="50px"
                        borderRadius="md"
                        objectFit="cover"
                        src={reward.images?.[0]?.image_url || NftProfile}
                        alt={reward.product_name}
                      />
                      <Box flex="1">
                        <HStack
                          justify="space-between"
                          align="flex-start"
                          mb={1}
                        >
                          <Text fontWeight="bold" color={textColor}>
                            {reward.product_name}
                          </Text>
                          <HStack spacing={1}>
                            {reward.quantity > 1 && (
                              <Badge
                                colorScheme="blue"
                                borderRadius="full"
                                px={2}
                              >
                                x{reward.quantity}
                              </Badge>
                            )}
                            {reward.expiry_date &&
                              isAfter(
                                new Date(),
                                new Date(reward.expiry_date),
                              ) && (
                                <Badge
                                  colorScheme="red"
                                  borderRadius="full"
                                  px={2}
                                >
                                  Expired
                                </Badge>
                              )}
                            <Badge
                              colorScheme={
                                reward.is_claimed ? 'green' : 'orange'
                              }
                              borderRadius="full"
                              px={2}
                            >
                              {reward.is_claimed ? 'Claimed' : 'Unclaimed'}
                            </Badge>
                          </HStack>
                        </HStack>
                        <Text fontSize="sm" color={textColorSecondary} mb={1}>
                          <Icon as={MdVerified} color="blue.500" mr={1} />
                          {reward.program_name}
                        </Text>
                        <HStack spacing={4}>
                          <HStack>
                            <Icon as={MdCalendarToday} color="blue.500" />
                            <Text fontSize="sm" color={textColorSecondary}>
                              Received:{' '}
                              {format(
                                new Date(reward.created_at),
                                'dd.MM.yyyy',
                              )}
                            </Text>
                          </HStack>
                          {reward.is_claimed && reward.updated_at && (
                            <HStack>
                              <Icon as={MdVerified} color="green.500" />
                              <Text fontSize="sm" color={textColorSecondary}>
                                Claimed:{' '}
                                {format(
                                  new Date(reward.updated_at),
                                  'dd.MM.yyyy',
                                )}
                              </Text>
                            </HStack>
                          )}
                        </HStack>
                      </Box>
                    </HStack>
                  </Box>
                ))}
              </VStack>
            ) : (
              <Flex justify="center" align="center" py={8} direction="column">
                <Icon as={MdComment} boxSize="30px" color="gray.300" mb={2} />
                <Text color="gray.500">No rewards yet</Text>
              </Flex>
            )}
            {rewards.length > itemsToShow && (
              <Button
                variant="ghost"
                colorScheme="blue"
                size="sm"
                w="full"
                mt={4}
                onClick={() => setShowAllRewards(!showAllRewards)}
              >
                {showAllRewards
                  ? 'Show Less'
                  : `Show More (${rewards.length - itemsToShow} more)`}
              </Button>
            )}
          </CardBody>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
