// components/Pagination.js
import React from 'react';
import { Flex, Button } from '@chakra-ui/react';

const Pagination = ({ currentPage, pageCount, onChange }) => {
  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < pageCount) {
      onChange(newPage);
    }
  };

  // Calculate the range of pages to show
  const getPageRange = () => {
    const range = [];
    const showPages = 5; // Number of pages to show
    let start = Math.max(0, currentPage - 2);
    let end = Math.min(pageCount, start + showPages);

    if (end - start < showPages) {
      start = Math.max(0, end - showPages);
    }

    for (let i = start; i < end; i++) {
      range.push(i);
    }

    return range;
  };

  return (
    <Flex gap={2} align="center">
      <Button
        size="sm"
        onClick={() => handlePageChange(0)}
        isDisabled={currentPage === 0}
        variant="outline"
      >
        ⇤
      </Button>
      <Button
        size="sm"
        onClick={() => handlePageChange(currentPage - 1)}
        isDisabled={currentPage === 0}
        variant="outline"
      >
        ←
      </Button>

      {getPageRange().map((page) => (
        <Button
          key={page}
          size="sm"
          onClick={() => handlePageChange(page)}
          variant={currentPage === page ? 'solid' : 'outline'}
          colorScheme={currentPage === page ? 'blue' : 'gray'}
        >
          {page + 1}
        </Button>
      ))}

      <Button
        size="sm"
        onClick={() => handlePageChange(currentPage + 1)}
        isDisabled={currentPage === pageCount - 1}
        variant="outline"
      >
        →
      </Button>
      <Button
        size="sm"
        onClick={() => handlePageChange(pageCount - 1)}
        isDisabled={currentPage === pageCount - 1}
        variant="outline"
      >
        ⇥
      </Button>
    </Flex>
  );
};

export default Pagination;
