import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  Select,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  Card,
  Text,
  useColorModeValue,
  HStack,
  Icon,
  Badge,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, AddIcon } from '@chakra-ui/icons';
import {
  getAttributes,
  createAttribute,
  updateAttribute,
  deleteAttribute,
  getAttributeValues,
  createAttributeValue,
  updateAttributeValue,
  deleteAttributeValue,
} from '../../api/attributesVariations';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import Pagination from '../../components/pagination';
import { AuthContext } from '../../contexts/AuthContext';
import { MdVisibility } from 'react-icons/md';

const languages = [
  { code: 'en', name: 'English' },
  { code: 'et', name: 'Estonian' },
];

const AttributesPage = () => {
  const toast = useToast();

  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [formData, setFormData] = useState({
    slug: '',
    type: 'product',
    translations: {},
  });
  const {
    isOpen: isAttrModalOpen,
    onOpen: onAttrModalOpen,
    onClose: onAttrModalClose,
  } = useDisclosure();

  const [valuesModalOpen, setValuesModalOpen] = useState(false);
  const [selectedAttributeForValues, setSelectedAttributeForValues] =
    useState(null);
  const [attributeValues, setAttributeValues] = useState([]);
  const [loadingValues, setLoadingValues] = useState(false);
  const [valueFormData, setValueFormData] = useState({
    slug: '',
    translations: {},
  });
  const [editingValue, setEditingValue] = useState(null);

  const {
    isOpen: isAttrDeleteConfirmOpen,
    onOpen: onAttrDeleteConfirmOpen,
    onClose: onAttrDeleteConfirmClose,
  } = useDisclosure();
  const [attributeToDelete, setAttributeToDelete] = useState(null);
  const attrCancelRef = useRef();

  const {
    isOpen: isValueDeleteConfirmOpen,
    onOpen: onValueDeleteConfirmOpen,
    onClose: onValueDeleteConfirmClose,
  } = useDisclosure();
  const [valueToDelete, setValueToDelete] = useState(null);
  const valueCancelRef = useRef();

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const { userRole } = useContext(AuthContext);
  const isViewer = userRole === 'VIEWER';

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const fetchAttributes = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getAttributes();
      setAttributes(data);
    } catch (error) {
      toast({
        title: 'Error fetching attributes',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  const fetchAttributeValues = async (attributeUuid) => {
    setLoadingValues(true);
    try {
      const data = await getAttributeValues({ attribute_uuid: attributeUuid });
      setAttributeValues(data);
    } catch (error) {
      toast({
        title: 'Error fetching attribute values',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoadingValues(false);
    }
  };

  useEffect(() => {
    fetchAttributes();
  }, [fetchAttributes]);

  const handleOpenAttrModal = (attribute = null) => {
    setSelectedAttribute(attribute);
    if (attribute) {
      const translationsObj = {};
      languages.forEach((lang) => {
        translationsObj[lang.code] =
          attribute.translations?.find((tr) => tr.language === lang.code)
            ?.value || '';
      });
      setFormData({
        slug: attribute.slug,
        type: attribute.type,
        translations: translationsObj,
      });
    } else {
      const translationsObj = {};
      languages.forEach((lang) => {
        translationsObj[lang.code] = '';
      });
      setFormData({ slug: '', type: 'product', translations: translationsObj });
    }
    onAttrModalOpen();
  };

  const handleDeleteAttributeConfirm = (attribute) => {
    setAttributeToDelete(attribute);
    onAttrDeleteConfirmOpen();
  };

  const confirmDeleteAttribute = async () => {
    try {
      await deleteAttribute(attributeToDelete.uuid);
      toast({
        title: 'Attribute deleted',
        status: 'success',
        isClosable: true,
      });
      fetchAttributes();
    } catch (error) {
      toast({
        title: 'Error deleting attribute',
        status: 'error',
        isClosable: true,
      });
    } finally {
      onAttrDeleteConfirmClose();
      setAttributeToDelete(null);
    }
  };

  const handleAttrSubmit = async () => {
    const translationsArray = Object.keys(formData.translations).map(
      (langCode) => ({
        language: langCode,
        key: 'name',
        value: formData.translations[langCode],
      }),
    );

    const payload = {
      slug: formData.slug,
      type: formData.type,
      translations: translationsArray,
    };

    try {
      if (selectedAttribute) {
        await updateAttribute(selectedAttribute.uuid, payload);
        toast({
          title: 'Attribute updated',
          status: 'success',
          isClosable: true,
        });
      } else {
        await createAttribute(payload);
        toast({
          title: 'Attribute created',
          status: 'success',
          isClosable: true,
        });
      }
      fetchAttributes();
      onAttrModalClose();
      setSelectedAttribute(null);
      setFormData({ slug: '', type: 'product', translations: {} });
    } catch (error) {
      toast({
        title: 'Error saving attribute',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const openValuesModal = (attribute) => {
    setSelectedAttributeForValues(attribute);
    const translationsObj = {};
    languages.forEach((lang) => {
      translationsObj[lang.code] = '';
    });
    setValueFormData({ slug: '', translations: translationsObj });
    setEditingValue(null);
    setValuesModalOpen(true);
    fetchAttributeValues(attribute.uuid);
  };

  const closeValuesModal = () => {
    setValuesModalOpen(false);
    setSelectedAttributeForValues(null);
    setAttributeValues([]);
  };

  const handleValueSubmit = async () => {
    const translationsArray = Object.keys(valueFormData.translations).map(
      (langCode) => ({
        language: langCode,
        key: 'name',
        value: valueFormData.translations[langCode],
      }),
    );

    const payload = {
      attribute_id: selectedAttributeForValues.id,
      slug: valueFormData.slug,
      translations: translationsArray,
    };

    try {
      if (editingValue) {
        await updateAttributeValue(editingValue.uuid, payload);
        toast({
          title: 'Attribute value updated',
          status: 'success',
          isClosable: true,
        });
      } else {
        await createAttributeValue(payload);
        toast({
          title: 'Attribute value created',
          status: 'success',
          isClosable: true,
        });
      }
      fetchAttributeValues(selectedAttributeForValues.uuid);
      const translationsObj = {};
      languages.forEach((lang) => {
        translationsObj[lang.code] = '';
      });
      setValueFormData({ slug: '', translations: translationsObj });
      setEditingValue(null);
    } catch (error) {
      toast({
        title: 'Error saving attribute value',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handleEditValue = (value) => {
    setEditingValue(value);
    const translationsObj = {};
    languages.forEach((lang) => {
      translationsObj[lang.code] =
        value.translations?.find((tr) => tr.language === lang.code)?.value ||
        '';
    });
    setValueFormData({ slug: value.slug, translations: translationsObj });
  };

  const handleDeleteValueConfirm = (value) => {
    setValueToDelete(value);
    onValueDeleteConfirmOpen();
  };

  const confirmDeleteValue = async () => {
    try {
      await deleteAttributeValue(valueToDelete.uuid);
      toast({
        title: 'Attribute value deleted',
        status: 'success',
        isClosable: true,
      });
      fetchAttributeValues(selectedAttributeForValues.uuid);
    } catch (error) {
      toast({
        title: 'Error deleting attribute value',
        status: 'error',
        isClosable: true,
      });
    } finally {
      onValueDeleteConfirmClose();
      setValueToDelete(null);
    }
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('id', {
      header: () => (
        <Text fontSize="12px" color="gray.400" textAlign="center" width="100%">
          ID
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight="500"
          textAlign="center"
        >
          {info.getValue()}
        </Text>
      ),
      size: 50,
    }),
    columnHelper.accessor(
      (row) => {
        const englishName =
          row.translations?.find((tr) => tr.language === 'en')?.value || '';
        return englishName;
      },
      {
        id: 'name',
        header: () => (
          <Text
            fontSize="12px"
            color="gray.400"
            textAlign="center"
            width="100%"
          >
            NAME
          </Text>
        ),
        cell: (info) => (
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="500"
            textAlign="center"
          >
            {info.getValue()}
          </Text>
        ),
        size: 200,
      },
    ),
    columnHelper.accessor('slug', {
      header: () => (
        <Text fontSize="12px" color="gray.400" textAlign="center" width="100%">
          SLUG
        </Text>
      ),
      cell: (info) => (
        <Flex justify="center">
          <Badge colorScheme="blue">{info.getValue()}</Badge>
        </Flex>
      ),
      size: 150,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text fontSize="12px" color="gray.400" textAlign="center">
          ACTIONS
        </Text>
      ),
      cell: (info) => (
        <Flex justify="center">
          <HStack spacing="2">
            <Icon
              w="16px"
              h="16px"
              as={isViewer ? MdVisibility : EditIcon}
              cursor="pointer"
              color={brandColor}
              onClick={() =>
                isViewer ? null : handleOpenAttrModal(info.row.original)
              }
            />
            {!isViewer && (
              <Icon
                w="16px"
                h="16px"
                as={DeleteIcon}
                cursor="pointer"
                color="red.500"
                onClick={() => handleDeleteAttributeConfirm(info.row.original)}
              />
            )}
            <Button
              size="xs"
              colorScheme="purple"
              onClick={() => openValuesModal(info.row.original)}
            >
              Values
            </Button>
          </HStack>
        </Flex>
      ),
      size: 150,
    }),
  ];

  const table = useReactTable({
    data: attributes,
    columns,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Flex direction="column" mt={{ sm: '150px', md: '125px', lg: '75px' }}>
      <Card
        w="100%"
        px={{ base: '16px', md: '24px' }}
        overflowX="auto"
        minHeight={{ sm: 'auto', lg: 'calc(100vh - 200px)' }}
      >
        {/* Add viewer alert */}
        {isViewer && (
          <Alert status="info" mb={4} mt={4}>
            <AlertIcon />
            You are in view-only mode. Contact an administrator to make changes.
          </Alert>
        )}
        {/* Header and Controls */}
        <Flex
          align={{ sm: 'flex-start', lg: 'center' }}
          justify={{ sm: 'flex-start', lg: 'space-between' }}
          direction={{ base: 'column', md: 'row' }}
          mb="36px"
          mt="24px"
        >
          <Heading size="lg" mb={{ base: 4, md: 0 }}>
            Attributes
          </Heading>
          {!isViewer && (
            <Button
              leftIcon={<AddIcon />}
              colorScheme="blue"
              onClick={() => handleOpenAttrModal()}
            >
              Add Attribute
            </Button>
          )}
        </Flex>

        {/* Main Content */}
        {loading ? (
          <Flex justify="center" align="center" h="200px">
            <Spinner size="lg" />
          </Flex>
        ) : (
          <>
            <Table variant="simple" color="gray.500" mb="24px">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id} bg="transparent">
                    {headerGroup.headers.map((header) => {
                      const canSort = header.column.getCanSort();
                      return (
                        <Th
                          key={header.id}
                          borderColor={borderColor}
                          cursor={canSort ? 'pointer' : 'default'}
                          onClick={header.column.getToggleSortingHandler()}
                          width={`${header.column.columnDef.size}px`}
                        >
                          <Flex justify="center" align="center">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </Flex>
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        borderColor={borderColor}
                        padding="12px"
                        textAlign="center"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>

            {/* Pagination */}
            {attributes.length > 10 && (
              <Flex
                w="100%"
                justify="space-between"
                px="20px"
                pt="10px"
                pb="5px"
                alignItems="center"
              >
                <Text fontSize="sm" color="gray.500" fontWeight="normal">
                  Showing{' '}
                  {attributes.length === 0
                    ? 0
                    : pagination.pageIndex * pagination.pageSize + 1}{' '}
                  to{' '}
                  {Math.min(
                    (pagination.pageIndex + 1) * pagination.pageSize,
                    attributes.length,
                  )}{' '}
                  of {attributes.length} entries
                </Text>

                <Pagination
                  currentPage={pagination.pageIndex}
                  pageCount={table.getPageCount()}
                  onChange={(newPage) =>
                    setPagination((prev) => ({
                      ...prev,
                      pageIndex: newPage,
                    }))
                  }
                  textColor={textColor}
                  borderColor={borderColor}
                  brandColor={brandColor}
                />
              </Flex>
            )}
          </>
        )}
      </Card>

      {/* Modal for Creating/Editing an Attribute */}
      <Modal
        isOpen={isAttrModalOpen}
        onClose={() => {
          onAttrModalClose();
          setSelectedAttribute(null);
          setFormData({ slug: '', type: 'product', translations: {} });
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedAttribute ? 'Edit Attribute' : 'Add Attribute'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="4">
              <FormLabel>Slug</FormLabel>
              <Input
                placeholder="Enter slug"
                value={formData.slug}
                onChange={(e) =>
                  setFormData({ ...formData, slug: e.target.value })
                }
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Type</FormLabel>
              <Select
                value={formData.type}
                onChange={(e) =>
                  setFormData({ ...formData, type: e.target.value })
                }
              >
                <option value="product">Product</option>
              </Select>
            </FormControl>
            <Tabs variant="enclosed" colorScheme="blue" mb="20px">
              <TabList>
                {languages.map((lang) => (
                  <Tab key={lang.code}>{lang.name}</Tab>
                ))}
              </TabList>
              <TabPanels>
                {languages.map((lang) => (
                  <TabPanel key={lang.code}>
                    <FormControl>
                      <FormLabel>Name ({lang.name})</FormLabel>
                      <Input
                        placeholder={`Enter attribute name in ${lang.name}`}
                        value={formData.translations[lang.code] || ''}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            translations: {
                              ...formData.translations,
                              [lang.code]: e.target.value,
                            },
                          })
                        }
                      />
                    </FormControl>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onAttrModalClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleAttrSubmit}>
              {selectedAttribute ? 'Update' : 'Create'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Managing Attribute Values */}
      <Modal isOpen={valuesModalOpen} onClose={closeValuesModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Manage Values for Attribute:{' '}
            {selectedAttributeForValues &&
              (selectedAttributeForValues.translations.find(
                (tr) => tr.language === 'en',
              )?.value ||
                selectedAttributeForValues.slug)}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Button to clear the value form for adding a new value */}
            {!isViewer && (
              <Button
                colorScheme="blue"
                mb="4"
                onClick={() => {
                  const translationsObj = {};
                  languages.forEach((lang) => {
                    translationsObj[lang.code] = '';
                  });
                  setValueFormData({ slug: '', translations: translationsObj });
                  setEditingValue(null);
                }}
              >
                Add Value
              </Button>
            )}
            {loadingValues ? (
              <Spinner />
            ) : (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>ID</Th>
                    <Th>Name</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {attributeValues.map((val) => {
                    const englishName =
                      val.translations?.find((tr) => tr.language === 'en')
                        ?.value || '';
                    return (
                      <Tr key={val.id}>
                        <Td>{val.id}</Td>
                        <Td>{englishName}</Td>
                        <Td>
                          <Button
                            size="sm"
                            colorScheme="teal"
                            mr={2}
                            onClick={() => handleEditValue(val)}
                          >
                            <Icon as={isViewer ? MdVisibility : EditIcon} />
                          </Button>
                          {!isViewer && (
                            <Button
                              size="sm"
                              colorScheme="red"
                              onClick={() => handleDeleteValueConfirm(val)}
                            >
                              <DeleteIcon />
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}
            {/* Form for Adding/Editing an Attribute Value */}
            {!isViewer && (
              <Box mt="6">
                <Heading size="md" mb="2">
                  {editingValue ? 'Edit Value' : 'Add New Value'}
                </Heading>
                <FormControl mb="4">
                  <FormLabel>Slug</FormLabel>
                  <Input
                    placeholder="Enter value slug"
                    value={valueFormData.slug}
                    onChange={(e) =>
                      setValueFormData({
                        ...valueFormData,
                        slug: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <Tabs variant="enclosed" colorScheme="blue" mb="20px">
                  <TabList>
                    {languages.map((lang) => (
                      <Tab key={lang.code}>{lang.name}</Tab>
                    ))}
                  </TabList>
                  <TabPanels>
                    {languages.map((lang) => (
                      <TabPanel key={lang.code}>
                        <FormControl>
                          <FormLabel>Name ({lang.name})</FormLabel>
                          <Input
                            placeholder={`Enter value name in ${lang.name}`}
                            value={valueFormData.translations[lang.code] || ''}
                            onChange={(e) =>
                              setValueFormData({
                                ...valueFormData,
                                translations: {
                                  ...valueFormData.translations,
                                  [lang.code]: e.target.value,
                                },
                              })
                            }
                          />
                        </FormControl>
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
                <Button colorScheme="blue" onClick={handleValueSubmit}>
                  {editingValue ? 'Update Value' : 'Create Value'}
                </Button>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={closeValuesModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Confirmation AlertDialog for Attribute Deletion */}
      <AlertDialog
        isOpen={isAttrDeleteConfirmOpen}
        leastDestructiveRef={attrCancelRef}
        onClose={onAttrDeleteConfirmClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Attribute
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this attribute? This action cannot
              be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={attrCancelRef} onClick={onAttrDeleteConfirmClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDeleteAttribute} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Confirmation AlertDialog for Attribute Value Deletion */}
      <AlertDialog
        isOpen={isValueDeleteConfirmOpen}
        leastDestructiveRef={valueCancelRef}
        onClose={onValueDeleteConfirmClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Attribute Value
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this attribute value? This action
              cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={valueCancelRef} onClick={onValueDeleteConfirmClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDeleteValue} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default AttributesPage;
