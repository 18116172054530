// CreditBalanceWidget.js

import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Icon,
  useColorModeValue,
  Spinner,
  Divider,
  Box,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { getCreditBalance } from 'api/dashboard';
import { MdShoppingCart, MdLoyalty, MdAttachMoney } from 'react-icons/md';
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import IconBox from 'components/icons/IconBox';

export default function CreditBalanceWidget(props) {
  const { ...rest } = props;

  // State variables
  const [creditBalance, setCreditBalance] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [creditBalanceProducts, setCreditBalanceProducts] = useState(0);
  const [creditBalanceLoyalty, setCreditBalanceLoyalty] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // Chakra color mode values
  const textColor = useColorModeValue('gray.700', 'white');
  const cardBg = useColorModeValue('white', 'navy.700');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const iconBoxBg = useColorModeValue('secondaryGray.300', 'navy.700');
  const dividerColor = useColorModeValue('gray.200', 'whiteAlpha.300');
  const salesBg = useColorModeValue('green.50', 'whiteAlpha.100');

  // Fetch credit balance data
  useEffect(() => {
    const fetchCreditBalance = async () => {
      setIsLoading(true);
      try {
        const data = await getCreditBalance();
        setCreditBalance(data.credit_balance);
        setTotalSales(data.total_sales);
        setCreditBalanceProducts(data.credit_balance_products);
        setCreditBalanceLoyalty(data.credit_balance_loyalty);
      } catch (error) {
        console.error('Error fetching credit balance:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCreditBalance();
  }, []);

  return (
    <Card bg={cardBg} borderRadius="20px" p="20px" {...rest}>
      {isLoading ? (
        <Flex justify="center" align="center" minH="150px">
          <Spinner />
        </Flex>
      ) : (
        <Flex direction="column">
          <Flex
            align="center"
            p="20px"
            mb="20px"
            borderRadius="16px"
            bgColor={balanceBg}
            bgImage={balanceImg}
            bgPosition="right"
            bgSize="cover"
          >
            <Flex direction="column">
              <Text fontSize="2xl" fontWeight="bold" color="white">
                €{creditBalance?.toFixed(2)}
              </Text>
              <Text fontSize="md" color="white">
                Total Credit Balance
              </Text>
            </Flex>
          </Flex>
          <Flex direction="column" gap="15px">
            {/* Products Row */}
            <Flex alignItems="center" w="100%">
              <IconBox
                h="42px"
                w="42px"
                bg={iconBoxBg}
                me="20px"
                icon={
                  <Icon
                    as={MdShoppingCart}
                    w="24px"
                    h="24px"
                    color="teal.300"
                  />
                }
              />
              <Flex direction="column" align="start" me="auto">
                <Text color={textColor} fontSize="md" fontWeight="700">
                  Products
                </Text>
              </Flex>
              <Text ms="auto" color={textColor} fontSize="sm" fontWeight="700">
                €{creditBalanceProducts?.toFixed(2)}
              </Text>
            </Flex>
            {/* Loyalty Row */}
            <Flex alignItems="center" w="100%">
              <IconBox
                h="42px"
                w="42px"
                bg={iconBoxBg}
                me="20px"
                icon={
                  <Icon as={MdLoyalty} w="24px" h="24px" color="purple.400" />
                }
              />
              <Flex direction="column" align="start" me="auto">
                <Text color={textColor} fontSize="md" fontWeight="700">
                  Loyalty
                </Text>
              </Flex>
              <Text ms="auto" color={textColor} fontSize="sm" fontWeight="700">
                €{creditBalanceLoyalty?.toFixed(2)}
              </Text>
            </Flex>

            {/* Divider to separate credit balance from total sales */}
            <Divider my="3" borderColor={dividerColor} />

            {/* Total Sales Row */}
            <Box p="3" borderRadius="lg" bg={salesBg}>
              <Flex alignItems="center" w="100%">
                <IconBox
                  h="42px"
                  w="42px"
                  bg={iconBoxBg}
                  me="20px"
                  icon={
                    <Icon
                      as={MdAttachMoney}
                      w="24px"
                      h="24px"
                      color="green.500"
                    />
                  }
                />
                <Flex direction="column">
                  <Text color={textColor} fontSize="md" fontWeight="700">
                    Total Sales
                  </Text>
                  <Text fontSize="xs" color="gray.500">
                    All-time revenue
                  </Text>
                </Flex>
                <Text
                  ms="auto"
                  color={textColor}
                  fontSize="md"
                  fontWeight="700"
                >
                  €{totalSales?.toFixed(2)}
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
