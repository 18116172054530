import apiClient from './axios';

/* =========================
   ATTRIBUTES ENDPOINTS
   ========================= */

export const getAttributes = async (filters = {}) => {
  try {
    const response = await apiClient.get('/attributes', {
      params: filters,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching attributes:', error);
    throw error;
  }
};

export const getAttribute = async (attributeId, lang) => {
  try {
    const response = await apiClient.get(`/attributes/${attributeId}`, {
      params: lang ? { lang } : {},
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching attribute with id ${attributeId}:`, error);
    throw error;
  }
};

export const createAttribute = async (data) => {
  try {
    const response = await apiClient.post('/attributes', data);
    return response.data;
  } catch (error) {
    console.error('Error creating attribute:', error);
    throw error;
  }
};

export const updateAttribute = async (attributeId, data) => {
  try {
    const response = await apiClient.put(`/attributes/${attributeId}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error updating attribute with id ${attributeId}:`, error);
    throw error;
  }
};

export const deleteAttribute = async (attributeId) => {
  try {
    const response = await apiClient.delete(`/attributes/${attributeId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting attribute with id ${attributeId}:`, error);
    throw error;
  }
};

/* =========================
   ATTRIBUTE VALUES ENDPOINTS
   ========================= */

export const getAttributeValues = async (filters = {}) => {
  try {
    const response = await apiClient.get('/attribute_values', {
      params: filters,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching attribute values:', error);
    throw error;
  }
};

export const getAttributeValue = async (valueId) => {
  try {
    const response = await apiClient.get(`/attribute_values/${valueId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching attribute value with id ${valueId}:`, error);
    throw error;
  }
};

export const createAttributeValue = async (data) => {
  try {
    const response = await apiClient.post('/attribute_values', data);
    return response.data;
  } catch (error) {
    console.error('Error creating attribute value:', error);
    throw error;
  }
};

export const updateAttributeValue = async (valueId, data) => {
  try {
    const response = await apiClient.put(`/attribute_values/${valueId}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error updating attribute value with id ${valueId}:`, error);
    throw error;
  }
};

export const deleteAttributeValue = async (valueId) => {
  try {
    const response = await apiClient.delete(`/attribute_values/${valueId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting attribute value with id ${valueId}:`, error);
    throw error;
  }
};

/* =========================
   PRODUCT ATTRIBUTES ENDPOINTS
   ========================= */

export const getProductAttributes = async (productUuid) => {
  try {
    const response = await apiClient.get(`/products/${productUuid}/attributes`);
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching attributes for product UUID ${productUuid}:`,
      error,
    );
    throw error;
  }
};

export const addProductAttribute = async (productUuid, attributeId) => {
  try {
    const response = await apiClient.post(
      `/products/${productUuid}/attributes`,
      {
        attribute_id: attributeId,
      },
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error adding attribute ${attributeId} to product UUID ${productUuid}:`,
      error,
    );
    throw error;
  }
};

export const removeProductAttribute = async (productUuid, attributeId) => {
  try {
    // Ensure we're using the UUID string, not the numeric ID
    if (
      !productUuid ||
      typeof productUuid !== 'string' ||
      !productUuid.includes('-')
    ) {
      throw new Error(
        'Invalid product UUID format. UUIDs must be used for API requests.',
      );
    }

    const response = await apiClient.delete(
      `/products/${productUuid}/attributes/${attributeId}`,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error removing attribute ${attributeId} from product ${productUuid}:`,
      error,
    );
    throw error;
  }
};

/* =========================
   CATEGORY ATTRIBUTES ENDPOINTS
   ========================= */

export const getCategoryAttributes = async (categoryId) => {
  try {
    const response = await apiClient.get(
      `/categories/${categoryId}/attributes`,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching attributes for category id ${categoryId}:`,
      error,
    );
    throw error;
  }
};

export const addCategoryAttribute = async (categoryId, attributeId) => {
  try {
    const response = await apiClient.post(
      `/categories/${categoryId}/attributes`,
      {
        attribute_id: attributeId,
      },
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error adding attribute ${attributeId} to category ${categoryId}:`,
      error,
    );
    throw error;
  }
};

export const removeCategoryAttribute = async (categoryId, attributeId) => {
  try {
    const response = await apiClient.delete(
      `/categories/${categoryId}/attributes/${attributeId}`,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error removing attribute ${attributeId} from category ${categoryId}:`,
      error,
    );
    throw error;
  }
};

/* =========================
   PRODUCT VARIATIONS ENDPOINTS
   ========================= */

// Get all product variations (optionally filtered by product_id and is_active)
export const getProductVariations = async (filters = {}) => {
  try {
    const response = await apiClient.get('/product-variations', {
      params: filters,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching product variations:', error);
    throw error;
  }
};

// Get a single product variation by ID
export const getProductVariation = async (variationId) => {
  try {
    const response = await apiClient.get(`/product-variations/${variationId}`);
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching product variation with id ${variationId}:`,
      error,
    );
    throw error;
  }
};

// Create a new product variation
export const createProductVariation = async (data) => {
  try {
    const response = await apiClient.post('/product-variations', data);
    return response.data;
  } catch (error) {
    console.error('Error creating product variation:', error);
    throw error;
  }
};

// Update an existing product variation by ID
export const updateProductVariation = async (variationId, data) => {
  try {
    const response = await apiClient.put(
      `/product-variations/${variationId}`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error updating product variation with id ${variationId}:`,
      error,
    );
    throw error;
  }
};

// Delete a product variation by ID
export const deleteProductVariation = async (variationId) => {
  try {
    const response = await apiClient.delete(
      `/product-variations/${variationId}`,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error deleting product variation with id ${variationId}:`,
      error,
    );
    throw error;
  }
};

/* =========================
   CATEGORY VARIATIONS ENDPOINTS
   ========================= */

// Get all category variations (optionally filtered by category_id and is_active)
export const getCategoryVariations = async (filters = {}) => {
  try {
    const response = await apiClient.get('/category-variations', {
      params: filters,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching category variations:', error);
    throw error;
  }
};

// Get a single category variation by UUID
export const getCategoryVariation = async (variationUuid) => {
  try {
    const response = await apiClient.get(
      `/category-variations/${variationUuid}`,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching category variation with uuid ${variationUuid}:`,
      error,
    );
    throw error;
  }
};

// Create a new category variation
export const createCategoryVariation = async (data) => {
  try {
    const response = await apiClient.post('/category-variations', data);
    return response.data;
  } catch (error) {
    console.error('Error creating category variation:', error);
    throw error;
  }
};

// Update an existing category variation by UUID
export const updateCategoryVariation = async (variationUuid, data) => {
  try {
    const response = await apiClient.put(
      `/category-variations/${variationUuid}`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error updating category variation with uuid ${variationUuid}:`,
      error,
    );
    throw error;
  }
};

// Delete a category variation by UUID
export const deleteCategoryVariation = async (variationUuid) => {
  try {
    const response = await apiClient.delete(
      `/category-variations/${variationUuid}`,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error deleting category variation with uuid ${variationUuid}:`,
      error,
    );
    throw error;
  }
};
