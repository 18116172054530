import React from 'react';
import {
  Box,
  SimpleGrid,
  Checkbox,
  Badge,
  Flex,
  Text,
  VStack,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  Switch,
  IconButton,
  Divider,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import { DeleteIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { getAttributeKey } from '../../../utils/utils';

const VariationsGrid = ({
  filteredVariations,
  availableAttributes,
  selectedRows,
  handleRowSelect,
  isViewer,
  productPrice,
  productQuantity,
  unlimitedQuantity,
  editedValues,
  handleVariationChange,
  onDeleteConfirmOpen,
  setVariationsToDelete,
  isUsingProductPrice,
}) => {
  const formatStockDisplay = (value) => {
    if (value === null || value === undefined || value === '') {
      return unlimitedQuantity ? '∞' : '';
    }
    return value;
  };

  const isUsingProductStock = (variation) => {
    if (unlimitedQuantity) {
      return (
        variation.stock_quantity === null ||
        variation.stock_quantity === undefined
      );
    }
    return Number(variation.stock_quantity) === Number(productQuantity);
  };

  return (
    <Box width="100%">
      <SimpleGrid
        columns={{
          base: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
        }}
        spacing="5"
      >
        {filteredVariations.map((variation) => {
          const attrValues = variation.attribute_values_json || {};
          const isBeingEdited = Object.keys(editedValues).some((key) =>
            key.startsWith(`${variation.uuid}-`),
          );

          return (
            <Box
              key={variation.uuid}
              borderWidth="1px"
              borderRadius="md"
              overflow="hidden"
              position="relative"
              transition="all 0.2s"
              shadow="sm"
              _hover={{ shadow: 'md', transform: 'translateY(-2px)' }}
              bg={isBeingEdited ? 'blue.50' : 'white'}
            >
              {/* Selection checkbox */}
              <Box position="absolute" top="3" left="3" zIndex="1">
                <Checkbox
                  isChecked={selectedRows.includes(variation.uuid)}
                  onChange={() => handleRowSelect(variation.uuid)}
                  isDisabled={isViewer}
                  colorScheme="blue"
                  size="md"
                />
              </Box>

              {/* Status badge */}
              <Box position="absolute" top="3" right="3" zIndex="1">
                <Badge
                  colorScheme={variation.is_active ? 'green' : 'gray'}
                  px="2"
                  py="1"
                  borderRadius="full"
                  fontWeight="medium"
                >
                  {variation.is_active ? 'Active' : 'Inactive'}
                </Badge>
              </Box>

              {/* Card content */}
              <Box p="0">
                {/* Attributes section with subtle background */}
                <Box pt="12" pb="3" px="4" bg="gray.50" borderBottomWidth="1px">
                  <Text
                    fontWeight="semibold"
                    fontSize="xs"
                    color="gray.500"
                    mb="2"
                  >
                    ATTRIBUTES
                  </Text>

                  <VStack align="stretch" spacing={2}>
                    {availableAttributes.map((attr) => {
                      const key = getAttributeKey(attr);
                      const attrName =
                        attr.translations?.find((tr) => tr.language === 'en')
                          ?.value || attr.slug;
                      return (
                        <Flex key={`${variation.uuid}-${attr.id}`} wrap="wrap">
                          <Text
                            fontWeight="medium"
                            width="40%"
                            color="gray.600"
                            fontSize="sm"
                            mr="1"
                          >
                            {attrName}:
                          </Text>
                          <Badge
                            colorScheme="purple"
                            variant="subtle"
                            ml="1"
                            whiteSpace="normal"
                            textAlign="left"
                            lineHeight="1.4"
                            py="1"
                          >
                            {attrValues[key] || '-'}
                          </Badge>
                        </Flex>
                      );
                    })}
                  </VStack>
                </Box>

                {/* Price and stock section */}
                <Box p="4">
                  <SimpleGrid columns={2} spacing={4} mb="4">
                    <FormControl>
                      <FormLabel
                        fontSize="xs"
                        fontWeight="medium"
                        color="gray.700"
                      >
                        Price
                      </FormLabel>
                      <InputGroup size="sm">
                        <InputLeftAddon>$</InputLeftAddon>
                        <NumberInput
                          className="perxify-price-input"
                          min={0}
                          isDisabled={isViewer}
                          value={
                            editedValues[`${variation.uuid}-price_override`] !==
                            undefined
                              ? editedValues[`${variation.uuid}-price_override`]
                              : variation.price_override !== null &&
                                  variation.price_override !== undefined
                                ? variation.price_override
                                : productPrice || ''
                          }
                          onChange={(valueStr) =>
                            handleVariationChange(
                              variation.uuid,
                              'price_override',
                              valueStr,
                            )
                          }
                          size="sm"
                          width="100%"
                        >
                          <NumberInputField
                            borderLeftRadius="0"
                            borderColor={
                              isUsingProductPrice(variation, productPrice)
                                ? 'green.300'
                                : variation.price_override !== null &&
                                    variation.price_override !== undefined
                                  ? 'orange.300'
                                  : 'inherit'
                            }
                          />
                        </NumberInput>
                      </InputGroup>
                      {isUsingProductPrice(variation, productPrice) ? (
                        <Badge
                          size="sm"
                          colorScheme="green"
                          mt={1}
                          fontSize="xs"
                          variant="subtle"
                        >
                          Synced with product
                        </Badge>
                      ) : (
                        variation.price_override !== null &&
                        variation.price_override !== undefined && (
                          <Badge
                            size="sm"
                            colorScheme="orange"
                            mt={1}
                            fontSize="xs"
                            variant="subtle"
                          >
                            Custom price
                          </Badge>
                        )
                      )}
                    </FormControl>

                    <FormControl>
                      <FormLabel
                        fontSize="xs"
                        fontWeight="medium"
                        color="gray.700"
                      >
                        Stock
                      </FormLabel>
                      <NumberInput
                        className="perxify-price-input"
                        min={0}
                        isDisabled={isViewer}
                        value={
                          editedValues[`${variation.uuid}-stock_quantity`] !==
                          undefined
                            ? formatStockDisplay(
                                editedValues[
                                  `${variation.uuid}-stock_quantity`
                                ],
                              )
                            : formatStockDisplay(variation.stock_quantity) ||
                              (unlimitedQuantity ? '∞' : productQuantity || '')
                        }
                        onChange={(valueStr) =>
                          handleVariationChange(
                            variation.uuid,
                            'stock_quantity',
                            valueStr,
                          )
                        }
                        size="sm"
                      >
                        <NumberInputField
                          borderRadius="md"
                          borderColor={
                            isUsingProductStock(variation)
                              ? 'green.300'
                              : 'orange.300'
                          }
                        />
                      </NumberInput>
                      {isUsingProductStock(variation) ? (
                        <Badge
                          size="sm"
                          colorScheme="green"
                          mt={1}
                          fontSize="xs"
                          variant="subtle"
                        >
                          Synced with product
                        </Badge>
                      ) : (
                        <Badge
                          size="sm"
                          colorScheme="orange"
                          mt={1}
                          fontSize="xs"
                          variant="subtle"
                        >
                          Custom stock
                        </Badge>
                      )}
                    </FormControl>
                  </SimpleGrid>

                  <Divider />

                  {/* Actions */}
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mt="3"
                  >
                    <FormControl
                      display="flex"
                      alignItems="center"
                      width="auto"
                    >
                      <FormLabel
                        htmlFor={`status-${variation.uuid}`}
                        mb="0"
                        fontSize="sm"
                        fontWeight="medium"
                        mr="2"
                      >
                        Active
                      </FormLabel>
                      <Switch
                        id={`status-${variation.uuid}`}
                        isChecked={
                          editedValues[`${variation.uuid}-is_active`] !==
                          undefined
                            ? editedValues[`${variation.uuid}-is_active`]
                            : variation.is_active
                        }
                        onChange={(e) =>
                          handleVariationChange(
                            variation.uuid,
                            'is_active',
                            e.target.checked,
                          )
                        }
                        isDisabled={isViewer}
                        size="sm"
                        colorScheme="green"
                      />
                    </FormControl>

                    <IconButton
                      aria-label="Delete variation"
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      size="sm"
                      isDisabled={isViewer}
                      onClick={() => {
                        setVariationsToDelete([variation.uuid]);
                        onDeleteConfirmOpen();
                      }}
                      variant="ghost"
                      _hover={{ bg: 'red.50' }}
                    />
                  </Flex>
                </Box>
              </Box>
            </Box>
          );
        })}
      </SimpleGrid>

      {/* Pagination placeholder for when there are many variations */}
      {filteredVariations.length > 24 && (
        <Flex justify="center" mt="6">
          <ButtonGroup isAttached variant="outline" size="sm">
            <Button
              isDisabled
              leftIcon={<ChevronDownIcon transform="rotate(90deg)" />}
            >
              Previous
            </Button>
            <Button variant="solid" colorScheme="blue">
              1
            </Button>
            <Button>2</Button>
            <Button>3</Button>
            <Button rightIcon={<ChevronDownIcon transform="rotate(-90deg)" />}>
              Next
            </Button>
          </ButtonGroup>
        </Flex>
      )}
    </Box>
  );
};

export default VariationsGrid;
