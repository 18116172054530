import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  NumberInput,
  NumberInputField,
  Badge,
  IconButton,
  Switch,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { getAttributeKey } from '../../../utils/utils';

const VariationsTable = ({
  filteredVariations,
  availableAttributes,
  selectedRows,
  selectAll,
  setSelectAll,
  handleRowSelect,
  isViewer,
  productPrice,
  productQuantity,
  unlimitedQuantity,
  editedValues,
  handleVariationChange,
  onDeleteConfirmOpen,
  setVariationsToDelete,
  isUsingProductPrice,
  isUsingProductStock,
}) => {
  const formatStockDisplay = (value) => {
    if (value === null || value === undefined || value === '') {
      return unlimitedQuantity ? '∞' : '';
    }
    return value;
  };

  return (
    <Box
      overflowX="auto"
      overflowY="auto"
      maxHeight="600px"
      borderWidth="1px"
      borderRadius="lg"
    >
      <Table variant="simple">
        <Thead position="sticky" top={0} bg="white" zIndex={1} boxShadow="sm">
          <Tr>
            <Th width="40px" px="3">
              <Checkbox
                isChecked={
                  selectAll ||
                  (selectedRows.length > 0 &&
                    selectedRows.length === filteredVariations.length)
                }
                onChange={() => setSelectAll(!selectAll)}
                isDisabled={isViewer}
                colorScheme="blue"
              />
            </Th>
            {availableAttributes.map((attr) => (
              <Th key={attr.id}>
                {attr.translations?.find((tr) => tr.language === 'en')?.value ||
                  attr.slug}
              </Th>
            ))}
            <Th>Price</Th>
            <Th>Stock</Th>
            <Th>Status</Th>
            <Th width="80px">Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredVariations.map((variation) => {
            const attrValues = variation.attribute_values_json || {};
            const isBeingEdited = Object.keys(editedValues).some((key) =>
              key.startsWith(`${variation.uuid}-`),
            );

            return (
              <Tr
                key={variation.uuid}
                bg={isBeingEdited ? 'blue.50' : undefined}
                _hover={{ bg: 'gray.50' }}
                transition="background-color 0.3s"
              >
                <Td px="3">
                  <Checkbox
                    isChecked={selectedRows.includes(variation.uuid)}
                    onChange={() => handleRowSelect(variation.uuid)}
                    isDisabled={isViewer}
                    colorScheme="blue"
                  />
                </Td>
                {availableAttributes.map((attr) => {
                  const key = getAttributeKey(attr);
                  return (
                    <Td
                      key={`${variation.uuid}-${attr.id}`}
                      fontWeight="medium"
                    >
                      {attrValues[key] || '-'}
                    </Td>
                  );
                })}
                <Td>
                  <NumberInput
                    className="perxify-price-input"
                    min={0}
                    isDisabled={isViewer}
                    value={
                      editedValues[`${variation.uuid}-price_override`] !==
                      undefined
                        ? editedValues[`${variation.uuid}-price_override`]
                        : variation.price_override !== null &&
                            variation.price_override !== undefined
                          ? variation.price_override
                          : productPrice || ''
                    }
                    onChange={(valueStr) =>
                      handleVariationChange(
                        variation.uuid,
                        'price_override',
                        valueStr,
                      )
                    }
                    size="sm"
                    width="60px"
                  >
                    <NumberInputField
                      borderRadius="md"
                      borderColor={
                        isUsingProductPrice(variation, productPrice)
                          ? 'green.300'
                          : variation.price_override !== null &&
                              variation.price_override !== undefined
                            ? 'orange.300'
                            : 'inherit'
                      }
                    />
                  </NumberInput>
                  {isUsingProductPrice(variation, productPrice) ? (
                    <Badge
                      size="sm"
                      colorScheme="green"
                      mt={1}
                      fontSize="xs"
                      variant="subtle"
                    >
                      Synced with product
                    </Badge>
                  ) : (
                    <Badge
                      size="sm"
                      colorScheme="orange"
                      mt={1}
                      fontSize="xs"
                      variant="subtle"
                    >
                      Custom price
                    </Badge>
                  )}
                </Td>
                <Td>
                  <NumberInput
                    className="perxify-price-input"
                    min={0}
                    isDisabled={isViewer}
                    value={
                      editedValues[`${variation.uuid}-stock_quantity`] !==
                      undefined
                        ? formatStockDisplay(
                            editedValues[`${variation.uuid}-stock_quantity`],
                          )
                        : formatStockDisplay(variation.stock_quantity) ||
                          (unlimitedQuantity ? '∞' : productQuantity || '')
                    }
                    onChange={(valueStr) =>
                      handleVariationChange(
                        variation.uuid,
                        'stock_quantity',
                        valueStr,
                      )
                    }
                    size="sm"
                    width="40px"
                  >
                    <NumberInputField
                      borderRadius="md"
                      borderColor={
                        isUsingProductStock(variation)
                          ? 'green.300'
                          : 'orange.300'
                      }
                    />
                  </NumberInput>
                  {isUsingProductStock(variation) ? (
                    <Badge
                      size="sm"
                      colorScheme="green"
                      mt={1}
                      fontSize="xs"
                      variant="subtle"
                    >
                      Synced with product
                    </Badge>
                  ) : (
                    <Badge
                      size="sm"
                      colorScheme="orange"
                      mt={1}
                      fontSize="xs"
                      variant="subtle"
                    >
                      Custom stock
                    </Badge>
                  )}
                </Td>
                <Td>
                  <Switch
                    isChecked={
                      editedValues[`${variation.uuid}-is_active`] !== undefined
                        ? editedValues[`${variation.uuid}-is_active`]
                        : variation.is_active
                    }
                    onChange={(e) =>
                      handleVariationChange(
                        variation.uuid,
                        'is_active',
                        e.target.checked,
                      )
                    }
                    colorScheme="green"
                    size="md"
                    isDisabled={isViewer}
                  />
                </Td>
                <Td>
                  <IconButton
                    aria-label="Delete variation"
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    size="sm"
                    isDisabled={isViewer}
                    onClick={() => {
                      setVariationsToDelete([variation.uuid]);
                      onDeleteConfirmOpen();
                    }}
                    variant="ghost"
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default VariationsTable;
