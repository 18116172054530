import React, { useState, useCallback } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Image,
  Text,
  Progress,
  Flex,
  Icon,
  Button,
  useColorModeValue,
  HStack,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import {
  MdAddPhotoAlternate,
  MdOutlineCloudUpload,
  MdImage,
  MdFileUpload,
} from 'react-icons/md';

const CompanyImageUploader = ({
  title,
  image,
  onUpload,
  isDisabled = false,
  defaultImage,
  maxFileSizeMB = 5,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const acceptedFileTypes = 'PNG, JPG, GIF';

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        // Simulate upload progress
        setUploadProgress(0);
        const timer = setInterval(() => {
          setUploadProgress((prev) => {
            const newValue = prev + 5;
            if (newValue >= 100) clearInterval(timer);
            return Math.min(newValue, 100);
          });
        }, 200);

        onUpload(acceptedFiles);
      }
    },
    [onUpload],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
    },
    onDrop,
    disabled: isDisabled,
    maxSize: maxFileSizeMB * 1024 * 1024,
    onDragEnter: () => setIsDraggingOver(true),
    onDragLeave: () => setIsDraggingOver(false),
    onDropAccepted: () => setIsDraggingOver(false),
    onDropRejected: () => setIsDraggingOver(false),
  });

  const handleImageClick = () => {
    setSelectedImage(
      image && typeof image === 'object'
        ? URL.createObjectURL(image)
        : image || defaultImage,
    );
    onOpen();
  };

  const dropzoneColor = useColorModeValue('gray.50', 'gray.700');
  const hoverColor = useColorModeValue('blue.50', 'blue.900');
  const activeColor = useColorModeValue('blue.100', 'blue.800');
  const rejectColor = useColorModeValue('red.50', 'red.900');

  const bgColor = isDragAccept
    ? activeColor
    : isDragReject
      ? rejectColor
      : isDraggingOver
        ? hoverColor
        : dropzoneColor;

  const borderColor = isDragAccept
    ? 'green.400'
    : isDragReject
      ? 'red.400'
      : isDraggingOver
        ? 'blue.400'
        : 'gray.200';

  const imageUrl =
    image && typeof image === 'object'
      ? URL.createObjectURL(image)
      : image || defaultImage;

  return (
    <Card
      variant="outline"
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="md"
      overflow="hidden"
      mb={6}
      height="100%"
    >
      <CardHeader bg="blue.50" py={4}>
        <Heading size="md">{title}</Heading>
      </CardHeader>
      <CardBody p={4}>
        {/* Image preview section */}
        <Box
          position="relative"
          mb={6}
          borderWidth="1px"
          borderRadius="md"
          overflow="hidden"
        >
          <Image
            src={imageUrl}
            alt={title}
            objectFit="contain"
            w="100%"
            h="200px"
            cursor="pointer"
            onClick={handleImageClick}
            fallbackSrc="https://via.placeholder.com/300?text=No+Image"
          />
        </Box>

        {!isDisabled && (
          <Box
            {...getRootProps()}
            border="2px dashed"
            borderColor={borderColor}
            borderRadius="md"
            bg={bgColor}
            p={4}
            transition="all 0.3s ease"
            _hover={{ borderColor: 'blue.300', bg: hoverColor }}
            position="relative"
          >
            <input {...getInputProps()} />

            <Flex
              direction="column"
              align="center"
              justify="center"
              py={3}
              opacity={isDraggingOver ? 0.7 : 1}
              transition="opacity 0.2s ease"
            >
              <Icon
                as={isDragActive ? MdOutlineCloudUpload : MdAddPhotoAlternate}
                boxSize={10}
                color={isDragReject ? 'red.500' : 'blue.400'}
                mb={2}
                transition="transform 0.2s ease"
                transform={isDragActive ? 'scale(1.1)' : 'scale(1)'}
              />

              <Text fontWeight="bold" mb={1} fontSize="md" textAlign="center">
                {isDragActive
                  ? isDragReject
                    ? 'File type not supported'
                    : 'Drop to upload'
                  : 'Drag & drop image here'}
              </Text>

              <HStack spacing={2} my={1} width="100%" maxW="220px">
                <Divider flex="1" />
                <Text color="gray.500" fontSize="sm">
                  OR
                </Text>
                <Divider flex="1" />
              </HStack>

              <Button
                size="sm"
                colorScheme="blue"
                leftIcon={<MdFileUpload />}
                variant="outline"
                onClick={(e) => e.stopPropagation()}
                mb={2}
              >
                Browse Files
              </Button>

              <Text fontSize="xs" color="gray.500" textAlign="center">
                Accepts {acceptedFileTypes} up to {maxFileSizeMB}MB
              </Text>
            </Flex>
          </Box>
        )}

        {/* Upload progress section */}
        {uploadProgress > 0 && uploadProgress < 100 && (
          <Box mt={4} borderWidth="1px" borderRadius="md" p={4} bg="gray.50">
            <Flex align="center" mb={2}>
              <Icon as={MdImage} color="blue.500" boxSize={6} mr={3} />
              <Box flex="1">
                <Text fontWeight="medium">Uploading image...</Text>
                <Text fontSize="xs" color="gray.500">
                  {uploadProgress < 25
                    ? 'Starting upload...'
                    : uploadProgress < 50
                      ? 'Uploading...'
                      : uploadProgress < 75
                        ? 'Processing...'
                        : 'Finalizing...'}
                </Text>
              </Box>
              <Text fontWeight="bold" fontSize="sm">
                {Math.round(uploadProgress)}%
              </Text>
            </Flex>

            <Progress
              value={uploadProgress}
              size="md"
              colorScheme={
                uploadProgress < 30
                  ? 'blue'
                  : uploadProgress < 70
                    ? 'cyan'
                    : 'green'
              }
              borderRadius="full"
              height="8px"
              hasStripe
              isAnimated
            />
          </Box>
        )}

        {/* Image preview modal */}
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay backdropFilter="blur(3px)" />
          <ModalContent>
            <ModalCloseButton zIndex={2} color="white" />
            <ModalBody p={0}>
              {selectedImage && (
                <Image
                  src={selectedImage}
                  alt={title}
                  w="100%"
                  borderRadius="md"
                  objectFit="contain"
                  maxH="80vh"
                />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </CardBody>
    </Card>
  );
};

export default CompanyImageUploader;
