import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Heading,
  Input,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { MdOutlineCloudUpload } from 'react-icons/md';

function Dropzone(props) {
  const { onDrop, children, ...rest } = props;
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // Use Card styling instead of just a Flex with border
  return (
    <Card
      variant="outline"
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="md"
      overflow="hidden"
      {...rest}
    >
      <CardHeader bg="blue.50" py={4}>
        <Heading size="md">Product Images</Heading>
      </CardHeader>
      <CardBody p={0}>
        <Flex
          align="center"
          justify="center"
          border="2px dashed"
          borderColor="blue.200"
          m={4}
          borderRadius="md"
          bg="blue.50"
          h="200px"
          cursor="pointer"
          transition="all 0.2s"
          _hover={{ bg: 'blue.100', borderColor: 'blue.300' }}
          {...getRootProps()}
        >
          <Input {...getInputProps()} />
          {children || (
            <Flex direction="column" align="center" justify="center">
              <Icon
                as={MdOutlineCloudUpload}
                boxSize={10}
                color="blue.500"
                mb={2}
              />
              <Text fontWeight="bold" mb={1}>
                Drag and drop images here
              </Text>
              <Text fontSize="sm" color="gray.600">
                PNG, JPG, and GIF files are allowed
              </Text>
            </Flex>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
}

export default Dropzone;
