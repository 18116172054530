import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  VStack,
  HStack,
  Button,
  Collapse,
  IconButton,
  useDisclosure,
  Text,
  Input,
  Alert,
  AlertDescription,
  FormControl,
  FormLabel,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Textarea,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  AddIcon,
  DragHandleIcon,
  ViewIcon,
  DeleteIcon,
} from '@chakra-ui/icons';
import {
  createCategory,
  updateCategory,
  getCategories,
  deleteCategory,
} from '../../api/category';
import EditCategoryForm from './EditCategoryForm';
import { transformCategories } from '../../utils/CategoryUtils';

const findNodeByUuid = (nodes, uuid) => {
  for (const node of nodes) {
    if (node.uuid === uuid) return node;
    if (node.child_categories) {
      const found = findNodeByUuid(node.child_categories, uuid);
      if (found) return found;
    }
  }
  return null;
};

const removeNodeByUuid = (nodes, uuid) => {
  return nodes.reduce((acc, node) => {
    if (node.uuid === uuid) {
      return acc;
    }
    if (node.child_categories) {
      const updatedChildren = removeNodeByUuid(node.child_categories, uuid);
      acc.push({
        ...node,
        child_categories: updatedChildren,
      });
    } else {
      acc.push(node);
    }
    return acc;
  }, []);
};

const DropZone = ({ onDrop, isDraggingOver, onDragOver, onDragLeave }) => (
  <Box
    h="2"
    my={2}
    borderRadius="md"
    transition="background-color 0.2s"
    bg={isDraggingOver ? 'blue.200' : 'transparent'}
    onDragOver={(e) => {
      e.preventDefault();
      onDragOver?.();
    }}
    onDragLeave={() => onDragLeave?.()}
    onDrop={onDrop}
  />
);

const AddCategoryForm = ({ onAdd, onCancel, parentId }) => {
  const [translations, setTranslations] = useState({
    en: { category_name: '', category_description: '' },
    et: { category_name: '', category_description: '' },
  });
  const [error, setError] = useState('');

  const handleTranslationChange = (lang, field, value) => {
    setTranslations((prev) => ({
      ...prev,
      [lang]: {
        ...prev[lang],
        [field]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!translations.en.category_name.trim()) {
      setError('Category name in English is required.');
      return;
    }

    onAdd({
      parent_id: parentId,
      translations,
    });

    setTranslations({
      en: { category_name: '', category_description: '' },
      et: { category_name: '', category_description: '' },
    });
    setError('');
  };

  return (
    <Box p={4} bg="gray.50" borderRadius="md" mt={2}>
      <form onSubmit={handleSubmit}>
        <Tabs isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab>English</Tab>
            <Tab>Estonian</Tab>
          </TabList>
          <TabPanels>
            {['en', 'et'].map((lang) => (
              <TabPanel key={lang}>
                <VStack spacing={3}>
                  <FormControl isRequired={lang === 'en'}>
                    <FormLabel>
                      Category Name ({lang === 'en' ? 'English' : 'Estonian'})
                    </FormLabel>
                    <Input
                      value={translations[lang].category_name}
                      onChange={(e) =>
                        handleTranslationChange(
                          lang,
                          'category_name',
                          e.target.value,
                        )
                      }
                      placeholder={`Enter category name in ${lang === 'en' ? 'English' : 'Estonian'}`}
                      size="sm"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      Category Description (
                      {lang === 'en' ? 'English' : 'Estonian'})
                    </FormLabel>
                    <Textarea
                      value={translations[lang].category_description}
                      onChange={(e) =>
                        handleTranslationChange(
                          lang,
                          'category_description',
                          e.target.value,
                        )
                      }
                      placeholder={`Enter category description in ${lang === 'en' ? 'English' : 'Estonian'}`}
                      size="sm"
                    />
                  </FormControl>
                </VStack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
        {error && (
          <Alert status="error" borderRadius="md" mt={2}>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <HStack w="full" spacing={2} mt={4}>
          <Button size="sm" type="submit" colorScheme="blue">
            Add
          </Button>
          <Button size="sm" variant="ghost" onClick={onCancel}>
            Cancel
          </Button>
        </HStack>
      </form>
    </Box>
  );
};

const CategoryNodeComponent = ({
  node,
  level = 0,
  onUpdateCategory,
  onMoveCategory,
  onAddCategory,
  isAllExpanded,
  activeEditingUuid,
  setActiveEditingUuid,
  setCategories,
  isViewer,
}) => {
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const hasChildren = node.child_categories && node.child_categories.length > 0;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const cancelRef = useRef();

  const handleDeleteClick = (uuid) => {
    setCategoryToDelete(uuid);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    handleDelete(categoryToDelete);
    setIsDeleteDialogOpen(false);
    setCategoryToDelete(null);
  };

  const cancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setCategoryToDelete(null);
  };

  useEffect(() => {
    if (isAllExpanded !== undefined) {
      if (isAllExpanded !== isOpen) {
        onToggle();
      }
    }
  }, [isAllExpanded, isOpen, onToggle]);

  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', node.uuid);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const dragUuid = e.dataTransfer.getData('text/plain');
    if (dragUuid && dragUuid !== node.uuid) {
      const newOrder = node.order;
      onMoveCategory(dragUuid, node.uuid, newOrder);
    }
  };

  const handleUpdateCategoryDetails = async (translations) => {
    const updatedCategory = {
      uuid: node.uuid,
      translations,
    };
    onUpdateCategory(updatedCategory);
  };

  const handleDelete = async (categoryUuid) => {
    try {
      await deleteCategory(categoryUuid);
      setCategories((prev) => removeNodeByUuid(prev, categoryUuid));
      toast({
        title: 'Success',
        description: 'Category deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(
        'Error deleting category:',
        error.response || error.message || error,
      );
      toast({
        title: 'Error',
        description:
          error.response?.data?.error || 'Failed to delete category.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Box
        p={2}
        bg={isDraggingOver ? 'blue.50' : 'transparent'}
        _hover={{ bg: 'gray.50' }}
        borderRadius="md"
        onDragOver={isViewer ? null : handleDragOver}
        onDragLeave={isViewer ? null : handleDragLeave}
        onDrop={isViewer ? null : handleDrop}
        transition="background-color 0.2s"
      >
        <HStack spacing={2}>
          <Box
            draggable={!isViewer}
            onDragStart={isViewer ? null : handleDragStart}
            cursor={isViewer ? 'default' : 'grab'}
            display="flex"
            alignItems="center"
            flex={1}
          >
            {!isViewer && <DragHandleIcon mr={2} color="gray.400" />}
            {hasChildren && (
              <IconButton
                size="sm"
                aria-label={isOpen ? 'Collapse' : 'Expand'}
                icon={isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
                onClick={onToggle}
                variant="ghost"
              />
            )}
            <Text>{node.name}</Text>
          </Box>
          {!isViewer && (
            <HStack>
              <IconButton
                size="sm"
                aria-label="Add subcategory"
                icon={<AddIcon />}
                onClick={() => setIsAddingCategory(true)}
                variant="ghost"
              />
              <IconButton
                size="sm"
                aria-label="Edit category"
                icon={<ViewIcon />}
                onClick={() => {
                  if (activeEditingUuid === node.uuid) {
                    setActiveEditingUuid(null);
                  } else {
                    setActiveEditingUuid(node.uuid);
                  }
                }}
                variant="ghost"
              />
              <IconButton
                size="sm"
                aria-label="Delete category"
                icon={<DeleteIcon />}
                onClick={() => handleDeleteClick(node.uuid)}
                variant="ghost"
              />
            </HStack>
          )}
        </HStack>
      </Box>

      {/* Confirmation Dialog */}
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={cancelDelete}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Confirm Deletion</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this category?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={cancelDelete}>
                No
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {isAddingCategory && (
        <AddCategoryForm
          parentId={node.uuid}
          onAdd={(newCategory) => {
            onAddCategory(node.uuid, newCategory);
            setIsAddingCategory(false);
          }}
          onCancel={() => setIsAddingCategory(false)}
        />
      )}

      {activeEditingUuid === node.uuid && (
        <EditCategoryForm
          initialTranslations={node.translations}
          onUpdate={handleUpdateCategoryDetails}
          onCancel={() => setActiveEditingUuid(null)}
        />
      )}

      {hasChildren && (
        <Collapse in={isOpen} animateOpacity>
          <VStack align="stretch" spacing={2} pl={6} mt={2}>
            {node.child_categories.map((child) => (
              <CategoryNodeComponent
                key={child.uuid}
                node={child}
                onUpdateCategory={onUpdateCategory}
                onMoveCategory={onMoveCategory}
                onAddCategory={onAddCategory}
                isAllExpanded={isAllExpanded}
                activeEditingUuid={activeEditingUuid}
                setActiveEditingUuid={setActiveEditingUuid}
                setCategories={setCategories}
                isViewer={isViewer}
              />
            ))}
          </VStack>
        </Collapse>
      )}
    </Box>
  );
};

const CategoryTree = ({
  initialData,
  onUpdateCategories,
  onMoveCategory,
  isViewer,
}) => {
  const [categories, setCategories] = useState(initialData);
  const [isAddingMainCategory, setIsAddingMainCategory] = useState(false);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [topDropZoneActive, setTopDropZoneActive] = useState(false);
  const [bottomDropZoneActive, setBottomDropZoneActive] = useState(false);
  const [activeEditingUuid, setActiveEditingUuid] = useState(null);
  const toast = useToast();

  useEffect(() => {
    setCategories(initialData);
  }, [initialData]);

  const updateCategories = (newCategories) => {
    setCategories(newCategories);
    onUpdateCategories?.(newCategories);
  };

  const handleMainLevelDrop = (e, position) => {
    e.preventDefault();
    const dragId = e.dataTransfer.getData('text/plain');
    const draggedNode = findNodeByUuid(categories, dragId);

    if (draggedNode) {
      const newCategories = removeNodeByUuid(categories, dragId);
      const updatedCategories =
        position === 'top'
          ? [{ ...draggedNode }, ...newCategories]
          : [...newCategories, { ...draggedNode }];

      updateCategories(updatedCategories);
    }

    setTopDropZoneActive(false);
    setBottomDropZoneActive(false);
  };

  const findIdByUuid = (nodes, uuid) => {
    for (const node of nodes) {
      console.log(`Checking node: ${node.uuid}`);
      if (node.uuid === uuid) {
        console.log(`Found node with ID: ${node.id}`);
        return node.id;
      }
      if (node.child_categories) {
        const found = findIdByUuid(node.child_categories, uuid);
        if (found) return found;
      }
    }
    return null;
  };

  const handleDragAndDropUpdate = async (dragUuid, dropUuid, newOrder) => {
    try {
      const draggedCategory = findNodeByUuid(categories, dragUuid);
      if (!draggedCategory) throw new Error('Dragged category not found.');

      const targetCategory = findNodeByUuid(categories, dropUuid);
      if (!targetCategory) throw new Error('Target category not found.');

      const updatePayload = {
        parent_id: targetCategory.id,
        order: newOrder,
        translations: draggedCategory.translations,
      };

      await updateCategory(dragUuid, updatePayload);

      const updatedCategoriesData = await getCategories();
      const transformedCategories = transformCategories(
        updatedCategoriesData,
        'en',
      );

      onMoveCategory?.(transformedCategories);

      toast({
        title: 'Success',
        description: 'Category reordered successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating category:', error);
      toast({
        title: 'Error',
        description: error.message || 'Failed to reorder category.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleCategoryUpdate = async (updatedCategory) => {
    try {
      const existingCategory = findNodeByUuid(categories, updatedCategory.uuid);
      if (!existingCategory) {
        throw new Error('Category not found.');
      }

      const updatePayload = {
        translations: updatedCategory.translations,
        parent_id: existingCategory.parent_id,
      };

      const response = await updateCategory(
        updatedCategory.uuid,
        updatePayload,
      );

      // Update the local categories to reflect the changes
      const updatedCategories = [...categories];
      const index = updatedCategories.findIndex(
        (cat) => cat.uuid === response.uuid,
      );

      if (index !== -1) {
        // Top-level category found and updated
        updatedCategories[index] = response;
        updateCategories(updatedCategories); // Important: Trigger UI update
      } else {
        // Category might be nested, so update it recursively
        const updateNestedCategory = (nodes) => {
          return nodes.map((node) => {
            if (node.uuid === response.uuid) return response;
            if (node.child_categories) {
              return {
                ...node,
                child_categories: updateNestedCategory(node.child_categories),
              };
            }
            return node;
          });
        };

        const newTree = updateNestedCategory(updatedCategories);
        updateCategories(newTree); // Trigger UI update for nested category
      }

      toast({
        title: 'Success',
        description: 'Category updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating category:', error);
      toast({
        title: 'Error',
        description: error.message || 'Failed to update category.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddCategory = async (parentUuid, newCategoryData) => {
    try {
      const parentId = findIdByUuid(categories, parentUuid);
      if (parentId === null) {
        throw new Error('Parent category ID not found.');
      }

      const createdCategory = await createCategory({
        parent_id: parentId,
        translations: newCategoryData.translations,
      });

      const { id, uuid, translations } = createdCategory.parent_category;

      const updatedCategories = addCategoryToTree(categories, parentUuid, {
        id,
        uuid,
        name: translations.en.category_name || 'Unnamed Category',
        translations: translations || {},
        child_categories: [],
      });

      setCategories(updatedCategories);

      toast({
        title: 'Success',
        description: 'Category added successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onUpdateCategories(updatedCategories);
    } catch (error) {
      console.error('Error creating category:', error);
      toast({
        title: 'Error',
        description: error.message || 'Failed to create category.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const addCategoryToTree = (categories, parentUuid, newCategory) => {
    return categories.map((cat) => {
      if (cat.uuid === parentUuid) {
        return {
          ...cat,
          child_categories: [...cat.child_categories, newCategory],
        };
      }
      if (cat.child_categories && cat.child_categories.length > 0) {
        return {
          ...cat,
          child_categories: addCategoryToTree(
            cat.child_categories,
            parentUuid,
            newCategory,
          ),
        };
      }
      return cat;
    });
  };

  const handleAddMainCategory = async (newCategoryData) => {
    try {
      const dataToCreate = {
        ...newCategoryData,
        parent_id: null,
      };
      const createdCategory = await createCategory(dataToCreate);

      const { id, uuid, translations } = createdCategory.parent_category;

      const newCategory = {
        id,
        uuid,
        name: translations.en.category_name || 'Unnamed Category',
        translations: translations || {},
        child_categories: [],
      };

      // Update local state
      setCategories((prevCategories) => [...prevCategories, newCategory]);

      // Update parent component via callback with the new array
      const updatedCategories = [...categories, newCategory];
      onUpdateCategories(updatedCategories);

      // Show success toast
      toast({
        title: 'Success',
        description: 'Main category added successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding main category:', error);
      toast({
        title: 'Error',
        description: error.message || 'Failed to add main category.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      w="full"
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      bg="white"
      shadow="sm"
    >
      <HStack justify="space-between" mb={4}>
        <Text fontSize="lg" fontWeight="semibold">
          Categories
        </Text>
        <HStack spacing={2}>
          <Button
            size="sm"
            variant="outline"
            onClick={() => setIsAllExpanded(!isAllExpanded)}
          >
            {isAllExpanded ? 'Collapse All' : 'Expand All'}
          </Button>
          {!isViewer && (
            <Button
              size="sm"
              variant="outline"
              onClick={() => setIsAddingMainCategory(true)}
            >
              Add Main Category
            </Button>
          )}
        </HStack>
      </HStack>

      {!isViewer && (
        <DropZone
          onDrop={(e) => handleMainLevelDrop(e, 'top')}
          isDraggingOver={topDropZoneActive}
          onDragOver={() => setTopDropZoneActive(true)}
          onDragLeave={() => setTopDropZoneActive(false)}
        />
      )}

      {isAddingMainCategory && !isViewer && (
        <AddCategoryForm
          parentId="0"
          onAdd={(newCategory) => {
            handleAddMainCategory(newCategory);
            setIsAddingMainCategory(false);
          }}
          onCancel={() => setIsAddingMainCategory(false)}
        />
      )}

      <VStack align="stretch" spacing={2}>
        {categories.map((node) => (
          <CategoryNodeComponent
            key={node.uuid}
            node={node}
            onUpdateCategory={handleCategoryUpdate}
            onMoveCategory={handleDragAndDropUpdate}
            onAddCategory={handleAddCategory}
            isAllExpanded={isAllExpanded}
            activeEditingUuid={activeEditingUuid}
            setActiveEditingUuid={setActiveEditingUuid}
            setCategories={setCategories}
            isViewer={isViewer}
          />
        ))}
      </VStack>

      {!isViewer && (
        <DropZone
          onDrop={(e) => handleMainLevelDrop(e, 'bottom')}
          isDraggingOver={bottomDropZoneActive}
          onDragOver={() => setBottomDropZoneActive(true)}
          onDragLeave={() => setBottomDropZoneActive(false)}
        />
      )}
    </Box>
  );
};

export default CategoryTree;
