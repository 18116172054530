import React, { useEffect, useState } from 'react';
import {
  Box,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Text,
  Badge,
  Spinner,
  useToast,
  Card,
  Flex,
  Button,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import apiClient from 'api/axios';
import { format } from 'date-fns';
import { MdPrint } from 'react-icons/md';
import 'assets/css/Print.css';

export default function PayoutDetail() {
  const { uuid } = useParams();
  const [payout, setPayout] = useState(null);
  const [billingDetails, setBillingDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [payoutResponse, billingResponse] = await Promise.all([
          apiClient.get(`/company/invoices/${uuid}`),
          apiClient.get('/company/billing')
        ]);
        setPayout(payoutResponse.data);
        setBillingDetails(billingResponse.data);
      } catch (error) {
        console.error('Error fetching details:', error);
        toast({
          title: 'Error fetching details',
          description: error.response?.data?.error || 'An error occurred',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [uuid, toast]);

  const handlePrint = () => {
    window.print();
  };

  const getStatusBadge = (status) => {
    const statusConfig = {
      created: { color: 'blue', text: 'Created' },
      paid: { color: 'green', text: 'Paid' },
      declined: { color: 'red', text: 'Declined' },
      overdue: { color: 'orange', text: 'Overdue' },
    };

    const config = statusConfig[status] || { color: 'gray', text: status };
    return (
      <Badge colorScheme={config.color} variant="subtle">
        {config.text}
      </Badge>
    );
  };

  if (isLoading) {
    return (
      <Box>
          <Flex justify="center" align="center">
            <Spinner />
          </Flex>
      </Box>
    );
  }

  if (!payout) {
    return (
      <Box>
          <Text textAlign="center">No payout details found</Text>
      </Box>
    );
  }

  return (
    <Box className="invoice-detail">
      <Flex direction="column" pt={{ sm: '60px', lg: '30px' }} flex="1">
        <Card
          w="100%"
          px={{ base: '16px', md: '24px' }}
          py={{ base: '16px', md: '24px' }}
          mt={{ base: '30px', md: '40px', xl: '50px' }}
          flex="1"
          overflowX="hidden"
          overflowY="visible"
          display="flex"
          flexDirection="column"
        >
          <Flex justify="space-between" align="flex-start" mb={6}>
            <Box>
              <Heading size="lg" mb={2}>Invoice</Heading>
              <Text fontSize="md">
                Alder Solutions OÜ
              </Text>
              <Text>Liivaoja tn 1b-25</Text>
              <Text>Kesklinna linnaosa</Text>
              <Text>10155 Tallinn</Text>
              <Text>Harju maakond, Eesti</Text>
              <Text>17099988</Text>
            </Box>
            <Box mt={1}>
              <Button
                leftIcon={<MdPrint />}
                bg="black"
                color="white"
                onClick={handlePrint}
                className="no-print"
              >
                Print Invoice
              </Button>
            </Box>
          </Flex>

          <SimpleGrid columns={{ sm: 1, xl: 1 }} spacing={5}>
            <Box mb={5}>
              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={5} mb={5} className="invoice-details">
                <Box>
                  <Text fontWeight="bold">Bill to</Text>
                  {billingDetails && (
                    <>
                      <Text>{billingDetails.company_name}</Text>
                      <Text>{billingDetails.legal_name}</Text>
                      <Text>{billingDetails.street_address}</Text>
                      <Text>{billingDetails.city}, {billingDetails.postal_code}</Text>
                      <Text>{billingDetails.country}</Text>
                      <Text>{billingDetails.registry_number}</Text>
                    </>
                  )}
                </Box>
                <Box>
                  <Text fontWeight="bold">Invoice Details</Text>
                  <Text><Text as="span" fontWeight="bold">Invoice No. </Text>{payout.invoice_number || "N/A"}</Text>
                  <Text><Text as="span" fontWeight="bold">Invoice Period: </Text>{payout.period || "N/A"}</Text>
                  <Text><Text as="span" fontWeight="bold">Invoice Date: </Text> {payout.invoice_date ? format(new Date(payout.invoice_date), 'dd/MM/yyyy') : 'N/A'}</Text>
                  <Text><Text as="span" fontWeight="bold">Due Date: </Text> {payout.invoice_deadline ? format(new Date(payout.invoice_deadline), 'dd/MM/yyyy') : 'N/A'}</Text>
                </Box>
              </SimpleGrid>
              <Box className="purchase-details">
                <Heading size="md" mb={4}>
                  Purchase Details
                </Heading>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Activity</Th>
                      <Th>Amount</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>Total amount</Td>
                      <Td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(payout.total_amount)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Perxify service fee</Td>
                      <Td>
                        - {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(payout.service_fee_amount)}
                        {' '}
                        ({payout.service_fee_percent}%)
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Subtotal</Td>
                      <Td>{payout.subtotal || "N/A"}</Td>
                    </Tr>
                    <Tr>
                      <Td>VAT(22%)</Td>
                      <Td>{payout.vat || "N/A"}</Td>
                    </Tr>
                    <Tr>
                      <Td fontWeight="bold">Total revenue</Td>
                      <Td fontWeight="bold">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(payout.total_revenue)}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Box>
          </SimpleGrid>
        </Card>
      </Flex>
    </Box>
  );
}
