import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Spinner,
  useColorModeValue,
  FormControl,
  Select,
  SimpleGrid,
  Heading,
  Divider,
} from '@chakra-ui/react';
import LineChart from 'components/charts/LineChart';
import Card from 'components/card/Card';
import { getLoyaltyProgramsSold } from 'api/dashboard';
import { format, parseISO, startOfWeek, addWeeks, isValid } from 'date-fns';

export default function LoyaltyProgramsWidget(props) {
  const { startDate, endDate, groupBy, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // State variables
  const [loyaltyData, setLoyaltyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [levelsSelections, setLevelSelections] = useState({});

  // Fetch loyalty programs data
  useEffect(() => {
    const fetchLoyaltyPrograms = async () => {
      setIsLoading(true);
      try {
        const params = {
          group_by: groupBy,
        };
        if (startDate) params.start_date = startDate;
        if (endDate) params.end_date = endDate;

        const data = await getLoyaltyProgramsSold(params);
        setLoyaltyData(data);
      } catch (error) {
        console.error('Error fetching loyalty programs sold:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLoyaltyPrograms();
  }, [groupBy, startDate, endDate]);

  const LevelSelector = ({ programId, levels, selectedLevel, onChange }) => (
    <FormControl w="auto" display="inline-block" fontSize="sm">
      <Select
        fontSize="sm"
        variant="subtle"
        value={selectedLevel}
        onChange={(e) => onChange(programId, e.target.value)}
      >
        <option value="all">All Levels</option>
        {levels.map((level) => (
          <option key={level.level_id} value={level.level_id}>
            {level.level_name}
          </option>
        ))}
      </Select>
    </FormControl>
  );

  // Calculate program totals
  const calculateProgramTotals = (program, selectedLevel) => {
    if (selectedLevel === 'all') {
      // Calculate totals across all levels
      return program.loyalty_levels.reduce(
        (acc, level) => {
          if (level.data && level.data.length > 0) {
            level.data.forEach((item) => {
              acc.orders += item.orders;
              acc.sales += item.sales;
            });
          }
          return acc;
        },
        { orders: 0, sales: 0 },
      );
    } else {
      // Calculate totals for selected level only
      const selectedLevelData = program.loyalty_levels.find(
        (level) => level.level_id === parseInt(selectedLevel),
      );

      if (selectedLevelData && selectedLevelData.data) {
        return selectedLevelData.data.reduce(
          (acc, item) => {
            acc.orders += item.orders;
            acc.sales += item.sales;
            return acc;
          },
          { orders: 0, sales: 0 },
        );
      }
      return { orders: 0, sales: 0 };
    }
  };

  const renderCharts = () => {
    return loyaltyData
      .filter((program) =>
        program.loyalty_levels?.some(
          (level) => level.data && level.data.length > 0,
        ),
      )
      .map((program) => {
        const programId = program.loyalty_program_id;
        const programName =
          program.loyalty_program_name || `Program ${programId}`;
        const selectedLevel = levelsSelections[programId] || 'all';

        const programTotals = calculateProgramTotals(program, selectedLevel);

        const allPeriods = new Set();
        program.loyalty_levels.forEach((level) => {
          level.data.forEach((item) => allPeriods.add(item.period));
        });
        const sortedPeriods = Array.from(allPeriods).sort();

        let processedData = [];
        if (selectedLevel === 'all') {
          // Sum up data for all levels per period
          processedData = sortedPeriods.map((period) => {
            const periodData = { period, orders: 0, sales: 0 };
            program.loyalty_levels.forEach((level) => {
              if (level.data && level.data.length > 0) {
                const dataItem = level.data.find(
                  (item) => item.period === period,
                );
                if (dataItem) {
                  periodData.orders += dataItem.orders;
                  periodData.sales += dataItem.sales;
                }
              }
            });
            return periodData;
          });
        } else {
          // Get data for selected level only
          const selectedLevelData = program.loyalty_levels.find(
            (level) => level.level_id === parseInt(selectedLevel),
          );
          processedData = selectedLevelData
            ? sortedPeriods.map((period) => {
                const dataItem = selectedLevelData.data.find(
                  (item) => item.period === period,
                );
                return {
                  period,
                  orders: dataItem ? dataItem.orders : 0,
                  sales: dataItem ? dataItem.sales : 0,
                };
              })
            : [];
        }

        // Prepare chart categories
        const chartCategories = processedData.map((item) => {
          let date = parseISO(item.period);

          if (groupBy === 'week') {
            // Parse week number and year
            const year = parseInt(item.period.slice(0, 4), 10);
            const week = parseInt(item.period.slice(4), 10);
            date = startOfWeek(addWeeks(new Date(year, 0, 1), week - 1), {
              weekStartsOn: 1,
            });
          }

          if (!isValid(date)) {
            console.error(`Invalid date value: ${item.period}`);
            return item.period; // Return the original value if the date is invalid
          }

          switch (groupBy) {
            case 'day':
              return format(date, 'dd MMM');
            case 'week':
              return `W${format(date, 'w yyyy')}`;
            case 'month':
              return format(date, 'MMM');
            case 'year':
              return format(date, 'yyyy');
            default:
              return item.period;
          }
        });

        const chartData = [
          {
            name: 'Orders',
            data: processedData.map((item, index) => ({
              x: chartCategories[index],
              y: item.orders,
            })),
          },
          {
            name: 'Sales (€)',
            data: processedData.map((item, index) => ({
              x: chartCategories[index],
              y: parseFloat(item.sales.toFixed(2)),
            })),
          },
        ];

        // Chart options
        const chartOptions = {
          chart: {
            toolbar: { show: false },
            stacked: false,
          },
          xaxis: {
            show: false,
            categories: chartCategories,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          grid: {
            show: false,
          },
          yaxis: {
            show: false,
          },
          stroke: {
            curve: 'smooth',
            width: 5,
          },
          colors: ['#3182CE', '#68D391'],
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (value, { seriesIndex }) {
                if (seriesIndex === 1) {
                  return `€${value.toFixed(2)}`;
                }
                return value;
              },
            },
          },
          legend: {
            show: true,
            position: 'top',
          },
        };

        return (
          <Box key={`${programId}-${selectedLevel}`} w="100%" mb="40px">
            <Divider mb={2} />
            <Flex
              justify="space-between"
              align="center"
              mb="15px"
              pl="20px"
              pr="20px"
            >
              <Heading size="md" color={textColor}>
                {programName}
              </Heading>
              <LevelSelector
                programId={programId}
                levels={program.loyalty_levels}
                selectedLevel={selectedLevel}
                onChange={(id, value) =>
                  setLevelSelections((prev) => ({
                    ...prev,
                    [id]: value,
                  }))
                }
              />
            </Flex>

            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing="20px"
              w="100%"
              p="20px"
            >
              <Flex
                direction="column"
                align="center"
                bg="blue.50"
                p={4}
                borderRadius="lg"
              >
                <Text color="secondaryGray.600" fontSize="sm">
                  {selectedLevel === 'all' ? 'Total Sales' : 'Level Sales'}
                </Text>
                <Text color={textColor} fontSize="2xl" fontWeight="700" mt={2}>
                  €{programTotals.sales.toFixed(2)}
                </Text>
              </Flex>

              <Flex
                direction="column"
                align="center"
                bg="green.50"
                p={4}
                borderRadius="lg"
              >
                <Text color="secondaryGray.600" fontSize="sm">
                  {selectedLevel === 'all'
                    ? 'Total Memberships'
                    : 'Level Memberships'}
                </Text>
                <Text color={textColor} fontSize="2xl" fontWeight="700" mt={2}>
                  {programTotals.orders}
                </Text>
              </Flex>
            </SimpleGrid>

            <Box minH="300px" w="100%" px="20px" pb="20px" mt={4}>
              <LineChart
                chartData={chartData}
                chartOptions={chartOptions}
                h="300px"
              />
            </Box>
          </Box>
        );
      });
  };

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      {...rest}
    >
      {isLoading ? (
        <Flex justify="center" align="center" h="300px">
          <Spinner />
        </Flex>
      ) : (
        <>
          <Flex
            justify="space-between"
            px="20px"
            pt="20px"
            w="100%"
            flexWrap="wrap"
            mb={6}
          >
            <Text
              color={textColor}
              fontSize="xl"
              fontWeight="700"
              lineHeight="100%"
            >
              Loyalty Programs Overview
            </Text>
          </Flex>

          <Box w="100%" mt="auto">
            {renderCharts()}
          </Box>
        </>
      )}
    </Card>
  );
}
