// OrdersOverview.js
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Flex,
  Spinner,
  Text,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Badge,
  Card,
  Icon,
  useDisclosure,
  useToast,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { MdEdit, MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { FiFilter } from 'react-icons/fi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import debounce from 'lodash/debounce';

// Import your API call and components
import { getOrders } from '../../api/order';
import DebouncedInput from 'components/DebouncedInput';
import OrdersFilter from '../../components/orders/OrdersFilter';
import { createPages } from 'utils/helpers';

export default function OrdersOverview() {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();

  // Filter states (initialized from URL parameters)
  const [orderType, setOrderType] = useState(
    searchParams.get('product_type') || 'all',
  );
  const [status, setStatus] = useState(searchParams.get('status') || 'all');
  const [dateRange, setDateRange] = useState({
    start: searchParams.get('start_date') || '',
    end: searchParams.get('end_date') || '',
  });
  // Search state
  const [searchText, setSearchText] = useState(
    searchParams.get('search') || '',
  );

  // Pagination and Sorting
  const currentPage = parseInt(searchParams.get('page')) || 1;
  const [pagination, setPagination] = useState({
    pageIndex: currentPage - 1,
    pageSize: parseInt(searchParams.get('limit')) || 20,
    pageCount: 0,
    total: 0,
  });
  const [sorting, setSorting] = useState([{ id: 'purchase_date', desc: true }]);

  // Orders data state
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  // Helper to update URL parameters
  const updateUrlParams = useCallback(
    (newParams) => {
      const current = Object.fromEntries([...searchParams]);
      const updated = { ...current, ...newParams };

      // Remove keys with undefined or empty string values
      Object.keys(updated).forEach(
        (key) =>
          (updated[key] === undefined || updated[key] === '') &&
          delete updated[key],
      );

      setSearchParams(updated, { replace: true });
    },
    [searchParams, setSearchParams],
  );

  // Debounce search updates so that API calls aren't made on every keystroke.
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        updateUrlParams({ search: value, page: 1 });
      }, 500),
    [updateUrlParams],
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Fetch orders from API using current filters, pagination, and sorting
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const currentSort = sorting[0] || { id: 'purchase_date', desc: true };

        const filters = {
          status: status !== 'all' ? status : undefined,
          start_date: dateRange.start || undefined,
          end_date: dateRange.end || undefined,
          global_filter: searchParams.get('search') || undefined,
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
          sort_by: currentSort.id,
          sort_order: currentSort.desc ? 'desc' : 'asc',
          product_type: orderType !== 'all' ? orderType : undefined,
        };

        const response = await getOrders(filters);

        setOrders(
          response.orders.map((order) => ({
            id: order.id,
            uuid: order.uuid,
            user_email: order.user_email || order.user_primary_email || 'N/A',
            product_name: order.product_name,
            product_type: order.product_type,
            purchase_date: new Date(order.purchase_date).toISOString(),
            purchase_price: parseFloat(order.purchase_price),
            status: order.status?.toLowerCase() || 'unknown',
          })),
        );

        setPagination((prev) => ({
          ...prev,
          pageCount: response.pagination.pageCount,
          total: response.pagination.total,
        }));
      } catch (error) {
        console.error('Error fetching orders:', error);
        toast({
          title: 'Error fetching orders',
          description: 'Unable to load orders. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [
    orderType,
    status,
    dateRange,
    searchParams,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    toast,
  ]);

  // Table configuration
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const paginationBorderColor = useColorModeValue('gray.200', 'white');
  const buttonBorderColor = useColorModeValue('gray.200', 'white');
  const columnHelper = createColumnHelper();

  // Calculate active filters count
  const activeFiltersCount = [
    orderType !== 'all',
    status !== 'all',
    dateRange.start,
    dateRange.end,
  ].filter(Boolean).length;

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        id: 'id',
        header: () => (
          <Text
            justifyContent="center"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            ID
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="md" fontWeight="500">
            #{info.getValue()}
          </Text>
        ),
        size: 50,
        maxWidth: 50,
      }),
      columnHelper.accessor('user_email', {
        id: 'user_email',
        header: () => (
          <Text
            justifyContent="center"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            Customer
          </Text>
        ),
        cell: (info) => (
          <Text
            color={textColor}
            fontSize="md"
            fontWeight="500"
            noOfLines={1}
            title={info.getValue()}
          >
            {info.getValue()}
          </Text>
        ),
        size: 200,
        maxWidth: 200,
      }),
      columnHelper.accessor('product_name', {
        id: 'product_name',
        header: () => (
          <Text
            justifyContent="center"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            Product
          </Text>
        ),
        cell: (info) => (
          <Text
            color={textColor}
            fontSize="md"
            fontWeight="500"
            noOfLines={1}
            title={info.getValue()}
          >
            {info.getValue()}
          </Text>
        ),
        size: 200,
        maxWidth: 250,
      }),
      columnHelper.accessor('purchase_date', {
        id: 'purchase_date',
        header: () => (
          <Text
            justifyContent="center"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            Purchase Date
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {format(new Date(info.getValue()), 'MMM dd, yyyy')}
          </Text>
        ),
        size: 120,
        maxWidth: 120,
      }),
      columnHelper.accessor('purchase_price', {
        id: 'purchase_price',
        header: () => (
          <Text
            justifyContent="center"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            Price
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="md" fontWeight="500">
            €{parseFloat(info.getValue()).toFixed(2)}
          </Text>
        ),
        size: 100,
        maxWidth: 100,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: () => (
          <Text
            justifyContent="center"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            Status
          </Text>
        ),
        cell: (info) => (
          <Flex justify="center">
            <Badge
              colorScheme={
                {
                  complete: 'green',
                  paid: 'purple',
                  processing: 'yellow',
                  initiated: 'cyan',
                  failed: 'red',
                }[info.getValue()] || 'gray'
              }
              fontSize="sm"
              px={2}
              py={1}
              borderRadius="full"
            >
              {info.getValue().charAt(0).toUpperCase() +
                info.getValue().slice(1)}
            </Badge>
          </Flex>
        ),
        size: 100,
        maxWidth: 100,
      }),
      columnHelper.accessor('uuid', {
        id: 'actions',
        header: () => (
          <Text
            justifyContent="center"
            align="center"
            fontSize={{ sm: '10px', lg: '12px' }}
            color="gray.400"
          >
            Actions
          </Text>
        ),
        cell: (info) => {
          const orderUrl = `/admin/orders/${info.getValue()}`;

          return (
            <HStack spacing={3} justify="center" width="100%">
              <Box
                as="a"
                href={orderUrl}
                onClick={(e) => {
                  if (e.button === 0) {
                    e.preventDefault();
                    navigate(orderUrl);
                  }
                }}
                display="inline-block"
              >
                <Icon
                  w="20px"
                  h="20px"
                  as={MdEdit}
                  cursor="pointer"
                  color={brandColor}
                />
              </Box>
            </HStack>
          );
        },
        size: 50,
        maxWidth: 50,
      }),
    ],
    [columnHelper, navigate, textColor, brandColor],
  );

  const table = useReactTable({
    data: orders,
    columns,
    state: { sorting, pagination },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualPagination: true,
    pageCount: pagination.pageCount,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // Handlers for the OrdersFilter drawer
  const handleClearFilters = () => {
    setOrderType('all');
    setStatus('all');
    setDateRange({ start: '', end: '' });
    setSearchText('');
    updateUrlParams({
      product_type: undefined,
      status: undefined,
      start_date: undefined,
      end_date: undefined,
      search: undefined,
      page: 1,
    });
  };

  const handleApplyFilters = () => {
    updateUrlParams({
      product_type: orderType !== 'all' ? orderType : undefined,
      status: status !== 'all' ? status : undefined,
      start_date: dateRange.start || undefined,
      end_date: dateRange.end || undefined,
      page: 1,
    });
    onClose();
  };

  return (
    <Flex direction="column" mt={{ sm: '150px', md: '125px', lg: '75px' }}>
      <Card
        w="100%"
        px={{ base: '16px', md: '24px' }}
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify="space-between"
          mb="36px"
          mt="24px"
        >
          <DebouncedInput
            placeholder="Search orders"
            onChange={(value) => {
              setSearchText(value);
              debouncedSearch(value);
            }}
            defaultValue={searchText}
          />

          <Button
            leftIcon={<FiFilter />}
            onClick={onOpen}
            colorScheme={activeFiltersCount > 0 ? 'blue' : 'gray'}
            variant={activeFiltersCount > 0 ? 'solid' : 'outline'}
          >
            Filter
            {activeFiltersCount > 0 && (
              <Badge ml={2} colorScheme="blue" borderRadius="full">
                {activeFiltersCount}
              </Badge>
            )}
          </Button>
        </Flex>

        {loading ? (
          <Flex justify="center" align="center" h="200px">
            <Spinner size="xl" />
          </Flex>
        ) : orders.length > 0 ? (
          <>
            <Table variant="simple" color="gray.500">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        borderColor={borderColor}
                        key={header.id}
                        colSpan={header.colSpan}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {header.isPlaceholder ? null : (
                          <Flex
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                            justify="center"
                            align="center"
                            fontSize={{ sm: '10px', lg: '12px' }}
                            color="gray.400"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted()] ?? null}
                          </Flex>
                        )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: '14px' }}
                        borderColor={borderColor}
                        textAlign="center"
                        py={4}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>

            <Flex
              w="100%"
              justify="space-between"
              align="center"
              px="20px"
              py="24px"
            >
              <Text fontSize="sm" color="gray.500" fontWeight="normal">
                Showing {pagination.pageSize * pagination.pageIndex + 1} to{' '}
                {pagination.pageSize * (pagination.pageIndex + 1) <=
                pagination.total
                  ? pagination.pageSize * (pagination.pageIndex + 1)
                  : pagination.total}{' '}
                of {pagination.total} entries
              </Text>
              <Stack direction="row" spacing="4px" ms="auto">
                <Button
                  variant="no-effects"
                  onClick={() => {
                    table.previousPage();
                    updateUrlParams({ page: pagination.pageIndex });
                  }}
                  disabled={!table.getCanPreviousPage()}
                  transition="all .5s ease"
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                  borderColor={buttonBorderColor}
                  display={table.getCanPreviousPage() ? 'flex' : 'none'}
                  _hover={{
                    bg: 'whiteAlpha.100',
                    opacity: '0.7',
                  }}
                >
                  <Icon
                    as={MdChevronLeft}
                    w="16px"
                    h="16px"
                    color={textColor}
                  />
                </Button>
                {createPages(table.getPageCount()).map((pageNumber, index) => (
                  <Button
                    variant="no-effects"
                    transition="all .5s ease"
                    onClick={() => {
                      table.setPageIndex(pageNumber - 1);
                      updateUrlParams({ page: pageNumber });
                    }}
                    w="40px"
                    h="40px"
                    borderRadius="50%"
                    bg={
                      pageNumber === pagination.pageIndex + 1
                        ? brandColor
                        : 'transparent'
                    }
                    border={
                      pageNumber === pagination.pageIndex + 1
                        ? 'none'
                        : '1px solid lightgray'
                    }
                    _hover={
                      pageNumber === pagination.pageIndex + 1
                        ? { opacity: '0.7' }
                        : { bg: 'whiteAlpha.100' }
                    }
                    key={index}
                  >
                    <Text
                      fontSize="sm"
                      color={
                        pageNumber === pagination.pageIndex + 1
                          ? '#fff'
                          : textColor
                      }
                    >
                      {pageNumber}
                    </Text>
                  </Button>
                ))}
                <Button
                  variant="no-effects"
                  onClick={() => {
                    table.nextPage();
                    updateUrlParams({ page: pagination.pageIndex + 2 });
                  }}
                  disabled={!table.getCanNextPage()}
                  transition="all .5s ease"
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                  bg="transparent"
                  border="1px solid"
                  borderColor={paginationBorderColor}
                  display={table.getCanNextPage() ? 'flex' : 'none'}
                  _hover={{
                    bg: 'whiteAlpha.100',
                    opacity: '0.7',
                  }}
                >
                  <Icon
                    as={MdChevronRight}
                    w="16px"
                    h="16px"
                    color={textColor}
                  />
                </Button>
              </Stack>
            </Flex>
          </>
        ) : (
          <Flex justify="center" align="center" py={10}>
            <Text color="gray.500">No orders found</Text>
          </Flex>
        )}
      </Card>

      {/* OrdersFilter Drawer */}
      <OrdersFilter
        isOpen={isOpen}
        onClose={onClose}
        orderType={orderType}
        setOrderType={setOrderType}
        status={status}
        setStatus={setStatus}
        dateRange={dateRange}
        setDateRange={setDateRange}
        onClear={handleClearFilters}
        onApply={handleApplyFilters}
      />
    </Flex>
  );
}
