import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';
import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { FaEthereum } from 'react-icons/fa';
import { MdNotificationsNone, MdNotificationAdd } from 'react-icons/md';
import Configurator from 'components/navbar/Configurator';
import routes from 'routes';
import { ItemContent } from 'components/menu/ItemContent';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import * as CookieConsent from 'vanilla-cookieconsent';
import decodeCookie from 'utils/decodeCookie';
import cookie from 'cookie';
import { useNavigate } from 'react-router-dom';
import { getLoggedInUserDetails } from 'api/user'; // Adjust the import path as necessary
import CustomAvatar from 'components/CustomAvatar'; // Import your CustomAvatar component
import { getUserNotifications } from 'utils/notificationUtils';
import { markNotificationAsRead } from 'utils/notificationUtils';
import { getProductDetails } from 'api/product';
import { getLoyaltyPrograms } from 'api/loyalty';
import { useDisclosure } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { fetchCompanies, setCompanyCookie } from 'services/companyService';

export default function HeaderLinks(props) {
  const { hasRole } = useContext(AuthContext);
  const hasAdminAccess = hasRole('SUPER_ADMIN') || hasRole('COMPANY_ADMIN');

  const { secondary, theme, setTheme } = props;
  const { logout } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState(null); // State to store user details
  const [notifications, setNotifications] = useState([]); // State to store user notifications
  const navigate = useNavigate();
  const [companies, setCompanies] = React.useState([]);

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    const token = Cookies.get('token');
    const currentCompany = Cookies.get('companyUuid');
    try {
      const companies = await fetchCompanies(token);
      const companiesExcludingSelected = companies.filter(
        (company) => company.uuid !== currentCompany,
      );
      setCompanies(companiesExcludingSelected);
    } catch (error) {
      console.error('Error fetching companies', error);
    }
  };

  const handleCompanySelect = async (companyId) => {
    try {
      const selected = companies.find((company) => company.uuid === companyId);
      setCompanyCookie(selected);
      navigate('/');
    } catch (error) {
      console.error('Error switching company:', error);
    }
  };

  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const handleShowPreferences = () => {
    CookieConsent.showPreferences();
  };

  const savedCookies = cookie.parse(document.cookie);
  const companyCookie = savedCookies['x-company'];

  const decodedCookie = decodeCookie(companyCookie);

  // Fetch user details on component mount
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getLoggedInUserDetails();
        setUser(userData);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    clearInterval();
    const fetchUserNotifications = async () => {
      try {
        const userNotifications = await getUserNotifications();
        setNotifications(userNotifications);
      } catch (error) {
        console.error('Error fetching user notifications:', error);
      }
    };

    fetchUserNotifications();

    const intervalId = setInterval(fetchUserNotifications, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const handleNotificationClick = async (notification) => {
    await markNotificationAsRead(notification.id);
    const updatedNotifications = notifications.filter(
      (n) => n.id !== notification.id,
    );
    setNotifications(updatedNotifications);

    onClose();

    // Add your navigation logic here
    switch (notification.type) {
      case 'product_sold_out':
        const product = await getProductDetails(notification.product_uuid);

        if (product) {
          if (product.loyalty_level_uuid) {
            const loyaltyPrograms = await getLoyaltyPrograms();
            const loyaltyProgram = loyaltyPrograms.find(
              (program) => program.uuid === product.loyalty_program_uuid,
            );

            navigate(`/admin/loyalty-programs/${loyaltyProgram.uuid}`);
          } else {
            navigate(`/admin/products/edit/${notification.product_uuid}`);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      for (const notification of notifications) {
        await markNotificationAsRead(notification.id);
      }
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
    setNotifications([]);
  };

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      px="10px"
      py="5px"
      borderRadius="999px"
      boxShadow={shadow}
    >
      {/*<SearchBar
        mb={() => {
          if (secondary) {
            return { base: '10px', md: 'unset' };
          }
          return 'unset';
        }}
        me="10px"
        borderRadius="20px"
      />*/}
      <Flex
        bg={ethBg}
        display={secondary ? 'flex' : 'none'}
        borderRadius="20px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      >
        <Flex
          align="center"
          justify="center"
          bg={ethBox}
          h="29px"
          w="29px"
          borderRadius="20px"
          me="7px"
        >
          <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
        </Flex>
        <Text
          w="max-content"
          color={ethColor}
          fontSize="sm"
          fontWeight="700"
          me="6px"
        >
          1,924
          <Text as="span" display={{ base: 'none', md: 'unset' }}>
            {' '}
            ETH
          </Text>
        </Text>
      </Flex>
      <SidebarResponsive routes={routes} />

      {/* Notifications Menu */}
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton p="0px" onClick={onOpen}>
          <Icon
            mt="6px"
            as={notifications.length ? MdNotificationAdd : MdNotificationsNone}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: '30px', md: 'unset' }}
          minW={{ base: 'unset', md: '400px', xl: '450px' }}
          maxW={{ base: '360px', md: 'unset' }}
        >
          <Flex w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
              onClick={handleMarkAllAsRead}
            >
              Mark all read
            </Text>
          </Flex>
          <Flex flexDirection="column">
            {notifications.length ? (
              notifications?.map((notification) => (
                <ItemContent
                  key={notification.id}
                  label={notification.label}
                  description={notification.description}
                  created_at={notification.created_at}
                  onClick={() => handleNotificationClick(notification)}
                />
              ))
            ) : (
              <Text fontSize="sm" color={textColor}>
                No new notifications
              </Text>
            )}
          </Flex>
        </MenuList>
      </Menu>

      {/* Info Menu */}
      {/*<Menu>*/}
      {/*  <MenuButton p="0px">*/}
      {/*    <Icon*/}
      {/*      mt="6px"*/}
      {/*      as={MdInfoOutline}*/}
      {/*      color={navbarIcon}*/}
      {/*      w="18px"*/}
      {/*      h="18px"*/}
      {/*      me="10px"*/}
      {/*    />*/}
      {/*  </MenuButton>*/}
      {/*  <MenuList*/}
      {/*    boxShadow={shadow}*/}
      {/*    p="20px"*/}
      {/*    me={{ base: '30px', md: 'unset' }}*/}
      {/*    borderRadius="20px"*/}
      {/*    bg={menuBg}*/}
      {/*    border="none"*/}
      {/*    mt="22px"*/}
      {/*    minW={{ base: 'unset' }}*/}
      {/*    maxW={{ base: '360px', md: 'unset' }}*/}
      {/*  >*/}
      {/*    <Image src={navImage} borderRadius="16px" mb="28px" />*/}
      {/*    <Flex flexDirection="column">*/}
      {/*      <Link w="100%" href="https://horizon-ui.com/pro">*/}
      {/*        <Button w="100%" h="44px" mb="10px" variant="brand">*/}
      {/*          Buy Horizon UI PRO*/}
      {/*        </Button>*/}
      {/*      </Link>*/}
      {/*      <Link*/}
      {/*        w="100%"*/}
      {/*        href="https://horizon-ui.com/documentation/docs/introduction"*/}
      {/*      >*/}
      {/*        <Button*/}
      {/*          w="100%"*/}
      {/*          h="44px"*/}
      {/*          mb="10px"*/}
      {/*          border="1px solid"*/}
      {/*          bg="transparent"*/}
      {/*          borderColor={borderButton}*/}
      {/*        >*/}
      {/*          See Documentation*/}
      {/*        </Button>*/}
      {/*      </Link>*/}
      {/*      <Link*/}
      {/*        w="100%"*/}
      {/*        href="https://github.com/horizon-ui/horizon-ui-chakra-ts"*/}
      {/*      >*/}
      {/*        <Button*/}
      {/*          w="100%"*/}
      {/*          h="44px"*/}
      {/*          variant="no-hover"*/}
      {/*          color={textColor}*/}
      {/*          bg="transparent"*/}
      {/*        >*/}
      {/*          Try Horizon Free*/}
      {/*        </Button>*/}
      {/*      </Link>*/}
      {/*    </Flex>*/}
      {/*  </MenuList>*/}
      {/*</Menu>*/}

      {/* Configurator */}
      <Configurator
        mini={props.mini}
        setMini={props.setMini}
        theme={theme}
        setTheme={setTheme}
      />

      {/* User Avatar Menu */}
      <Menu>
        <MenuButton p="0px">
          {/* Use CustomAvatar for better error handling */}
          <CustomAvatar
            _hover={{ cursor: 'pointer' }}
            name={user?.full_name || 'User'}
            src={user?.avatar_url}
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="10px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              pe="20px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              {decodedCookie?.name}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            {companies.length > 1 && (
              <Accordion allowToggle>
                <AccordionItem border="none">
                  <AccordionButton>
                    <Box
                      flex="1"
                      textAlign="left"
                      fontSize="sm"
                      color={textColor}
                    >
                      Switch Company
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4} fontSize="sm">
                    {companies?.map((company) => (
                      <MenuItem
                        key={company.uuid}
                        onClick={() => handleCompanySelect(company.uuid)}
                      >
                        {company.name}
                      </MenuItem>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}

            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={() => {
                navigate('/admin/company/settings');
              }}
            >
              <Text fontSize="sm">Company Settings</Text>
            </MenuItem>

            {hasAdminAccess && (
              <MenuItem
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                borderRadius="8px"
                px="14px"
                onClick={() => {
                  navigate('/admin/subscription');
                }}
              >
                <Text fontSize="sm">Manage Subscription</Text>
              </MenuItem>
            )}

            {hasAdminAccess && (
              <MenuItem
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                borderRadius="8px"
                px="14px"
                onClick={() => {
                  navigate('/admin/payouts');
                }}
              >
                <Text fontSize="sm">Manage Payouts</Text>
              </MenuItem>
            )}

            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={() => {
                navigate('/admin/main/profile/settings');
              }}
            >
              <Text fontSize="sm">Profile Settings</Text>
            </MenuItem>

            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={handleShowPreferences}
            >
              <Text fontSize="sm">Manage cookie policy</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={logout}
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
