import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Button,
  useToast,
  Text,
  Badge,
  Spinner,
  Select,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Icon,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Container,
  HStack,
  VStack,
  IconButton,
} from '@chakra-ui/react';
import { MdArrowBack, MdComment, MdSend, MdLockOutline } from 'react-icons/md';
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getOrderDetails,
  updateOrderStatus,
  addOrderComment,
  getOrderStatusLogs,
} from '../../api/order';
import { getProductDetails } from '../../api/product';
import { getUserDetails } from '../../api/user';
import { format } from 'date-fns';
import { AuthContext } from 'contexts/AuthContext';

export default function OrderDetail() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [orderDetails, setOrderDetails] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [comment, setComment] = useState('');
  const [statusLogs, setStatusLogs] = useState([]);
  const [loadingLogs, setLoadingLogs] = useState(true);

  const { userRole } = useContext(AuthContext);
  const isViewer = userRole === 'VIEWER';

  // Fetch the order details
  useEffect(() => {
    const fetchData = async () => {
      try {
        // First fetch order details
        const order = await getOrderDetails(uuid);
        setOrderDetails(order);

        // Then fetch related data in parallel
        const [product, user, logs] = await Promise.all([
          getProductDetails(order.product_uuid),
          getUserDetails(order.user_uuid),
          getOrderStatusLogs(uuid),
        ]);

        setProductImage(product.images[0]?.image_url || null);
        setUserDetails(user);
        setStatusLogs(logs);
      } catch (error) {
        console.error('Error fetching order details:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch order details.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
        setLoadingLogs(false);
      }
    };

    fetchData();
  }, [uuid, toast]);

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    if (newStatus === orderDetails.status) return; // Don't update if status hasn't changed

    setUpdatingStatus(true);
    try {
      await updateOrderStatus(uuid, newStatus, comment);
      setOrderDetails((prevDetails) => ({
        ...prevDetails,
        status: newStatus,
      }));

      // Refresh status logs
      const logs = await getOrderStatusLogs(uuid);
      setStatusLogs(logs);

      setComment(''); // Clear comment after successful update

      toast({
        title: 'Success',
        description: `Order status updated to ${newStatus}.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating order status:', error);
      toast({
        title: 'Error',
        description: 'Failed to update order status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setUpdatingStatus(false);
    }
  };

  const handleAddComment = async () => {
    if (!comment.trim()) return;

    try {
      await addOrderComment(uuid, comment);

      // Refresh status logs
      const logs = await getOrderStatusLogs(uuid);
      setStatusLogs(logs);

      setComment(''); // Clear comment

      toast({
        title: 'Success',
        description: 'Comment added successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding comment:', error);
      toast({
        title: 'Error',
        description: 'Failed to add comment.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getStatusColor = (status) => {
    const statusColors = {
      complete: 'green',
      paid: 'purple',
      processing: 'yellow',
      initiated: 'cyan',
      failed: 'red',
    };
    return statusColors[status] || 'gray';
  };

  if (loading) {
    return (
      <Flex
        justify="center"
        align="center"
        h="100vh"
        pt={{ base: '130px', md: '80px', xl: '80px' }}
      >
        <Spinner size="xl" thickness="4px" color="blue.500" />
      </Flex>
    );
  }

  if (!orderDetails) {
    return (
      <Container centerContent pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Card p={8} textAlign="center" w="100%">
          <Icon
            as={MdComment}
            boxSize="50px"
            color="gray.300"
            mx="auto"
            mb={4}
          />
          <Heading size="md" mb={3}>
            No Order Found
          </Heading>
          <Text color="gray.500">The order details could not be found.</Text>
          <Button
            mt={6}
            colorScheme="blue"
            onClick={() => navigate('/admin/orders')}
          >
            Go To Orders
          </Button>
        </Card>
      </Container>
    );
  }

  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      px={{ base: 4, md: 8 }}
      maxW="1600px"
      mx="auto"
    >
      {/* Header with back button */}
      <HStack spacing={3} mb={6}>
        <IconButton
          icon={<MdArrowBack />}
          variant="outline"
          rounded="full"
          onClick={() => navigate('/admin/orders')}
          aria-label="Back to orders"
        />
        <Heading size="lg">Order Details</Heading>
        <Badge
          colorScheme={getStatusColor(orderDetails.status)}
          variant="solid"
          fontSize="md"
          px={3}
          py={1}
          borderRadius="full"
          ml={2}
        >
          {orderDetails.status?.charAt(0).toUpperCase() +
            orderDetails.status?.slice(1)}
        </Badge>
      </HStack>

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} mb={8}>
        {/* Order Information Card */}
        <Card
          variant="outline"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          overflow="hidden"
          height="fit-content"
        >
          <CardHeader bg="blue.50" py={4}>
            <Heading size="md">Order Information</Heading>
          </CardHeader>
          <CardBody px={6} pt={4} pb={5}>
            {/* Two-column grid layout for order details */}
            <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4}>
              {/* Order ID */}
              <Box>
                <Text color="gray.500" fontSize="sm" mb={1}>
                  Order ID
                </Text>
                <Text fontSize="lg" fontWeight="bold">
                  #{orderDetails.id}
                </Text>
              </Box>

              {/* Purchase Date */}
              <Box>
                <Text color="gray.500" fontSize="sm" mb={1}>
                  Purchase Date
                </Text>
                <Text fontSize="md">
                  {format(
                    new Date(orderDetails.purchase_date),
                    'MMM dd, yyyy, h:mm a',
                  )}
                </Text>
              </Box>
            </SimpleGrid>

            <Divider my={3} borderColor="gray.200" />

            {/* Customer Information */}
            <Box>
              <Text color="gray.500" fontSize="sm" mb={1}>
                Customer
              </Text>
              <VStack spacing={1} align="flex-start">
                <Text fontSize="lg" fontWeight="bold">
                  {userDetails
                    ? userDetails.full_name || userDetails.email
                    : 'Loading...'}
                </Text>
                {userDetails?.email && (
                  <Text fontSize="sm" color="gray.600">
                    {userDetails.email}
                  </Text>
                )}
              </VStack>
            </Box>

            <Divider my={3} borderColor="gray.200" />

            {/* Product and Price in two columns */}
            <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4}>
              {/* Product */}
              <Box>
                <Text color="gray.500" fontSize="sm" mb={1}>
                  Product
                </Text>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  noOfLines={2}
                  title={orderDetails.product_name}
                >
                  {orderDetails.product_name}
                </Text>
              </Box>

              {/* Price */}
              <Box>
                <Text color="gray.500" fontSize="sm" mb={1}>
                  Price
                </Text>
                <Text fontSize="lg" fontWeight="bold" color="green.600">
                  €{orderDetails.purchase_price.toFixed(2)}
                </Text>
              </Box>
            </SimpleGrid>

            <Divider my={4} borderColor="gray.200" />

            {/* Status Update Section - Full Width */}
            {/* Status Update Box */}
            {!isViewer ? (
              <Box p={1}>
                <Text fontWeight="medium" mb={3}>
                  Update Status
                </Text>
                <Select
                  value={orderDetails.status}
                  onChange={handleStatusChange}
                  isDisabled={updatingStatus}
                  mb={3}
                  size="md"
                  borderColor={getStatusColor(orderDetails.status)}
                  borderWidth="2px"
                  focusBorderColor={getStatusColor(orderDetails.status)}
                  bg={`${getStatusColor(orderDetails.status)}.50`}
                  _hover={{
                    borderColor: `${getStatusColor(orderDetails.status)}.600`,
                  }}
                >
                  <option
                    value="initiated"
                    style={{ backgroundColor: '#E6FFFA' }}
                  >
                    Initiated
                  </option>
                  <option value="paid" style={{ backgroundColor: '#FAF5FF' }}>
                    Paid
                  </option>
                  <option
                    value="processing"
                    style={{ backgroundColor: '#FFFDE7' }}
                  >
                    Processing
                  </option>
                  <option
                    value="complete"
                    style={{ backgroundColor: '#F0FFF4' }}
                  >
                    Complete
                  </option>
                  <option value="failed" style={{ backgroundColor: '#FFF5F5' }}>
                    Failed
                  </option>
                </Select>

                <Textarea
                  placeholder="Add a comment about this status change (optional)"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  size="md"
                  height="80px"
                  resize="vertical"
                />
              </Box>
            ) : (
              <Box
                p={4}
                bg="gray.50"
                borderRadius="md"
                borderWidth="1px"
                borderColor="gray.200"
                display="flex"
                alignItems="center"
              >
                <Icon as={MdLockOutline} mr={3} color="gray.500" boxSize={5} />
                <Text color="gray.600" fontSize="sm">
                  You have view-only access. Status updates are restricted.
                </Text>
              </Box>
            )}
          </CardBody>
        </Card>

        {/* Product Image Card */}
        <Card
          variant="outline"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          overflow="hidden"
          height="fit-content"
          flexDirection="column"
        >
          <CardHeader bg="blue.50" py={4}>
            <Heading size="md">Product Image</Heading>
          </CardHeader>
          <CardBody p={0}>
            {productImage ? (
              <Image
                src={productImage}
                alt={orderDetails.product_name}
                objectFit="contain"
                w="100%"
                h="350px"
                cursor="pointer"
                onClick={onOpen}
              />
            ) : (
              <Flex
                justify="center"
                align="center"
                bg="gray.100"
                h="350px"
                w="100%"
              >
                <VStack spacing={2}>
                  <Icon as={MdComment} boxSize="40px" color="gray.300" />
                  <Text color="gray.500">No image available</Text>
                </VStack>
              </Flex>
            )}

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
              <ModalOverlay backdropFilter="blur(3px)" />
              <ModalContent>
                <ModalCloseButton zIndex={2} />
                <ModalBody p={0}>
                  <Image
                    src={productImage}
                    alt={orderDetails.product_name}
                    w="100%"
                    borderRadius="md"
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </CardBody>
        </Card>
      </SimpleGrid>

      {/* Add Comment Section */}
      {!isViewer ? (
        <Card
          variant="outline"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          overflow="hidden"
          mb={8}
        >
          <CardHeader bg="blue.50" py={4}>
            <Heading size="md">Add Comment</Heading>
          </CardHeader>
          <CardBody px={6} py={5}>
            <Textarea
              placeholder="Add a comment about this order..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              size="md"
              height="120px"
              resize="vertical"
              mb={4}
            />
            <Button
              colorScheme="blue"
              onClick={handleAddComment}
              isDisabled={!comment.trim()}
              size="md"
              width={{ base: '100%', md: 'auto' }}
              rightIcon={<MdSend />}
            >
              Add Comment
            </Button>
          </CardBody>
        </Card>
      ) : null}

      {/* Status and Comments Log */}
      <Card
        variant="outline"
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="md"
        overflow="hidden"
        mb={8}
      >
        <CardHeader bg="blue.50" py={4}>
          <Heading size="md">Status History & Comments</Heading>
        </CardHeader>
        <CardBody px={0} py={0}>
          {loadingLogs ? (
            <Flex justify="center" py={8}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                color="blue.500"
                size="lg"
              />
            </Flex>
          ) : statusLogs.length > 0 ? (
            <Box overflowX="auto">
              <Table variant="striped" size="md" colorScheme="gray">
                <Thead bg="gray.50">
                  <Tr>
                    <Th width="20%">Date</Th>
                    <Th width="20%">User</Th>
                    <Th width="20%">Status Change</Th>
                    <Th width="40%">Comment</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {statusLogs.map((log) => (
                    <Tr key={log.id}>
                      <Td fontWeight="medium">
                        {format(new Date(log.updated_at), 'MMM dd, yyyy HH:mm')}
                      </Td>
                      <Td>{log.user_name || 'Unknown User'}</Td>
                      <Td>
                        {log.status_from !== log.status_to ? (
                          <Flex align="center">
                            <Badge
                              colorScheme={getStatusColor(log.status_from)}
                              variant="solid"
                              px={2}
                              py={0.5}
                            >
                              {log.status_from}
                            </Badge>
                            <Icon
                              as={MdArrowBack}
                              transform="rotate(180deg)"
                              mx={2}
                            />
                            <Badge
                              colorScheme={getStatusColor(log.status_to)}
                              variant="solid"
                              px={2}
                              py={0.5}
                            >
                              {log.status_to}
                            </Badge>
                          </Flex>
                        ) : (
                          <Badge
                            colorScheme={getStatusColor(log.status_from)}
                            variant="solid"
                            px={2}
                            py={0.5}
                          >
                            {log.status_from}
                          </Badge>
                        )}
                      </Td>
                      <Td>
                        {log.comment || (
                          <Text color="gray.500" fontStyle="italic">
                            No comment
                          </Text>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          ) : (
            <Flex justify="center" align="center" py={10} direction="column">
              <Icon as={MdComment} boxSize="40px" color="gray.300" mb={3} />
              <Text color="gray.500">No status history available</Text>
            </Flex>
          )}
        </CardBody>
      </Card>
    </Box>
  );
}
