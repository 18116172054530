import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  RadioGroup,
  Radio,
  Stack,
} from '@chakra-ui/react';
import { fetchCompanies, setCompanyCookie } from 'services/companyService';

export default function CompanySelector({ token, onCompanySelected }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [companies, setCompanies] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const companies = await fetchCompanies(token);
        setCompanies(companies);
        onOpen();
      } catch (error) {
        console.error('Error fetching companies', error);
      }
    };
    getCompanies();
  }, [token, onOpen]);

  const handleCompanySelect = () => {
    const selected = companies.find(
      (company) => company.uuid === selectedCompany,
    );
    setCompanyCookie(selected);
    onCompanySelected(selected);
    onClose();
    navigate('/');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Company</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RadioGroup onChange={setSelectedCompany} value={selectedCompany}>
            <Stack>
              {companies.map((company) => (
                <Radio key={company.uuid} value={company.uuid}>
                  {company.name}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleCompanySelect}>
            Select
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
