import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Box,
  Heading,
  Button,
  Flex,
  Spinner,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import Card from '../card/Card';
import { AddIcon } from '@chakra-ui/icons';
import { FaCubes } from 'react-icons/fa';
import {
  getProductVariations,
  updateProductVariation,
  deleteProductVariation,
  getCategoryVariations,
  updateCategoryVariation,
  deleteCategoryVariation,
} from '../../api/attributesVariations';
import VariationsHeader from './variations/VariationsHeader';
import VariationsTable from './variations/VariationsTable';
import VariationsGrid from './variations/VariationsGrid';
import GenerateVariationsModal from './variations/GenerateVariationsModal';
import BulkEditDrawer from './variations/BulkEditDrawer';

import { debounce } from 'lodash';
import { getAttributeKey } from '../../utils/utils';

const ProductVariationsSection = ({
  productUuid,
  categoryId,
  availableAttributes,
  variationType,
  isViewer,
  productPrice,
  productQuantity,
  unlimitedQuantity,
}) => {
  const toast = useToast();
  const [variations, setVariations] = useState([]);
  const [loadingVariations, setLoadingVariations] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [bulkEditMode, setBulkEditMode] = useState(false);
  const [bulkValues, setBulkValues] = useState({
    price: '',
    stock: '',
    active: true,
  });
  const [editedValues, setEditedValues] = useState({});
  const cancelRef = useRef();
  const [viewMode, setViewMode] = useState('table');
  const [isCalculatingCombinations, setIsCalculatingCombinations] =
    useState(false);

  const {
    isOpen: isGenerateVariationsOpen,
    onOpen: onGenerateVariationsOpen,
    onClose: onGenerateVariationsClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteConfirmOpen,
    onOpen: onDeleteConfirmOpen,
    onClose: onDeleteConfirmClose,
  } = useDisclosure();

  const [variationsToDelete, setVariationsToDelete] = useState([]);

  const fetchVariations = useCallback(async () => {
    if (variationType === 'product' && !productUuid) return;
    if (variationType === 'category' && !categoryId) return;

    setLoadingVariations(true);
    try {
      let data;
      if (variationType === 'product') {
        data = await getProductVariations({ product_uuid: productUuid });
      } else {
        data = await getCategoryVariations({ category_uuid: categoryId });
      }
      setVariations(data);
      setEditedValues({});
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setVariations([]);
      } else {
        toast({
          title: 'Error',
          description: 'Failed to fetch variations.',
          status: 'error',
          isClosable: true,
        });
      }
    } finally {
      setLoadingVariations(false);
    }
  }, [productUuid, categoryId, variationType, toast]);

  useEffect(() => {
    fetchVariations();
  }, [fetchVariations]);

  useEffect(() => {
    if (variations.length > 0) {
      fetchVariations();
    }
  }, [
    productPrice,
    productQuantity,
    unlimitedQuantity,
    fetchVariations,
    variations.length,
  ]);

  useEffect(() => {
    if (selectAll) {
      setSelectedRows(variations.map((v) => v.uuid));
    } else if (selectedRows.length === variations.length) {
      setSelectedRows([]);
    }
  }, [selectAll, variations, selectedRows.length]);

  const handleRowSelect = (uuid) => {
    if (selectedRows.includes(uuid)) {
      setSelectedRows(selectedRows.filter((id) => id !== uuid));
    } else {
      setSelectedRows([...selectedRows, uuid]);
    }
  };

  const handleGenerateVariationsOpen = async () => {
    if (!availableAttributes || availableAttributes.length === 0) {
      toast({
        title: 'No attributes available',
        description: 'Please add attributes to the product first.',
        status: 'warning',
        isClosable: true,
      });
      return;
    }

    setIsCalculatingCombinations(true);
    try {
      onGenerateVariationsOpen();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to prepare variation generator.',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsCalculatingCombinations(false);
    }
  };

  const variationsRef = useRef([]);

  useEffect(() => {
    variationsRef.current = variations;
  }, [variations]);

  // Define the update function
  const updateVariation = useCallback(
    async (variationId, uuid, field, value) => {
      const variation = variationsRef.current.find((v) => v.uuid === uuid);
      if (!variation) return;

      const payload = {
        attribute_values_json: variation.attribute_values_json || {},
      };

      if (variationType === 'product') {
        payload.product_uuid = productUuid;
      } else {
        payload.category_uuid = categoryId;
      }

      let processedValue = value;
      if (field === 'price_override') {
        processedValue = value === '' ? null : Number(value);
        payload.price_override = processedValue;
      } else if (field === 'stock_quantity') {
        processedValue = value === '' ? null : Number(value);
        payload.stock_quantity = processedValue;
      } else if (field === 'is_active') {
        payload.is_active = value;
      }

      try {
        let updatedVariation;
        if (variationType === 'product') {
          updatedVariation = await updateProductVariation(variationId, payload);
        } else {
          // Use uuid instead of id for category variations
          updatedVariation = await updateCategoryVariation(
            variation.uuid,
            payload,
          );
        }

        setVariations((prevVariations) =>
          prevVariations.map((v) =>
            v.uuid === uuid
              ? {
                  ...v,
                  price_override: updatedVariation.price_override,
                  stock_quantity: updatedVariation.stock_quantity,
                  is_active: updatedVariation.is_active,
                  attribute_values_json:
                    Object.keys(updatedVariation.attribute_values_json || {})
                      .length > 0
                      ? updatedVariation.attribute_values_json
                      : v.attribute_values_json,
                }
              : v,
          ),
        );

        setEditedValues((prev) => {
          const newValues = { ...prev };
          delete newValues[`${uuid}-${field}`];
          return newValues;
        });
      } catch (error) {
        console.error('Update error:', error);
        toast({
          title: 'Error',
          description: `Failed to update ${field.replace('_', ' ')}.`,
          status: 'error',
          isClosable: true,
        });

        setEditedValues((prev) => {
          const updated = { ...prev };
          delete updated[`${uuid}-${field}`];
          return updated;
        });
      }
    },
    [productUuid, categoryId, variationType, toast],
  );

  // Create a debounced version that calls the update function
  const debouncedUpdateVariation = useCallback(
    (variationId, uuid, field, value) => {
      const debouncedFn = debounce(() => {
        updateVariation(variationId, uuid, field, value);
      }, 800);

      debouncedFn();

      // Return cleanup function to cancel debounce if component rerenders
      return () => debouncedFn.cancel();
    },
    [updateVariation],
  );

  const handleVariationChange = (uuid, field, value) => {
    if (isViewer) return;

    const variation = variations.find((v) => v.uuid === uuid);
    if (!variation) return;

    setEditedValues((prev) => ({
      ...prev,
      [`${uuid}-${field}`]: value,
    }));

    setVariations((prevVariations) =>
      prevVariations.map((v) =>
        v.uuid === uuid ? { ...v, [field]: value } : v,
      ),
    );

    debouncedUpdateVariation(variation.id, uuid, field, value);
  };

  const applyBulkEdit = async () => {
    if (selectedRows.length === 0) {
      toast({
        title: 'No variations selected',
        status: 'warning',
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      for (const uuid of selectedRows) {
        const variation = variations.find((v) => v.uuid === uuid);
        if (!variation) continue;

        const payload = {
          attribute_values_json: variation.attribute_values_json,
        };

        // Set the appropriate UUID based on variation type
        if (variationType === 'product') {
          payload.product_uuid = productUuid;
        } else {
          payload.category_uuid = categoryId;
        }

        if (bulkValues.resetPrice) {
          payload.price_override = Number(productPrice);
        } else if (bulkValues.price !== '') {
          payload.price_override = Number(bulkValues.price);
        }

        if (bulkValues.stock !== '') {
          payload.stock_quantity = Number(bulkValues.stock);
        }

        payload.is_active = bulkValues.active;

        // Use the appropriate API function based on variation type
        if (variationType === 'product') {
          await updateProductVariation(variation.id, payload);
        } else {
          await updateCategoryVariation(variation.id, payload);
        }
      }

      toast({
        title: 'Success',
        description: `Updated ${selectedRows.length} variations.`,
        status: 'success',
        isClosable: true,
      });

      fetchVariations();
      setBulkEditMode(false);
      setSelectedRows([]);
      setSelectAll(false);
    } catch (error) {
      console.error('Bulk edit error:', error);
      toast({
        title: 'Error',
        description: 'Failed to apply bulk updates.',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteVariations = () => {
    if (isViewer) {
      toast({
        title: 'Permission Denied',
        description: 'You do not have permission to delete variations.',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    if (selectedRows.length === 0) {
      toast({
        title: 'No variations selected',
        status: 'warning',
        isClosable: true,
      });
      return;
    }

    setVariationsToDelete(selectedRows);
    onDeleteConfirmOpen();
  };

  const confirmDeleteVariations = async () => {
    try {
      for (const uuid of variationsToDelete) {
        if (variationType === 'product') {
          await deleteProductVariation(uuid);
        } else {
          await deleteCategoryVariation(uuid);
        }
      }

      toast({
        title: 'Success',
        description: `Deleted ${variationsToDelete.length} variations.`,
        status: 'success',
        isClosable: true,
      });

      fetchVariations();
      setSelectedRows([]);
      setSelectAll(false);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete variations.',
        status: 'error',
        isClosable: true,
      });
    } finally {
      onDeleteConfirmClose();
      setVariationsToDelete([]);
    }
  };

  const filteredVariations = (() => {
    if (!availableAttributes || availableAttributes.length === 0) {
      return variations;
    }

    if (variationType !== 'category' && variationType !== 'product') {
      return variations;
    }

    const allowedKeys = availableAttributes.map(getAttributeKey);

    return variations.filter((variation) => {
      if (!variation.attribute_values_json) return false;

      const variationKeys = Object.keys(variation.attribute_values_json);

      return variationKeys.some((key) => allowedKeys.includes(key));
    });
  })();
  const syncSelectedWithProductStock = async () => {
    if (selectedRows.length === 0) {
      toast({
        title: 'No variations selected',
        status: 'warning',
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      for (const uuid of selectedRows) {
        const variation = variations.find((v) => v.uuid === uuid);
        if (!variation) continue;

        const payload = {
          product_uuid: productUuid,
          attribute_values_json: variation.attribute_values_json,
          stock_quantity: unlimitedQuantity ? null : Number(productQuantity),
        };

        // Use the correct ID for API calls
        if (variationType === 'product') {
          await updateProductVariation(variation.id, payload);
        } else {
          await updateCategoryVariation(variation.uuid, payload);
        }
      }

      const stockDescription = unlimitedQuantity
        ? 'unlimited stock (∞)'
        : `product stock (${productQuantity})`;

      toast({
        title: 'Success',
        description: `Synced ${selectedRows.length} variations with ${stockDescription}.`,
        status: 'success',
        isClosable: true,
      });

      fetchVariations();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to sync variations with product stock.',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isUsingProductStock = (variation) => {
    if (unlimitedQuantity) {
      return true; // If product has unlimited quantity, variations are synced
    }

    // For category variations, which may not have stock_quantity field at all
    if (
      variationType === 'category' &&
      (variation.stock_quantity === undefined ||
        variation.stock_quantity === null)
    ) {
      return true;
    }

    // For product variations or category variations with explicit stock quantity
    return (
      variation.stock_quantity === null ||
      Number(variation.stock_quantity) === Number(productQuantity)
    );
  };

  const syncSelectedWithProductPrice = async () => {
    if (selectedRows.length === 0) {
      toast({
        title: 'No variations selected',
        status: 'warning',
        isClosable: true,
      });
      return;
    }

    if (
      productPrice === undefined ||
      productPrice === null ||
      productPrice === ''
    ) {
      toast({
        title: 'Invalid product price',
        description: 'The main product price is not set or invalid.',
        status: 'warning',
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      for (const uuid of selectedRows) {
        const variation = variations.find((v) => v.uuid === uuid);
        if (!variation) continue;

        // Prepare the base payload
        const payload = {
          attribute_values_json: variation.attribute_values_json,
          // Explicitly set the price_override to the product's price value
          // Setting to null/undefined usually means "sync", but here the goal is to *set* the value
          price_override: Number(productPrice),
          // NOTE: You might want to set price_override to `null` if your API interprets
          // null as "use product price". Check your backend logic. If the button's
          // explicit purpose is to SET the variation price TO the product price,
          // then sending the Number(productPrice) is correct.
        };

        // Use the appropriate API call and identifier based on variation type
        if (variationType === 'product') {
          // Add product_uuid for product variations
          payload.product_uuid = productUuid;
          // Use variation.id (assuming it's the correct numeric ID)
          await updateProductVariation(variation.id, payload);
        } else {
          // variationType === 'category'
          // Add category_uuid for category variations
          payload.category_uuid = categoryId;
          // Use variation.uuid (assuming it's the correct identifier for category variations)
          await updateCategoryVariation(variation.uuid, payload);
        }
      }

      toast({
        title: 'Success',
        description: `Updated price for ${selectedRows.length} variations to match product price (€${Number(productPrice).toFixed(2)}).`, // Updated description
        status: 'success',
        isClosable: true,
      });

      fetchVariations(); // Refresh data
      // Optionally clear selection
      setSelectedRows([]);
      setSelectAll(false);
    } catch (error) {
      console.error(
        `Error syncing ${variationType} variations with product price:`,
        error,
      ); // Log specific error
      toast({
        title: 'Error',
        description: `Failed to sync ${variationType} variations with product price. ${error?.response?.data?.error || error.message || ''}`, // More specific error message
        status: 'error',
        isClosable: true,
        duration: 5000, // Longer duration for errors
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isUsingProductPrice = (variation, productPrice) => {
    // Check for null or undefined price_override (indicating sync with product)
    if (
      variation.price_override === null ||
      variation.price_override === undefined
    ) {
      return true;
    }

    // If productPrice is not set, return false
    if (
      productPrice === undefined ||
      productPrice === null ||
      productPrice === ''
    ) {
      return false;
    }

    // Compare numeric values after conversion
    return Number(variation.price_override) === Number(productPrice);
  };

  const handleOpenBulkEditDrawer = () => {
    if (selectedRows.length > 0) {
      const selectedVariations = variations.filter((v) =>
        selectedRows.includes(v.uuid),
      );

      const prices = selectedVariations.map((v) => v.price_override);
      const commonPrice = prices.every((p) => p === prices[0]) ? prices[0] : '';

      const allUnlimited = selectedVariations.every(
        (v) =>
          unlimitedQuantity &&
          (v.stock_quantity === null || v.stock_quantity === undefined),
      );

      let commonStock = '';
      if (allUnlimited) {
        commonStock = '∞';
      } else {
        const stocks = selectedVariations.map((v) => v.stock_quantity);
        commonStock = stocks.every((s) => s === stocks[0]) ? stocks[0] : '';
      }

      const allUsingProductPrice = selectedVariations.every((v) =>
        isUsingProductPrice(v, productPrice),
      );

      setBulkValues({
        price:
          commonPrice !== null && commonPrice !== undefined
            ? String(commonPrice)
            : '',
        stock:
          commonStock !== null && commonStock !== undefined
            ? String(commonStock)
            : '',
        active: true,
        resetPrice: allUsingProductPrice,
        unlimitedStock: allUnlimited,
      });
    }

    setBulkEditMode(true);
  };

  return (
    <Card mt="8" p="6">
      <VariationsHeader
        variationType={variationType}
        viewMode={viewMode}
        setViewMode={setViewMode}
        productUuid={productUuid}
        isViewer={isViewer}
        availableAttributes={availableAttributes}
        selectedRows={selectedRows}
        handleGenerateVariationsOpen={handleGenerateVariationsOpen}
        isCalculatingCombinations={isCalculatingCombinations}
        syncSelectedWithProductPrice={syncSelectedWithProductPrice}
        syncSelectedWithProductStock={syncSelectedWithProductStock}
        handleDeleteVariations={handleDeleteVariations}
        setBulkEditMode={handleOpenBulkEditDrawer}
      />

      {/* Loading, Empty state, or Content */}
      {loadingVariations ? (
        <Flex justify="center" align="center" height="300px" direction="column">
          <Spinner size="xl" mb="4" color="blue.500" />
          <Text color="gray.500">Loading variations...</Text>
        </Flex>
      ) : filteredVariations.length === 0 ? (
        <Flex
          direction="column"
          align="center"
          justify="center"
          py="12"
          px="6"
          borderWidth="1px"
          borderRadius="lg"
          borderStyle="dashed"
          borderColor="gray.300"
          bg="gray.50"
        >
          <Box fontSize="6xl" mb="4" color="gray.300">
            <FaCubes />
          </Box>
          <Heading size="md" mb="2" textAlign="center">
            No Variations Found
          </Heading>
          <Text textAlign="center" mb="6" maxW="md" color="gray.600">
            Create {variationType === 'product' ? 'product' : 'category'}{' '}
            variations to offer different options like sizes, colors, or
            materials.
          </Text>
          <Button
            colorScheme="blue"
            onClick={handleGenerateVariationsOpen}
            isDisabled={
              isViewer ||
              (variationType === 'product' && !productUuid) ||
              (variationType === 'category' && !categoryId) ||
              availableAttributes.length === 0
            }
            leftIcon={<AddIcon />}
          >
            Generate Variations
          </Button>
        </Flex>
      ) : viewMode === 'table' ? (
        <VariationsTable
          filteredVariations={filteredVariations}
          availableAttributes={availableAttributes}
          selectedRows={selectedRows}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          handleRowSelect={handleRowSelect}
          isViewer={isViewer}
          productPrice={productPrice}
          productQuantity={productQuantity}
          unlimitedQuantity={unlimitedQuantity}
          editedValues={editedValues}
          handleVariationChange={handleVariationChange}
          onDeleteConfirmOpen={onDeleteConfirmOpen}
          setVariationsToDelete={setVariationsToDelete}
          isUsingProductPrice={isUsingProductPrice}
          isUsingProductStock={isUsingProductStock}
        />
      ) : (
        <VariationsGrid
          filteredVariations={filteredVariations}
          availableAttributes={availableAttributes}
          selectedRows={selectedRows}
          handleRowSelect={handleRowSelect}
          isViewer={isViewer}
          productPrice={productPrice}
          productQuantity={productQuantity}
          unlimitedQuantity={unlimitedQuantity}
          editedValues={editedValues}
          handleVariationChange={handleVariationChange}
          onDeleteConfirmOpen={onDeleteConfirmOpen}
          setVariationsToDelete={setVariationsToDelete}
          isUsingProductPrice={isUsingProductPrice}
          isUsingProductStock={isUsingProductStock}
        />
      )}

      {/* Generate Variations Modal */}
      <GenerateVariationsModal
        isOpen={isGenerateVariationsOpen}
        onClose={onGenerateVariationsClose}
        availableAttributes={availableAttributes}
        productUuid={productUuid}
        categoryId={categoryId}
        variationType={variationType}
        productPrice={productPrice}
        productQuantity={productQuantity}
        unlimitedQuantity={unlimitedQuantity}
        onVariationsGenerated={fetchVariations}
        existingVariations={variations}
      />

      {/* Bulk Edit Drawer */}
      <BulkEditDrawer
        isOpen={bulkEditMode}
        onClose={() => setBulkEditMode(false)}
        bulkValues={bulkValues}
        setBulkValues={setBulkValues}
        selectedRowsCount={selectedRows.length}
        productPrice={productPrice}
        isSubmitting={isSubmitting}
        onApply={applyBulkEdit}
      />
      {/* Confirmation AlertDialog for deleting variation(s) */}
      <AlertDialog
        isOpen={isDeleteConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteConfirmClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Variation{variationsToDelete.length > 1 ? 's' : ''}
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete{' '}
              {variationsToDelete.length > 1
                ? `these ${variationsToDelete.length} variations`
                : 'this variation'}
              ? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteConfirmClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={confirmDeleteVariations}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
};

export default ProductVariationsSection;
