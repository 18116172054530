import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  SimpleGrid,
  Select,
  HStack,
  FormControl,
  Card,
  CardBody,
  Divider,
  Spinner,
} from '@chakra-ui/react';
import PieChart from 'components/charts/PieChart';
import BarChart from 'components/charts/BarChart';
import { format, subMonths, subYears, subDays } from 'date-fns';

export default function VoucherAnalyticsWidget({ vouchers }) {
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const [dateRange, setDateRange] = useState('lastYear');
  const [groupBy, setGroupBy] = useState('month');
  const [filteredVouchers, setFilteredVouchers] = useState([]);
  const [chartKey, setChartKey] = useState(1);

  useEffect(() => {
    if (!vouchers || vouchers.length === 0) {
      setFilteredVouchers([]);
      return;
    }

    const today = new Date();
    let startDate;

    switch (dateRange) {
      case 'last7days':
        startDate = subDays(today, 7);
        break;
      case 'lastMonth':
        startDate = subMonths(today, 1);
        break;
      case 'lastYear':
        startDate = subYears(today, 1);
        break;
      default:
        startDate = subYears(today, 5); // Show all
        break;
    }

    const filtered = vouchers.filter(
      (voucher) => new Date(voucher.created_at) >= startDate,
    );

    setFilteredVouchers(filtered);

    setChartKey((prev) => prev + 1);
  }, [vouchers, dateRange]);

  useEffect(() => {
    setChartKey((prev) => prev + 1);
  }, [groupBy]);

  const statusData = useMemo(() => {
    if (!filteredVouchers.length) return { claimed: 0, expired: 0, active: 0 };

    const now = new Date();
    let claimed = 0;
    let expired = 0;
    let active = 0;

    filteredVouchers.forEach((voucher) => {
      const isExpired = new Date(voucher.expiration_date) < now;

      if (voucher.is_claimed) {
        claimed++;
      } else if (isExpired) {
        expired++;
      } else {
        active++;
      }
    });

    return { claimed, expired, active };
  }, [filteredVouchers]);

  const hasPieData =
    statusData.active > 0 || statusData.claimed > 0 || statusData.expired > 0;

  const pieChartData = useMemo(() => {
    const nonZeroData = [];
    const statusValues = [
      { value: statusData.active, label: 'Active', color: '#3182CE' },
      { value: statusData.claimed, label: 'Claimed', color: '#68D391' },
      { value: statusData.expired, label: 'Expired', color: '#F56565' },
    ];

    statusValues.forEach((item) => {
      if (item.value > 0) {
        nonZeroData.push(item.value);
      }
    });

    return nonZeroData;
  }, [statusData]);

  const pieChartOptions = useMemo(() => {
    const nonZeroLabels = [];
    const nonZeroColors = [];

    const statusValues = [
      { value: statusData.active, label: 'Active', color: '#3182CE' },
      { value: statusData.claimed, label: 'Claimed', color: '#68D391' },
      { value: statusData.expired, label: 'Expired', color: '#F56565' },
    ];

    statusValues.forEach((item) => {
      if (item.value > 0) {
        nonZeroLabels.push(item.label);
        nonZeroColors.push(item.color);
      }
    });

    return {
      labels: nonZeroLabels,
      colors: nonZeroColors.length > 0 ? nonZeroColors : ['#3182CE'],
      chart: {
        width: '100%',
        type: 'pie',
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: true,
      },
      hover: { mode: null },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
            },
          },
        },
      },
      tooltip: {
        enabled: true,
        theme: 'dark',
      },
    };
  }, [statusData]);

  const timeSeriesData = useMemo(() => {
    if (!filteredVouchers.length) return [];

    const groupedData = {};
    const now = new Date();

    filteredVouchers.forEach((voucher) => {
      const createdDate = new Date(voucher.created_at);
      let period;

      switch (groupBy) {
        case 'day':
          period = format(createdDate, 'yyyy-MM-dd');
          break;
        case 'week':
          period = `Week ${format(createdDate, 'w, yyyy')}`;
          break;
        case 'month':
          period = format(createdDate, 'MMM yyyy');
          break;
        case 'year':
          period = format(createdDate, 'yyyy');
          break;
        default:
          period = format(createdDate, 'MMM yyyy');
      }

      if (!groupedData[period]) {
        groupedData[period] = { active: 0, claimed: 0, expired: 0 };
      }

      const isExpired = new Date(voucher.expiration_date) < now;

      if (voucher.is_claimed) {
        groupedData[period].claimed++;
      } else if (isExpired) {
        groupedData[period].expired++;
      } else {
        groupedData[period].active++;
      }
    });

    const sortedPeriods = Object.keys(groupedData).sort((a, b) => {
      return new Date(a) - new Date(b);
    });

    return [
      {
        name: 'Active',
        data: sortedPeriods.map((period) => groupedData[period].active),
        type: 'column',
      },
      {
        name: 'Claimed',
        data: sortedPeriods.map((period) => groupedData[period].claimed),
        type: 'column',
      },
      {
        name: 'Expired',
        data: sortedPeriods.map((period) => groupedData[period].expired),
        type: 'column',
      },
    ];
  }, [filteredVouchers, groupBy]);

  const barChartOptions = useMemo(() => {
    if (!filteredVouchers.length) return {};

    const groupedData = {};
    filteredVouchers.forEach((voucher) => {
      const createdDate = new Date(voucher.created_at);
      let period;
      switch (groupBy) {
        case 'day':
          period = format(createdDate, 'yyyy-MM-dd');
          break;
        case 'week':
          period = `Week ${format(createdDate, 'w, yyyy')}`;
          break;
        case 'month':
          period = format(createdDate, 'MMM yyyy');
          break;
        case 'year':
          period = format(createdDate, 'yyyy');
          break;
        default:
          period = format(createdDate, 'MMM yyyy');
      }
      if (!groupedData[period]) groupedData[period] = true;
    });

    const sortedPeriods = Object.keys(groupedData).sort((a, b) => {
      return new Date(a) - new Date(b);
    });

    return {
      chart: {
        toolbar: { show: false },
        stacked: true,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        theme: 'dark',
      },
      xaxis: {
        categories: sortedPeriods,
        type: 'category',
        labels: {
          style: {
            colors: '#A3AED0',
            fontSize: '12px',
            fontWeight: '500',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            colors: '#A3AED0',
            fontSize: '12px',
            fontWeight: '500',
          },
        },
      },
      grid: {
        borderColor: borderColor,
        strokeDashArray: 5,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        type: 'solid',
        colors: ['#3182CE', '#68D391', '#F56565'],
      },
      legend: {
        show: true,
        position: 'top',
      },
      colors: ['#3182CE', '#68D391', '#F56565'],
    };
  }, [filteredVouchers, groupBy, borderColor]);

  if (!vouchers || !filteredVouchers) {
    return (
      <Card>
        <CardBody>
          <Flex justify="center" align="center" h="300px">
            <Spinner />
          </Flex>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card
      w="100%"
      p={4}
      pb={0}
      mb={8}
      borderRadius="lg"
      boxShadow="md"
      overflow="hidden"
    >
      <Flex align="center" justify="space-between" mb={4}>
        <Text fontSize="xl" fontWeight="bold" color={textColor}>
          Voucher Analytics
        </Text>

        <HStack spacing={4}>
          <FormControl w="auto">
            <Select
              size="sm"
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
              borderRadius="md"
            >
              <option value="last7days">Last 7 Days</option>
              <option value="lastMonth">Last Month</option>
              <option value="lastYear">Last Year</option>
              <option value="all">All Time</option>
            </Select>
          </FormControl>

          <FormControl w="auto">
            <Select
              size="sm"
              value={groupBy}
              onChange={(e) => setGroupBy(e.target.value)}
              borderRadius="md"
            >
              <option value="day">By Day</option>
              <option value="week">By Week</option>
              <option value="month">By Month</option>
              <option value="year">By Year</option>
            </Select>
          </FormControl>
        </HStack>
      </Flex>

      <Divider mb={4} />

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={4} textAlign="center">
            Voucher Status Distribution
          </Text>

          <Flex justify="space-around" mb={4}>
            <Box textAlign="center">
              <Text fontSize="sm" color="gray.500">
                Active
              </Text>
              <Text fontSize="2xl" fontWeight="bold" color="blue.500">
                {statusData.active}
              </Text>
            </Box>
            <Box textAlign="center">
              <Text fontSize="sm" color="gray.500">
                Claimed
              </Text>
              <Text fontSize="2xl" fontWeight="bold" color="green.500">
                {statusData.claimed}
              </Text>
            </Box>
            <Box textAlign="center">
              <Text fontSize="sm" color="gray.500">
                Expired
              </Text>
              <Text fontSize="2xl" fontWeight="bold" color="red.500">
                {statusData.expired}
              </Text>
            </Box>
          </Flex>

          {hasPieData ? (
            <Box h="300px" key={`pie-${chartKey}`}>
              <PieChart
                chartData={pieChartData}
                chartOptions={pieChartOptions}
              />
            </Box>
          ) : (
            <Flex justify="center" align="center" h="200px">
              <Text color="gray.500">No voucher data available for chart</Text>
            </Flex>
          )}
        </Box>

        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={4} textAlign="center">
            Voucher Status Over Time
          </Text>

          <Box h="300px" key={`bar-${chartKey}`}>
            <BarChart
              chartData={timeSeriesData}
              chartOptions={barChartOptions}
            />
          </Box>
        </Box>
      </SimpleGrid>
    </Card>
  );
}
