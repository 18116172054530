import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  VStack,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftAddon,
  Switch,
  Flex,
} from '@chakra-ui/react';

const BulkEditDrawer = ({
  isOpen,
  onClose,
  bulkValues,
  setBulkValues,
  selectedRowsCount,
  productPrice,
  isSubmitting,
  onApply,
}) => {
  const handleUnlimitedStockToggle = (e) => {
    const isUnlimited = e.target.checked;
    setBulkValues({
      ...bulkValues,
      unlimitedStock: isUnlimited,
      stock: isUnlimited ? '∞' : '',
    });
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Bulk Edit {selectedRowsCount} Variations
        </DrawerHeader>

        <DrawerBody>
          <VStack spacing="5" mt="4">
            <FormControl>
              <FormLabel>Price</FormLabel>
              <InputGroup>
                <InputLeftAddon>$</InputLeftAddon>
                <Input
                  placeholder="Set price for all selected"
                  value={bulkValues.price}
                  onChange={(e) =>
                    setBulkValues({
                      ...bulkValues,
                      price: e.target.value,
                      resetPrice: false,
                    })
                  }
                  type="number"
                />
              </InputGroup>
              <FormHelperText>
                Leave empty to keep current values
              </FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel>Stock</FormLabel>
              <Input
                placeholder="Set stock for all selected"
                value={bulkValues.stock}
                onChange={(e) =>
                  setBulkValues({
                    ...bulkValues,
                    stock: e.target.value,
                    unlimitedStock: false,
                  })
                }
                type={bulkValues.unlimitedStock ? 'text' : 'number'}
                isReadOnly={bulkValues.unlimitedStock}
              />
              <FormHelperText>
                Leave empty to keep current values
              </FormHelperText>
            </FormControl>

            <FormControl mt={1}>
              <Flex align="center">
                <Switch
                  id="unlimitedStock"
                  isChecked={bulkValues.unlimitedStock}
                  onChange={handleUnlimitedStockToggle}
                  colorScheme="green"
                  mr={3}
                />
                <FormLabel htmlFor="unlimitedStock" mb={0}>
                  Unlimited Stock (∞)
                </FormLabel>
              </Flex>
              <FormHelperText pl={10}>
                Set unlimited stock quantity for all selected variations
              </FormHelperText>
            </FormControl>

            <FormControl mt={3}>
              <Flex align="center">
                <Switch
                  id="resetPrice"
                  isChecked={bulkValues.resetPrice}
                  onChange={(e) => {
                    setBulkValues({
                      ...bulkValues,
                      resetPrice: e.target.checked,
                      price: e.target.checked ? '' : bulkValues.price,
                    });
                  }}
                  colorScheme="blue"
                  mr={3}
                />
                <FormLabel htmlFor="resetPrice" mb={0}>
                  Use product price (clear price override)
                </FormLabel>
              </Flex>
              <FormHelperText pl={10}>
                Reset variations to use the main product price ({productPrice})
              </FormHelperText>
            </FormControl>
          </VStack>
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={onApply} isLoading={isSubmitting}>
            Apply Changes
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default BulkEditDrawer;
