import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAutomation } from '../../contexts/AutomationContext';
import {
  Button,
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
  Tooltip,
  Box,
  IconButton,
  Heading,
} from '@chakra-ui/react';
import { AddIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  MdEdit,
  MdStar,
  MdEmojiEvents,
  MdCardGiftcard,
  MdAccessTime,
  MdGroup,
  MdAdsClick,
  MdMilitaryTech,
  MdBolt,
  MdVisibility,
} from 'react-icons/md';

import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  createLoyaltyAutomation,
  fetchAutomations,
} from '../../api/automation';
import CreateAutomationModal from 'components/automation/CreateAutomationModal';
import { createPages } from 'utils/helpers';
import DebouncedInput from 'components/DebouncedInput';
import { AuthContext } from 'contexts/AuthContext';

const AutomationTemplates = () => {
  const scrollRef = React.useRef(null);
  const cardBg = useColorModeValue('white', 'navy.800');
  const textColorPrimary = useColorModeValue('navy.700', 'white');
  const textColorSecondary = useColorModeValue('gray.500', 'white');

  const templates = [
    {
      icon: MdStar,
      color: 'yellow.500',
      title: 'First Purchase Booster',
      description: 'Reward first-time buyers with a special discount voucher',
    },
    {
      icon: MdEmojiEvents,
      color: 'blue.500',
      title: 'Tier Progression Accelerator',
      description: 'Help customers reach higher loyalty tiers faster',
    },
    {
      icon: MdCardGiftcard,
      color: 'purple.500',
      title: 'Birthday Celebration',
      description: 'Delight customers on their special day with rewards',
    },
    {
      icon: MdAccessTime,
      color: 'green.500',
      title: 'Win-Back Campaign',
      description: 'Re-engage inactive customers with targeted incentives',
    },
    {
      icon: MdGroup,
      color: 'red.500',
      title: 'Referral Rewards',
      description: 'Encourage customer advocacy with dual rewards',
    },
    {
      icon: MdAdsClick,
      color: 'cyan.500',
      title: 'Spend Milestone Rewards',
      description: 'Celebrate customer spending milestones',
    },
    {
      icon: MdMilitaryTech,
      color: 'orange.500',
      title: 'VIP Status Unlocks',
      description: 'Provide exclusive benefits to valuable customers',
    },
    {
      icon: MdBolt,
      color: 'brand.500',
      title: 'Flash Purchase Bonus',
      description: 'Reward quick repeat purchases with bonus incentives',
    },
  ];

  const scroll = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <Box mb={2} position="relative">
      <Flex justify="space-between" align="center" mb={4}>
        <Heading size="md" color={textColorPrimary}>
          Automation Templates
        </Heading>
        <Flex>
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={() => scroll(-200)}
            mr={2}
            variant="ghost"
            aria-label="Scroll left"
          />
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={() => scroll(200)}
            variant="ghost"
            aria-label="Scroll right"
          />
        </Flex>
      </Flex>

      <Box
        ref={scrollRef}
        overflowX="auto"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
        }}
      >
        <Flex gap={4} pb={2}>
          {templates.map((template, index) => (
            <Card
              key={index}
              p={6}
              bg={cardBg}
              minW="280px"
              cursor="pointer"
              _hover={{ transform: 'translateY(-5px)', shadow: 'lg' }}
              transition="all 0.2s"
            >
              <Stack spacing={4}>
                <Icon as={template.icon} w={8} h={8} color={template.color} />
                <Text fontSize="lg" fontWeight="bold" color={textColorPrimary}>
                  {template.title}
                </Text>
                <Text color={textColorSecondary}>{template.description}</Text>
              </Stack>
            </Card>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

const calculateNextRunDate = (lastRunDate, intervalDays) => {
  const baseDate = lastRunDate ? new Date(lastRunDate) : new Date();
  const nextDate = new Date(baseDate);
  nextDate.setDate(nextDate.getDate() + intervalDays);
  return nextDate.toDateString();
};

export default function AutomationsOverview() {
  const [automations, setAutomations] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { userRole } = useContext(AuthContext);
  const isViewer = userRole === 'VIEWER';

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const navigate = useNavigate();

  const { setFlowName, setFlowDescription, setAutomationUuid } =
    useAutomation();

  const handleViewDetails = (automationId) => {
    setAutomationUuid(automationId);
    navigate(`/admin/automations/builder/${automationId}`);
  };

  const handleCreateNewFlow = (name, description, loyaltyProgramId) => {
    if (isViewer) return;

    setFlowName(name);
    setFlowDescription(description);
    const automationInfo = {
      name,
      description,
      loyalty_program_uuid: loyaltyProgramId,
    };
    createLoyaltyAutomation(automationInfo)
      .then((response) => {
        setAutomationUuid(response.uuid);
        navigate(`/admin/automations/builder/${response.uuid}`);
      })
      .catch((error) => {
        console.error('Error creating automation:', error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAutomations();
        const updatedAutomations = response.map((automation) => {
          const intervalDays = automation.automation_interval;
          const nextRunDate = calculateNextRunDate(
            automation.last_run,
            intervalDays,
          );
          return {
            ...automation,
            next_run_date: nextRunDate,
          };
        });
        setAutomations(updatedAutomations);
      } catch (error) {
        console.error('Error fetching automations:', error);
      }
    };

    fetchData();
  }, []);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          color="gray.400"
          textAlign="center"
        >
          NAME
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: () => (
        <Text justifyContent="center" align="center" color="gray.400">
          DESCRIPTION
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('is_active', {
      id: 'is_active',
      header: () => (
        <Text justifyContent="center" align="center" color="gray.400">
          ACTIVITY STATUS
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue() ? 'On' : 'Off'}
        </Text>
      ),
    }),
    columnHelper.accessor('next_run_date', {
      id: 'next_run_date',
      header: () => (
        <Text justifyContent="center" align="center" color="gray.400">
          NEXT RUN DATE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    /* columnHelper.accessor('start_date', {
      id: 'start_date',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          color="gray.400"
        >
          START DATE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }), */
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text justifyContent="center" align="center" color="gray.400">
          CREATED AT
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => (
        <Text justifyContent="center" align="center" color="gray.400">
          ACTIONS
        </Text>
      ),
      cell: (info) => {
        // Create URL for automation builder
        const automationUrl = `/admin/automations/builder/${info.row.original.uuid}`;

        return (
          <Flex justify="center" align="center" width="100%">
            <Box
              as="a"
              href={automationUrl}
              onClick={(e) => {
                if (e.button === 0) {
                  e.preventDefault();
                  handleViewDetails(info.row.original.uuid);
                }
              }}
              display="inline-block"
            >
              <Icon
                as={isViewer ? MdVisibility : MdEdit}
                w="16px"
                h="16px"
                cursor="pointer"
                color={brandColor}
              />
            </Box>
          </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: automations,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Box>
      <Flex direction="column" pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <AutomationTemplates />
        <Card
          w="100%"
          px={{ base: '16px', md: '24px' }}
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
          mt={4}
        >
          <Flex
            align={{ sm: 'center', lg: 'center' }}
            justify={{ sm: 'center', lg: 'center' }}
            mb="36px"
            mt="24px"
          >
            <DebouncedInput
              value={globalFilter ?? ''}
              onChange={(value) => setGlobalFilter(String(value))}
              className="p-2 font-lg shadow border border-block"
            />
            {!isViewer && (
              <Button
                ml="auto"
                colorScheme="blue"
                onClick={() => setIsModalOpen(true)}
                leftIcon={<AddIcon />}
              >
                New Flow
              </Button>
            )}
          </Flex>

          {!isViewer && (
            <CreateAutomationModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              createFlow={handleCreateNewFlow}
            />
          )}
          <Table variant="simple" color="gray.500">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      borderColor={borderColor}
                      key={header.id}
                      colSpan={header.colSpan}
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {header.isPlaceholder ? null : (
                        <Flex
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                          justify="center"
                          align="center"
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted()] ?? null}
                        </Flex>
                      )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr px="20px" key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Tooltip
                      label={cell.getValue()}
                      aria-label="Full text"
                      borderRadius="md"
                      key={cell.id}
                      bg="gray.600"
                    >
                      <Td
                        key={cell.id}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        maxW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor={borderColor}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        textAlign="center"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Td>
                    </Tooltip>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Flex
            w="100%"
            justify="space-between"
            align="center"
            px="20px"
            py="24px"
          >
            <Text fontSize="sm" color="gray.500" fontWeight="normal">
              Showing {pagination.pageSize * pagination.pageIndex + 1} to{' '}
              {pagination.pageSize * (pagination.pageIndex + 1) <=
              automations.length
                ? pagination.pageSize * (pagination.pageIndex + 1)
                : automations.length}{' '}
              of {automations.length} entries
            </Text>
            <div className="flex items-center gap-2">
              <Stack direction="row" spacing="4px" ms="auto">
                <Button
                  variant="no-effects"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                  transition="all .5s ease"
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                  bg="transparent"
                  border="1px solid"
                  borderColor={useColorModeValue('gray.200', 'white')}
                  display={
                    pagination.pageSize === 5
                      ? 'none'
                      : table.getCanPreviousPage()
                        ? 'flex'
                        : 'none'
                  }
                  _hover={{
                    bg: 'whiteAlpha.100',
                    opacity: '0.7',
                  }}
                >
                  <Icon
                    as={MdChevronLeft}
                    w="16px"
                    h="16px"
                    color={textColor}
                  />
                </Button>
                {createPages(table.getPageCount()).map((pageNumber, index) => (
                  <Button
                    variant="no-effects"
                    transition="all .5s ease"
                    onClick={() => table.setPageIndex(pageNumber - 1)}
                    w="40px"
                    h="40px"
                    borderRadius="50%"
                    bg={
                      pageNumber === pagination.pageIndex + 1
                        ? brandColor
                        : 'transparent'
                    }
                    border={
                      pageNumber === pagination.pageIndex + 1
                        ? 'none'
                        : '1px solid lightgray'
                    }
                    _hover={
                      pageNumber === pagination.pageIndex + 1
                        ? {
                            opacity: '0.7',
                          }
                        : {
                            bg: 'whiteAlpha.100',
                          }
                    }
                    key={index}
                  >
                    <Text
                      fontSize="sm"
                      color={
                        pageNumber === pagination.pageIndex + 1
                          ? '#fff'
                          : textColor
                      }
                    >
                      {pageNumber}
                    </Text>
                  </Button>
                ))}
                <Button
                  variant="no-effects"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                  transition="all .5s ease"
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                  bg="transparent"
                  border="1px solid"
                  borderColor={useColorModeValue('gray.200', 'white')}
                  display={
                    pagination.pageSize === 5
                      ? 'none'
                      : table.getCanNextPage()
                        ? 'flex'
                        : 'none'
                  }
                  _hover={{
                    bg: 'whiteAlpha.100',
                    opacity: '0.7',
                  }}
                >
                  <Icon
                    as={MdChevronRight}
                    w="16px"
                    h="16px"
                    color={textColor}
                  />
                </Button>
              </Stack>
            </div>
          </Flex>
        </Card>
      </Flex>
    </Box>
  );
}
