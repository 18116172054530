import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Heading,
  Button,
  Flex,
  Spinner,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import {
  getProductAttributes,
  addProductAttribute,
  removeProductAttribute,
  getAttributes,
  getProductVariations,
  deleteProductVariation,
} from '../../api/attributesVariations';
import LinkedAttributesTags from './LinkedAttributesTags';
import Card from '../card/Card';
import { Select } from 'chakra-react-select';

const ProductAttributesSection = ({
  productUuid,
  onLinkedAttributesChange,
  onVariationsChange,
  isViewer = false,
}) => {
  const toast = useToast();
  const [productAttributes, setProductAttributes] = useState([]);
  const [allAttributes, setAllAttributes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  const {
    isOpen: isRemoveConfirmOpen,
    onOpen: onRemoveConfirmOpen,
    onClose: onRemoveConfirmClose,
  } = useDisclosure();
  const [attributeToRemove, setAttributeToRemove] = useState(null);
  const cancelRef = useRef();

  const fetchProductAttributes = async () => {
    try {
      setLoading(true);
      const data = await getProductAttributes(productUuid);
      setProductAttributes(data);
    } catch (error) {
      toast({
        title: 'Error fetching attributes',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchAllAttributes = async () => {
    try {
      const data = await getAttributes();
      setAllAttributes(data);
    } catch (error) {
      console.error('Error fetching attributes:', error);
      toast({
        title: 'Error fetching all attributes',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchAllAttributes();

    if (productUuid) {
      fetchProductAttributes();
    }
  }, [productUuid]);

  const linkedAttributeIds = productAttributes.map((attr) => attr.id);
  const availableAttributesToLink = allAttributes.filter(
    (attr) => !linkedAttributeIds.includes(attr.id),
  );
  const options = availableAttributesToLink.map((attr) => ({
    value: attr.id,
    label:
      attr.translations?.find((tr) => tr.language === 'en')?.value || attr.slug,
  }));

  const handleAddAttributes = async () => {
    if (isViewer) {
      toast({
        title: 'Permission Denied',
        description: 'You do not have permission to add attributes.',
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (!selectedAttributes || selectedAttributes.length === 0) {
      toast({
        title: 'No attributes selected',
        status: 'warning',
        isClosable: true,
      });
      return;
    }
    try {
      for (const attribute of selectedAttributes) {
        await addProductAttribute(productUuid, attribute.value);
      }
      toast({
        title: 'Attributes added',
        status: 'success',
        isClosable: true,
      });
      fetchProductAttributes();
      if (onLinkedAttributesChange) {
        onLinkedAttributesChange();
      }
      setSelectedAttributes([]);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to add attributes.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handleRemoveAttribute = (attributeId) => {
    if (isViewer) {
      toast({
        title: 'Permission Denied',
        description: 'You do not have permission to remove attributes.',
        status: 'error',
        isClosable: true,
      });
      return;
    }
    setAttributeToRemove(attributeId);
    onRemoveConfirmOpen();
  };

  const confirmRemoveAttribute = async () => {
    try {
      await removeProductAttribute(productUuid, attributeToRemove);

      const removedAttribute = productAttributes.find(
        (attr) => attr.id === attributeToRemove,
      );
      if (removedAttribute) {
        const attributeKey = (
          removedAttribute.translations?.find((tr) => tr.language === 'en')
            ?.value || removedAttribute.slug
        ).toLowerCase();

        const variations = await getProductVariations({
          product_uuid: productUuid,
        });

        const variationsToDelete = variations.filter(
          (variation) =>
            variation.attribute_values_json &&
            Object.keys(variation.attribute_values_json).includes(attributeKey),
        );

        for (const variation of variationsToDelete) {
          await deleteProductVariation(variation.uuid);
        }
      }

      toast({
        title: 'Attribute and associated variations removed',
        status: 'success',
        isClosable: true,
      });
      fetchProductAttributes();
      if (onLinkedAttributesChange) {
        onLinkedAttributesChange();
      }
      if (onVariationsChange) {
        onVariationsChange();
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to remove attribute and associated variations.',
        status: 'error',
        isClosable: true,
      });
    } finally {
      onRemoveConfirmClose();
      setAttributeToRemove(null);
    }
  };

  return (
    <Card mt="8" p="6">
      <Heading size="md" mb="4">
        Linked Product Attributes
      </Heading>

      {!productUuid && (
        <Alert status="warning" mb="4">
          <AlertIcon />
          You need to save the product first before adding attributes.
        </Alert>
      )}

      {isViewer && (
        <Alert status="info" mb="4">
          <AlertIcon />
          You are in view-only mode. Contact an administrator to manage
          attributes.
        </Alert>
      )}

      <Flex alignItems="center" gap="2" mb="4">
        <Box width="100%">
          <Select
            placeholder={
              productUuid
                ? 'Select attributes to add'
                : 'Save product first to add attributes'
            }
            value={selectedAttributes}
            onChange={setSelectedAttributes}
            options={options}
            isMulti
            isClearable
            closeMenuOnSelect={false}
            isDisabled={isViewer || !productUuid}
            menuPortalTarget={document.body}
            maxMenuHeight={300}
            menuPlacement="auto"
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </Box>
        <Flex direction="column" gap="2">
          <Button
            colorScheme="blue"
            onClick={handleAddAttributes}
            isDisabled={isViewer || !productUuid}
          >
            Add Attributes
          </Button>
        </Flex>
      </Flex>

      {loading ? (
        <Spinner />
      ) : productAttributes.length === 0 ? (
        <Box mb="4">No attributes linked yet.</Box>
      ) : (
        <LinkedAttributesTags
          attributes={productAttributes}
          onRemove={handleRemoveAttribute}
          isViewer={isViewer}
        />
      )}

      {/* Confirmation AlertDialog for attribute removal */}
      <AlertDialog
        isOpen={isRemoveConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={onRemoveConfirmClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove Attribute
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to remove this attribute from the product?
              This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onRemoveConfirmClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmRemoveAttribute} ml={3}>
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
};

export default ProductAttributesSection;
